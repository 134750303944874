import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setFilters, resetFilters} from 'reducers/filtersRegistersSlice';
import { resetPagination } from 'reducers/paginationRegistersSlice';
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box';  
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import TableSortingRegisterBook from "pages/registerBook/SortingTablesRegisterBook";
import RegisterBookEditOrCreate from "pages/registerBook/editOrNewRegisterBook/RegisterBookEditOrCreate";
import exportToExcel from "pages/registerBook/editOrNewRegisterBook/RegisterBookExportToExcel"

import PropTypes from 'prop-types'; 

const initialFiltersRegisterBook= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "isinput":true,
  "isoutput":false,
  "registerbookFrom":"",
  "registerbookTo":"",
  "preiddocSheetFrom":"",
  "preiddocSheetTo":"",
  "sufiddocSheetFrom":"",
  "sufiddocSheetTo":"",
  "numberDTFrom":"",
  "numberDTTo":"",
  "dateSheetFrom":null,
  "dateSheetTo":null,  
  "ori_tras_tradenameFrom":"",
  "ori_tras_tradenameTo":"",
  "opt_tras_tradenameFrom":"",
  "opt_tras_tradenameTo":"",
  "codelerFrom":"",
  "codelerTo":"",
  "rel_trans_tradenameFrom":"",
  "rel_trans_tradenameTo":"",
  "dest_tras_tradenameFrom":"",
  "dest_tras_tradenameTo":"",
  "codetreatmentFrom":"",
  "codetreatmentTo":"",
  "codedangerousnessFrom":"",
  "codedangerousnessTo":""
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": "",
  "name": ""
}];
 

const initialStateRegisterBook = [{
  "id":0,
  "registerbook_id":0,
  "defaultStore_id":0,
  "tradename":"",
  "useraccount_id":0,
  "username":"",
  "isinput":true,
  "isoutput":false,
  "formdi_id":0,
  "formdi_ownformdi":0,
  "preiddoc_sheet":"",
  "sufiddoc_sheet":0,
  "number_dt":"",
  "date_sheet":null,
  "ori_tras_tradename":"",
  "ori_tras_nima":"",
  "ori_tras_postalcode":"",
  "opt_tras_tradename":"",
  "opt_tras_nima":"",
  "amount":0,
  "codeler":"",
  "namecodeler":"",
  "rel_trans_tradename":"",
  "rel_trans_nima":"",
  "dest_tras_tradename":"",
  "dest_tras_nima":"",
  "dest_tras_inscription":"",
  "dest_tras_postalcode":"",
  "codetreatment":"",
  "nametreatment":"",
  "codedangerousness":"",
  "namedangerousness":"",
  "column1":"",
  "column2":""
}];  

const FormRegisterBook = ({ showModalRegisterBookSettings,valueTabs, setValueTabs}) => {
  const {t,i18n} = useTranslation(['listDataRegisterBook']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);  
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const FILETOUPLOAD = useSelector((state) => state.formatDIUser.filenameexcel); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code);  
  const DELETE_CODE_FORMDI = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataRegisterBook, setDataRegisterBook] = useState(initialStateRegisterBook);
  const [formRegisterBook, setFormRegisterBook] = useState(initialStateRegisterBook[0]);
  const filtersRegistersDetails = useSelector((state) => state.filtersRegisters);
  const [filtersRegisterBook, setFiltersRegisterBook]= useState(filtersRegistersDetails);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegRegisterBook,setNumTotRegRegisterBook]= useState(0);
  const [errorsFiltersRegisterBook, setErrorsFiltersRegisterBook] = useState({});
  const [errorsRegisterBook, setErrorsRegisterBook] = useState({});
  const [inputTextRegisterBook, setInputTextRegisterBook] = useState(""); 
  const [openCreateRegisterBook, setOpenCreateRegisterBook] = useState(false);
  const [openDeleteRowRegisterBook,setOpenDeleteRowRegisterBook]= useState(false); 
  const [openEditRowRegisterBook,setOpenEditRowRegisterBook]= useState(false);
  const [openEditFilterRegisterBook,setOpenEditFilterRegisterBook]= useState(false);
  const [rowEditRegisterBook,setRowEditRegisterBook]= useState(initialStateRegisterBook[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorRegisterBook, setIsLoadingErrorRegisterBook] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE); 
  const [showModalSearchDI, setShowModalSearchDI] = useState(false); 
  const [showModalSearchCodeLERS, setShowModalSearchCodeLERS] = useState(false); 
  const [showModalSearchDangerousness, setShowModalSearchDangerousness] = useState(false); 
  const [showModalSearchTreatmentType, setShowModalSearchTreatmentType] = useState(false); 
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [selectStores, setSelectStores] = useState("Opt_Tras"); 
  const dispatch = useDispatch();
  const [activeOffsetRegisterBook, setActiveOffsetRegisterBook]= useState(false);
  const [currencyOffsetRegisterBook, setCurrencyOffsetRegisterBook] = useState(0);
  const [rangeOffsetRegisterBook, setRangeOffsetRegisterBook] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect); 
  const [refeshData,setRefeshData]=useState(false);
 
  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalRegisterBookSettings) {
        setDataRegisterBook(initialStateRegisterBook);
        setFormRegisterBook(initialStateRegisterBook[0]);   
        setNumTotRegFilter(0);
        setNumTotRegRegisterBook(0);
        setErrorsFiltersRegisterBook({});
        setErrorsRegisterBook({});
        setInputTextRegisterBook("");
        setOpenDeleteRowRegisterBook(false); 
        setOpenEditRowRegisterBook(false);
        setOpenEditFilterRegisterBook(false);
        setRowEditRegisterBook(initialStateRegisterBook[0]);
        setIsLoadingErrorRegisterBook(false);
        setOpenCreateRegisterBook(false);
        setShowModalSearchDI(false);
        setShowModalSearchCodeLERS(false);
        setShowModalSearchDangerousness(false);
        setShowModalSearchTreatmentType(false);
        setShowModalSearchStores(false);
        setSelectStores("Opt_Tras");
        setIdCodeDelete(DELETE_CODE);
        setRefeshData(false);
        setActiveOffsetRegisterBook(false);
        setCurrencyOffsetRegisterBook(0);
        setRangeOffsetRegisterBook([{}]); 
        setStoresToSelect(initialStoresToSelect); 
        dispatch(setLoading(false));
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFiltersRegisterBook(initialFiltersRegisterBook);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataRegisterBook,
      setFormRegisterBook,
      setFiltersRegisterBook,
      setNumTotRegFilter,
      setNumTotRegRegisterBook,
      setErrorsFiltersRegisterBook,
      setErrorsRegisterBook,
      setInputTextRegisterBook,
      setOpenCreateRegisterBook,
      setOpenDeleteRowRegisterBook, 
      setOpenEditRowRegisterBook,
      setOpenEditFilterRegisterBook,
      setRowEditRegisterBook, 
      setShowModalSearchDI,
      setShowModalSearchCodeLERS,
      setShowModalSearchDangerousness,
      setShowModalSearchTreatmentType,
      setShowModalSearchStores,
      setSelectStores,
      setIsLoadingErrorRegisterBook,
      setIdCodeDelete, 
      setRefeshData,
      setActiveOffsetRegisterBook,
      setCurrencyOffsetRegisterBook,
      setRangeOffsetRegisterBook,
      setStoresToSelect, 
      showModalRegisterBookSettings,  
      navigate,
      dispatch,
      DELETE_CODE]
  );

  const closeModalRegisterBook = () => {    
    setDataRegisterBook(initialStateRegisterBook);
    setFormRegisterBook(initialStateRegisterBook[0]); 
    setNumTotRegFilter(0);
    setNumTotRegRegisterBook(0);
    setErrorsFiltersRegisterBook({});
    setErrorsRegisterBook({});
    setInputTextRegisterBook("");
    setOpenCreateRegisterBook(false);
    setOpenDeleteRowRegisterBook(false); 
    setOpenEditRowRegisterBook(false);
    setOpenEditFilterRegisterBook(false);
    setShowModalSearchDI(false);
    setShowModalSearchCodeLERS(false);
    setShowModalSearchDangerousness(false);
    setShowModalSearchTreatmentType(false);
    setShowModalSearchStores(false);
    setSelectStores("Opt_Tras");
    setRowEditRegisterBook(initialStateRegisterBook[0]);
    setIsLoadingErrorRegisterBook(false);
    setIdCodeDelete(DELETE_CODE); 
    setRefeshData(false);
    setActiveOffsetRegisterBook(false);
    setCurrencyOffsetRegisterBook(0);
    setRangeOffsetRegisterBook([{}]);
    setStoresToSelect(initialStoresToSelect); 
    dispatch(setLoading(false));
    dispatch(resetPagination());
    dispatch(resetFilters());
    setFiltersRegisterBook(initialFiltersRegisterBook);
    navigate("/home");
    return () => {};
  }; 

  const openCreateOrEditDataRegisterBook = () => {
    setInputTextRegisterBook("");
    setFormRegisterBook(formRegisterBook => ({
      ...formRegisterBook,
      id:0,
      registerbook_id:0,
      defaultStore_id:defaultStore.id,
      tradename:"",
      useraccount_id:useraccount_id,
      username:"",
      isinput: valueTabs==='sheetin'?true:false,
      isoutput: valueTabs==='sheetout'?true:false,
      formdi_id:0,
      formdi_ownformdi:0,
      preiddoc_sheet:"",
      sufiddoc_sheet:0,
      number_dt:"",
      date_sheet:null,
      ori_tras_tradename:"",
      ori_tras_nima:"",
      ori_tras_postalcode:"",
      opt_tras_tradename:"",
      opt_tras_nima:"",
      amount:0,
      codeler:"",
      namecodeler:"",
      rel_trans_tradename:"",
      rel_trans_nima:"",
      dest_tras_tradename:"",
      dest_tras_nima:"",
      dest_tras_inscription:"",
      dest_tras_postalcode:"",
      codetreatment:"",
      nametreatment:"",
      codedangerousness:"",
      namedangerousness:"",
      column1:"",
      column2:""  
    }));
    setOpenCreateRegisterBook(true);
  };

  const openEditFiltersRegisterBook = () => { 
      setOpenEditFilterRegisterBook(!openEditFilterRegisterBook);
  };

  const handleSubmitFilterEditRegisterBook = () => {
    setErrorsFiltersRegisterBook(validateFormFilterRegisterBook());
    if (Object.keys(errorsFiltersRegisterBook).length === 0) {
      setErrorsFiltersRegisterBook({});
      dispatch(resetPagination());
      dispatch(setFilters(filtersRegisterBook)); 
      setOpenEditFilterRegisterBook(false); 
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersRegisterBook = () => {
    setErrorsFiltersRegisterBook({});
    dispatch(resetFilters());
    dispatch(resetPagination());
    setFiltersRegisterBook(initialFiltersRegisterBook);  
    setOpenEditFilterRegisterBook(!openEditFilterRegisterBook);
  };

  const handleCancelFiltersRegisterBook = () => {
    setErrorsFiltersRegisterBook({}); 
    setOpenEditFilterRegisterBook(!openEditFilterRegisterBook);
  };
 
  const handleChangeSelectDateSheetFrom = (event) => {
    setFiltersRegisterBook(filtersRegisterBook => ({
      ...filtersRegisterBook,
      dateSheetFrom: event!==null?new Date(event):null,
      dateSheetTo:null
    }));
  };
 
  const handleBlurSelectDateSheetFrom = (e) => {
    handleChangeSelectDateSheetFrom(e);
    setErrorsFiltersRegisterBook(validateFormFilterRegisterBook());
  };

  const handleChangeSelectDateSheetTo = (event) => {
    setFiltersRegisterBook(filtersRegisterBook => ({
      ...filtersRegisterBook,
      dateSheetTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateSheetTo = (e) => {
    handleChangeSelectDateSheetTo(e);
    setErrorsFiltersRegisterBook(validateFormFilterRegisterBook());
  };
   

  const handleChangeFilterEditRegisterBook = (e) => {
    const { name, value } = e.target;
    setFiltersRegisterBook(filtersRegisterBook => ({
      ...filtersRegisterBook,
      [name]: value
    }));
  };

  const handleBlurFilterEditRegisterBook = (e) => {
    setErrorsFiltersRegisterBook(validateFormFilterRegisterBook());
    handleChangeFilterEditRegisterBook(e);
  };

  const toggleDrawerFilterEditRegisterBook = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditRegisterBook();
    }
  };

  const handleExport = () => {
    const fetchDataRegisterBook = async () => {
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Register_Book_Store_Master')))
        ? await helpHttp().post6(ENDPOINT,`register/geteegsiterbook`,token,currencyOffsetRegisterBook,LIMITPAGESIZE,"-1","-1","sheetbooth",filtersRegistersDetails)
        : await helpHttp().post6(ENDPOINT,`register/geteegsiterbook`,token,currencyOffsetRegisterBook,LIMITPAGESIZE,defaultStore.id,"-1","sheetbooth",filtersRegistersDetails);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (getData && getData.length>0) {
          exportToExcel(getData, FILETOUPLOAD!==undefined && FILETOUPLOAD!==""?FILETOUPLOAD:"LibroRegistro"); 
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (dataRegisterBook && dataRegisterBook.length>0){
      fetchDataRegisterBook();
    } 
  };
  
  const validateFormFilterRegisterBook = () => {

    let errorsFiltersRegisterBook = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersRegisterBook.idFrom!=="" & !regexNumber.test(filtersRegisterBook.idFrom)) {
      errorsFiltersRegisterBook.idFrom = 'Text_TextNoNumber';
    }
    if (filtersRegisterBook.idTo!=="" & !regexNumber.test(filtersRegisterBook.idTo)) {
      errorsFiltersRegisterBook.idTo = 'Text_TextNoNumber';
    }
    if (filtersRegisterBook.idFrom!=="" & !errorsFiltersRegisterBook.idFrom!==undefined){
      if (filtersRegisterBook.idTo!=="" & !errorsFiltersRegisterBook.idTo!==undefined){
        if (parseInt(filtersRegisterBook.idTo)<parseInt(filtersRegisterBook.idFrom)){
          errorsFiltersRegisterBook.idTo = 'Text_TextGreater_Id';
        }
      }
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.registerbookFrom)) {
      errorsFiltersRegisterBook.registerbookFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.registerbookTo)) {
      errorsFiltersRegisterBook.registerbookTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.preiddocSheetFrom)) {
      errorsFiltersRegisterBook.preiddocSheetFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.preiddocSheetTo)) {
      errorsFiltersRegisterBook.preiddocSheetTo = 'Text_TextNoValid';
    } 
    if (filtersRegisterBook.sufiddocSheetFrom!=="" & !regexNumber.test(filtersRegisterBook.sufiddocSheetFrom)) {
      errorsFiltersRegisterBook.sufiddocSheetFrom = 'Text_TextNoNumber';
    }
    if (filtersRegisterBook.sufiddocSheetTo!=="" & !regexNumber.test(filtersRegisterBook.sufiddocSheetTo)) {
      errorsFiltersRegisterBook.sufiddocSheetTo = 'Text_TextNoNumber';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.numberDTFrom)) {
      errorsFiltersRegisterBook.numberDTFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.numberDTTo)) {
      errorsFiltersRegisterBook.numberDTTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.ori_tras_tradenameFrom)) {
      errorsFiltersRegisterBook.ori_tras_tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.ori_tras_tradenameTo)) {
      errorsFiltersRegisterBook.ori_tras_tradenameTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.opt_tras_tradenameFrom)) {
      errorsFiltersRegisterBook.opt_tras_tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.opt_tras_tradenameTo)) {
      errorsFiltersRegisterBook.opt_tras_tradenameTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.codelerFrom)) {
      errorsFiltersRegisterBook.codelerFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.codelerTo)) {
      errorsFiltersRegisterBook.codelerTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.rel_trans_tradenameFrom)) {
      errorsFiltersRegisterBook.rel_trans_tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.rel_trans_tradenameTo)) {
      errorsFiltersRegisterBook.rel_trans_tradenameTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.dest_tras_tradenameFrom)) {
      errorsFiltersRegisterBook.dest_tras_tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.dest_tras_tradenameTo)) {
      errorsFiltersRegisterBook.dest_tras_tradenameTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.codetreatmentFrom)) {
      errorsFiltersRegisterBook.codetreatmentFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.codetreatmentTo)) {
      errorsFiltersRegisterBook.codetreatmentTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filtersRegisterBook.codedangerousnessFrom)) {
      errorsFiltersRegisterBook.codedangerousnessFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersRegisterBook.codedangerousnessTo)) {
      errorsFiltersRegisterBook.codedangerousnessTo = 'Text_TextNoValid';
    } 
    if (filtersRegisterBook.defaultStoreIdTo!=="" & filtersRegisterBook.defaultStoreIdTo<filtersRegisterBook.defaultStoreIdFrom){
      errorsFiltersRegisterBook.defaultStoreIdTo = 'Text_TextGreater_Store';
    }
    if (filtersRegisterBook.useraccountIdTo!=="" & filtersRegisterBook.useraccountIdTo<filtersRegisterBook.useraccountIdFrom){
      errorsFiltersRegisterBook.useraccountIdTo = 'Text_TextGreater_UserAccount';
    } 
    if (filtersRegisterBook.registerbookTo!=="" & filtersRegisterBook.registerbookTo<filtersRegisterBook.registerbookFrom){
      errorsFiltersRegisterBook.registerbookTo = 'Text_TextGreater_FormDI';
    }
    if (filtersRegisterBook.preiddocSheetTo!=="" & filtersRegisterBook.preiddocSheetTo<filtersRegisterBook.preiddocSheetFrom){
      errorsFiltersRegisterBook.preiddocSheetTo = 'Text_TextGreater_PreiddocSheet';
    }
    if (filtersRegisterBook.sufiddocSheetTo!=="" & filtersRegisterBook.sufiddocSheetTo<filtersRegisterBook.sufiddocSheetFrom){
      errorsFiltersRegisterBook.sufiddocSheetTo = 'Text_TextGreater_SufiddocSheet';
    }
    if (filtersRegisterBook.numberDTTo!=="" & filtersRegisterBook.numberDTTo<filtersRegisterBook.numberDTFrom){
      errorsFiltersRegisterBook.numberDTTo = 'Text_TextGreater_numberDTTo';
    }
    if (filtersRegisterBook.ori_tras_tradenameTo!=="" & filtersRegisterBook.ori_tras_tradenameTo<filtersRegisterBook.ori_tras_tradenameFrom){
      errorsFiltersRegisterBook.ori_tras_tradenameTo = 'Text_TextGreaterOri_Tras_Tradename';
    }
    if (filtersRegisterBook.opt_tras_tradenameTo!=="" & filtersRegisterBook.opt_tras_tradenameTo<filtersRegisterBook.opt_tras_tradenameFrom){
      errorsFiltersRegisterBook.opt_tras_tradenameTo = 'Text_TextGreaterOpt_Tras_Tradename';
    }
    if (filtersRegisterBook.codelerTo!=="" & filtersRegisterBook.codelerTo<filtersRegisterBook.codelerFrom){
      errorsFiltersRegisterBook.codelerTo = 'Text_TextGreater_Codeler';
    }
    if (filtersRegisterBook.rel_trans_tradenameTo!=="" & filtersRegisterBook.rel_trans_tradenameTo<filtersRegisterBook.rel_trans_tradenameFrom){
      errorsFiltersRegisterBook.rel_trans_tradenameTo = 'Text_TextGreaterRel_Trans_Tradename';
    }
    if (filtersRegisterBook.dest_tras_tradenameTo!=="" & filtersRegisterBook.dest_tras_tradenameTo<filtersRegisterBook.dest_tras_tradenameFrom){
      errorsFiltersRegisterBook.dest_tras_tradenameTo = 'Text_TextGreaterDest_Tras_Tradename';
    }
    if (filtersRegisterBook.codetreatmentTo!=="" & filtersRegisterBook.codetreatmentTo<filtersRegisterBook.codetreatmentFrom){
      errorsFiltersRegisterBook.codetreatmentTo = 'Text_TextGreater_CodeTreatment';
    }
    if (filtersRegisterBook.codedangerousnessTo!=="" & filtersRegisterBook.codedangerousnessTo<filtersRegisterBook.codedangerousnessFrom){
      errorsFiltersRegisterBook.codedangerousnessTo = 'Text_TextGreater_CodeDangerousness';
    }
    return errorsFiltersRegisterBook;
  }  

  const filteredDataRegisterBook = dataRegisterBook.filter((el) => {
    if (inputTextRegisterBook === '') {
        return el;
    }
    else {
      if(valueTabs==='sheetin'){
        let dataTranslate = 
          String(el.registerbook_id || '') +
          String(el.formdi_ownformdi || '') +
          String(el.preiddoc_sheet || '') +
          String(el.sufiddoc_sheet || '') +
          String(el.number_dt || '') +
          String(el.ori_tras_tradename || '') +
          String(el.ori_tras_nima || '') +
          String(el.opt_tras_tradename || '') + 
          String(el.opt_tras_nima || '') +
          String(el.codeler || '') +
          String(el.rel_trans_tradename || '') +
          String(el.rel_trans_nima || '');
        let result = dataTranslate.toLowerCase().includes(inputTextRegisterBook.toLowerCase());
        return result;
      }else{
        let dataTranslate = 
          String(el.registerbook_id || '') +
          String(el.formdi_ownformdi || '') +
          String(el.preiddoc_sheet || '') +
          String(el.sufiddoc_sheet || '') +
          String(el.number_dt || '') +
          String(el.codeler || '') +
          String(el.dest_tras_tradename || '') +
          String(el.dest_tras_nima || '') +
          String(el.dest_tras_inscription || '') + 
          String(el.rel_trans_nima || '') +
          String(el.codetreatment || '') +
          String(el.codedangerousness || '');
        let result = dataTranslate.toLowerCase().includes(inputTextRegisterBook.toLowerCase());
        return result;
      } 
    }
  })

  let inputHandlerRegisterBook = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextRegisterBook(lowerCase);
  };

  const handleChangeDeleteRowRegisterBook = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowRegisterBook = () => {
    setRowEditRegisterBook(initialStateRegisterBook[0]);
    setIsLoadingErrorRegisterBook(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowRegisterBook(false);
  };

  const handleChangeValueTabs = (event,newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const handleCloseDeleteRowRegisterBook = (e) => {
    if (idCodeDelete !== DELETE_CODE_FORMDI) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditRegisterBook.id!=='') {      
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT,`register/deleteregisterbook`,token,rowEditRegisterBook);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
                setRefeshData(true);
                handleCloseDataEditRegisterBook(); 
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          } 
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowRegisterBook(false);
    setIsLoadingErrorRegisterBook(false);
    handleCloseDataEditRegisterBook(); 
  };

  const handleCloseDataEditRegisterBook = () => { 
    setOpenEditRowRegisterBook(false);
    setOpenCreateRegisterBook(false);
    setRowEditRegisterBook(openEditRowRegisterBook?rowEditRegisterBook:initialStateRegisterBook[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorRegisterBook(false);
    toggleDrawerFilterEditRegisterBook(false);   
    setShowModalSearchDI(false);
    setShowModalSearchCodeLERS(false);
    setShowModalSearchDangerousness(false);
    setShowModalSearchTreatmentType(false);
    setShowModalSearchStores(false);
    setRefeshData(false);
	};
 
  useEffect(() => {
    if (numTotRegRegisterBook > Number(LIMITPAGESIZE)) {
      setActiveOffsetRegisterBook(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegRegisterBook; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetRegisterBook(newRangeOffset);
    } else {
      setCurrencyOffsetRegisterBook(0);
      setActiveOffsetRegisterBook(false);
      setRangeOffsetRegisterBook([{}]);
    }
  }, [numTotRegRegisterBook, LIMITPAGESIZE]);
  
  useEffect(() => {
    if (showModalRegisterBookSettings){ 
      if( rowEditRegisterBook.id!==undefined && rowEditRegisterBook.id!==null && rowEditRegisterBook.id!=="" && rowEditRegisterBook.id!==0){
        setFormRegisterBook(formRegisterBook => ({
          ...formRegisterBook,
          id : rowEditRegisterBook.id!==null?rowEditRegisterBook.id:0,
          registerbook_id : rowEditRegisterBook.registerbook_id!==null?rowEditRegisterBook.registerbook_id:0,
          defaultStore_id : rowEditRegisterBook.defaultStore_id!==null?rowEditRegisterBook.defaultStore_id:defaultStore.id,
          tradename :rowEditRegisterBook.tradename!==null?rowEditRegisterBook.tradename:"",
          useraccount_id :rowEditRegisterBook.useraccount_id!==null?rowEditRegisterBook.useraccount_id:useraccount_id,
          username :rowEditRegisterBook.username!==null?rowEditRegisterBook.username:"",
          isinput :rowEditRegisterBook.isinput!==null?rowEditRegisterBook.isinput:true,
          isoutput :rowEditRegisterBook.isoutput!==null?rowEditRegisterBook.isoutput:false,
          formdi_id :rowEditRegisterBook.formdi_id!==null?rowEditRegisterBook.formdi_id:0,
          formdi_ownformdi:rowEditRegisterBook.formdi_ownformdi!==null?rowEditRegisterBook.formdi_ownformdi:0,
          preiddoc_sheet :rowEditRegisterBook.preiddoc_sheet!==null?rowEditRegisterBook.preiddoc_sheet:"",
          sufiddoc_sheet :rowEditRegisterBook.sufiddoc_sheet!==null?rowEditRegisterBook.sufiddoc_sheet:0,
          number_dt :rowEditRegisterBook.number_dt!==null?rowEditRegisterBook.number_dt:"",
          date_sheet :rowEditRegisterBook.date_sheet!==null?rowEditRegisterBook.date_sheet:null,
          ori_tras_tradename :rowEditRegisterBook.ori_tras_tradename!==null?rowEditRegisterBook.ori_tras_tradename:"",
          ori_tras_nima :rowEditRegisterBook.ori_tras_nima!==null?rowEditRegisterBook.ori_tras_nima:"",
          ori_tras_postalcode :rowEditRegisterBook.ori_tras_postalcode!==null?rowEditRegisterBook.ori_tras_postalcode:"",
          opt_tras_tradename :rowEditRegisterBook.opt_tras_tradename!==null?rowEditRegisterBook.opt_tras_tradename:"",
          opt_tras_nima :rowEditRegisterBook.opt_tras_nima!==null?rowEditRegisterBook.opt_tras_nima:"",
          amount : rowEditRegisterBook.amount!==null?rowEditRegisterBook.amount:0,
          codeler :rowEditRegisterBook.codeler!==null?rowEditRegisterBook.codeler:"",
          namecodeler :rowEditRegisterBook.namecodeler!==null?rowEditRegisterBook.namecodeler:"",
          rel_trans_tradename :rowEditRegisterBook.rel_trans_tradename!==null?rowEditRegisterBook.rel_trans_tradename:"",
          rel_trans_nima :rowEditRegisterBook.rel_trans_nima!==null?rowEditRegisterBook.rel_trans_nima:"",
          dest_tras_tradename :rowEditRegisterBook.dest_tras_tradename!==null?rowEditRegisterBook.dest_tras_tradename:"",
          dest_tras_nima :rowEditRegisterBook.dest_tras_nima!==null?rowEditRegisterBook.dest_tras_nima:"",
          dest_tras_inscription :rowEditRegisterBook.dest_tras_inscription!==null?rowEditRegisterBook.dest_tras_inscription:"",
          dest_tras_postalcode :rowEditRegisterBook.dest_tras_postalcode!==null?rowEditRegisterBook.dest_tras_postalcode:"",
          codetreatment :rowEditRegisterBook.codetreatment!==null?rowEditRegisterBook.codetreatment:"",
          nametreatment :rowEditRegisterBook.nametreatment!==null?rowEditRegisterBook.nametreatment:"",
          codedangerousness :rowEditRegisterBook.codedangerousness!==null?rowEditRegisterBook.codedangerousness:"",
          namedangerousness :rowEditRegisterBook.namedangerousness!==null?rowEditRegisterBook.namedangerousness:"",
          column1 :rowEditRegisterBook.column1!==null?rowEditRegisterBook.column1:"",
          column2 :rowEditRegisterBook.column2!==null?rowEditRegisterBook.column2:""       
        }));
      }else{
        setFormRegisterBook(initialStateRegisterBook[0]);
        setFormRegisterBook(formRegisterBook => ({
          ...formRegisterBook,
          id:0,
          registerbook_id:0,
          defaultStore_id:defaultStore.id,
          tradename:"",
          useraccount_id:useraccount_id,
          username:"",
          isinput: valueTabs==='sheetin'?true:false,
          isoutput: valueTabs==='sheetout'?true:false,
          formdi_id:0,
          formdi_ownformdi:0,
          preiddoc_sheet:"",
          sufiddoc_sheet:0,
          number_dt:"",
          date_sheet:null,
          ori_tras_tradename:"",
          ori_tras_nima:"",
          ori_tras_postalcode:"",
          opt_tras_tradename:"",
          opt_tras_nima:"",
          amount:0,
          codeler:"",
          namecodeler:"",
          rel_trans_tradename:"",
          rel_trans_nima:"",
          dest_tras_tradename:"",
          dest_tras_nima:"",
          dest_tras_inscription:"",
          dest_tras_postalcode:"",
          codetreatment:"",
          nametreatment:"",
          codedangerousness:"",
          namedangerousness:"",
          column1:"",
          column2:""  
        }));
      }
    }
  },[rowEditRegisterBook,showModalRegisterBookSettings,valueTabs,defaultStore.id,useraccount_id]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegRegisterBook = async () => {
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Register_Book_Store_Master')))
        ? await helpHttp().get2(ENDPOINT,`register/getinformationtableregsiterbook/`,token,valueTabs) 
        :  await helpHttp().get7(ENDPOINT,`register/getinformationtableregsiterbookwithstoreanduseraccount`,token,defaultStore.id,useraccount_id,valueTabs);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataRegisterBook(initialStateRegisterBook);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegRegisterBook(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }   
    };

    const fetchnumTotRegFilter = async () => {
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Register_Book_Store_Master')))
        ? await helpHttp().post4(ENDPOINT,`register/getcountfilterregsiterbook`,token,"-1","-1",valueTabs,filtersRegistersDetails)
        : await helpHttp().post4(ENDPOINT,`register/getcountfilterregsiterbook`,token,defaultStore.id,useraccount_id,valueTabs,filtersRegistersDetails);
         if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataRegisterBook(initialStateRegisterBook);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    }; 
 
    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`register/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    const fetchDataRegisterBook = async () => {
      dispatch(setLoading(true));
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Register_Book_Store_Master')))
        ? await helpHttp().post6(ENDPOINT,`register/geteegsiterbook`,token,currencyOffsetRegisterBook,LIMITPAGESIZE,"-1","-1",valueTabs,filtersRegistersDetails)
        : await helpHttp().post6(ENDPOINT,`register/geteegsiterbook`,token,currencyOffsetRegisterBook,LIMITPAGESIZE,defaultStore.id,useraccount_id,valueTabs,filtersRegistersDetails);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataRegisterBook(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalRegisterBookSettings || refeshData){
      fetchnumTotRegRegisterBook();
      fetchnumTotRegFilter();
      fetchDataRegisterBook();
      setRefeshData(false);
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Register_Book_Store_Master'))){
        fetchDataStore();
      }  
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,valueTabs,LIMITPAGESIZE,filtersRegistersDetails,showModalRegisterBookSettings,currencyOffsetRegisterBook,defaultStore.id,useraccount_id ,openCreateRegisterBook, openDeleteRowRegisterBook,setOpenDeleteRowRegisterBook,setOpenEditRowRegisterBook,openEditRowRegisterBook,rowEditRegisterBook,defaultStore, refeshData]);

  return (
    <>
       {showModalRegisterBookSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={valueTabs==="sheetin"?"hANewStyle mb-2":"hBNewStyle mb-2"}>
            {isLoadingErrorRegisterBook?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowRegisterBook?"warning":"primary"}/>:<i className="fas fa-regular fa-clipboard text-warning"></i>} {valueTabs==="sheetin"?t('Text_RegisterBook_INSHEET'):t('Text_RegisterBook_OUTSHEET')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Register_Book')) ?
                <div>
                  <button type="button" className="buttonCreate" onClick={openCreateOrEditDataRegisterBook}>{t('Button_Create')}</button><> </>
                  <button type="button" className="buttonBack" onClick={closeModalRegisterBook}>{t('Button_Back')}</button><> </>
                </div>:
                <div>
                  <button type="button" className="buttonBack" onClick={closeModalRegisterBook}>{t('Button_Back')}</button><> </>
                </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextRegisterBook}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_RegisterBook_Search')} 
              onChange={inputHandlerRegisterBook}
              fullWidth
              label={t('Text_RegisterBook_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyleHigh mb-2">
            {t('Text_Filter')}&nbsp;&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.idFrom!=="" || filtersRegistersDetails.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Register_Book_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.defaultStoreIdFrom!=="" || filtersRegistersDetails.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Store')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.registerbookFrom!=="" || filtersRegistersDetails.registerbookTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_FORMDI')}</button><> </> 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.preiddocSheetFrom!=="" || filtersRegistersDetails.preiddocSheetTo!=="" || filtersRegistersDetails.sufiddocSheetFrom!=="" || filtersRegistersDetails.sufiddocSheetTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_NUMBER_DI')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.numberDTFrom!=="" || filtersRegistersDetails.numberDTTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_NumberNT')}</button><> </> 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.dateSheetFrom!==null || filtersRegistersDetails.dateSheetTo!==null )?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Date')}</button><> </> 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersRegisterBook}><i className="fas fa-solid fa-circle" style={{color:(filtersRegistersDetails.defaultformatnumberFrom 
                      || filtersRegistersDetails.registerbookFrom
                      || filtersRegistersDetails.registerbookTo
                      || filtersRegistersDetails.ori_tras_tradenameFrom
                      || filtersRegistersDetails.ori_tras_tradenameTo
                      || filtersRegistersDetails.opt_tras_tradenameFrom
                      || filtersRegistersDetails.opt_tras_tradenameTo
                      || filtersRegistersDetails.codelerFrom
                      || filtersRegistersDetails.codelerTo!=="" 
                      || filtersRegistersDetails.rel_trans_tradenameFrom!==""
                      || filtersRegistersDetails.rel_trans_tradenameTo!=="" 
                      || filtersRegistersDetails.dest_tras_tradenameFrom!==""
                      || filtersRegistersDetails.dest_tras_tradenameTo!==""
                      || filtersRegistersDetails.codetreatmentFrom!==""
                      || filtersRegistersDetails.codetreatmentTo!==""
                      || filtersRegistersDetails.codedangerousnessFrom!==""
                      || filtersRegistersDetails.codedangerousnessTo!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </> 
                <button type="button" className="buttonExcel" onClick={handleExport}>{t('Button_Excel')}</button><> </> 
                
            </h1>
          </div>
          <br/> 
          <div className="row p-2"> 
            <div className="form-group"> 
              <Box sx={{ width: '120%' }}>
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeValueTabs}
                  style={{color:"#4e73df",width:"100%"}}
                  indicatorColor="primary"
                  variant="fullWidth"
                  aria-label="secondary tabs example">
                  <Tab value="sheetin" label={t('Text_RegisterBook_INSHEET')}/>
                  <Tab value="sheetout" label={t('Text_RegisterBook_OUTSHEET')}/> 
                </Tabs>  
              </Box>
            </div>
          </div>

          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingRegisterBook
                      dataRegisterBook={filteredDataRegisterBook} 
                      numTotRegFilter={ numTotRegFilter}
                      titleTable={valueTabs==='sheetin'?t('Text_RegisterBook_INSHEET'):t('Text_RegisterBook_OUTSHEET')}
                      setOpenDeleteRowRegisterBook={setOpenDeleteRowRegisterBook}
                      setOpenEditRowRegisterBook={setOpenEditRowRegisterBook}
                      setRowEditRegisterBook={setRowEditRegisterBook}
                      showEditButtonRegisterBook={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_Edit'))?true:false}
                      showDeleteButtonRegisterBook={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_Delete'))?true:false}
                      numTotRegRegisterBook={numTotRegRegisterBook}
                      activeOffsetRegisterBook={activeOffsetRegisterBook}
                      rangeOffsetRegisterBook={rangeOffsetRegisterBook}
                      currencyOffsetRegisterBook={currencyOffsetRegisterBook}
                      setCurrencyOffsetRegisterBook={setCurrencyOffsetRegisterBook} 
                      valueTabs={valueTabs}
                    > 
                    </TableSortingRegisterBook>
                </div>             
              </div>
              
              {openEditFilterRegisterBook &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditRegisterBook(false)}
                 onOpen={toggleDrawerFilterEditRegisterBook(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfiltregisterbook" onSubmit={handleCancelFiltersRegisterBook}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                              id="idFrom" 
                              name="idFrom"
                              value={filtersRegisterBook.idFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.idFrom!=null?t(errorsFiltersRegisterBook.idFrom):t('Text_Filter_Code_From')}
                              placeholder={t('Placeholder_Filter_Code_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                              id="idTo" 
                              name="idTo"
                              value={filtersRegisterBook.idTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.idTo!=null?t(errorsFiltersRegisterBook.idTo):t('Text_Filter_Code_To')}
                              placeholder={t('Placeholder_Filter_Code_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                           </div>
                           {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Register_Book_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersRegisterBook.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsFiltersRegisterBook.defaultStoreIdFrom!=null?t(errorsFiltersRegisterBook.defaultStoreIdFrom):t('Text_Filter_Store_From')}
                                      placeholder={t('Placeholder_Filter_Store_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditRegisterBook}
                                      onChange={handleChangeFilterEditRegisterBook}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                             {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="defaultStoreIdTo"
                                        name="defaultStoreIdTo"
                                        value={filtersRegisterBook.defaultStoreIdTo}  
                                        inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        label={errorsFiltersRegisterBook.defaultStoreIdTo!=null?t(errorsFiltersRegisterBook.defaultStoreIdTo):t('Text_Filter_Store_To')}
                                        placeholder={t('Placeholder_Filter_Store_To')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditRegisterBook}
                                        onChange={handleChangeFilterEditRegisterBook}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {storesToSelect.filter(item=>(item.id>=filtersRegisterBook.defaultStoreIdFrom)).map(option => (
                                          <option key={option.id} value={option.id}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                             <div className="form-group">
                             <TextField
                              id="registerbookFrom" 
                              name="registerbookFrom"
                              value={filtersRegisterBook.registerbookFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.registerbookFrom!=null?t(errorsFiltersRegisterBook.registerbookFrom):t('Text_Filter_formdiFrom_From')}
                              placeholder={t('Placeholder_Filter_formdiFrom_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="registerbookTo" 
                              name="registerbookTo"
                              value={filtersRegisterBook.registerbookTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.registerbookTo!=null?t(errorsFiltersRegisterBook.registerbookTo):t('Text_Filter_formdiFrom_To')}
                              placeholder={t('Placeholder_Filter_formdiFrom_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                           </div>
                            
                           <div className="form-group">
                             <TextField
                              id="preiddocSheetFrom" 
                              name="preiddocSheetFrom"
                              value={filtersRegisterBook.preiddocSheetFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.preiddocSheetFrom!=null?t(errorsFiltersRegisterBook.preiddocSheetFrom):t('Text_Filter_PreiddocSheet_From')}
                              placeholder={t('Placeholder_Filter_PreiddocSheet_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="preiddocSheetTo" 
                              name="preiddocSheetTo"
                              value={filtersRegisterBook.preiddocSheetTo} 
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.preiddocSheetTo!=null?t(errorsFiltersRegisterBook.preiddocSheetTo):t('Text_Filter_PreiddocSheet_To')}
                              placeholder={t('Placeholder_Filter_PreiddocSheet_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="sufiddocSheetFrom" 
                              name="sufiddocSheetFrom"
                              value={filtersRegisterBook.sufiddocSheetFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.sufiddocSheetFrom!=null?t(errorsFiltersRegisterBook.sufiddocSheetFrom):t('Text_Filter_SufiddocSheet_From')}
                              placeholder={t('Placeholder_Filter_SufiddocSheet_From')}
                              variant="outlined" 
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="sufiddocSheetTo" 
                              name="sufiddocSheetTo"
                              value={filtersRegisterBook.sufiddocSheetTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.sufiddocSheetTo!=null?t(errorsFiltersRegisterBook.sufiddocSheetTo):t('Text_Filter_SufiddocSheet_To')}
                              placeholder={t('Placeholder_Filter_SufiddocSheet_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="numberDTFrom" 
                              name="numberDTFrom"
                              value={filtersRegisterBook.numberDTFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.numberDTFrom!=null?t(errorsFiltersRegisterBook.numberDTFrom):t('Text_Filter_NumberNT_From')}
                              placeholder={t('Placeholder_Filter_NumberNT_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="numberDTTo" 
                              name="numberDTTo"
                              value={filtersRegisterBook.numberDTTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.numberDTTo!=null?t(errorsFiltersRegisterBook.numberDTTo):t('Text_Filter_NumberNT_To')}
                              placeholder={t('Placeholder_Filter_NumberNT_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                           </div>

                          <div className="form-group">
                          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                id="dateSheetFrom" 
                                name="dateSheetFrom"
                                value={ filtersRegisterBook.dateSheetFrom!== null ? new Date(filtersRegisterBook.dateSheetFrom): null}
                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                label={errorsFiltersRegisterBook.dateSheetFrom!=null?t(errorsFiltersRegisterBook.dateSheetFrom):t('Placeholder_Filter_DateSheet_From')}
                                onBlur={handleBlurSelectDateSheetFrom}
                                onChange={handleChangeSelectDateSheetFrom}
                                slotProps={{
                                  textField: {
                                    placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                    sx: {
                                      color: '#1565c0',
                                      maxLength: '100',
                                      width: 430,  
                                      fontSize: '18px'
                                    },
                                    InputProps: {
                                      style: {
                                        fontSize: '18px',
                                      }
                                    },
                                    InputLabelProps:{ 
                                      style: {
                                        fontSize: '18px', 
                                        background: '#FFFFFF'
                                      }
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                id="dateSheetTo" 
                                name="dateSheetTo"
                                minDate={filtersRegisterBook.dateSheetFrom!== null ? new Date(filtersRegisterBook.dateSheetFrom): null}
                                value={ filtersRegisterBook.dateSheetTo!== null ? new Date(filtersRegisterBook.dateSheetTo): null}
                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                label={errorsFiltersRegisterBook.dateSheetTo!=null?t(errorsFiltersRegisterBook.dateSheetTo):t('Placeholder_Filter_DateSheet_To')}
                                onBlur={handleBlurSelectDateSheetTo}
                                onChange={handleChangeSelectDateSheetTo}
                                slotProps={{
                                  textField: {
                                    placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                    sx: {
                                      color: '#1565c0',
                                      maxLength: '100',
                                      width: 430,  
                                      fontSize: '18px'
                                    },
                                    InputProps: {
                                      style: {
                                        fontSize: '18px',
                                      }
                                    },
                                    InputLabelProps:{ 
                                      style: {
                                        fontSize: '18px', 
                                        background: '#FFFFFF'
                                      }
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                          {valueTabs==='sheetin'?
                            <>
                              <div className="form-group">
                                <TextField
                                  id="ori_tras_tradenameFrom" 
                                  name="ori_tras_tradenameFrom"
                                  value={filtersRegisterBook.ori_tras_tradenameFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.ori_tras_tradenameFrom!=null?t(errorsFiltersRegisterBook.ori_tras_tradenameFrom):t('Text_Filter_tradename_Ori_TrasFrom')}
                                  placeholder={t('Placeholder_Filter_tradename_Ori_TrasFrom')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="ori_tras_tradenameTo" 
                                  name="ori_tras_tradenameTo"
                                  value={filtersRegisterBook.ori_tras_tradenameTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.ori_tras_tradenameTo!=null?t(errorsFiltersRegisterBook.ori_tras_tradenameTo):t('Text_Filter_tradename_Ori_TrasTo')}
                                  placeholder={t('Placeholder_Filter_tradename_Ori_TrasTo')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div>

                              <div className="form-group">
                                <TextField
                                  id="opt_tras_tradenameFrom" 
                                  name="opt_tras_tradenameFrom"
                                  value={filtersRegisterBook.opt_tras_tradenameFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                  label={errorsFiltersRegisterBook.opt_tras_tradenameFrom!=null?t(errorsFiltersRegisterBook.opt_tras_tradenameFrom):t('Text_Filter_tradename_Opt_TrasFrom')}
                                  placeholder={t('Placeholder_Filter_tradename_Opt_TrasFrom')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="opt_tras_tradenameTo" 
                                  name="opt_tras_tradenameTo"
                                  value={filtersRegisterBook.opt_tras_tradenameTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.ori_tras_tradenameTo!=null?t(errorsFiltersRegisterBook.opt_tras_tradenameTo):t('Text_Filter_tradename_Opt_TrasTo')}
                                  placeholder={t('Placeholder_Filter_tradename_Opt_TrasTo')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div>
                              <div className="form-group">
                                <TextField
                                  id="rel_trans_tradenameFrom" 
                                  name="rel_trans_tradenameFrom"
                                  value={filtersRegisterBook.rel_trans_tradenameFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                  label={errorsFiltersRegisterBook.rel_trans_tradenameFrom!=null?t(errorsFiltersRegisterBook.rel_trans_tradenameFrom):t('Text_Filter_tradename_Rel_TransFrom')}
                                  placeholder={t('Placeholder_Filter_tradename_Rel_TransFrom')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="rel_trans_tradenameTo" 
                                  name="rel_trans_tradenameTo"
                                  value={filtersRegisterBook.rel_trans_tradenameTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.rel_trans_tradenameTo!=null?t(errorsFiltersRegisterBook.rel_trans_tradenameTo):t('Text_Filter_tradename_Rel_TransTo')}
                                  placeholder={t('Placeholder_Filter_tradename_Rel_TransTo')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div>
                            </>:
                            <>
                              <div className="form-group">
                                <TextField
                                  id="dest_tras_tradenameFrom" 
                                  name="dest_tras_tradenameFrom"
                                  value={filtersRegisterBook.dest_tras_tradenameFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.dest_tras_tradenameFrom!=null?t(errorsFiltersRegisterBook.dest_tras_tradenameFrom):t('Text_Filter_tradename_Dest_TrasFrom')}
                                  placeholder={t('Placeholder_Filter_tradename_Dest_TrasFrom')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="dest_tras_tradenameTo" 
                                  name="dest_tras_tradenameTo"
                                  value={filtersRegisterBook.dest_tras_tradenameTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.dest_tras_tradenameTo!=null?t(errorsFiltersRegisterBook.dest_tras_tradenameTo):t('Text_Filter_tradename_Dest_TrasTo')}
                                  placeholder={t('Placeholder_Filter_tradename_Dest_TrasTo')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div> 
                              <div className="form-group">
                                <TextField
                                  id="rel_trans_tradenameFrom" 
                                  name="rel_trans_tradenameFrom"
                                  value={filtersRegisterBook.rel_trans_tradenameFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.rel_trans_tradenameFrom!=null?t(errorsFiltersRegisterBook.rel_trans_tradenameFrom):t('Text_Filter_tradename_Rel_TransFrom')}
                                  placeholder={t('Placeholder_Filter_tradename_Rel_TransFrom')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="rel_trans_tradenameTo" 
                                  name="rel_trans_tradenameTo"
                                  value={filtersRegisterBook.rel_trans_tradenameTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.rel_trans_tradenameTo!=null?t(errorsFiltersRegisterBook.rel_trans_tradenameTo):t('Text_Filter_tradename_Rel_TransTo')}
                                  placeholder={t('Placeholder_Filter_tradename_Rel_TransTo')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div>
                            </>
                          }
                           <div className="form-group">
                             <TextField
                              id="codelerFrom" 
                              name="codelerFrom"
                              value={filtersRegisterBook.codelerFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.codelerFrom!=null?t(errorsFiltersRegisterBook.codelerFrom):t('Text_Filter_CodeLer_From')}
                              placeholder={t('Placeholder_Filter_CodeLer_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="codelerTo" 
                              name="codelerTo"
                              value={filtersRegisterBook.codelerTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersRegisterBook.codelerTo!=null?t(errorsFiltersRegisterBook.codelerTo):t('Text_Filter_CodeLer_To')}
                              placeholder={t('Placeholder_Filter_CodeLer_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditRegisterBook}
                              onChange={handleChangeFilterEditRegisterBook}/>
                           </div>
                           {valueTabs!=='sheetin'&&
                            <> 
                              <div className="form-group">
                                <TextField
                                  id="codetreatmentFrom" 
                                  name="codetreatmentFrom"
                                  value={filtersRegisterBook.codetreatmentFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.codetreatmentFrom!=null?t(errorsFiltersRegisterBook.codetreatmentFrom):t('Text_Filter_Treatment_From')}
                                  placeholder={t('Placeholder_Filter_Treatment_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="codetreatmentTo" 
                                  name="codetreatmentTo"
                                  value={filtersRegisterBook.codetreatmentTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.codetreatmentTo!=null?t(errorsFiltersRegisterBook.codetreatmentTo):t('Text_Filter_Treatment_To')}
                                  placeholder={t('Placeholder_Filter_Treatment_To')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div>

                              <div className="form-group">
                                <TextField
                                  id="codedangerousnessFrom" 
                                  name="codedangerousnessFrom"
                                  value={filtersRegisterBook.codedangerousnessFrom}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.codedangerousnessFrom!=null?t(errorsFiltersRegisterBook.codedangerousnessFrom):t('Text_Filter_Dangerousness_From')}
                                  placeholder={t('Placeholder_Filter_Dangerousness_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <TextField
                                  id="codedangerousnessTo" 
                                  name="codedangerousnessTo"
                                  value={filtersRegisterBook.codedangerousnessTo}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsFiltersRegisterBook.codedangerousnessTo!=null?t(errorsFiltersRegisterBook.codedangerousnessTo):t('Text_Filter_Dangerousness_To')}
                                  placeholder={t('Placeholder_Filter_Dangerousness_To')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterEditRegisterBook}
                                  onChange={handleChangeFilterEditRegisterBook}/>
                              </div>
                            </>
                          }
                         </div>
                         <hr className="sidebar-divider d-none d-md-block"/>
                         <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                           <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditRegisterBook}>{t('Button_Apply')}</button><> </>
                           <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersRegisterBook}>{t('Button_Cancel_Filters')}</button>
                         </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
             </div>
            }   
            <RegisterBookEditOrCreate
              openCreateRegisterBook={openCreateRegisterBook}
              openEditRowRegisterBook={openEditRowRegisterBook}
              errorsRegisterBook={errorsRegisterBook}
              setErrorsRegisterBook={setErrorsRegisterBook}
              formRegisterBook={formRegisterBook}
              setFormRegisterBook={setFormRegisterBook}
              valueTabs={valueTabs}
              handleCloseDataEditRegisterBook={handleCloseDataEditRegisterBook} 
              showModalSearchDI={showModalSearchDI}
              setShowModalSearchDI={setShowModalSearchDI}
              showModalSearchCodeLERS={showModalSearchCodeLERS}
              setShowModalSearchCodeLERS={setShowModalSearchCodeLERS}
              showModalSearchStores={showModalSearchStores}
              setShowModalSearchStores={setShowModalSearchStores} 
              showModalSearchDangerousness={showModalSearchDangerousness}
              setShowModalSearchDangerousness={setShowModalSearchDangerousness}
              showModalSearchTreatmentType={showModalSearchTreatmentType}
              setShowModalSearchTreatmentType={setShowModalSearchTreatmentType} 
              selectStores={selectStores}
              setSelectStores={setSelectStores}
            />
            
            <Dialog open={openDeleteRowRegisterBook} onClose={handleCancelDeleteRowRegisterBook}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditRegisterBook.registerbook_id}  - {rowEditRegisterBook.fullname} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowRegisterBook}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowRegisterBook}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowRegisterBook}>{t('Button_Delete')}</Button>
                </DialogActions>
            </Dialog> 
            {/* End Content Row */}
          </>: 
           <LoadingNews/>  
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

FormRegisterBook.propTypes = { 
  showModalRegisterBookSettings: PropTypes.bool.isRequired,
  valueTabs: PropTypes.string.isRequired
};

export default FormRegisterBook;

