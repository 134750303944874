import React, { useState }from 'react';
import { useSelector, useDispatch } from "react-redux";
import { resetPagination } from 'reducers/paginationShippingsSlice';
import { resetFilters } from 'reducers/filtersShippingsSlice'; 
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; 
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { ToastContainer } from 'react-toastify'; 
import { TodayIs} from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import RoadSettings from "pages/settings/RoadSettings";
import DocumentTypeSettings from "pages/settings/DocumentTypeSettings"; 
import CountrySettings from "pages/settings/CountrySettings"; 
import CommunitySettings from "pages/settings/CommunitySettings"
import ShippingFilter from "pages/shipping/filterShipping/ShippingFilter";
 
const HomeShippng = () => {
    const {t,i18n} = useTranslation(['listDataShipping']);
    const dispatch = useDispatch();
    const filtersShippingsDetails = useSelector((state) => state.filtersShippings); // Obtener filtros desde Redux
    const state = useSelector((state) => state);
    const enabled = state.loginUser.enabled;
    const roles = state.loginUser.roles;
    const defaultStore = state.loginUser.defaultStore;
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showDocumentTypeSettings, setShowModalDocumentTypeSettings] = useState(false);
    const [showCommunitySettings, setShowCommunitySettings] = useState(false);
    const [showRoadSettings, setShowModalRoadSettings] = useState(false);
    const [showCountrySettings, setShowModalCountrySettings] = useState(false);
    const [showModalEmployeeSettings, setShowModalEmployeeSettings] = useState(false); 
    const [showModalStoreSettings, setShowModalStoreSettings] = useState(false); 
    const [showModalFormSettings, setShowModalFormSettings] = useState(false); 
    const [showModalTicketsSettings, setShowModalTicketsSettings] = useState(false); 
    const [showModalContractSettings, setShowModalContractSettings] = useState(true);  
    const [showModalArticlesSettings, setShowModalArticlesSettings] = useState(false); 
    const [showModalPurchasesDeliverySettings, setShowModalPurchasesDeliverySettings] = useState(false); 
    const [showModalShippingsSettings, setShowModalShippingsSettings] = useState(true); 
    const scriptUrl = '/js/sb-admin-2.min.js'; 

    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");
    };

    const closeModalShipping = () => {    
        setIndexShowSettings(0);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings);
        setShowModalStoreSettings(showModalStoreSettings); 
        setShowModalFormSettings(showModalFormSettings);
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalGeneralSettings(showModalGeneralSettings);
        setShowModalContractSettings(showModalContractSettings);
        setShowModalArticlesSettings(showModalArticlesSettings);
        setShowModalPurchasesDeliverySettings(showModalPurchasesDeliverySettings);
        setShowModalShippingsSettings(showModalShippingsSettings);
        dispatch(resetPagination());
        dispatch(resetFilters());
        return () => {};
    };
 
    function openModaDocuemntsSettings(){
        setIndexShowSettings(2);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(true);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }
    
    function openModaCountrySettings(){
        setIndexShowSettings(4);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(true);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaRoadSettings(){
        setIndexShowSettings(5);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(true);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCommunitySettings(){
        setIndexShowSettings(6);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(true);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    } 
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/"></Navigate>:
        <div id="page-top">
           
            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary toggled sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Sidebar - INFORMATION FILTROS APLICADOS */}
                    <li className="nav-item active">
                        <Link className="nav-link" to="/shipping">
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className={filtersShippingsDetails.idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_From')}
                    </div>
                    <li className={filtersShippingsDetails.idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersShippingsDetails.idFrom==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.idFrom}
                    </li>
                    <div className={filtersShippingsDetails.idTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_To')}
                    </div>
                    <li className={filtersShippingsDetails.idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersShippingsDetails.idTo==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.idTo}
                    </li>
                    
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_Store_Master"))&&
                        <>
                            <div className={filtersShippingsDetails.defaultStoreIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Store_From')}
                            </div>
                            <li className={filtersShippingsDetails.defaultStoreIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { filtersShippingsDetails.defaultStoreIdFrom==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.defaultStoreIdFrom}
                            </li>
                            <div className={filtersShippingsDetails.defaultStoreIdTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Store_To')}
                            </div>
                            <li className={filtersShippingsDetails.defaultStoreIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { filtersShippingsDetails.defaultStoreIdTo==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.defaultStoreIdTo}
                            </li>
                        </>
                    }
                    {(roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_Store_Master"))
                        || roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_User_Master"))) &&
                        <>
                            <div className={filtersShippingsDetails.useraccountIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_UserAccount_From')}
                            </div>
                            <li className={filtersShippingsDetails.useraccountIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { filtersShippingsDetails.useraccountIdFrom==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.useraccountIdFrom}
                            </li>
                            <div className={filtersShippingsDetails.useraccountIdTo===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_UserAccount_To')}
                            </div>
                            <li className={filtersShippingsDetails.useraccountIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { filtersShippingsDetails.useraccountIdTo==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.useraccountIdTo}
                            </li>
                        </>
                    }

                    <div className={filtersShippingsDetails.numberShippingFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Number_From')}
                    </div>
                    <li className={filtersShippingsDetails.numberShippingFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersShippingsDetails.numberShippingFrom==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.numberShippingFrom}
                    </li>
                    <div className={filtersShippingsDetails.numberShippingTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Number_To')}
                    </div>
                    <li className={filtersShippingsDetails.numberShippingTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersShippingsDetails.numberShippingTo==='' ? t('Text_Filter_Not_Apply'): filtersShippingsDetails.numberShippingTo}
                    </li>

                    <div className={filtersShippingsDetails.dateShippingFrom===null || filtersShippingsDetails.dateShippingFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Date_From')}
                    </div>
                    <li className={filtersShippingsDetails.dateShippingFrom===null || filtersShippingsDetails.dateShippingFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        {filtersShippingsDetails.dateShippingFrom===null || filtersShippingsDetails.dateShippingFrom===''? t('Text_Filter_Not_Apply'): new Date(filtersShippingsDetails.dateShippingFrom).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>
                    <div className={filtersShippingsDetails.dateShippingTo===null || filtersShippingsDetails.dateShippingTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Date_To')}
                    </div>
                    <li className={filtersShippingsDetails.dateShippingTo===null || filtersShippingsDetails.dateShippingTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersShippingsDetails.dateShippingTo===null || filtersShippingsDetails.dateShippingTo===''? t('Text_Filter_Not_Apply'):new Date(filtersShippingsDetails.dateShippingTo).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>
               
               
                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/shipping" data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Docuemnts"))&&
                                    <Link className="collapse-item"  to="/shipping" onClick={openModaDocuemntsSettings}>{t('SubMenu_Settings_Type_Documents')} </Link>}
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Locations"))&&
                                    <Link className="collapse-item"  to="/shipping" onClick={openModaCountrySettings}>{t('SubMenu_Settings_Type_Locations')} </Link>}
                                   
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Roads"))&&
                                    <Link className="collapse-item"  to="/shipping" onClick={openModaRoadSettings}>{t('SubMenu_Settings_Type_Roads')} </Link>}
                                 
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Community"))&&
                                    <Link className="collapse-item"  to="/shipping" onClick={openModaCommunitySettings}>{t('SubMenu_Settings_Type_Community')} </Link>}
                                  
                                    <Link className="collapse-item" to="/shipping" onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                   
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to="/home" onClick={closeModalShipping} >
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>   
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                {/* Show Modals */} 
                <ShippingFilter 
                    showModalShippingsSettings={showModalShippingsSettings}  
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                /> 
                <CommunitySettings  
                    showCommunitySettings={showCommunitySettings} 
                    setShowCommunitySettings={setShowCommunitySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={true}

                />
                <DocumentTypeSettings  
                    showDocumentTypeSettings={showDocumentTypeSettings}
                    setShowModalDocumentTypeSettings={setShowModalDocumentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={true}
                />
               
                <RoadSettings  
                    showRoadSettings={showRoadSettings} 
                    setShowModalRoadSettings={setShowModalRoadSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={true}
                />
                <CountrySettings  
                    showCountrySettings={showCountrySettings} 
                    setShowModalCountrySettings={setShowModalCountrySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={true}
                />
                 
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}
         
      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomeShippng;
