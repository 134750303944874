import React, { useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, resetFilters } from 'reducers/filtersPurchaseDeliverySlice';
import { resetPagination } from 'reducers/paginationPurchaseDeliverySlice';
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; 
import { ToastContainer } from 'react-toastify'; 
import { TodayIs} from 'components/utils/TodayIs'; 
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import RoadSettings from "pages/settings/RoadSettings";
import DocumentTypeSettings from "pages/settings/DocumentTypeSettings"; 
import CountrySettings from "pages/settings/CountrySettings"; 
import CommunitySettings from "pages/settings/CommunitySettings"
import CodeLerSettings from "pages/settings/CodeLerSettings";
import DescriptionSettings from "pages/settings/DescriptionSettings";
import TreatmentTypeSettings from "pages/settings/TreatmentTypeSettings";
import ProcessTypeSettings from "pages/settings/ProcessTypeSettings";
import DangerousnessSettings from "pages/settings/DangerousnessSettings"; 
import PurchaseDeliveryFilter from "pages/purchaseDelivery/purchaseDeliveryFilterList/PurchaseDeiveryFilter";
import PaymentTypeSettings from "pages/settings/PaymentTypeSettings";

const HomePurchaseDelivery = (typeList) => {
    const {t,i18n} = useTranslation(['listDataPurchases']);
    const dispatch = useDispatch();
    const filtersPurchaseDelivery = useSelector((state) => state.filtersPurchaseDelivery); // Obtener filtros desde Redux
    const state = useSelector((state) => state);
    const enabled = state.loginUser.enabled;
    const roles = state.loginUser.roles;
    const defaultStore = state.loginUser.defaultStore;
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showDocumentTypeSettings, setShowModalDocumentTypeSettings] = useState(false);
    const [showCommunitySettings, setShowCommunitySettings] = useState(false);
    const [showModalCodeLerSettings, setShowModalCodeLerSettings] = useState(false);
    const [showModalDescriptionSettings, setShowModalDescriptionSettings] = useState(false);
    const [showModalDangerousnessSettings, setShowModalDangerousnessSettings] = useState(false);
    const [showModalProcessTypeSettings, setShowModalProcessTypeSettings] = useState(false);
    const [showModalTreatmentTypeSettings, setShowModalTreatmentTypeSettings] = useState(false);
    const [showRoadSettings, setShowModalRoadSettings] = useState(false);
    const [showModalPaymentTypeSettings, setShowModalPaymentTypeSettings] = useState(false); 
    const [showCountrySettings, setShowModalCountrySettings] = useState(false);
    const [showModalEmployeeSettings, setShowModalEmployeeSettings] = useState(false); 
    const [showModalStoreSettings, setShowModalStoreSettings] = useState(false); 
    const [showModalFormSettings, setShowModalFormSettings] = useState(false); 
    const [showModalTicketsSettings, setShowModalTicketsSettings] = useState(false); 
    const [showModalContractSettings, setShowModalContractSettings] = useState(false);  
    const [showModalArticlesSettings, setShowModalArticlesSettings] = useState(false); 
    const [showModalPurchasesDeliverySettings, setShowModalPurchasesDeliverySettings] = useState(true); 
    const [showModalShippingsSettings, setShowModalShippingsSettings] = useState(false); 
    const [showModalRegisterBookSettings, setShowModalRegisterBookSettings] = useState(false); 
    
    const [typeOfList] = useState(typeList);
    const scriptUrl = '/js/sb-admin-2.min.js'; 

    const changeSelectedLanguage = () => {
        if (i18n.language === "es") {
          i18n.changeLanguage("en");
          dispatch(setFilters({ paymentFromES: "", paymentToES: "" }));
        } else {
            i18n.changeLanguage("es");
            dispatch(setFilters({ paymentFromEN: "", paymentToEN: "" }));
        }
    };
    const closeModalPurchaseDelivery = () => {    
        setIndexShowSettings(0);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings);
        setShowModalStoreSettings(showModalStoreSettings); 
        setShowModalFormSettings(showModalFormSettings);
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalGeneralSettings(showModalGeneralSettings);
        setShowModalContractSettings(showModalContractSettings);
        setShowModalArticlesSettings(showModalArticlesSettings);
        setShowModalPurchasesDeliverySettings(showModalPurchasesDeliverySettings);
        setShowModalShippingsSettings(showModalShippingsSettings);
        setShowModalRegisterBookSettings(showModalRegisterBookSettings);
        dispatch(resetPagination());
        dispatch(resetFilters());
        return () => {};
    };
 
    function openModaDocuemntsSettings(){
        setIndexShowSettings(2);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(true);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }
    
    function openModaCountrySettings(){
        setIndexShowSettings(4);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(true);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaRoadSettings(){
        setIndexShowSettings(5);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(true);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaPaymentTypeSettings(){
        setIndexShowSettings(15);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(true);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCommunitySettings(){
        setIndexShowSettings(6);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(true);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCodeLerSettings(){
        setIndexShowSettings(8);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(true);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaDescriptionSettings(){
        setIndexShowSettings(9);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(true);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaTreatmentTypeSettings(){
        setIndexShowSettings(10);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(true); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaProcessTypeSettings(){
        setIndexShowSettings(11);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(true);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaDangerousnessSettings(){
        setIndexShowSettings(12);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(true);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false); 
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/"></Navigate>:
        <div id="page-top">
           
            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar toggled sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Sidebar - INFORMATION FILTROS APLICADOS */}
                    <li className="nav-item active">
                        <Link className="nav-link" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"}>
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className={filtersPurchaseDelivery.idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_From')}
                    </div>
                    <li className={filtersPurchaseDelivery.idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.idFrom==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.idFrom}
                    </li>
                    <div className={filtersPurchaseDelivery.idTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_To')}
                    </div>
                    <li className={filtersPurchaseDelivery.idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.idTo==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.idTo}
                    </li>
                    <div className={filtersPurchaseDelivery.datepurchaseFrom===null || filtersPurchaseDelivery.datepurchaseFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_DatePurchase_From')}
                    </div>
                    <li className={filtersPurchaseDelivery.datepurchaseFrom === null || filtersPurchaseDelivery.datepurchaseFrom === '' ? "sidebar-heading text-warning" : "sidebar-heading text-white"}>
                        {filtersPurchaseDelivery.datepurchaseFrom === null || filtersPurchaseDelivery.datepurchaseFrom === ''
                        ? t('Text_Filter_Not_Apply')
                        : new Date(filtersPurchaseDelivery.datepurchaseFrom).toLocaleDateString(i18n.language === "es" ? 'es-ES' : 'en-US')}
                    </li>
                    <div className={filtersPurchaseDelivery.datepurchaseTo===null || filtersPurchaseDelivery.datepurchaseTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_DatePurchase_To')}
                    </div>
                    <li className={filtersPurchaseDelivery.datepurchaseTo === null || filtersPurchaseDelivery.datepurchaseTo === '' ? "sidebar-heading text-warning" : "sidebar-heading text-white"}>
                        {filtersPurchaseDelivery.datepurchaseTo === null || filtersPurchaseDelivery.datepurchaseTo === ''
                            ? t('Text_Filter_Not_Apply')
                            : new Date(filtersPurchaseDelivery.datepurchaseTo).toLocaleDateString(i18n.language === "es" ? 'es-ES' : 'en-US')}
                    </li>

                    <div className={filtersPurchaseDelivery.ori_tras_store_idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_TradeName_From')}
                    </div>
                    <li className={filtersPurchaseDelivery.ori_tras_store_idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.ori_tras_store_idFrom==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.ori_tras_store_idFrom}
                    </li>
                    <div className={filtersPurchaseDelivery.ori_tras_store_idTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_TradeName_To')}
                    </div>
                    <li className={filtersPurchaseDelivery.ori_tras_store_idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.ori_tras_store_idTo==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.ori_tras_store_idTo}
                    </li>
                    <div className={filtersPurchaseDelivery.ori_tras_workplaceFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_workplace_From')}
                    </div>
                    <li className={filtersPurchaseDelivery.ori_tras_workplaceFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.ori_tras_workplaceFrom==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.ori_tras_workplaceFrom}
                    </li>
                    <div className={filtersPurchaseDelivery.ori_tras_workplaceTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_workplace_To')}
                    </div>
                    <li className={filtersPurchaseDelivery.ori_tras_workplaceTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.ori_tras_workplaceTo==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.ori_tras_workplaceTo}
                    </li>
                    <div className={filtersPurchaseDelivery.rel_trans_store_idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_Trans_From')}
                    </div>
                    <li className={filtersPurchaseDelivery.rel_trans_store_idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.rel_trans_store_idFrom==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.rel_trans_store_idFrom}
                    </li>
                    <div className={filtersPurchaseDelivery.rel_trans_store_idTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_Trans_To')}
                    </div>
                    <li className={filtersPurchaseDelivery.rel_trans_store_idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.rel_trans_store_idTo==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.rel_trans_store_idTo}
                    </li>
                    <div className={filtersPurchaseDelivery.vehiclestore_licenseplateFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_vehicles_From')}
                    </div>
                    <li className={filtersPurchaseDelivery.vehiclestore_licenseplateFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.vehiclestore_licenseplateFrom==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.vehiclestore_licenseplateFrom}
                    </li>
                    <div className={filtersPurchaseDelivery.vehiclestore_licenseplateTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_vehicles_To')}
                    </div>
                    <li className={filtersPurchaseDelivery.vehiclestore_licenseplateTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { filtersPurchaseDelivery.vehiclestore_licenseplateTo==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.vehiclestore_licenseplateTo}
                    </li>
                    <div className={(filtersPurchaseDelivery.paymentFromEN==='' && filtersPurchaseDelivery.paymentFromES==='')?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_payment_From')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { i18n.language==="en"?filtersPurchaseDelivery.paymentFromEN==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.paymentFromEN:filtersPurchaseDelivery.paymentFromES==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.paymentFromES}
                    </li>
                    <div className={(filtersPurchaseDelivery.paymentToEN==='' && filtersPurchaseDelivery.paymentToES==='')?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_payment_To')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { i18n.language==="en"?filtersPurchaseDelivery.paymentToEN==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.paymentToEN:filtersPurchaseDelivery.paymentToES==='' ? t('Text_Filter_Not_Apply'): filtersPurchaseDelivery.paymentToES}
                    </li>               

                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Docuemnts"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaDocuemntsSettings}>{t('SubMenu_Settings_Type_Documents')} </Link>}
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Locations"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaCountrySettings}>{t('SubMenu_Settings_Type_Locations')} </Link>}
                                   
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Roads"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaRoadSettings}>{t('SubMenu_Settings_Type_Roads')} </Link>}
                                 
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TypesOfPayment"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaPaymentTypeSettings}>{t('SubMenu_Settings_Type_Payment')} </Link>}
                                 
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Community"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaCommunitySettings}>{t('SubMenu_Settings_Type_Community')} </Link>}
                                  
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_CodeLers"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaCodeLerSettings}>{t('SubMenu_Settings_Type_CodeLers')} </Link>}
                                  
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Desciption"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaDescriptionSettings}>{t('SubMenu_Settings_Type_Description')} </Link>}

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TreatmentType"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaTreatmentTypeSettings}>{t('SubMenu_Settings_Type_TreatmentType')} </Link>}

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_ProcessType"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaProcessTypeSettings}>{t('SubMenu_Settings_Type_ProcessType')} </Link>}

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Dangerousness"))&&
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={openModaDangerousnessSettings}>{t('SubMenu_Settings_Type_Dangerousnes')} </Link>}
              
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?"/purchasedelivery":typeOfList.typeList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin"} onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                   
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to="/home" onClick={closeModalPurchaseDelivery} >
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>   
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                {/* Show Modals */}  
                <PurchaseDeliveryFilter 
                    showModalPurchasesDeliverySettings={showModalPurchasesDeliverySettings}  
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    typeOfList={typeOfList.typeList}
                />
                <CodeLerSettings  
                    showModalCodeLerSettings={showModalCodeLerSettings}
                    setShowModalCodeLerSettings={setShowModalCodeLerSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <DescriptionSettings  
                    showModalDescriptionSettings={showModalDescriptionSettings}
                    setShowModalDescriptionSettings={setShowModalDescriptionSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                 <TreatmentTypeSettings  
                    showModalTreatmentTypeSettings={showModalTreatmentTypeSettings}
                    setShowModalTreatmentTypeSettings={setShowModalTreatmentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <ProcessTypeSettings  
                    showModalProcessTypeSettings={showModalProcessTypeSettings}
                    setShowModalProcessTypeSettings={setShowModalProcessTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                 <DangerousnessSettings  
                    showModalDangerousnessSettings={showModalDangerousnessSettings}
                    setShowModalDangerousnessSettings={setShowModalDangerousnessSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <CommunitySettings  
                    showCommunitySettings={showCommunitySettings} 
                    setShowCommunitySettings={setShowCommunitySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <DocumentTypeSettings  
                    showDocumentTypeSettings={showDocumentTypeSettings}
                    setShowModalDocumentTypeSettings={setShowModalDocumentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
               
                <RoadSettings  
                    showRoadSettings={showRoadSettings} 
                    setShowModalRoadSettings={setShowModalRoadSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                 <PaymentTypeSettings  
                    showModalPaymentTypeSettings={showModalPaymentTypeSettings}
                    setShowModalPaymentTypeSettings={setShowModalPaymentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                /> 
                <CountrySettings  
                    showCountrySettings={showCountrySettings} 
                    setShowModalCountrySettings={setShowModalCountrySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={true}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                 
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}
         
      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomePurchaseDelivery;
