import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'; 
import MenuItem  from "@mui/material/MenuItem";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import FormSearchStores from "pages/forms/editOrNewFormDI/FormSearchStores";
import FormSearchStoresAddresses from "./FormSearchStoresAddress";
import FormSearchStoreVehicles from "./FormSearchVehicles"
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const initialDocumentIdToSelect = [{
    "id": "",
    "documentId": "",
    "tradename": ""
}];

const FormCreateOrEditAutDest = ({formForm,setFormForm,showModalFormSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormForm,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataForms']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
    const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
    const aut_dest_view_name = useSelector((state) => state.formatDIUser.aut_dest_view_name); 
    const aut_dest_view_contactname = useSelector((state) => state.formatDIUser.aut_dest_view_contactname); 
    const aut_dest_view_address = useSelector((state) => state.formatDIUser.aut_dest_view_address); 
    const aut_dest_view_country = useSelector((state) => state.formatDIUser.aut_dest_view_country); 
    const aut_dest_view_community = useSelector((state) => state.formatDIUser.aut_dest_view_community); 
    const aut_dest_view_phone = useSelector((state) => state.formatDIUser.aut_dest_view_phone); 
    const aut_dest_view_email = useSelector((state) => state.formatDIUser.aut_dest_view_email); 
    const aut_dest_view_nima = useSelector((state) => state.formatDIUser.aut_dest_view_nima); 
    const aut_dest_view_cnae = useSelector((state) => state.formatDIUser.aut_dest_view_cnae); 
    const aut_dest_view_inscription = useSelector((state) => state.formatDIUser.aut_dest_view_inscription); 
    const aut_dest_view_opt = useSelector((state) => state.formatDIUser.aut_dest_view_opt); 
    const aut_dest_view_licenseplate = useSelector((state) => state.formatDIUser.aut_dest_view_licenseplate); 
    const aut_dest_view_economicactivity = useSelector((state) => state.formatDIUser.aut_dest_view_economicactivity); 

    const aut_dest_handle_documnettype= useSelector((state) => state.formatDIUser.aut_dest_handle_documnettype); 
    const aut_dest_handle_documentid= useSelector((state) => state.formatDIUser.aut_dest_handle_documentid); 
    const aut_dest_handle_tradename= useSelector((state) => state.formatDIUser.aut_dest_handle_tradename); 
    const aut_dest_handle_name= useSelector((state) => state.formatDIUser.aut_dest_handle_name); 
    const aut_dest_handle_contactname= useSelector((state) => state.formatDIUser.aut_dest_handle_contactname); 
    const aut_dest_handle_address= useSelector((state) => state.formatDIUser.aut_dest_handle_address); 
    const aut_dest_handle_phone= useSelector((state) => state.formatDIUser.aut_dest_handle_phone); 
    const aut_dest_handle_email= useSelector((state) => state.formatDIUser.aut_dest_handle_email); 
    const aut_dest_handle_nima= useSelector((state) => state.formatDIUser.aut_dest_handle_nima); 
    const aut_dest_handle_cnae= useSelector((state) => state.formatDIUser.aut_dest_handle_cnae); 
    const aut_dest_handle_inscription= useSelector((state) => state.formatDIUser.aut_dest_handle_inscription); 
    const aut_dest_handle_opt= useSelector((state) => state.formatDIUser.aut_dest_handle_opt); 
    const aut_dest_handle_licenseplate= useSelector((state) => state.formatDIUser.aut_dest_handle_licenseplate); 
    const aut_dest_handle_economicactivity= useSelector((state) => state.formatDIUser.aut_dest_handle_economicactivity); 
    
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false); 
    const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false); 

    const [provinceToSelect_Aut_Dest,setProvinceToSelect_Aut_Dest]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Aut_Dest,setPostalCodeToSelect_Aut_Dest]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const [documentIdAutDestToSelect,setDocumentIdAutDestToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredDocumentIdAutDest, setFilteredDocumentIdAutDest] = useState([]); // Opciones filtradas
    const [highlightedIndexDocumentIdAutDest, setHighlightedIndexDocumentIdAutDest] = useState(-1); // Índice de la opción resaltada 
    const [tradenameAutDestToSelect,setTradenameAutDestToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredTradenamedAutDest, setFilteredTradenamedAutDest] = useState([]); // Opciones filtradas
    const [highlightedIndexTradenamedAutDest, setHighlightedIndexTradenamedAutDest] = useState(-1); // Índice de la opción resaltada 
        
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchDocumentIdAutDest = (event) => {
        const searchDocumentIdAutDest = event.target.value;
        setFormForm(formForm => ({
        ...formForm,
        aut_dest_documentid: searchDocumentIdAutDest,
        })); 
        if (searchDocumentIdAutDest.trim() === "") {
        setFilteredDocumentIdAutDest([]);
        setHighlightedIndexDocumentIdAutDest(-1);
        return;
        } 
        const filtered = documentIdAutDestToSelect.filter((store) => {
        if (!store || !store.documentId) return false;
        const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
        // Comparar después de eliminar puntos y guiones de ambos
        const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        return normalizedDocumentId.includes(searchNormailzedDocumentId);
        });
        setFilteredDocumentIdAutDest(filtered);
        setHighlightedIndexDocumentIdAutDest(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectDocumentIdAutDest = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormForm((formForm) => ({
        ...formForm,
        aut_dest_documentid: store.documentId || "",
        }));
        setFilteredDocumentIdAutDest([]);
        setHighlightedIndexDocumentIdAutDest(-1);
    }; 
    const handleKeyDownDocumentIdAutDest = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredDocumentIdAutDest.length === 0 && formForm.aut_dest_documentid.trim() === "") {
            setFilteredDocumentIdAutDest(documentIdAutDestToSelect);
            setHighlightedIndexDocumentIdAutDest(0);
        } else {
            setHighlightedIndexDocumentIdAutDest((prevIndex) => {
            const nextIndex = prevIndex < filteredDocumentIdAutDest.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexDocumentIdAutDest((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentIdAutDest >= 0) {
        if (highlightedIndexDocumentIdAutDest >= 0 && highlightedIndexDocumentIdAutDest < filteredDocumentIdAutDest.length) {
            handleSelectDocumentIdAutDest(filteredDocumentIdAutDest[highlightedIndexDocumentIdAutDest]);
        }
        } else if (event.key === "Escape") {
        const isValid = documentIdAutDestToSelect.some((store) => {
            if (!store || !store.documentId) return false; 
            const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
            const normalizedInputId = formForm.aut_dest_documentid.toUpperCase();
            return normalizedStoreId === normalizedInputId;
        }); 
        if (!isValid) {
            setFormForm((formForm) => ({
            ...formForm,
            aut_dest_documentid: "",
            }));
        } 
        setFilteredTradenamedAutDest([]);
        setHighlightedIndexDocumentIdAutDest(-1);
        }
    };
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchTradenameAutDest = (event) => {
        const searchTradenameAutDest = event.target.value.toUpperCase();
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_tradename: searchTradenameAutDest,
        })); 
        if (searchTradenameAutDest.trim() === "") {
            setFilteredTradenamedAutDest([]);
            setHighlightedIndexTradenamedAutDest(-1);
            return;
        } 
        const filtered = tradenameAutDestToSelect.filter((store) => {
        if (!store || !store.tradename) return false;
            // Comparar después de eliminar puntos y guiones de ambos
            const normalizedTradeName = store.tradename.toUpperCase();
            return normalizedTradeName.includes(searchTradenameAutDest);
        });
        setFilteredTradenamedAutDest(filtered);
        setHighlightedIndexTradenamedAutDest(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectTradeNameAutDest = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormForm((formForm) => ({
        ...formForm,
        aut_dest_tradename: store.tradename || "",
        aut_dest_documentid: store.documentId || formForm.aut_dest_documentid,
        
        }));
        setFilteredTradenamedAutDest([]);
        setHighlightedIndexTradenamedAutDest(-1);
    }; 
    const handleKeyDownTradenameAutDest = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredTradenamedAutDest.length === 0 && formForm.aut_dest_tradename.trim() === "") {
            setFilteredTradenamedAutDest(tradenameAutDestToSelect);
            setHighlightedIndexTradenamedAutDest(0);
        } else {
            setHighlightedIndexTradenamedAutDest((prevIndex) => {
            const nextIndex = prevIndex < filteredTradenamedAutDest.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexTradenamedAutDest((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradenamedAutDest >= 0) {
        if (highlightedIndexTradenamedAutDest >= 0 && highlightedIndexTradenamedAutDest < filteredTradenamedAutDest.length) {
            handleSelectTradeNameAutDest(filteredTradenamedAutDest[highlightedIndexTradenamedAutDest]);
        }
        } else if (event.key === "Escape") {
        const isValid = tradenameAutDestToSelect.some((store) => {
            if (!store || !store.tradename) return false; 
            const normalizedTradeName = store.tradename.toUpperCase();
            const normalizedInputTradeName = formForm.aut_dest_tradename.toUpperCase();
            return normalizedTradeName === normalizedInputTradeName;
        }); 
        if (!isValid) {
            setFormForm((formForm) => ({
            ...formForm,
            aut_dest_tradename: "",
            }));
        } 
        setFilteredTradenamedAutDest([]);
        setHighlightedIndexTradenamedAutDest(-1);
        }
    };

    // Función para desplazar el contenedor
    const scrollToElement = (index) => {
        const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
        const item = dropdown?.children[index];
        if (item) {
        item.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
        });
        }
    };

    const handleBlur = useCallback((event) => {
        if (!event || !event.currentTarget) return;
        // Asegúrate de que no se cierra si haces clic en un elemento de la lista
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setFilteredDocumentIdAutDest([]);
            setHighlightedIndexDocumentIdAutDest(-1);
            setFilteredTradenamedAutDest([]);
            setHighlightedIndexTradenamedAutDest(-1); 
        }
    }, []);
    
    const handleChangeSelectDocumentType_Aut_Dest = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Aut_Dest = (e) => {
        handleChangeSelectDocumentType_Aut_Dest(e);
    };
    
    const handleChangeSelectRoadType_Aut_Dest = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Aut_Dest = (e) => {
        handleChangeSelectRoadType_Aut_Dest(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectCountry_Aut_Dest = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_country_id: event.target.value,
            aut_dest_province_id:Number(DEFAULTPROVINCE),
            aut_dest_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Aut_Dest = (e) => {
        handleChangeSelectCountry_Aut_Dest(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectProvince_Aut_Dest = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_province_id :event.target.value,
            aut_dest_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Aut_Dest = (e) => {
        handleChangeSelectProvince_Aut_Dest(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectPostalCode_Aut_Dest = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Aut_Dest = (e) => {
        handleChangeSelectPostalCode_Aut_Dest(e);
        setErrorsForm(validateFormForm());
    };

    const handleChangeSelectCommunity_Aut_Dest = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            aut_dest_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Aut_Dest = (e) => {
        handleChangeSelectCommunity_Aut_Dest(e);
        setErrorsForm(validateFormForm());
    };

    const handleChangeDataEditForm_Aut_Dest = (e) => {
        const { name, value } = e.target;
        setFormForm(formForm => ({
          ...formForm,
          [name]: value,
        }));
    };
    const handleBlurDataEditForm_Aut_Dest = (e) => {
        handleChangeDataEditForm_Aut_Dest(e);
        setErrorsForm(validateFormForm());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setShowModalSearchStoresAddress(true);
    };

    const searchFormVehicles = (event) => {
        setShowModalSearchVehicles(true);
    };

    const deleteDateAutDest = (event) => {
        setFormForm(formForm => ({
          ...formForm,
            aut_dest_store_id:0,
            aut_dest_documnettype_id: "",
            aut_dest_documentid:"",
            aut_dest_tradename: "",
            aut_dest_name: "",
            aut_dest_contactname: "",
            aut_dest_roadtype_id: 0,
            aut_dest_street: "",
            aut_dest_country_id: Number(DEFAULTCOUNTRY),
            aut_dest_province_id: Number(DEFAULTPROVINCE),
            aut_dest_postalcode_id: "",
            aut_dest_community_id: Number(DEFAULTCOMMUNITY),
            aut_dest_phone: "",
            aut_dest_email: "",
            aut_dest_nima: "",
            aut_dest_cnae: "",
            aut_dest_inscription: "",
            aut_dest_opt: 0,
            aut_dest_licenseplate: "",
            aut_dest_economicactivity:""
        }));
        setErrorsForm(validateFormForm());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            aut_dest_store_id:0,
            aut_dest_documnettype_id: "",
            aut_dest_documentid:"",
            aut_dest_tradename: "",
            aut_dest_name: "",
            aut_dest_contactname: "",
            aut_dest_roadtype_id: "",
            aut_dest_street: "",
            aut_dest_country_id: "",
            aut_dest_province_id: "",
            aut_dest_postalcode_id: "",
            aut_dest_community_id: "",
            aut_dest_phone: "",
            aut_dest_email: "",
            aut_dest_nima: "",
            aut_dest_cnae: "",
            aut_dest_inscription: "",
            aut_dest_opt: "",
            aut_dest_licenseplate: "",
            aut_dest_economicactivity:""
        }));
    };

    const deleteDataSteetAutDest = (event) => {
        setFormForm(formForm => ({
          ...formForm,
            aut_dest_roadtype_id: 0,
            aut_dest_street: "",
            aut_dest_country_id: Number(DEFAULTCOUNTRY),
            aut_dest_province_id: Number(DEFAULTPROVINCE),
            aut_dest_postalcode_id: "",
            aut_dest_community_id: Number(DEFAULTCOMMUNITY),
            aut_dest_nima: "", 
            aut_dest_inscription: "",
            aut_dest_opt: 0
        }));
        setErrorsForm(validateFormForm());
    };

    const deleteDataVehicleAutDest = (event) => {
        setFormForm(formForm => ({
          ...formForm,
          aut_dest_licenseplate: ""
        }));
        setErrorsForm(validateFormForm());
    };

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`form/getexiststorebynumberdocument/`,token,formForm.aut_dest_documentid.replace(/[.\-/\\|]/g, "").trim());
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formForm.aut_dest_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get5(ENDPOINT,`form/getformstorebynumberdocument`,token,formForm.aut_dest_documentid.replace(/[.\-/\\|]/g, "").trim(),5);
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                       setFormForm(formForm => ({
                            ...formForm,
                            aut_dest_store_id: getData.store_id,
                            aut_dest_documnettype_id: getData.documnettype_id,
                            aut_dest_tradename: getData.tradename,
                            aut_dest_name: getData.name,
                            aut_dest_contactname: getData.contacperson,
                            aut_dest_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            aut_dest_street: getData.street,
                            aut_dest_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            aut_dest_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id,
                            aut_dest_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            aut_dest_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id,
                            aut_dest_phone: getData.phone,
                            aut_dest_email: getData.email,
                            aut_dest_nima: getData.nima_aut_dest,
                            aut_dest_cnae: getData.cnae,
                            aut_dest_inscription: getData.pygr_aut_dest,
                            aut_dest_opt: getData.opt_aut_dest,
                            aut_dest_licenseplate: getData.licenseplate,
                            aut_dest_economicactivity:getData.economicactivity
                        }));
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            if (formForm.aut_dest_documentid && formForm.aut_dest_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
                fetchExistNumberDocument();
            }
        }
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,expanded,formForm.aut_dest_documentid,errorsForm.aut_dest_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormForm,formForm.aut_dest_store_id]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formForm.aut_dest_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formForm.aut_dest_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Aut_Dest(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [ENDPOINT,t,token,dispatch,i18n,showModalFormSettings,formForm.aut_dest_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formForm.aut_dest_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formForm.aut_dest_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formForm.aut_dest_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Aut_Dest(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [ENDPOINT,t,token,dispatch,i18n,showModalFormSettings,formForm.aut_dest_country_id,formForm.aut_dest_province_id,expanded]);
 
    useEffect(() => {
        let isMounted = true; 
        const fetchDataDocumentIdAutDest = async () => {
            try {
            const getData = await helpHttp().get2(ENDPOINT,`form/getlistdocumentidformdi/`,token,
                (formForm.aut_dest_documentid!==undefined && formForm.aut_dest_documentid!==null && formForm.aut_dest_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formForm.aut_dest_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
            if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setDocumentIdAutDestToSelect(getData); 
            }
            } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };   
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            if (formForm.aut_dest_documentid && formForm.aut_dest_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataDocumentIdAutDest();
            }
        } else{
            setDocumentIdAutDestToSelect(initialDocumentIdToSelect);
        } 
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,formForm.aut_dest_documentid,expanded]);
    
    useEffect(() => {
        let isMounted = true; 
        const fetchDataTradenameAutDest = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`form/getlisttradenameformdi/`,token,
                    (formForm.aut_dest_tradename!==undefined && formForm.aut_dest_tradename!==null && formForm.aut_dest_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formForm.aut_dest_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    setTradenameAutDestToSelect(getData);
                }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };  
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5'){
            if (formForm.aut_dest_tradename && formForm.aut_dest_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataTradenameAutDest();
            }
        } else{
            setTradenameAutDestToSelect(initialDocumentIdToSelect);
        }  
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,formForm.aut_dest_tradename,expanded]);

    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel5') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="aut_dest_documnettype_id"
                    name="aut_dest_documnettype_id"
                    value={ (formForm.aut_dest_documnettype_id === undefined || formForm.aut_dest_documnettype_id ===null || formForm.aut_dest_documnettype_id ===""
                        || formForm.aut_dest_documnettype_id ==="0" || formForm.aut_dest_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formForm.aut_dest_documnettype_id}
                    inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Aut_Dest}
                    onChange={handleChangeSelectDocumentType_Aut_Dest}
                    disabled={!aut_dest_handle_documnettype}
                    helperText={errorsForm.aut_dest_documnettype_id!=null && errorsForm.aut_dest_documnettype_id!==""?t(errorsForm.aut_dest_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formForm.aut_dest_documnettype_id === undefined || formForm.aut_dest_documnettype_id ===null || formForm.aut_dest_documnettype_id ===""
                            || formForm.aut_dest_documnettype_id ==="0" || formForm.aut_dest_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formForm.aut_dest_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CIF')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                    <TextField
                        id="aut_dest_documentid" 
                        name="aut_dest_documentid"
                        value={formForm.aut_dest_documentid}
                        onChange={handleSearchDocumentIdAutDest}
                        onKeyDown={handleKeyDownDocumentIdAutDest}
                        onBlur={(event) => {
                            // Solo cerrar si el foco se pierde fuera del desplegable
                            if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                              handleBlur();
                            }
                        }}
                        label={errorsForm.aut_dest_documentid!=null && errorsForm.aut_dest_documentid!==""?t(errorsForm.aut_dest_documentid):t('PlaceHolder_FORM_CIF')}
                        placeholder={t('PlaceHolder_FORM_CIF')}
                        variant="standard"
                        inputProps={{ style: {width: i18n.language==='es'?245:285,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        disabled={!aut_dest_handle_documentid}
                    />
                    {/* Desplegable dinámico */}
                    {filteredDocumentIdAutDest.length > 0 && (
                        <div
                            className="dropdown-class"
                            style={{
                            position: "absolute",
                            left:'0px',
                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                            borderRadius: "4px",
                            marginTop: "4px",
                            maxHeight: "200px",
                            overflowY: "auto",
                            width: "350%",
                            fontSize: '18px',
                            backgroundColor: "#f8f9fa", // Fondo gris claro
                            zIndex: 1000,
                            }}
                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                        >
                            {filteredDocumentIdAutDest.map((store, index) => (
                            <MenuItem
                                key={store.id}
                                onClick={() => handleSelectDocumentIdAutDest(store)}
                                style={{
                                    cursor: "pointer",
                                    padding: "8px",
                                    borderBottom: "1px solid #eee",
                                    fontSize: '18px',
                                    backgroundColor:
                                    index === highlightedIndexDocumentIdAutDest ? "#4e73df" : "transparent",
                                    color: index === highlightedIndexDocumentIdAutDest ? "white" : "black",
                                }}
                            >
                                {store.documentId} - {store.tradename}
                            </MenuItem>
                            ))}
                        </div>
                    )}
                </span>  
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDateAutDest}>
                    <DeleteIcon style={{fontSize: 28}}/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 18}} onClick={searchFormStore}>
                        <SearchIcon style={{fontSize: 28}}/>
                    </IconButton>
                }
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_TRADENAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                        <TextField
                            id="aut_dest_tradename" 
                            name="aut_dest_tradename"
                            value={formForm.aut_dest_tradename}
                            onChange={handleSearchTradenameAutDest}
                            onKeyDown={handleKeyDownTradenameAutDest}
                            onBlur={(event) => {
                                // Solo cerrar si el foco se pierde fuera del desplegable
                                if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                  handleBlur();
                                }
                            }}
                            label={errorsForm.aut_dest_tradename!=null && errorsForm.aut_dest_tradename!==""?t(errorsForm.aut_dest_tradename):t('PlaceHolder_FORM_TRADENAME')}
                            placeholder={t('PlaceHolder_FORM_TRADENAME')}
                            variant="standard"
                            inputProps={{ style: {width: i18n.language==='es'?720:696,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            disabled={!aut_dest_handle_tradename}
                        />
                        {/* Desplegable dinámico */}
                        {filteredTradenamedAutDest.length > 0 && (
                            <div
                                className="dropdown-class"
                                style={{
                                position: "absolute",
                                left:'0px',
                                border: "2px solid #4e73df", // Borde azul (#4e73df)
                                borderRadius: "4px",
                                marginTop: "4px",
                                maxHeight: "200px",
                                overflowY: "auto",
                                width: "150%",
                                fontSize: '18px',
                                backgroundColor: "#f8f9fa", // Fondo gris claro
                                zIndex: 1000,
                                }}
                                onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                            >
                                {filteredTradenamedAutDest.map((store, index) => (
                                <MenuItem
                                    key={store.id}
                                    onClick={() => handleSelectTradeNameAutDest(store)}
                                    style={{
                                        cursor: "pointer",
                                        padding: "8px",
                                        borderBottom: "1px solid #eee",
                                        fontSize: '18px',
                                        backgroundColor:
                                        index === highlightedIndexTradenamedAutDest ? "#4e73df" : "transparent",
                                        color: index === highlightedIndexTradenamedAutDest ? "white" : "black",
                                    }}
                                >
                                    {store.tradename} - {store.documentId}
                                </MenuItem>
                                ))}
                            </div>
                        )}
                    </span> 
                </div>
            </div>
            {aut_dest_view_name &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_NAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_name" 
                        name="aut_dest_name"
                        value={formForm.aut_dest_name}
                        inputProps={{ style: {width: i18n.language==='es'?674:719,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.aut_dest_name!=null && errorsForm.aut_dest_name!==""?t(errorsForm.aut_dest_name):t('PlaceHolder_FORM_NAME')}
                        placeholder={t('PlaceHolder_FORM_NAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Aut_Dest}
                        onChange={handleChangeDataEditForm_Aut_Dest}
                        disabled={!aut_dest_handle_name}
                    />
                    </div>
                </div> 
            }
            {aut_dest_view_contactname &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CONTACT')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_contactname" 
                        name="aut_dest_contactname"
                        value={formForm.aut_dest_contactname}
                        inputProps={{ style: {width: i18n.language==='es'?657:702,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.aut_dest_contactname!=null && errorsForm.aut_dest_contactname!==""?t(errorsForm.aut_dest_contactname):t('PlaceHolder_FORM_CONTACT')}
                        placeholder={t('PlaceHolder_FORM_CONTACT')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Aut_Dest}
                        onChange={handleChangeDataEditForm_Aut_Dest}
                        disabled={!aut_dest_handle_contactname}
                    />
                    </div>
                </div> 
            }
            {aut_dest_view_address &&
                <>
                    <div className="row p-2"> 
                        <div className="form-group">
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_RoadType')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="aut_dest_roadtype"
                            name="aut_dest_roadtype"
                            value={ (formForm.aut_dest_roadtype_id === undefined || formForm.aut_dest_roadtype_id ===null || formForm.aut_dest_roadtype_id ===""
                                || formForm.aut_dest_roadtype_id ==="0" || formForm.aut_dest_roadtype_id ===0)?"":formForm.aut_dest_roadtype_id}
                            inputProps={{ style: {width: i18n.language==='es'?100:100,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            variant="outlined"
                            onBlur={handleBlurSelectRoadType_Aut_Dest}
                            onChange={handleChangeSelectRoadType_Aut_Dest}
                            disabled={!aut_dest_handle_address}
                            helperText={errorsForm.aut_dest_roadtype_id!=null && errorsForm.aut_dest_roadtype_id!==""?t(errorsForm.aut_dest_roadtype_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (formForm.aut_dest_roadtype_id === undefined || formForm.aut_dest_roadtype_id ===null || formForm.aut_dest_roadtype_id ===""
                                    || formForm.aut_dest_roadtype_id ==="0" || formForm.aut_dest_roadtype_id ===0)?"": formForm.aut_dest_roadtype_id
                            }}>
                            <option key={0} value=""/>
                            {roadTypesToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                        </TextField>  
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Address')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="aut_dest_street" 
                            name="aut_dest_street"
                            value={formForm.aut_dest_street}
                            inputProps={{ style: {width: i18n.language==='es'?361:374,  fontSize: '18px', }, maxLength: 250 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={errorsForm.aut_dest_street!=null && errorsForm.aut_dest_street!==""?t(errorsForm.aut_dest_street):t('PlaceHolder_FORM_Address')}
                            placeholder={t('PlaceHolder_FORM_Address')}
                            variant="standard"
                            onBlur={handleBlurDataEditForm_Aut_Dest}
                            onChange={handleChangeDataEditForm_Aut_Dest}
                            disabled={!aut_dest_handle_address}
                        />
                        </div>
                        &nbsp;&nbsp;
                        <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataSteetAutDest}>
                            <DeleteIcon style={{fontSize: 28}}/>
                        </IconButton>
                        &nbsp;&nbsp;
                        {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&& formForm.aut_dest_documentid!==undefined && formForm.aut_dest_documentid!=="") ?
                            <IconButton  size="small"  style={{
                                color:
                                (formForm.aut_dest_store_id===undefined
                                || formForm.aut_dest_store_id===""
                                || formForm.aut_dest_store_id==="0"
                                || formForm.aut_dest_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                                disabled={formForm.aut_dest_store_id===undefined
                                        || formForm.aut_dest_store_id===""
                                        || formForm.aut_dest_store_id==="0"
                                        || formForm.aut_dest_store_id===0 } onClick={searchFormStoreAddress}>
                            <SearchIcon style={{fontSize: 28}}/>
                            </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                                <SearchIcon style={{fontSize: 28}}/>
                            </IconButton>
                        }
                    </div>
 
                    {(aut_dest_view_country || aut_dest_view_community) &&
                        <div className="row p-2"> 
                            <div className="form-group">
                                {aut_dest_view_country &&
                                    <>
                                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Country')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                            id="aut_dest_country_id"
                                            name="aut_dest_country_id"
                                            value={ (formForm.aut_dest_country_id === undefined || formForm.aut_dest_country_id ===null || formForm.aut_dest_country_id ===""
                                                    || formForm.aut_dest_country_id ==="0" || formForm.aut_dest_country_id ===0)?Number(DEFAULTCOUNTRY):formForm.aut_dest_country_id}
                                            inputProps={{ style: {width: i18n.language==='es'?235:235,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                            variant="outlined"
                                            onBlur={handleBlurSelectCountry_Aut_Dest}
                                            onChange={handleChangeSelectCountry_Aut_Dest}
                                            disabled={!aut_dest_handle_address}
                                            helperText={errorsForm.aut_dest_country_id!=null && errorsForm.aut_dest_country_id!==""?t(errorsForm.aut_dest_country_id):""}
                                            select
                                            SelectProps={{
                                                native: true,
                                                value: (formForm.aut_dest_country_id === undefined || formForm.aut_dest_country_id ===null || formForm.aut_dest_country_id ===""
                                                    || formForm.aut_dest_country_id ==="0" || formForm.aut_dest_country_id ===0)?Number(DEFAULTCOUNTRY): formForm.aut_dest_country_id
                                            }}>
                                            {countryToSelect.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {i18n.language==="es"?option.namees:option.nameen}
                                            </option>
                                            ))}
                                        </TextField>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                }
                                {aut_dest_view_community &&
                                <>
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Community')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                        id="aut_dest_community_id"
                                        name="aut_dest_community_id"
                                        value={ (formForm.aut_dest_community_id === undefined || formForm.aut_dest_community_id ===null || formForm.aut_dest_community_id ===""
                                            || formForm.aut_dest_community_id ==="0" || formForm.aut_dest_community_id ===0)?Number(DEFAULTCOMMUNITY):formForm.aut_dest_community_id}
                                        inputProps={{ style: {width: i18n.language==='es'?294:268,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        variant="outlined"
                                        onBlur={handleBlurSelectCommunity_Aut_Dest}
                                        onChange={handleChangeSelectCommunity_Aut_Dest}
                                        disabled={!aut_dest_handle_address}
                                        helperText={errorsForm.aut_dest_community_id!=null  && errorsForm.aut_dest_community_id!==""?t(errorsForm.aut_dest_community_id):""}
                                        select
                                        SelectProps={{
                                            native: true,
                                            value: (formForm.aut_dest_community_id === undefined || formForm.aut_dest_community_id ===null || formForm.aut_dest_community_id ===""
                                                || formForm.aut_dest_community_id ==="0" || formForm.aut_dest_community_id ===0)?Number(DEFAULTCOMMUNITY): formForm.aut_dest_community_id
                                        }}>
                                        {communityToSelect.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {i18n.language==="es"?option.namees:option.nameen}
                                            </option>
                                        ))}
                                    </TextField>  
                                </>
                            } 
                            </div>
                        </div>
                    }
                    <div className="row p-2"> 
                        <div className="form-group">
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Province')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="aut_dest_province_id"
                                name="aut_dest_province_id"
                                value={ (formForm.aut_dest_province_id === undefined || formForm.aut_dest_province_id ===null || formForm.aut_dest_province_id ===""
                                    || formForm.aut_dest_province_id ==="0" || formForm.aut_dest_province_id ===0)?Number(DEFAULTPROVINCE):formForm.aut_dest_province_id}
                                inputProps={{ style: {width: i18n.language==='es'?200:200,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                                onBlur={handleBlurSelectProvince_Aut_Dest}
                                onChange={handleChangeSelectProvince_Aut_Dest}
                                disabled={!aut_dest_handle_address}
                                helperText={errorsForm.aut_dest_province_id!=null && errorsForm.aut_dest_province_id!==""?t(errorsForm.aut_dest_province_id):""}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (formForm.aut_dest_province_id === undefined || formForm.aut_dest_province_id ===null || formForm.aut_dest_province_id ===""
                                        || formForm.aut_dest_province_id ==="0" || formForm.aut_dest_province_id ===0)?Number(DEFAULTPROVINCE): formForm.aut_dest_province_id
                                }}>
                                <option key={0} value=""/>
                                {provinceToSelect_Aut_Dest.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                                ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_PostalCode')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="aut_dest_postalcode_id"
                                name="aut_dest_postalcode_id"
                                value={ (formForm.aut_dest_postalcode_id === undefined || formForm.aut_dest_postalcode_id ===null || formForm.aut_dest_postalcode_id ===""
                                    || formForm.aut_dest_postalcode_id ==="0" || formForm.aut_dest_postalcode_id ===0)?"":formForm.aut_dest_postalcode_id}
                                inputProps={{ style: {width: i18n.language==='es'?284:302,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                                onBlur={handleBlurSelectPostalCode_Aut_Dest}
                                onChange={handleChangeSelectPostalCode_Aut_Dest}
                                disabled={!aut_dest_handle_address}
                                helperText={errorsForm.aut_dest_postalcode_id!=null && errorsForm.aut_dest_postalcode_id!==""?t(errorsForm.aut_dest_postalcode_id):""}
                                select
                                SelectProps={{
                                native: true,
                                value: (formForm.aut_dest_postalcode_id === undefined || formForm.aut_dest_postalcode_id ===null || formForm.aut_dest_postalcode_id ===""
                                    || formForm.aut_dest_postalcode_id ==="0" || formForm.aut_dest_postalcode_id ===0)?"": formForm.aut_dest_postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect_Aut_Dest.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </>
            }    
            { (aut_dest_view_phone || aut_dest_view_email || aut_dest_view_nima) &&
                <div className="row p-2"> 
                    <div className="form-group">
                        {aut_dest_view_phone &&
                            <>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Phone')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="aut_dest_phone" 
                                    name="aut_dest_phone"
                                    value={formForm.aut_dest_phone}
                                    inputProps={{ style: {width: i18n.language==='es'?180:180,  fontSize: '18px', }, maxLength: 30 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.aut_dest_phone!=null && errorsForm.aut_dest_phone!==""?t(errorsForm.aut_dest_phone):t('PlaceHolder_FORM_Phone')}
                                    placeholder={t('PlaceHolder_FORM_Phone')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Aut_Dest}
                                    onChange={handleChangeDataEditForm_Aut_Dest}
                                    disabled={!aut_dest_handle_phone}
                                />
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            </>
                        }
                        {aut_dest_view_email &&
                            <>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Email')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="aut_dest_email" 
                                    name="aut_dest_email"
                                    value={formForm.aut_dest_email}
                                    inputProps={{ style: {width:  (!aut_dest_view_phone || !aut_dest_view_nima)?(i18n.language==='es'?380:394):(i18n.language==='es'?181:197) ,  fontSize: '18px'}, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.aut_dest_email!=null && errorsForm.aut_dest_email!==""?t(errorsForm.aut_dest_email):t('PlaceHolder_FORM_Email')}
                                    placeholder={t('PlaceHolder_FORM_Email')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Aut_Dest}
                                    onChange={handleChangeDataEditForm_Aut_Dest}
                                    disabled={!aut_dest_handle_email}
                                />
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            </>
                        }
                        {aut_dest_view_nima &&
                            <>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_NIMA')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="aut_dest_nima" 
                                    name="aut_dest_nima"
                                    value={formForm.aut_dest_nima}
                                    inputProps={{ style: {width: (!aut_dest_view_phone || !aut_dest_view_email)?180:180,  fontSize: '18px'}, maxLength: 50 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.aut_dest_nima!=null && errorsForm.aut_dest_nima!==""?t(errorsForm.aut_dest_nima):t('PlaceHolder_FORM_NIMA')}
                                    placeholder={t('PlaceHolder_FORM_NIMA')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Aut_Dest}
                                    onChange={handleChangeDataEditForm_Aut_Dest}
                                    disabled={!aut_dest_handle_nima}
                                />
                            </>
                        }
                    </div>
                </div>  
            }
            { (aut_dest_view_inscription || aut_dest_view_opt) &&
                <div className="row p-2"> 
                    <div className="form-group">
                    {aut_dest_view_inscription &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_INSCRIPTION')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="aut_dest_inscription" 
                                name="aut_dest_inscription"
                                value={formForm.aut_dest_inscription}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                inputProps={{ style: {width: !aut_dest_view_opt?350:250,  fontSize: '18px'}, maxLength: 50 }}
                                label={errorsForm.aut_dest_inscription!=null && errorsForm.aut_dest_inscription!==""?t(errorsForm.aut_dest_inscription):t('PlaceHolder_FORM_INSCRIPTION')}
                                placeholder={t('PlaceHolder_FORM_INSCRIPTION')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Aut_Dest}
                                onChange={handleChangeDataEditForm_Aut_Dest}
                                disabled={!aut_dest_handle_inscription}
                            />
                            {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </>
                    }
                    {aut_dest_view_opt &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_OPT')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="aut_dest_opt"
                                name="aut_dest_opt"
                                value={ (formForm.aut_dest_opt === undefined || formForm.aut_dest_opt ===null || formForm.aut_dest_opt ===""
                                    || formForm.aut_dest_opt ==="0" || formForm.aut_dest_opt ===0)?0:formForm.aut_dest_opt}
                                inputProps={{ style: {width: i18n.language==='es'?170:147,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                                onBlur={handleBlurDataEditForm_Aut_Dest}
                                onChange={handleChangeDataEditForm_Aut_Dest} 
                                helperText={errorsForm.aut_dest_opt!=null?t(errorsForm.aut_dest_opt):""}
                                disabled={!aut_dest_handle_opt}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (formForm.aut_dest_opt === undefined || formForm.aut_dest_opt ===null || formForm.aut_dest_opt ===""
                                        || formForm.aut_dest_opt ==="0" || formForm.aut_dest_opt ===0)?0: formForm.aut_dest_opt
                                }}>
                                <option key={0} value=""/>
                                {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {option.namees}
                                </option>
                                ))}
                            </TextField>   
                        </>
                    }
                    </div>
                </div> 
            }

            { (aut_dest_view_cnae || aut_dest_view_licenseplate) &&
                <div className="row p-2"> 
                    <div className="form-group">
                    {aut_dest_view_cnae &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CNAE')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="aut_dest_cnae" 
                                name="aut_dest_cnae"
                                value={formForm.aut_dest_cnae}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                inputProps={{ style: {width: !aut_dest_view_licenseplate?355:250,  fontSize: '18px'}, maxLength: 100 }}
                                label={errorsForm.aut_dest_cnae!=null && errorsForm.aut_dest_cnae!==""?t(errorsForm.aut_dest_cnae):t('PlaceHolder_FORM_CNAE')}
                                placeholder={t('PlaceHolder_FORM_CNAE')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Aut_Dest}
                                onChange={handleChangeDataEditForm_Aut_Dest}
                                disabled={!aut_dest_handle_cnae}
                            />
                            {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </>
                    }
                    {aut_dest_view_licenseplate &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Vehicles')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="aut_dest_licenseplate" 
                                name="aut_dest_licenseplate"
                                value={formForm.aut_dest_licenseplate}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                inputProps={{ style: {width: !aut_dest_view_cnae?313:(i18n.language==='es'?313:324),  fontSize: '18px'}, maxLength: 100 }}
                                label={errorsForm.aut_dest_licenseplate!=null && errorsForm.aut_dest_licenseplate!==""?t(errorsForm.aut_dest_licenseplate):t('PlaceHolder_FORM_Vehicles')}
                                placeholder={t('PlaceHolder_FORM_Vehicles')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Aut_Dest}
                                onChange={handleChangeDataEditForm_Aut_Dest}
                                disabled={!aut_dest_handle_licenseplate}
                            />
                             &nbsp;&nbsp;
                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataVehicleAutDest}>
                                <DeleteIcon style={{fontSize: 28}}/>
                            </IconButton>
                            &nbsp;&nbsp;
                            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                <IconButton  size="small"  style={{
                                            color:
                                            (formForm.aut_dest_store_id===undefined
                                            || formForm.aut_dest_store_id===""
                                            || formForm.aut_dest_store_id==="0"
                                            || formForm.aut_dest_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                                            disabled={formForm.aut_dest_store_id===undefined
                                                    || formForm.aut_dest_store_id===""
                                                    || formForm.aut_dest_store_id==="0"
                                                    || formForm.aut_dest_store_id===0 } onClick={searchFormVehicles}>
                                    <SearchIcon style={{fontSize: 28}}/>
                                </IconButton>
                            }

                        </>
                    }
                    </div>
                </div> 
            }
            {aut_dest_view_economicactivity &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Economicactivity')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="aut_dest_economicactivity" 
                        name="aut_dest_economicactivity"
                        value={formForm.aut_dest_economicactivity}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        inputProps={{ style: {width: i18n.language==='es'?659:685,  fontSize: '18px'}, maxLength: 100 }}
                        label={errorsForm.aut_dest_economicactivity!=null && errorsForm.aut_dest_economicactivity!==""?t(errorsForm.aut_dest_economicactivity):t('PlaceHolder_FORM_Economicactivity')}
                        placeholder={t('PlaceHolder_FORM_Economicactivity')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Aut_Dest}
                        onChange={handleChangeDataEditForm_Aut_Dest}
                        disabled={!aut_dest_handle_economicactivity}
                    />
                    </div>
                </div> 
            }
            <FormSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Aut_Dest"}
                setFormForm={setFormForm}
            />
            <FormSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={"Aut_Dest"}
                setFormForm={setFormForm}
                titleTable={formForm.aut_dest_tradename}
                store_id={formForm.aut_dest_store_id}
                priority={5}
            />

            <FormSearchStoreVehicles
                showModalSearchVehicles={showModalSearchVehicles}
                setShowModalSearchVehicles={setShowModalSearchVehicles}
                selectStores={"Aut_Dest"}
                setFormForm={setFormForm}
                titleTable={formForm.aut_dest_tradename}
                store_id={formForm.aut_dest_store_id}
            /> 
        </> 
    )
}

FormCreateOrEditAutDest.propTypes = {
    formForm: PropTypes.object.isRequired,
    setFormForm: PropTypes.func.isRequired,
    showModalFormSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormForm: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default FormCreateOrEditAutDest;
