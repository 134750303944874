import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem  from "@mui/material/MenuItem";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import ContractSearchStores from "pages/contracts/editOrNewContract/ContractSearchStores";
import ContractSearchStoresAddresses from "pages/contracts/editOrNewContract/ContractSearchStoresAddress";
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const initialDocumentIdToSelect = [{
    "id": "",
    "documentId": "",
    "tradename": ""
}];

const ContractCreateOrEditDestTras = ({formContract,setFormContract,showModalContractSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormContract,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataContracts']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
    const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
     const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false); 
    const [provinceToSelect_Dest_Tras,setProvinceToSelect_Dest_Tras]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Dest_Tras,setPostalCodeToSelect_Dest_Tras]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const [documentIdDestToSelect,setDocumentIdDestToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredDocumentIdDest, setFilteredDocumentIdDest] = useState([]); // Opciones filtradas
    const [highlightedIndexDocumentIdDest, setHighlightedIndexDocumentIdDest] = useState(-1); // Índice de la opción resaltada 
    const [tradenameDestToSelect,setTradenameDestToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredTradenamedDest, setFilteredTradenamedDest] = useState([]); // Opciones filtradas
    const [highlightedIndexTradenamedDest, setHighlightedIndexTradenamedDest] = useState(-1); // Índice de la opción resaltada 
      
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchDocumentIdDest = (event) => {
        const searchDocumentIdDest = event.target.value;
        setFormContract(formContract => ({
        ...formContract,
        dest_tras_documentid: searchDocumentIdDest,
        })); 
        if (searchDocumentIdDest.trim() === "") {
        setFilteredDocumentIdDest([]);
        setHighlightedIndexDocumentIdDest(-1);
        return;
        } 
        const filtered = documentIdDestToSelect.filter((store) => {
        if (!store || !store.documentId) return false;
        const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
        // Comparar después de eliminar puntos y guiones de ambos
        const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        return normalizedDocumentId.includes(searchNormailzedDocumentId);
        });
        setFilteredDocumentIdDest(filtered);
        setHighlightedIndexDocumentIdDest(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectDocumentIdDest = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormContract((formContract) => ({
        ...formContract,
        dest_tras_documentid: store.documentId || "",
        }));
        setFilteredDocumentIdDest([]);
        setHighlightedIndexDocumentIdDest(-1);
    }; 
    const handleKeyDownDocumentIdDest = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredDocumentIdDest.length === 0 && formContract.dest_tras_documentid.trim() === "") {
            setFilteredDocumentIdDest(documentIdDestToSelect);
            setHighlightedIndexDocumentIdDest(0);
        } else {
            setHighlightedIndexDocumentIdDest((prevIndex) => {
            const nextIndex = prevIndex < filteredDocumentIdDest.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexDocumentIdDest((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentIdDest >= 0) {
        if (highlightedIndexDocumentIdDest >= 0 && highlightedIndexDocumentIdDest < filteredDocumentIdDest.length) {
            handleSelectDocumentIdDest(filteredDocumentIdDest[highlightedIndexDocumentIdDest]);
        }
        } else if (event.key === "Escape") {
        const isValid = documentIdDestToSelect.some((store) => {
            if (!store || !store.documentId) return false; 
            const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
            const normalizedInputId = formContract.dest_tras_documentid.toUpperCase();
            return normalizedStoreId === normalizedInputId;
        }); 
        if (!isValid) {
            setFormContract((formContract) => ({
            ...formContract,
            dest_tras_documentid: "",
            }));
        } 
        setFilteredTradenamedDest([]);
        setHighlightedIndexDocumentIdDest(-1);
        }
    };
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchTradenameDest = (event) => {
        const searchTradenameDest = event.target.value.toUpperCase();
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_tradename: searchTradenameDest,
        })); 
        if (searchTradenameDest.trim() === "") {
            setFilteredTradenamedDest([]);
            setHighlightedIndexTradenamedDest(-1);
            return;
        } 
        const filtered = tradenameDestToSelect.filter((store) => {
        if (!store || !store.tradename) return false;
            // Comparar después de eliminar puntos y guiones de ambos
            const normalizedTradeName = store.tradename.toUpperCase();
            return normalizedTradeName.includes(searchTradenameDest);
        });
        setFilteredTradenamedDest(filtered);
        setHighlightedIndexTradenamedDest(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectTradeNameDest = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormContract((formContract) => ({
        ...formContract,
        dest_tras_tradename: store.tradename || "",
        dest_tras_documentid: store.documentId || formContract.dest_tras_documentid,
        
        }));
        setFilteredTradenamedDest([]);
        setHighlightedIndexTradenamedDest(-1);
    }; 
    const handleKeyDownTradenameDest = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredTradenamedDest.length === 0 && formContract.dest_tras_tradename.trim() === "") {
            setFilteredTradenamedDest(tradenameDestToSelect);
            setHighlightedIndexTradenamedDest(0);
        } else {
            setHighlightedIndexTradenamedDest((prevIndex) => {
            const nextIndex = prevIndex < filteredTradenamedDest.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexTradenamedDest((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradenamedDest >= 0) {
        if (highlightedIndexTradenamedDest >= 0 && highlightedIndexTradenamedDest < filteredTradenamedDest.length) {
            handleSelectTradeNameDest(filteredTradenamedDest[highlightedIndexTradenamedDest]);
        }
        } else if (event.key === "Escape") {
        const isValid = tradenameDestToSelect.some((store) => {
            if (!store || !store.tradename) return false; 
            const normalizedTradeName = store.tradename.toUpperCase();
            const normalizedInputTradeName = formContract.dest_tras_tradename.toUpperCase();
            return normalizedTradeName === normalizedInputTradeName;
        }); 
        if (!isValid) {
            setFormContract((formContract) => ({
            ...formContract,
            dest_tras_tradename: "",
            }));
        } 
        setFilteredTradenamedDest([]);
        setHighlightedIndexTradenamedDest(-1);
        }
    };

    // Función para desplazar el contenedor
    const scrollToElement = (index) => {
        const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
        const item = dropdown?.children[index];
        if (item) {
        item.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
        });
        }
    };

    const handleBlur = useCallback((event) => {
        if (!event || !event.currentTarget) return;
        // Asegúrate de que no se cierra si haces clic en un elemento de la lista
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setFilteredDocumentIdDest([]);
          setHighlightedIndexDocumentIdDest(-1);
          setFilteredTradenamedDest([]);
          setHighlightedIndexTradenamedDest(-1); 
        }
    }, []);

    const handleChangeSelectDocumentType_Dest_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Dest_Tras = (e) => {
        handleChangeSelectDocumentType_Dest_Tras(e);
    };
    
    const handleChangeSelectRoadType_Dest_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Dest_Tras = (e) => {
        handleChangeSelectRoadType_Dest_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectCountry_Dest_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_country_id: event.target.value,
            dest_tras_province_id:Number(DEFAULTPROVINCE),
            dest_tras_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Dest_Tras = (e) => {
        handleChangeSelectCountry_Dest_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectProvince_Dest_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_province_id :event.target.value,
            dest_tras_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Dest_Tras = (e) => {
        handleChangeSelectProvince_Dest_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectPostalCode_Dest_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Dest_Tras = (e) => {
        handleChangeSelectPostalCode_Dest_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectCommunity_Dest_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            dest_tras_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Dest_Tras = (e) => {
        handleChangeSelectCommunity_Dest_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeDataEditContract_Dest_Tras = (e) => {
        const { name, value } = e.target;
        setFormContract(formContract => ({
          ...formContract,
          [name]: value,
        }));
    };
    const handleBlurDataEditContract_Dest_Tras = (e) => {
        handleChangeDataEditContract_Dest_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setShowModalSearchStoresAddress(true);
    };

 

    const deleteDateDestTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            dest_tras_store_id:0,
            dest_tras_documnettype_id: "",
            dest_tras_documentid:"",
            dest_tras_tradename: "",
            dest_tras_name: "",
            dest_tras_contactname: "",
            dest_tras_contactname_position: "",
            dest_tras_nima: "",
            dest_tras_inscription: "",
            dest_tras_grnp: "",
            dest_tras_opt: 0,
            dest_tras_phone: "",
            dest_tras_email: "",
            dest_tras_roadtype_id: 0,
            dest_tras_street: "",
            dest_tras_country_id: Number(DEFAULTCOUNTRY),
            dest_tras_province_id: Number(DEFAULTPROVINCE),
            dest_tras_postalcode_id: "",
            dest_tras_community_id: Number(DEFAULTCOMMUNITY) 
        }));
        setErrorsForm(validateFormContract());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            dest_tras_store_id:0,
            dest_tras_documnettype_id: "",
            dest_tras_documentid:"",
            dest_tras_tradename: "",
            dest_tras_name: "",
            dest_tras_contactname: "",
            dest_tras_contactname_position: "",
            dest_tras_nima: "",
            dest_tras_inscription: "",
            dest_tras_opt: "",
            dest_tras_grnp: "",
            dest_tras_phone: "",
            dest_tras_email: "",
            dest_tras_roadtype_id: "",
            dest_tras_street: "",
            dest_tras_country_id: "",
            dest_tras_province_id: "",
            dest_tras_postalcode_id: "",
            dest_tras_community_id: ""
             
        }));
    };

    const deleteDataSteetDestTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            dest_tras_roadtype_id: 0,
            dest_tras_street: "",
            dest_tras_country_id: Number(DEFAULTCOUNTRY),
            dest_tras_province_id: Number(DEFAULTPROVINCE),
            dest_tras_postalcode_id: "",
            dest_tras_community_id: Number(DEFAULTCOMMUNITY),
            dest_tras_nima: "",
            dest_tras_inscription: "",
            dest_tras_grnp: "",
            dest_tras_opt: 0
        }));
        setErrorsForm(validateFormContract());
    };

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`contract/getexiststorebynumberdocument/`,token,formContract.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim());
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formContract.dest_tras_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get5(ENDPOINT,`contract/getstorebynumberdocumentpriority`,token,formContract.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim(),4);
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                        setFormContract(formContract => ({
                            ...formContract,
                            dest_tras_store_id: getData.store_id,
                            dest_tras_documnettype_id: getData.documnettype_id,
                            dest_tras_tradename: getData.tradename,
                            dest_tras_name: getData.name,
                            dest_tras_contactname: getData.contacperson,
                            dest_tras_nima: getData.nima_dest_tras,
                            dest_tras_inscription: getData.pygr_dest_tras,
                            dest_tras_grnp:getData.grnp_dest_tras,
                            dest_tras_opt: getData.opt_dest_tras,
                            dest_tras_phone: getData.phone,
                            dest_tras_email: getData.email,
                            dest_tras_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            dest_tras_street: getData.street,
                            dest_tras_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            dest_tras_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id,
                            dest_tras_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            dest_tras_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id
                        }));
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            if (formContract.dest_tras_documentid && formContract.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
                fetchExistNumberDocument();
            }
        }
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,expanded,formContract.dest_tras_documentid,errorsForm.dest_tras_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,setFormContract,formContract.dest_tras_store_id,DEFAULTPROVINCE]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formContract.dest_tras_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formContract.dest_tras_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Dest_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [ENDPOINT,t,token,dispatch,i18n,showModalContractSettings,formContract.dest_tras_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formContract.dest_tras_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formContract.dest_tras_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formContract.dest_tras_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Dest_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [ENDPOINT,t,token,dispatch,i18n,showModalContractSettings,formContract.dest_tras_country_id,formContract.dest_tras_province_id,expanded,DEFAULTPROVINCE]);
 
    useEffect(() => {
        let isMounted = true; 
        const fetchDataDocumentIdDest = async () => {
          try {
            const getData = await helpHttp().get2(ENDPOINT,`contract/getlistdocumentidcontracts/`,token,
              (formContract.dest_tras_documentid!==undefined && formContract.dest_tras_documentid!==null && formContract.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formContract.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
              setDocumentIdDestToSelect(getData); 
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };   
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            if (formContract.dest_tras_documentid && formContract.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataDocumentIdDest();
            }
        } else{
            setDocumentIdDestToSelect(initialDocumentIdToSelect);
        } 
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,formContract.dest_tras_documentid,expanded]);
    
    useEffect(() => {
        let isMounted = true; 
        const fetchDataTradenameDest = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`contract/getlisttradenamecontracts/`,token,
                    (formContract.dest_tras_tradename!==undefined && formContract.dest_tras_tradename!==null && formContract.dest_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formContract.dest_tras_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    setTradenameDestToSelect(getData);
                }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };  
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            if (formContract.dest_tras_tradename && formContract.dest_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataTradenameDest();
            }
        } else{
            setTradenameDestToSelect(initialDocumentIdToSelect);
        }  
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,formContract.dest_tras_tradename,expanded]);

    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="dest_tras_documnettype_id"
                    name="dest_tras_documnettype_id"
                    value={ (formContract.dest_tras_documnettype_id === undefined || formContract.dest_tras_documnettype_id ===null || formContract.dest_tras_documnettype_id ===""
                        || formContract.dest_tras_documnettype_id ==="0" || formContract.dest_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formContract.dest_tras_documnettype_id}
                    inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Dest_Tras}
                    onChange={handleChangeSelectDocumentType_Dest_Tras}
                    helperText={errorsForm.dest_tras_documnettype_id!=null && errorsForm.dest_tras_documnettype_id!==""?t(errorsForm.dest_tras_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formContract.dest_tras_documnettype_id === undefined || formContract.dest_tras_documnettype_id ===null || formContract.dest_tras_documnettype_id ===""
                            || formContract.dest_tras_documnettype_id ==="0" || formContract.dest_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formContract.dest_tras_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_CIF')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                    <TextField
                        id="dest_tras_documentid"
                        name="dest_tras_documentid"
                        value={formContract.dest_tras_documentid}
                        onChange={handleSearchDocumentIdDest}
                        onKeyDown={handleKeyDownDocumentIdDest}
                        onBlur={(event) => {
                            // Solo cerrar si el foco se pierde fuera del desplegable
                            if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                              handleBlur();
                            }
                        }}
                        label={errorsForm.dest_tras_documentid!=null?t(errorsForm.dest_tras_documentid):t('PlaceHolder_CONTRACT_CIF')}
                        placeholder={t('PlaceHolder_CONTRACT_CIF')}
                        variant="standard"
                        inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    />
                    {/* Desplegable dinámico */}
                    {filteredDocumentIdDest.length > 0 && (
                        <div
                            className="dropdown-class"
                            style={{
                            position: "absolute",
                            left:'0px',
                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                            borderRadius: "4px",
                            marginTop: "4px",
                            maxHeight: "200px",
                            overflowY: "desto",
                            width: "350%",
                            fontSize: '18px',
                            backgroundColor: "#f8f9fa", // Fondo gris claro
                            zIndex: 1000,
                            }}
                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                        >
                            {filteredDocumentIdDest.map((store, index) => (
                            <MenuItem
                                key={store.id}
                                onClick={() => handleSelectDocumentIdDest(store)}
                                style={{
                                    cursor: "pointer",
                                    padding: "8px",
                                    borderBottom: "1px solid #eee",
                                    fontSize: '18px',
                                    backgroundColor:
                                    index === highlightedIndexDocumentIdDest ? "#4e73df" : "transparent",
                                    color: index === highlightedIndexDocumentIdDest ? "white" : "black",
                                }}
                            >
                                {store.documentId} - {store.tradename}
                            </MenuItem>
                            ))}
                        </div>
                    )}
                </span>  
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDateDestTras}>
                    <DeleteIcon style={{fontSize: 28}}/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 18}} onClick={searchFormStore}>
                        <SearchIcon style={{fontSize: 28}}/>
                    </IconButton>
                } 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_CONTACT')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="dest_tras_contactname" 
                    name="dest_tras_contactname"
                    value={formContract.dest_tras_contactname}
                    inputProps={{ style: {width: i18n.language==='es'?447:532,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.dest_tras_contactname!=null && errorsForm.dest_tras_contactname!==""?t(errorsForm.dest_tras_contactname):t('PlaceHolder_CONTRACT_CONTACT')}
                    placeholder={t('PlaceHolder_CONTRACT_CONTACT')}
                    variant="standard"
                    onBlur={handleBlurDataEditContract_Dest_Tras}
                    onChange={handleChangeDataEditContract_Dest_Tras} 
                />
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_TRADENAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                        <TextField
                            id="dest_tras_tradename"
                            name="dest_tras_tradename"
                            value={formContract.dest_tras_tradename}
                            onChange={handleSearchTradenameDest}
                            onKeyDown={handleKeyDownTradenameDest}
                            onBlur={(event) => {
                                // Solo cerrar si el foco se pierde fuera del desplegable
                                if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                  handleBlur();
                                }
                            }}
                            label={errorsForm.dest_tras_tradename!=null?t(errorsForm.dest_tras_tradename):t('PlaceHolder_CONTRACT_TRADENAME')}
                            placeholder={t('PlaceHolder_CONTRACT_TRADENAME')}
                            variant="standard"
                            inputProps={{ style: {width: 590,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        />
                        {/* Desplegable dinámico */}
                        {filteredTradenamedDest.length > 0 && (
                            <div
                                className="dropdown-class"
                                style={{
                                position: "absolute",
                                left:'0px',
                                border: "2px solid #4e73df", // Borde azul (#4e73df)
                                borderRadius: "4px",
                                marginTop: "4px",
                                maxHeight: "200px",
                                overflowY: "desto",
                                width: "150%",
                                fontSize: '18px',
                                backgroundColor: "#f8f9fa", // Fondo gris claro
                                zIndex: 1000,
                                }}
                                onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                            >
                                {filteredTradenamedDest.map((store, index) => (
                                <MenuItem
                                    key={store.id}
                                    onClick={() => handleSelectTradeNameDest(store)}
                                    style={{
                                        cursor: "pointer",
                                        padding: "8px",
                                        borderBottom: "1px solid #eee",
                                        fontSize: '18px',
                                        backgroundColor:
                                        index === highlightedIndexTradenamedDest ? "#4e73df" : "transparent",
                                        color: index === highlightedIndexTradenamedDest ? "white" : "black",
                                    }}
                                >
                                    {store.tradename} - {store.documentId}
                                </MenuItem>
                                ))}
                            </div>
                        )}
                    </span>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_name" 
                        name="dest_tras_name"
                        value={formContract.dest_tras_name}
                        inputProps={{ style: {width: i18n.language==='es'?594:615,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        label={errorsForm.dest_tras_name!=null && errorsForm.dest_tras_name!==""?t(errorsForm.dest_tras_name):t('PlaceHolder_CONTRACT_NAME')}
                        placeholder={t('PlaceHolder_CONTRACT_NAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                    />
                </div>
            </div> 
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_EMPLOYEEE_position')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_contactname_position" 
                        name="dest_tras_contactname_position"
                        value={formContract.dest_tras_contactname_position}
                        inputProps={{ style: {width: 400,  fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        label={errorsForm.dest_tras_contactname_position!=null && errorsForm.dest_tras_contactname_position!==""?t(errorsForm.dest_tras_contactname_position):t('PlaceHolder_CONTRACT_EMPLOYEEE_position')}
                        placeholder={t('PlaceHolder_CONTRACT_EMPLOYEEE_position')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                    />
                </div>
            </div> 
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_ADDRESS')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_RoadType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_roadtype"
                        name="dest_tras_roadtype"
                        value={ (formContract.dest_tras_roadtype_id === undefined || formContract.dest_tras_roadtype_id ===null || formContract.dest_tras_roadtype_id ===""
                            || formContract.dest_tras_roadtype_id ==="0" || formContract.dest_tras_roadtype_id ===0)?"":formContract.dest_tras_roadtype_id}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        variant="outlined"
                        onBlur={handleBlurSelectRoadType_Dest_Tras}
                        onChange={handleChangeSelectRoadType_Dest_Tras}
                        helperText={errorsForm.dest_tras_roadtype_id!=null && errorsForm.dest_tras_roadtype_id!==""?t(errorsForm.dest_tras_roadtype_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.dest_tras_roadtype_id === undefined || formContract.dest_tras_roadtype_id ===null || formContract.dest_tras_roadtype_id ===""
                                || formContract.dest_tras_roadtype_id ==="0" || formContract.dest_tras_roadtype_id ===0)?"": formContract.dest_tras_roadtype_id
                        }}>
                        <option key={0} value=""/>
                        {roadTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_ADDRESS')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="dest_tras_street" 
                        name="dest_tras_street"
                        value={formContract.dest_tras_street}
                        inputProps={{ style: {width: i18n.language==='es'?497:540, fontSize: '18px'}, maxLength: 250, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.dest_tras_street!=null && errorsForm.dest_tras_street!==""?t(errorsForm.dest_tras_street):t('PlaceHolder_CONTRACT_ADDRESS')}
                        placeholder={t('PlaceHolder_CONTRACT_ADDRESS')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                    />
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataSteetDestTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&& formContract.dest_tras_documentid!==undefined && formContract.dest_tras_documentid!=="")?
                        <IconButton  size="small"  style={{
                            color:
                            (formContract.dest_tras_store_id===undefined
                            || formContract.dest_tras_store_id===""
                            || formContract.dest_tras_store_id==="0"
                            || formContract.dest_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                            disabled={formContract.dest_tras_store_id===undefined
                                    || formContract.dest_tras_store_id===""
                                    || formContract.dest_tras_store_id==="0"
                                    || formContract.dest_tras_store_id===0 } onClick={searchFormStoreAddress}>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Community')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_community_id"
                        name="dest_tras_community_id"
                        value={ (formContract.dest_tras_community_id === undefined || formContract.dest_tras_community_id ===null || formContract.dest_tras_community_id ===""
                            || formContract.dest_tras_community_id ==="0" || formContract.dest_tras_community_id ===0)?Number(DEFAULTCOMMUNITY):formContract.dest_tras_community_id}
                        inputProps={{ style: {width: 250, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCommunity_Dest_Tras}
                        onChange={handleChangeSelectCommunity_Dest_Tras}
                         helperText={errorsForm.dest_tras_community_id!=null  && errorsForm.dest_tras_community_id!==""?t(errorsForm.dest_tras_community_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.dest_tras_community_id === undefined || formContract.dest_tras_community_id ===null || formContract.dest_tras_community_id ===""
                                || formContract.dest_tras_community_id ==="0" || formContract.dest_tras_community_id ===0)?Number(DEFAULTCOMMUNITY): formContract.dest_tras_community_id
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                </div>
            </div>

            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="dest_tras_country_id"
                        name="dest_tras_country_id"
                        value={ (formContract.dest_tras_country_id === undefined || formContract.dest_tras_country_id ===null || formContract.dest_tras_country_id ===""
                                || formContract.dest_tras_country_id ==="0" || formContract.dest_tras_country_id ===0)?Number(DEFAULTCOUNTRY):formContract.dest_tras_country_id}
                        inputProps={{ style: {width: i18n.language==='es'?310:335, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCountry_Dest_Tras}
                        onChange={handleChangeSelectCountry_Dest_Tras}
                          helperText={errorsForm.dest_tras_country_id!=null && errorsForm.dest_tras_country_id!==""?t(errorsForm.dest_tras_country_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.dest_tras_country_id === undefined || formContract.dest_tras_country_id ===null || formContract.dest_tras_country_id ===""
                                || formContract.dest_tras_country_id ==="0" || formContract.dest_tras_country_id ===0)?Number(DEFAULTCOUNTRY): formContract.dest_tras_country_id
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_province_id"
                        name="dest_tras_province_id"
                        value={ (formContract.dest_tras_province_id === undefined || formContract.dest_tras_province_id ===null || formContract.dest_tras_province_id ===""
                            || formContract.dest_tras_province_id ==="0" || formContract.dest_tras_province_id ===0)?Number(DEFAULTPROVINCE):formContract.dest_tras_province_id}
                        inputProps={{ style: {width: 310, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectProvince_Dest_Tras}
                        onChange={handleChangeSelectProvince_Dest_Tras}
                         helperText={errorsForm.dest_tras_province_id!=null && errorsForm.dest_tras_province_id!==""?t(errorsForm.dest_tras_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.dest_tras_province_id === undefined || formContract.dest_tras_province_id ===null || formContract.dest_tras_province_id ===""
                                || formContract.dest_tras_province_id ==="0" || formContract.dest_tras_province_id ===0)?Number(DEFAULTPROVINCE): formContract.dest_tras_province_id
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Dest_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_postalcode_id"
                        name="dest_tras_postalcode_id"
                        value={ (formContract.dest_tras_postalcode_id === undefined || formContract.dest_tras_postalcode_id ===null || formContract.dest_tras_postalcode_id ===""
                            || formContract.dest_tras_postalcode_id ==="0" || formContract.dest_tras_postalcode_id ===0)?"":formContract.dest_tras_postalcode_id}
                        inputProps={{ style: {width: i18n.language==='es'?399:365, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCode_Dest_Tras}
                        onChange={handleChangeSelectPostalCode_Dest_Tras}
                          helperText={errorsForm.dest_tras_postalcode_id!=null && errorsForm.dest_tras_postalcode_id!==""?t(errorsForm.dest_tras_postalcode_id):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formContract.dest_tras_postalcode_id === undefined || formContract.dest_tras_postalcode_id ===null || formContract.dest_tras_postalcode_id ===""
                            || formContract.dest_tras_postalcode_id ==="0" || formContract.dest_tras_postalcode_id ===0)?"": formContract.dest_tras_postalcode_id
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Dest_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                
                </div>
            </div>
          
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Phone')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_phone" 
                        name="dest_tras_phone"
                        value={formContract.dest_tras_phone}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 30 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                        label={errorsForm.dest_tras_phone!=null && errorsForm.dest_tras_phone!==""?t(errorsForm.dest_tras_phone):t('PlaceHolder_CONTRACT_Phone')}
                        placeholder={t('PlaceHolder_CONTRACT_Phone')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras}
                    />
                    {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Email')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_email" 
                        name="dest_tras_email"
                        value={formContract.dest_tras_email}
                        inputProps={{ style: {width: i18n.language==='es'?350:350, fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                        label={errorsForm.dest_tras_email!=null && errorsForm.dest_tras_email!==""?t(errorsForm.dest_tras_email):t('PlaceHolder_CONTRACT_Email')}
                        placeholder={t('PlaceHolder_CONTRACT_Email')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_OPT')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;  
                    <TextField
                        id="dest_tras_opt"
                        name="dest_tras_opt"
                        value={ (formContract.dest_tras_opt === undefined || formContract.dest_tras_opt ===null || formContract.dest_tras_opt ===""
                            || formContract.dest_tras_opt ==="0" || formContract.dest_tras_opt ===0)?0:formContract.dest_tras_opt}
                        inputProps={{ style: {width: i18n.language==='es'?530:571, fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                        variant="outlined"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                        helperText={errorsForm.dest_tras_opt!=null?t(errorsForm.dest_tras_opt):""}
                         select
                        SelectProps={{
                            native: true,
                            value: (formContract.dest_tras_opt === undefined || formContract.dest_tras_opt ===null || formContract.dest_tras_opt ===""
                                || formContract.dest_tras_opt ==="0" || formContract.dest_tras_opt ===0)?0: formContract.dest_tras_opt
                        }}>
                        <option key={0} value=""/>
                        {optTypeToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.code} - {option.namees}
                        </option>
                        ))}
                    </TextField>   
                </div>
            </div>   
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NIMA')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="dest_tras_nima" 
                        name="dest_tras_nima"
                        value={formContract.dest_tras_nima}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                        label={errorsForm.dest_tras_nima!=null && errorsForm.dest_tras_nima!==""?t(errorsForm.dest_tras_nima):t('PlaceHolder_CONTRACT_NIMA')}
                        placeholder={t('PlaceHolder_CONTRACT_NIMA')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_INSCRIPTION')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="dest_tras_inscription" 
                        name="dest_tras_inscription"
                        value={formContract.dest_tras_inscription}
                        inputProps={{ style: {width: i18n.language==='es'?300:300, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                        label={errorsForm.dest_tras_inscription!=null && errorsForm.dest_tras_inscription!==""?t(errorsForm.dest_tras_inscription):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_GESTOR')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="dest_tras_grnp" 
                        name="dest_tras_grnp"
                        value={formContract.dest_tras_grnp}
                        inputProps={{ style: {width: i18n.language==='es'?250:250, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.dest_tras_grnp!=null && errorsForm.dest_tras_grnp!==""?t(errorsForm.dest_tras_grnp):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_GESTOR')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Dest_Tras}
                        onChange={handleChangeDataEditContract_Dest_Tras} 
                    />
                </div>
            </div> 
            <ContractSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Dest_Tras"}
                setFormContract={setFormContract}
            />
            <ContractSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={"Dest_Tras"}
                setFormContract={setFormContract}
                titleTable={formContract.dest_tras_tradename}
                store_id={formContract.dest_tras_store_id}
                priority={4}
            />

        </> 
    )
}

ContractCreateOrEditDestTras.propTypes = {
    formContract: PropTypes.object.isRequired,
    setFormContract: PropTypes.func.isRequired,
    showModalContractSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormContract: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default ContractCreateOrEditDestTras;

