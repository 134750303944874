import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  isinput:true,
  isoutput:false,
  registerbookFrom:"",
  registerbookTo:"",
  preiddocSheetFrom:"",
  preiddocSheetTo:"",
  sufiddocSheetFrom:"",
  sufiddocSheetTo:"",
  numberDTFrom:"",
  numberDTTo:"",
  dateSheetFrom:null,
  dateSheetTo:null,  

  ori_tras_tradenameFrom:"",
  ori_tras_tradenameTo:"",
  opt_tras_tradenameFrom:"",
  opt_tras_tradenameTo:"",
  codelerFrom:"",
  codelerTo:"",
  rel_trans_tradenameFrom:"",
  rel_trans_tradenameTo:"",
  dest_tras_tradenameFrom:"",
  dest_tras_tradenameTo:"",
  codetreatmentFrom:"",
  codetreatmentTo:"",
  codedangerousnessFrom:"",
  codedangerousnessTo:""
};

const filtersRegisterSlice = createSlice({
  name: 'filtersRegisters',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersRegisterSlice.actions;
export default filtersRegisterSlice.reducer;
