import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 0,
  order: 'asc',
  orderBy: 'calories',
  rowsPerPage:10,
  selectAll:false,
  selectAllPendig:false,
  selectedRowsPending: {},
  selectedRows: {}
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    setSelectAll(state, action) {
      state.selectAll = action.payload;
    },

    setSelectAllPendig(state, action) {
      state.selectAllPendig = action.payload;
    },
    toggleRowSelection(state, action) {
      const id = action.payload;
      if (id === 'ALL') {
        const allSelected = Object.keys(state.selectedRows).length !== 0;
        state.selectedRows = allSelected ? {} : action.meta.reduce((acc, row) => {
          acc[row.id] = true;
          return acc;
        }, {});
      } else {
        if (state.selectedRows[id]) {
          delete state.selectedRows[id];
        } else {
          state.selectedRows[id] = true;
        }
      }
    },
    updateSelectedRowsPending(state, action) {
      const { id, isFinished } = action.payload;
      state.selectedRowsPending = {
        ...state.selectedRowsPending,
        [id]: isFinished,
      };
    },

    updateSelectedRows(state, action) {
      const { id, isPending } = action.payload;
      state.selectedRows = {
        ...state.selectedRows,
        [id]: isPending,
      };
    },
        
    resetSelectedRows(state) {
      state.selectedRows = {};
    },

    resetPagination(state) {
      return initialState; // Reinicia el estado a los valores iniciales
    },
  },
});

export const { setCurrentPage, setOrder, setOrderBy, setRowsPerPage, resetPagination, setSelectAll, setSelectAllPendig, updateSelectedRowsPending, toggleRowSelection,updateSelectedRows, resetSelectedRows   } = paginationSlice.actions;
export default paginationSlice.reducer;
