import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  numberShippingFrom:"",
  numberShippingTo:"",
  dateShippingFrom:null,
  dateShippingTo:null
};

const filtersShippingsSlice = createSlice({
  name: 'filtersShippings',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersShippingsSlice.actions;
export default filtersShippingsSlice.reducer;
