import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';   
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import TextField from '@mui/material/TextField'; 
import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import TableSorting from "pages/settings/ticketsFormatSettings/ticketsFormatIFilterList/SortingTablesTicketsFormat" 
import PropTypes from 'prop-types';

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
};
const initialState = [{
  "id" : 0,
  "namees" : "",
  "nameen" : "",
  "limitsarticleperweight" : 1,
  "articlesButtonsList":[]
}];


const TicketsFormatFilter = ({applyFilters, setApplyFilters,showTicketsFormatSettings,setShowTicketsFormatSettings}) => {
  
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const NON_ERASABLE_TICKETSFORMAT_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_TICKETSFORMAT_ID); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [data, setData] = useState(initialState); 
  const [filters, setFilters] = useState(applyFilters);
  const [numTotReg, setNumTotReg] = useState(0);
  const [numTotRegFilter, setNumTotRegFilter] = useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [inputText, setInputText] = useState(""); 
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [rowEdit, setRowEdit] = useState(initialState[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete, setIdCodeDelete] = useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]);
   
  const navigate = useNavigate(); 

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showTicketsFormatSettings) {
        setData(initialState); 
        setFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({}); 
        setInputText(""); 
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        dispatch(setLoading(false));
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);
        setShowTicketsFormatSettings(false);
        setApplyFilters(initialFilters);
        navigate("/home");
        return () => {};
      }
    },
    [
      setData,
      setFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters, 
      setInputText, 
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit, 
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      setApplyFilters,
      showTicketsFormatSettings, 
      setShowTicketsFormatSettings, 
      DELETE_CODE,
      dispatch,
      navigate
    ]
  );

  const closeModalTicketsFormatSettings = () => {    
    setData(initialState); 
    setFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({}); 
    setInputText(""); 
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    dispatch(setLoading(false));
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);
    setShowTicketsFormatSettings(false);
    setApplyFilters(initialFilters);
    navigate("/home");
    return () => {};
  };

  const openCreateOrEditData = () => {
    setInputText(""); 
    navigate("/ticketsformatdetail/0");
  };

  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      closeModalTicketsFormatSettings();
    }
  };

  const validateFormFilter = () => {

    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
     
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filters.nameesFrom)) {
      errorsFilters.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameesTo)) {
      errorsFilters.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameenFrom)) {
      errorsFilters.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameenTo)) {
      errorsFilters.nameenTo = 'Text_TextNoValid';
    } 
    if (filters.nameesTo!=="" & filters.nameesTo<filters.nameesFrom){
      errorsFilters.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filters.nameenTo!=="" & filters.nameenTo<filters.nameenFrom){
      errorsFilters.nameenTo = 'Text_TextGreater_Nameen';
    }
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate = 
      String(el.id || '') +
      String(el.namees || '') + 
      String(el.nameen || '');
    let result = dataTranslate.toLowerCase().includes(inputText.toLowerCase());
    return result;
    }
  })
  
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setRowEdit(initialState[0]);
    dispatch(setLoading(false));
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
  
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT, `ticketsformat/deleteticketsformatbyid`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          } 
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    } 
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };
  
  const handleCloseDataEdit = () => { 
    setOpenEditRow(false);
    setRowEdit(openEditRow?rowEdit:initialState[0]); 
    setIsLoadingError(false);
    toggleDrawerFilterEdit(false);
	};
   
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);
 
  useEffect(() => {
    let ignore = false;
    const fetchnumTotReg = async () => { 
      try {
        const getData = await helpHttp().get(ENDPOINT, `ticketsformat/getinformationtableticketsformat`, token);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = await helpHttp().post(ENDPOINT, `ticketsformat/getcountfilterticketsformat`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
  
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().get3(ENDPOINT, `ticketsformat/getticketsformats`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    if (showTicketsFormatSettings){
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();;
    return () => { ignore = true };
    }
  }, [ENDPOINT,t,token,dispatch,openDeleteRow,openEditRow,rowEdit,showTicketsFormatSettings,LIMITPAGESIZE,applyFilters,currencyOffset]);

  return (
    <div> 
      { showTicketsFormatSettings && 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingError?
            <i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>
            :isLoadingData? 
            <CircularProgress color={openDeleteRow?"warning":"primary"}/>:
              <i className="fas fa-newspaper fa-align-justify text-warning"></i>} {t('Text_TicketsFormat_Config')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsFormat_Create'))?
              <div>
                <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
                <button type="button" className="buttonBack" onClick={closeModalTicketsFormatSettings}>{t('Button_Back')}</button>
              </div>:
              <div>
                  <button type="button" className="buttonBack" onClick={closeModalTicketsFormatSettings}>{t('Button_Back')}</button>
              </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_TicketsFormat_Search')} 
              onChange={inputHandler}
              fullWidth
              label={t('Text_TicketsFormat_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyleHigh mb-2">
            {t('Text_Filter')}&nbsp;&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameesFrom!=="" || applyFilters.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_NameEs')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameenFrom!=="" || applyFilters.nameenTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_NameEn')}</button><> </> 
                </h1>
          </div>
          <br/>
          <div className="card shadow mb-4">    
            <div className="card-body">
              <TableSorting
                data={filteredData}
                numTotRegFilter={numTotRegFilter}
                titleTable={t('Text_TicketsFormat_Table')}
                setOpenDeleteRow={setOpenDeleteRow}
                setRowEdit={setRowEdit}
                showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsFormat_Edit'))?true:false}
                showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsFormat_Delete'))?true:false}
                numTotReg={numTotReg}
                activeOffset={activeOffset}
                rangeOffset={rangeOffset}
                currencyOffset={currencyOffset}
                setCurrencyOffset={setCurrencyOffset}
                minvalue={Number(NON_ERASABLE_TICKETSFORMAT_ID)}
              >
              </TableSorting>
            </div>             
          </div>
          {openEditFilter &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEdit(false)}
                onOpen={toggleDrawerFilterEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '600px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFilters}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filters.idFrom}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 15 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filters.idTo}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 15 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_NameEs_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filters.nameesFrom}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            label={errorsFilters.nameesFrom!=null?t(errorsFilters.nameesFrom):t('PlaceHolder_Filter_NameEs_From')}
                            placeholder={t('PlaceHolder_Filter_NameEs_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_NameEs_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filters.nameesTo}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            label={errorsFilters.nameesTo!=null?t(errorsFilters.nameesTo):t('PlaceHolder_Filter_NameEs_to')}
                            placeholder={t('PlaceHolder_Filter_NameEs_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>

                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_NameEn_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenFrom" 
                            name="nameenFrom"
                            value={filters.nameenFrom}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            label={errorsFilters.nameenFrom!=null?t(errorsFilters.nameenFrom):t('PlaceHolder_Filter_NameEn_From')}
                            placeholder={t('PlaceHolder_Filter_NameEn_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_NameEn_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameenTo" 
                            name="nameenTo"
                            value={filters.nameenTo}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            label={errorsFilters.nameenTo!=null?t(errorsFilters.nameenTo):t('PlaceHolder_Filter_NameEn_to')}
                            placeholder={t('PlaceHolder_Filter_NameEn_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }  
         
          <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.namees} - {rowEdit.nameen} - {rowEdit.codehex} </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="idCode"
                label={t('Text_Label')}
                type="text"
                fullWidth
                variant="standard"
                error
                defaultValue={idCodeDelete}
                onChange={handleChangeDeleteRow}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          {/* End Content Row */}
        </div>
        //* End Page Content *//
      }
     </div>
  )
}

TicketsFormatFilter.propTypes = {
  applyFilters: PropTypes.object.isRequired,
  setApplyFilters: PropTypes.func.isRequired,
  showTicketsFormatSettings: PropTypes.bool.isRequired,
  setShowTicketsFormatSettings: PropTypes.func.isRequired 
};
  
export default TicketsFormatFilter;
