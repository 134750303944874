import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem  from "@mui/material/MenuItem";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import ContractSearchStores from "pages/contracts/editOrNewContract/ContractSearchStores";
import ContractSearchEmployees from "pages/contracts/editOrNewContract/ContractSearchEmployee";
import ContractSearchStoresAddresses from "pages/contracts/editOrNewContract/ContractSearchStoresAddress";
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const initialDocumentIdToSelect = [{
    "id": "",
    "documentId": "",
    "tradename": ""
}];

const ContractCreateOrEditOriTras = ({formContract,setFormContract,showModalContractSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormContract,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataContracts']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
    const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchEmployees, setShowModalSearchEmployees] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
    const [priority, setPriority] = useState(1);   
    const [tetxtSearch, setTetxtSearch] = useState("Ori_Tras");   
    const [provinceToSelect_Ori_Tras,setProvinceToSelect_Ori_Tras]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Ori_Tras,setPostalCodeToSelect_Ori_Tras]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const [documentIdOriToSelect,setDocumentIdOriToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredDocumentIdOri, setFilteredDocumentIdOri] = useState([]); // Opciones filtradas
    const [highlightedIndexDocumentIdOri, setHighlightedIndexDocumentIdOri] = useState(-1); // Índice de la opción resaltada 
    const [tradenameOriToSelect,setTradenameOriToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredTradenamedOri, setFilteredTradenamedOri] = useState([]); // Opciones filtradas
    const [highlightedIndexTradenamedOri, setHighlightedIndexTradenamedOri] = useState(-1); // Índice de la opción resaltada 
      
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchDocumentIdOri = (event) => {
        const searchDocumentIdOri = event.target.value;
        setFormContract(formContract => ({
        ...formContract,
        ori_tras_documentid: searchDocumentIdOri,
        })); 
        if (searchDocumentIdOri.trim() === "") {
        setFilteredDocumentIdOri([]);
        setHighlightedIndexDocumentIdOri(-1);
        return;
        } 
        const filtered = documentIdOriToSelect.filter((store) => {
        if (!store || !store.documentId) return false;
        const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
        // Comparar después de eliminar puntos y guiones de ambos
        const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        return normalizedDocumentId.includes(searchNormailzedDocumentId);
        });
        setFilteredDocumentIdOri(filtered);
        setHighlightedIndexDocumentIdOri(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectDocumentIdOri = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormContract((formContract) => ({
        ...formContract,
        ori_tras_documentid: store.documentId || "",
        }));
        setFilteredDocumentIdOri([]);
        setHighlightedIndexDocumentIdOri(-1);
    }; 
    const handleKeyDownDocumentIdOri = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredDocumentIdOri.length === 0 && formContract.ori_tras_documentid.trim() === "") {
            setFilteredDocumentIdOri(documentIdOriToSelect);
            setHighlightedIndexDocumentIdOri(0);
        } else {
            setHighlightedIndexDocumentIdOri((prevIndex) => {
            const nextIndex = prevIndex < filteredDocumentIdOri.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexDocumentIdOri((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentIdOri >= 0) {
        if (highlightedIndexDocumentIdOri >= 0 && highlightedIndexDocumentIdOri < filteredDocumentIdOri.length) {
            handleSelectDocumentIdOri(filteredDocumentIdOri[highlightedIndexDocumentIdOri]);
        }
        } else if (event.key === "Escape") {
        const isValid = documentIdOriToSelect.some((store) => {
            if (!store || !store.documentId) return false; 
            const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
            const normalizedInputId = formContract.ori_tras_documentid.toUpperCase();
            return normalizedStoreId === normalizedInputId;
        }); 
        if (!isValid) {
            setFormContract((formContract) => ({
            ...formContract,
            ori_tras_documentid: "",
            }));
        } 
        setFilteredTradenamedOri([]);
        setHighlightedIndexDocumentIdOri(-1);
        }
    };
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchTradenameOri = (event) => {
        const searchTradenameOri = event.target.value.toUpperCase();
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_tradename: searchTradenameOri,
        })); 
        if (searchTradenameOri.trim() === "") {
            setFilteredTradenamedOri([]);
            setHighlightedIndexTradenamedOri(-1);
            return;
        } 
        const filtered = tradenameOriToSelect.filter((store) => {
        if (!store || !store.tradename) return false;
            // Comparar después de eliminar puntos y guiones de ambos
            const normalizedTradeName = store.tradename.toUpperCase();
            return normalizedTradeName.includes(searchTradenameOri);
        });
        setFilteredTradenamedOri(filtered);
        setHighlightedIndexTradenamedOri(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectTradeNameOri = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormContract((formContract) => ({
        ...formContract,
        ori_tras_tradename: store.tradename || "",
        ori_tras_documentid: store.documentId || formContract.ori_tras_documentid,
        
        }));
        setFilteredTradenamedOri([]);
        setHighlightedIndexTradenamedOri(-1);
    }; 
    const handleKeyDownTradenameOri = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredTradenamedOri.length === 0 && formContract.ori_tras_tradename.trim() === "") {
            setFilteredTradenamedOri(tradenameOriToSelect);
            setHighlightedIndexTradenamedOri(0);
        } else {
            setHighlightedIndexTradenamedOri((prevIndex) => {
            const nextIndex = prevIndex < filteredTradenamedOri.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexTradenamedOri((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradenamedOri >= 0) {
        if (highlightedIndexTradenamedOri >= 0 && highlightedIndexTradenamedOri < filteredTradenamedOri.length) {
            handleSelectTradeNameOri(filteredTradenamedOri[highlightedIndexTradenamedOri]);
        }
        } else if (event.key === "Escape") {
        const isValid = tradenameOriToSelect.some((store) => {
            if (!store || !store.tradename) return false; 
            const normalizedTradeName = store.tradename.toUpperCase();
            const normalizedInputTradeName = formContract.ori_tras_tradename.toUpperCase();
            return normalizedTradeName === normalizedInputTradeName;
        }); 
        if (!isValid) {
            setFormContract((formContract) => ({
            ...formContract,
            ori_tras_tradename: "",
            }));
        } 
        setFilteredTradenamedOri([]);
        setHighlightedIndexTradenamedOri(-1);
        }
    };

    // Función para desplazar el contenedor
    const scrollToElement = (index) => {
        const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
        const item = dropdown?.children[index];
        if (item) {
        item.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
        });
        }
    };

    const handleBlur = useCallback((event) => {
        if (!event || !event.currentTarget) return;
        // Asegúrate de que no se cierra si haces clic en un elemento de la lista
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setFilteredDocumentIdOri([]);
          setHighlightedIndexDocumentIdOri(-1);
          setFilteredTradenamedOri([]);
          setHighlightedIndexTradenamedOri(-1); 
        }
    }, []);

    const handleChangeSelectDocumentType_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Ori_Tras = (e) => {
        handleChangeSelectDocumentType_Ori_Tras(e);
    };

    const handleChangeSelectDocumentTypeEmployee_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_contactname_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentTypeEmployee_Ori_Tras = (e) => {
        handleChangeSelectDocumentTypeEmployee_Ori_Tras(e);
    };
    
    const handleChangeSelectRoadType_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Ori_Tras = (e) => {
        handleChangeSelectRoadType_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleBlurSelectDocumentTypeFiscal_Ori_Tras = (e) => {
        handleChangeSelectRoadTypeFiscal_Ori_Tras(e);
    };
    
    const handleChangeSelectRoadTypeFiscal_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_roadtype_id_fiscal : event.target.value 
        }));
    };
    
    const handleChangeSelectCountry_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_country_id: event.target.value,
            ori_tras_province_id:Number(DEFAULTPROVINCE),
            ori_tras_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Ori_Tras = (e) => {
        handleChangeSelectCountry_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectCountryFiscal_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_country_id_fiscal: event.target.value,
            ori_tras_province_id_fiscal:Number(DEFAULTPROVINCE),
            ori_tras_postalcode_id_fiscal:""
        })); 
    };
    const handleBlurSelectCountryFiscal_Ori_Tras = (e) => {
        handleChangeSelectCountryFiscal_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectProvince_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_province_id :event.target.value,
            ori_tras_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Ori_Tras = (e) => {
        handleChangeSelectProvince_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectProvinceFiscal_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_province_id_fiscal :event.target.value,
            ori_tras_postalcode_id_fiscal:""
        }));
    };
    const handleBlurSelectProvinceFiscal_Ori_Tras = (e) => {
        handleChangeSelectProvinceFiscal_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectPostalCode_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Ori_Tras = (e) => {
        handleChangeSelectPostalCode_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectPostalCodeFiscal_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_postalcode_id_fiscal:event.target.value
        }));
    };
    const handleBlurSelectPostalCodeFiscal_Ori_Tras = (e) => {
        handleChangeSelectPostalCodeFiscal_Ori_Tras(e);
        setErrorsForm(validateFormContract()); 
    };

    const handleChangeSelectCommunity_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Ori_Tras = (e) => {
        handleChangeSelectCommunity_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectCommunityFiscal_Ori_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            ori_tras_community_id_fiscal : event.target.value   
        }));
    };
    const handleBlurSelectCommunityFiscal_Ori_Tras = (e) => {
        handleChangeSelectCommunityFiscal_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeDataEditContract_Ori_Tras = (e) => {
        const { name, value } = e.target;
        setFormContract(formContract => ({
          ...formContract,
          [name]: value,
        }));
    };
    const handleBlurDataEditContract_Ori_Tras = (e) => {
        handleChangeDataEditContract_Ori_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormEmployee = (event) => {
        setShowModalSearchEmployees(true);
    };

    const searchFormStoreAddress = (event) => {
        setPriority(2);
        setTetxtSearch("Ori_Tras");
        setShowModalSearchStoresAddress(true);
    };

    const searchFormStoreAddressFiscal = (event) => {
        setPriority(-1);
        setTetxtSearch("Ori_Tras_Fiscal")
        setShowModalSearchStoresAddress(true);
    };

    const deleteDateOriTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            ori_tras_store_id:0,
            ori_tras_documnettype_id: "",
            ori_tras_documentid:"",
            ori_tras_tradename: "",
            ori_tras_name: "",
            ori_tras_contactname_employee_id : 0,
            ori_tras_contactname_documnettype_id : 0,
            ori_tras_contactname_documentid : "",
            ori_tras_contactname_firstaname : "",
            ori_tras_contactname_lastname1 : "",
            ori_tras_contactname_lastname2 : "",
            ori_tras_contactname_position : "",
            ori_tras_contactname_email : "",
            ori_tras_contactname_phone : "", 
            ori_tras_nima: "", 
            ori_tras_inscription: "",
            ori_tras_grnp: "",
            ori_tras_opt: 0,
            ori_tras_phone: "",
            ori_tras_email: "",
            ori_tras_roadtype_id: 0,
            ori_tras_street: "",
            ori_tras_country_id: Number(DEFAULTCOUNTRY),
            ori_tras_province_id: Number(DEFAULTPROVINCE),
            ori_tras_postalcode_id: "",
            ori_tras_community_id: Number(DEFAULTCOMMUNITY),
            ori_tras_roadtype_id_fiscal: 0,
            ori_tras_street_fiscal: "",
            ori_tras_country_id_fiscal: Number(DEFAULTCOUNTRY),
            ori_tras_province_id_fiscal: Number(DEFAULTPROVINCE),
            ori_tras_postalcode_id_fiscal: "",
            ori_tras_community_id_fiscal: Number(DEFAULTCOMMUNITY),
           
        }));
        setErrorsForm(validateFormContract());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            ori_tras_store_id:"",
            ori_tras_documnettype_id: "",
            ori_tras_tradename: "",
            ori_tras_name: "",
            ori_tras_contactname_employee_id : "",
            ori_tras_contactname_documnettype_id : "",
            ori_tras_contactname_documentid : "",
            ori_tras_contactname_firstaname : "",
            ori_tras_contactname_lastname1 : "",
            ori_tras_contactname_lastname2 : "",
            ori_tras_contactname_position : "",
            ori_tras_contactname_email : "",
            ori_tras_contactname_phone : "", 
            ori_tras_nima: "",
            ori_tras_inscription: "",
            ori_tras_grnp: "",
            ori_tras_opt: "",
            ori_tras_phone: "",
            ori_tras_email: "",
            ori_tras_roadtype_id: "",
            ori_tras_street: "",
            ori_tras_country_id: "",
            ori_tras_province_id: "",
            ori_tras_postalcode_id: "",
            ori_tras_community_id: "",
            ori_tras_roadtype_id_fiscal: "",
            ori_tras_street_fiscal: "",
            ori_tras_country_id_fiscal: "",
            ori_tras_province_id_fiscal: "",
            ori_tras_postalcode_id_fiscal: "",
            ori_tras_community_id_fiscal: "",
        }));
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            ori_tras_documentid: 'Text_FieldDateOriTrasDocumentIDRequired'
        })); 
    };

    const deleteDataSteetOriTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            ori_tras_roadtype_id: 0,
            ori_tras_street: "",
            ori_tras_country_id: Number(DEFAULTCOUNTRY),
            ori_tras_province_id: Number(DEFAULTPROVINCE),
            ori_tras_postalcode_id: "",
            ori_tras_community_id: Number(DEFAULTCOMMUNITY), 
            ori_tras_nima: "", 
            ori_tras_inscription: "",
            ori_tras_grnp: "",
            ori_tras_opt: 0
        }));
        setErrorsForm(validateFormContract());
    };

    const deleteDataStreetFiscalOriTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            ori_tras_roadtype_id_fiscal: 0,
            ori_tras_street_fiscal: "",
            ori_tras_country_id_fiscal: Number(DEFAULTCOUNTRY),
            ori_tras_province_id_fiscal: Number(DEFAULTPROVINCE),
            ori_tras_postalcode_id_fiscal: "",
            ori_tras_community_id_fiscal: Number(DEFAULTCOMMUNITY)
        }));
        setErrorsForm(validateFormContract());
    };

    const deleteDateEmployeeOriTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            ori_tras_contactname_employee_id: 0,
            ori_tras_contactname_documnettype_id: "",
            ori_tras_contactname_documentid: "",
            ori_tras_contactname_firstaname: "",
            ori_tras_contactname_lastname1: "",
            ori_tras_contactname_lastname2: "",
            ori_tras_contactname_position: "",
            ori_tras_contactname_email: "",
            ori_tras_contactname_phone: "",

        }));
        setErrorsForm(validateFormContract());
    }; 

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`contract/getexiststorebynumberdocument/`,token,formContract.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim());
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formContract.ori_tras_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`contract/getcontractstorebynumberdocument/`,token,formContract.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim());
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){ 
                        setFormContract(formContract => ({
                            ...formContract,
                            ori_tras_store_id: getData.store_id,
                            ori_tras_documnettype_id: getData.documnettype_id,
                            ori_tras_tradename: getData.tradename,
                            ori_tras_name: getData.name,
                            ori_tras_nima: getData.nima_ori_tras,
                            ori_tras_inscription: getData.pygr_ori_tras,
                            ori_tras_grnp:getData.grnp_ori_tras,
                            ori_tras_opt: getData.opt_ori_tras,
                            ori_tras_phone: getData.phone,
                            ori_tras_email: getData.email,
                            ori_tras_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            ori_tras_street: getData.street,
                            ori_tras_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            ori_tras_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id,
                            ori_tras_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            ori_tras_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id,
                            ori_tras_roadtype_id_fiscal: (getData.roadtype_id_fiscal===undefined 
                                                    || getData.roadtype_id_fiscal===null  
                                                    || getData.roadtype_id_fiscal===""
                                                    || getData.roadtype_id_fiscal==="0"
                                                    || getData.roadtype_id_fiscal===0)?
                                                    0:getData.roadtype_id_fiscal,          
                            ori_tras_street_fiscal: getData.street_fiscal,
                            ori_tras_country_id_fiscal: (getData.country_id_fiscal===undefined 
                                                    || getData.country_id_fiscal===null  
                                                    || getData.country_id_fiscal===""
                                                    || getData.country_id_fiscal==="0"
                                                    || getData.country_id_fiscal===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id_fiscal,
                            ori_tras_province_id_fiscal: (getData.province_id_fiscal===undefined 
                                                    || getData.province_id_fiscal===null  
                                                    || getData.province_id_fiscal===""
                                                    || getData.province_id_fiscal==="0"
                                                    || getData.province_id_fiscal===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id_fiscal,
                            ori_tras_postalcode_id_fiscal: (getData.postalcode_id_fiscal===undefined 
                                                        || getData.postalcode_id_fiscal===null  
                                                        || getData.postalcode_id_fiscal===""
                                                        || getData.postalcode_id_fiscal==="0"
                                                        || getData.postalcode_id_fiscal===0)?
                                                        "":getData.postalcode_id_fiscal,
                            ori_tras_community_id_fiscal: (getData.community_id_fiscal===undefined 
                                                        || getData.community_id_fiscal===null  
                                                        || getData.community_id_fiscal===""
                                                        || getData.community_id_fiscal==="0"
                                                        || getData.community_id_fiscal===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id_fiscal,
                        }));
                        setErrorsForm(validateFormContract());
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2'){
            if (formContract.ori_tras_documentid && formContract.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
                fetchExistNumberDocument();
            }  
        }
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,expanded,formContract.ori_tras_documentid,errorsForm.ori_tras_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,setFormContract,formContract.ori_tras_store_id,setErrorsForm,validateFormContract,DEFAULTPROVINCE]);
    

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocumentEmployee = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`contract/getexistemployeebynumberdocument/`,token,formContract.ori_tras_contactname_documentid);
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formContract.ori_tras_contactname_documentid){
                    fetchStoreNumberDocumentEmpoyee();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocumentEmpoyee = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`contract/getempoyeebynumberdocument/`,token,formContract.ori_tras_contactname_documentid);
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                        setFormContract(formContract => ({
                            ...formContract,
                            ori_tras_contactname_employee_id: getData.store_id,
                            ori_tras_contactname_documnettype_id: (getData.documnettype_id===undefined 
                                                    || getData.documnettype_id===null  
                                                    || getData.documnettype_id===""
                                                    || getData.documnettype_id==="0"
                                                    || getData.documnettype_id===0)?
                                                    0:getData.documnettype_id,
                            ori_tras_contactname_firstaname: getData.firstname,
                            ori_tras_contactname_lastname1: getData.lastname1,
                            ori_tras_contactname_lastname2: getData.lastname2,
                            ori_tras_contactname_email: getData.email,
                            ori_tras_contactname_phone: getData.phone
                        }));
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2'){
            if (formContract.ori_tras_contactname_documentid!==""){
                fetchExistNumberDocumentEmployee();
            }
        }
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,expanded,formContract.ori_tras_contactname_documentid,errorsForm.ori_tras_contactname_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,setFormContract,formContract.ori_tras_contactname_employee_id]);
    

    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formContract.ori_tras_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formContract.ori_tras_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Ori_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [ENDPOINT,t,token,dispatch,i18n,showModalContractSettings,formContract.ori_tras_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formContract.ori_tras_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formContract.ori_tras_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formContract.ori_tras_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Ori_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [ENDPOINT,t,token,dispatch,i18n,showModalContractSettings,formContract.ori_tras_country_id,formContract.ori_tras_province_id,expanded,DEFAULTPROVINCE]);
 
    useEffect(() => {
        let isMounted = true; 
        const fetchDataDocumentIdOri = async () => {
          try {
            const getData = await helpHttp().get2(ENDPOINT,`contract/getlistdocumentidcontracts/`,token,
              (formContract.ori_tras_documentid!==undefined && formContract.ori_tras_documentid!==null && formContract.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formContract.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
              setDocumentIdOriToSelect(getData); 
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };   
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2'){
            if (formContract.ori_tras_documentid && formContract.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataDocumentIdOri();
            }
        } else{
            setDocumentIdOriToSelect(initialDocumentIdToSelect);
        } 
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,formContract.ori_tras_documentid,expanded]);
    
    useEffect(() => {
        let isMounted = true; 
        const fetchDataTradenameOri = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`contract/getlisttradenamecontracts/`,token,
                    (formContract.ori_tras_tradename!==undefined && formContract.ori_tras_tradename!==null && formContract.ori_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formContract.ori_tras_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    setTradenameOriToSelect(getData);
                }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };  
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2'){
            if (formContract.ori_tras_tradename && formContract.ori_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataTradenameOri();
            }
        } else{
            setTradenameOriToSelect(initialDocumentIdToSelect);
        }  
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,formContract.ori_tras_tradename,expanded]);

    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel2') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_DocuentType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_documnettype_id"
                        name="ori_tras_documnettype_id"
                        value={ (formContract.ori_tras_documnettype_id === undefined || formContract.ori_tras_documnettype_id ===null || formContract.ori_tras_documnettype_id ===""
                            || formContract.ori_tras_documnettype_id ==="0" || formContract.ori_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formContract.ori_tras_documnettype_id}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        variant="outlined"
                        onBlur={handleBlurSelectDocumentType_Ori_Tras}
                        onChange={handleChangeSelectDocumentType_Ori_Tras}
                        helperText={errorsForm.ori_tras_documnettype_id!=null && errorsForm.ori_tras_documnettype_id!==""?t(errorsForm.ori_tras_documnettype_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_documnettype_id === undefined || formContract.ori_tras_documnettype_id ===null || formContract.ori_tras_documnettype_id ===""
                                || formContract.ori_tras_documnettype_id ==="0" || formContract.ori_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formContract.ori_tras_documnettype_id
                        }}>
                        <option key={0} value=""/>
                        {documentTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_CIF')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;  
                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                        <TextField
                            id="ori_tras_documentid"
                            name="ori_tras_documentid"
                            value={formContract.ori_tras_documentid}
                            onChange={handleSearchDocumentIdOri}
                            onKeyDown={handleKeyDownDocumentIdOri}
                            onBlur={(event) => {
                                // Solo cerrar si el foco se pierde fuera del desplegable
                                if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                  handleBlur();
                                }
                            }}
                            label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('PlaceHolder_CONTRACT_CIF')}
                            placeholder={t('PlaceHolder_CONTRACT_CIF')}
                            variant="standard"
                            inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        />
                        {/* Desplegable dinámico */}
                        {filteredDocumentIdOri.length > 0 && (
                            <div
                                className="dropdown-class"
                                style={{
                                position: "absolute",
                                left:'0px',
                                border: "2px solid #4e73df", // Borde azul (#4e73df)
                                borderRadius: "4px",
                                marginTop: "4px",
                                maxHeight: "200px",
                                overflowY: "auto",
                                width: "350%",
                                fontSize: '18px',
                                backgroundColor: "#f8f9fa", // Fondo gris claro
                                zIndex: 1000,
                                }}
                                onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                            >
                                {filteredDocumentIdOri.map((store, index) => (
                                <MenuItem
                                    key={store.id}
                                    onClick={() => handleSelectDocumentIdOri(store)}
                                    style={{
                                        cursor: "pointer",
                                        padding: "8px",
                                        borderBottom: "1px solid #eee",
                                        fontSize: '18px',
                                        backgroundColor:
                                        index === highlightedIndexDocumentIdOri ? "#4e73df" : "transparent",
                                        color: index === highlightedIndexDocumentIdOri ? "white" : "black",
                                    }}
                                >
                                    {store.documentId} - {store.tradename}
                                </MenuItem>
                                ))}
                            </div>
                        )}
                    </span>   
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDateOriTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 18}} onClick={searchFormStore}>
                            <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_TRADENAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                        <TextField
                            id="ori_tras_tradename"
                            name="ori_tras_tradename"
                            value={formContract.ori_tras_tradename}
                            onChange={handleSearchTradenameOri}
                            onKeyDown={handleKeyDownTradenameOri}
                            onBlur={(event) => {
                                // Solo cerrar si el foco se pierde fuera del desplegable
                                if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                  handleBlur();
                                }
                            }}
                            label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('PlaceHolder_CONTRACT_TRADENAME')}
                            placeholder={t('PlaceHolder_CONTRACT_TRADENAME')}
                            variant="standard"
                            inputProps={{ style: {width: 590,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        />
                        {/* Desplegable dinámico */}
                        {filteredTradenamedOri.length > 0 && (
                            <div
                                className="dropdown-class"
                                style={{
                                position: "absolute",
                                left:'0px',
                                border: "2px solid #4e73df", // Borde azul (#4e73df)
                                borderRadius: "4px",
                                marginTop: "4px",
                                maxHeight: "200px",
                                overflowY: "auto",
                                width: "150%",
                                fontSize: '18px',
                                backgroundColor: "#f8f9fa", // Fondo gris claro
                                zIndex: 1000,
                                }}
                                onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                            >
                                {filteredTradenamedOri.map((store, index) => (
                                <MenuItem
                                    key={store.id}
                                    onClick={() => handleSelectTradeNameOri(store)}
                                    style={{
                                        cursor: "pointer",
                                        padding: "8px",
                                        borderBottom: "1px solid #eee",
                                        fontSize: '18px',
                                        backgroundColor:
                                        index === highlightedIndexTradenamedOri ? "#4e73df" : "transparent",
                                        color: index === highlightedIndexTradenamedOri ? "white" : "black",
                                    }}
                                >
                                    {store.tradename} - {store.documentId}
                                </MenuItem>
                                ))}
                            </div>
                        )}
                    </span> 
 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_name" 
                        name="ori_tras_name"
                        value={formContract.ori_tras_name}
                        inputProps={{ style: {width: i18n.language==='es'?594:615,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        label={errorsForm.ori_tras_name!=null && errorsForm.ori_tras_name!==""?t(errorsForm.ori_tras_name):t('PlaceHolder_CONTRACT_NAME')}
                        placeholder={t('PlaceHolder_CONTRACT_NAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                </div>
            </div> 
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_ADDRESS')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_RoadType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="ori_tras_roadtype"
                        name="ori_tras_roadtype"
                        value={ (formContract.ori_tras_roadtype_id === undefined || formContract.ori_tras_roadtype_id ===null || formContract.ori_tras_roadtype_id ===""
                            || formContract.ori_tras_roadtype_id ==="0" || formContract.ori_tras_roadtype_id ===0)?"":formContract.ori_tras_roadtype_id}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        variant="outlined"
                        onBlur={handleBlurSelectRoadType_Ori_Tras}
                        onChange={handleChangeSelectRoadType_Ori_Tras} 
                        helperText={errorsForm.ori_tras_roadtype_id!=null && errorsForm.ori_tras_roadtype_id!==""?t(errorsForm.ori_tras_roadtype_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_roadtype_id === undefined || formContract.ori_tras_roadtype_id ===null || formContract.ori_tras_roadtype_id ===""
                                || formContract.ori_tras_roadtype_id ==="0" || formContract.ori_tras_roadtype_id ===0)?"": formContract.ori_tras_roadtype_id
                        }}>
                        <option key={0} value=""/>
                        {roadTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_ADDRESS')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_street" 
                        name="ori_tras_street"
                        value={formContract.ori_tras_street}
                        inputProps={{ style: {width: i18n.language==='es'?497:540, fontSize: '18px'}, maxLength: 250, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.ori_tras_street!=null && errorsForm.ori_tras_street!==""?t(errorsForm.ori_tras_street):t('PlaceHolder_CONTRACT_ADDRESS')}
                        placeholder={t('PlaceHolder_CONTRACT_ADDRESS')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataSteetOriTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))  && formContract.ori_tras_documentid!==undefined && formContract.ori_tras_documentid!=="") ?
                        <IconButton  size="small"  style={{
                            color:
                            (formContract.ori_tras_store_id===undefined
                            || formContract.ori_tras_store_id===""
                            || formContract.ori_tras_store_id==="0"
                            || formContract.ori_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                            disabled={formContract.ori_tras_store_id===undefined
                                    || formContract.ori_tras_store_id===""
                                    || formContract.ori_tras_store_id==="0"
                                    || formContract.ori_tras_store_id===0 } onClick={searchFormStoreAddress}>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}> {t('Text_CONTRACT_Community')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_community_id"
                        name="ori_tras_community_id"
                        value={ (formContract.ori_tras_community_id === undefined || formContract.ori_tras_community_id ===null || formContract.ori_tras_community_id ===""
                            || formContract.ori_tras_community_id ==="0" || formContract.ori_tras_community_id ===0)?Number(DEFAULTCOMMUNITY):formContract.ori_tras_community_id}
                        inputProps={{ style: {width: 250, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCommunity_Ori_Tras}
                        onChange={handleChangeSelectCommunity_Ori_Tras} 
                        helperText={errorsForm.ori_tras_community_id!=null  && errorsForm.ori_tras_community_id!==""?t(errorsForm.ori_tras_community_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_community_id === undefined || formContract.ori_tras_community_id ===null || formContract.ori_tras_community_id ===""
                                || formContract.ori_tras_community_id ==="0" || formContract.ori_tras_community_id ===0)?Number(DEFAULTCOMMUNITY): formContract.ori_tras_community_id
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>   
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_country_id"
                        name="ori_tras_country_id"
                        value={ (formContract.ori_tras_country_id === undefined || formContract.ori_tras_country_id ===null || formContract.ori_tras_country_id ===""
                                || formContract.ori_tras_country_id ==="0" || formContract.ori_tras_country_id ===0)?Number(DEFAULTCOUNTRY):formContract.ori_tras_country_id}
                        inputProps={{ style: {width: i18n.language==='es'?310:335, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCountry_Ori_Tras}
                        onChange={handleChangeSelectCountry_Ori_Tras}
                        helperText={errorsForm.ori_tras_country_id!=null && errorsForm.ori_tras_country_id!==""?t(errorsForm.ori_tras_country_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_country_id === undefined || formContract.ori_tras_country_id ===null || formContract.ori_tras_country_id ===""
                                || formContract.ori_tras_country_id ==="0" || formContract.ori_tras_country_id ===0)?Number(DEFAULTCOUNTRY): formContract.ori_tras_country_id
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_province_id"
                        name="ori_tras_province_id"
                        value={ (formContract.ori_tras_province_id === undefined || formContract.ori_tras_province_id ===null || formContract.ori_tras_province_id ===""
                            || formContract.ori_tras_province_id ==="0" || formContract.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):formContract.ori_tras_province_id}
                        inputProps={{ style: {width: 310, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectProvince_Ori_Tras}
                        onChange={handleChangeSelectProvince_Ori_Tras}
                        helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_province_id === undefined || formContract.ori_tras_province_id ===null || formContract.ori_tras_province_id ===""
                                || formContract.ori_tras_province_id ==="0" || formContract.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): formContract.ori_tras_province_id
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Ori_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_postalcode_id"
                        name="ori_tras_postalcode_id"
                        value={ (formContract.ori_tras_postalcode_id === undefined || formContract.ori_tras_postalcode_id ===null || formContract.ori_tras_postalcode_id ===""
                            || formContract.ori_tras_postalcode_id ==="0" || formContract.ori_tras_postalcode_id ===0)?"":formContract.ori_tras_postalcode_id}
                        inputProps={{ style: {width: i18n.language==='es'?399:365, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCode_Ori_Tras}
                        onChange={handleChangeSelectPostalCode_Ori_Tras}
                        helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formContract.ori_tras_postalcode_id === undefined || formContract.ori_tras_postalcode_id ===null || formContract.ori_tras_postalcode_id ===""
                            || formContract.ori_tras_postalcode_id ==="0" || formContract.ori_tras_postalcode_id ===0)?"": formContract.ori_tras_postalcode_id
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Ori_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                </div>
            </div>
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_ADDRESS_FISCAL')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_RoadType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="ori_tras_roadtype_id_fiscal"
                        name="ori_tras_roadtype_id_fiscal"
                        value={ (formContract.ori_tras_roadtype_id_fiscal === undefined || formContract.ori_tras_roadtype_id_fiscal ===null || formContract.ori_tras_roadtype_id_fiscal ===""
                            || formContract.ori_tras_roadtype_id_fiscal ==="0" || formContract.ori_tras_roadtype_id_fiscal ===0)?"":formContract.ori_tras_roadtype_id_fiscal}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        variant="outlined"
                        onBlur={handleBlurSelectDocumentTypeFiscal_Ori_Tras}
                        onChange={handleChangeSelectRoadTypeFiscal_Ori_Tras}
                        helperText={errorsForm.ori_tras_roadtype_id_fiscal!=null && errorsForm.ori_tras_roadtype_id_fiscal!==""?t(errorsForm.ori_tras_roadtype_id_fiscal):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_roadtype_id_fiscal === undefined || formContract.ori_tras_roadtype_id_fiscal ===null || formContract.ori_tras_roadtype_id_fiscal ===""
                                || formContract.ori_tras_roadtype_id_fiscal ==="0" || formContract.ori_tras_roadtype_id_fiscal ===0)?"": formContract.ori_tras_roadtype_id_fiscal
                        }}>
                        <option key={0} value=""/>
                        {roadTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_ADDRESS_FISCAL')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_street_fiscal" 
                        name="ori_tras_street_fiscal"
                        value={formContract.ori_tras_street_fiscal}
                        inputProps={{ style: {width: i18n.language==='es'?533:547, fontSize: '18px'}, maxLength: 250, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.ori_tras_street_fiscal!=null && errorsForm.ori_tras_street_fiscal!==""?t(errorsForm.ori_tras_street_fiscal):t('PlaceHolder_CONTRACT_ADDRESS_FISCAL')}
                        placeholder={t('PlaceHolder_CONTRACT_ADDRESS_FISCAL')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras}
                    /> 
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataStreetFiscalOriTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&& formContract.ori_tras_documentid!==undefined && formContract.ori_tras_documentid!=="") ?
                        <IconButton  size="small"  style={{
                            color:
                            (formContract.ori_tras_store_id===undefined
                            || formContract.ori_tras_store_id===""
                            || formContract.ori_tras_store_id==="0"
                            || formContract.ori_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                            disabled={formContract.ori_tras_store_id===undefined
                                    || formContract.ori_tras_store_id===""
                                    || formContract.ori_tras_store_id==="0"
                                    || formContract.ori_tras_store_id===0 } onClick={searchFormStoreAddressFiscal}>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Community')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="ori_tras_community_id_fiscal"
                        name="ori_tras_community_id_fiscal"
                        value={ (formContract.ori_tras_community_id_fiscal === undefined || formContract.ori_tras_community_id_fiscal ===null || formContract.ori_tras_community_id_fiscal ===""
                            || formContract.ori_tras_community_id_fiscal ==="0" || formContract.ori_tras_community_id_fiscal ===0)?Number(DEFAULTCOMMUNITY):formContract.ori_tras_community_id_fiscal}
                        inputProps={{ style: {width: 250, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCommunityFiscal_Ori_Tras}
                        onChange={handleChangeSelectCommunityFiscal_Ori_Tras}
                        helperText={errorsForm.ori_tras_community_id_fiscal!=null  && errorsForm.ori_tras_community_id_fiscal!==""?t(errorsForm.ori_tras_community_id_fiscal):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_community_id_fiscal === undefined || formContract.ori_tras_community_id_fiscal ===null || formContract.ori_tras_community_id_fiscal ===""
                                || formContract.ori_tras_community_id_fiscal ==="0" || formContract.ori_tras_community_id_fiscal ===0)?Number(DEFAULTCOMMUNITY): formContract.ori_tras_community_id_fiscal
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_country_id_fiscal"
                        name="ori_tras_country_id_fiscal"
                        value={ (formContract.ori_tras_country_id_fiscal === undefined || formContract.ori_tras_country_id_fiscal ===null || formContract.ori_tras_country_id_fiscal ===""
                                || formContract.ori_tras_country_id_fiscal ==="0" || formContract.ori_tras_country_id_fiscal ===0)?Number(DEFAULTCOUNTRY):formContract.ori_tras_country_id_fiscal}
                        inputProps={{ style: {width: i18n.language==='es'?310:335, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCountryFiscal_Ori_Tras}
                        onChange={handleChangeSelectCountryFiscal_Ori_Tras}
                        helperText={errorsForm.ori_tras_country_id_fiscal!=null && errorsForm.ori_tras_country_id_fiscal!==""?t(errorsForm.ori_tras_country_id_fiscal):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_country_id_fiscal === undefined || formContract.ori_tras_country_id_fiscal ===null || formContract.ori_tras_country_id_fiscal ===""
                                || formContract.ori_tras_country_id_fiscal ==="0" || formContract.ori_tras_country_id_fiscal ===0)?Number(DEFAULTCOUNTRY): formContract.ori_tras_country_id_fiscal
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="ori_tras_province_id_fiscal"
                        name="ori_tras_province_id_fiscal"
                        value={ (formContract.ori_tras_province_id_fiscal === undefined || formContract.ori_tras_province_id_fiscal ===null || formContract.ori_tras_province_id_fiscal ===""
                            || formContract.ori_tras_province_id_fiscal ==="0" || formContract.ori_tras_province_id_fiscal ===0)?Number(DEFAULTPROVINCE):formContract.ori_tras_province_id_fiscal}
                        inputProps={{ style: {width: 310, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectProvinceFiscal_Ori_Tras}
                        onChange={handleChangeSelectProvinceFiscal_Ori_Tras}
                        helperText={errorsForm.ori_tras_province_id_fiscal!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_province_id_fiscal === undefined || formContract.ori_tras_province_id_fiscal ===null || formContract.ori_tras_province_id_fiscal ===""
                                || formContract.ori_tras_province_id_fiscal ==="0" || formContract.ori_tras_province_id_fiscal ===0)?Number(DEFAULTPROVINCE): formContract.ori_tras_province_id_fiscal
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Ori_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="ori_tras_postalcode_id_fiscal"
                        name="ori_tras_postalcode_id_fiscal"
                        value={ (formContract.ori_tras_postalcode_id_fiscal === undefined || formContract.ori_tras_postalcode_id_fiscal ===null || formContract.ori_tras_postalcode_id_fiscal ===""
                            || formContract.ori_tras_postalcode_id_fiscal ==="0" || formContract.ori_tras_postalcode_id_fiscal ===0)?"":formContract.ori_tras_postalcode_id_fiscal}
                        inputProps={{ style: {width: i18n.language==='es'?399:365, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCodeFiscal_Ori_Tras}
                        onChange={handleChangeSelectPostalCodeFiscal_Ori_Tras}
                        helperText={errorsForm.ori_tras_postalcode_id_fiscal!=null && errorsForm.ori_tras_postalcode_id_fiscal!==""?t(errorsForm.ori_tras_postalcode_id_fiscal):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formContract.ori_tras_postalcode_id_fiscal === undefined || formContract.ori_tras_postalcode_id_fiscal ===null || formContract.ori_tras_postalcode_id_fiscal ===""
                            || formContract.ori_tras_postalcode_id_fiscal ==="0" || formContract.ori_tras_postalcode_id_fiscal ===0)?"": formContract.ori_tras_postalcode_id_fiscal
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Ori_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                </div>
            </div>

            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Phone')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_phone" 
                        name="ori_tras_phone"
                        value={formContract.ori_tras_phone}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 30 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                        label={errorsForm.ori_tras_phone!=null && errorsForm.ori_tras_phone!==""?t(errorsForm.ori_tras_phone):t('PlaceHolder_CONTRACT_Phone')}
                        placeholder={t('PlaceHolder_CONTRACT_Phone')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Email')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_email" 
                        name="ori_tras_email"
                        value={formContract.ori_tras_email}
                        inputProps={{ style: {width: i18n.language==='es'?350:350, fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                        label={errorsForm.ori_tras_email!=null && errorsForm.ori_tras_email!==""?t(errorsForm.ori_tras_email):t('PlaceHolder_CONTRACT_Email')}
                        placeholder={t('PlaceHolder_CONTRACT_Email')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_OPT')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="ori_tras_opt"
                            name="ori_tras_opt"
                            value={ (formContract.ori_tras_opt === undefined || formContract.ori_tras_opt ===null || formContract.ori_tras_opt ===""
                                || formContract.ori_tras_opt ==="0" || formContract.ori_tras_opt ===0)?0:formContract.ori_tras_opt}
                            inputProps={{ style: {width: i18n.language==='es'?530:571, fontSize: '18px'}, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                            variant="outlined"
                            onBlur={handleBlurDataEditContract_Ori_Tras}
                            onChange={handleChangeDataEditContract_Ori_Tras} 
                            helperText={errorsForm.ori_tras_opt!=null?t(errorsForm.ori_tras_opt):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (formContract.ori_tras_opt === undefined || formContract.ori_tras_opt ===null || formContract.ori_tras_opt ===""
                                    || formContract.ori_tras_opt ==="0" || formContract.ori_tras_opt ===0)?0: formContract.ori_tras_opt
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.code} - {option.namees}
                            </option>
                            ))}
                        </TextField>    
                </div>
            </div>   
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NIMA')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_nima" 
                        name="ori_tras_nima"
                        value={formContract.ori_tras_nima}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                        label={errorsForm.ori_tras_nima!=null && errorsForm.ori_tras_nima!==""?t(errorsForm.ori_tras_nima):t('PlaceHolder_CONTRACT_NIMA')}
                        placeholder={t('PlaceHolder_CONTRACT_NIMA')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_INSCRIPTION')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_inscription" 
                        name="ori_tras_inscription"
                        value={formContract.ori_tras_inscription}
                        inputProps={{ style: {width: i18n.language==='es'?300:300, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                        label={errorsForm.ori_tras_inscription!=null && errorsForm.ori_tras_inscription!==""?t(errorsForm.ori_tras_inscription):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_GESTOR')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_grnp" 
                        name="ori_tras_grnp"
                        value={formContract.ori_tras_grnp}
                        inputProps={{ style: {width: i18n.language==='es'?250:250, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_grnp!=null && errorsForm.ori_tras_grnp!==""?t(errorsForm.ori_tras_grnp):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_GESTOR')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                </div>
            </div> 
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_CONTACT_CHARGE')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_DocuentType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_contactname_documnettype_id"
                        name="ori_tras_contactname_documnettype_id"
                        value={ (formContract.ori_tras_contactname_documnettype_id === undefined || formContract.ori_tras_contactname_documnettype_id ===null || formContract.ori_tras_contactname_documnettype_id ===""
                            || formContract.ori_tras_contactname_documnettype_id ==="0" || formContract.ori_tras_contactname_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formContract.ori_tras_contactname_documnettype_id}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        variant="outlined"
                        onBlur={handleChangeSelectDocumentTypeEmployee_Ori_Tras}
                        onChange={handleBlurSelectDocumentTypeEmployee_Ori_Tras}
                        helperText={errorsForm.ori_tras_contactname_documnettype_id!=null && errorsForm.ori_tras_contactname_documnettype_id!==""?t(errorsForm.ori_tras_contactname_documnettype_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.ori_tras_contactname_documnettype_id === undefined || formContract.ori_tras_contactname_documnettype_id ===null || formContract.ori_tras_contactname_documnettype_id ===""
                                || formContract.ori_tras_contactname_documnettype_id ==="0" || formContract.ori_tras_contactname_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formContract.ori_tras_contactname_documnettype_id
                        }}>
                        <option key={0} value=""/>
                        {documentTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_CIF')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;  
                    <TextField
                        id="ori_tras_contactname_documentid" 
                        name="ori_tras_contactname_documentid"
                        value={formContract.ori_tras_contactname_documentid}
                        inputProps={{ style: {width: 250,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_contactname_documentid!=null && errorsForm.ori_tras_contactname_documentid!==""?t(errorsForm.ori_tras_contactname_documentid):t('PlaceHolder_CONTRACT_CIF')}
                        placeholder={t('PlaceHolder_CONTRACT_CIF')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDateEmployeeOriTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 18}} onClick={searchFormEmployee}>
                            <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_EMPLOYEEE_position')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_contactname_position" 
                        name="ori_tras_contactname_position"
                        value={formContract.ori_tras_contactname_position}
                        inputProps={{ style: {width: i18n.language==='es'?567:593,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_contactname_position!=null && errorsForm.ori_tras_contactname_position!==""?t(errorsForm.ori_tras_contactname_position):t('PlaceHolder_CONTRACT_EMPLOYEEE_position')}
                        placeholder={t('PlaceHolder_CONTRACT_EMPLOYEEE_position')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                </div>
            </div> 
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_EMPLOYEEE_firstname')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_contactname_firstaname" 
                        name="ori_tras_contactname_firstaname"
                        value={formContract.ori_tras_contactname_firstaname}
                        inputProps={{ style: {width: i18n.language==='es'?350:385,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_contactname_firstaname!=null && errorsForm.ori_tras_contactname_firstaname!==""?t(errorsForm.ori_tras_contactname_firstaname):t('PlaceHolder_CONTRACT_EMPLOYEEE_firstname')}
                        placeholder={t('PlaceHolder_CONTRACT_EMPLOYEEE_firstname')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_EMPLOYEEE_lastname1')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;  
                    <TextField
                        id="ori_tras_contactname_lastname1" 
                        name="ori_tras_contactname_lastname1"
                        value={formContract.ori_tras_contactname_lastname1}
                        inputProps={{ style: {width: i18n.language==='es'?351:385,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_contactname_lastname1!=null && errorsForm.ori_tras_contactname_lastname1!==""?t(errorsForm.ori_tras_contactname_lastname1):t('PlaceHolder_CONTRACT_EMPLOYEEE_lastname1')}
                        placeholder={t('PlaceHolder_CONTRACT_EMPLOYEEE_lastname1')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_EMPLOYEEE_lastname2')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_contactname_lastname2" 
                        name="ori_tras_contactname_lastname2"
                        value={formContract.ori_tras_contactname_lastname2}
                        inputProps={{ style: {width: i18n.language==='es'?351:386,  fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_contactname_lastname2!=null && errorsForm.ori_tras_contactname_lastname2!==""?t(errorsForm.ori_tras_contactname_lastname2):t('PlaceHolder_CONTRACT_EMPLOYEEE_lastname2')}
                        placeholder={t('PlaceHolder_CONTRACT_EMPLOYEEE_lastname2')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                </div>
            </div> 
            <div className="row p-2"> 
                <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Phone')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="ori_tras_contactname_phone" 
                        name="ori_tras_contactname_phone"
                        value={formContract.ori_tras_contactname_phone}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 30 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.ori_tras_contactname_phone!=null && errorsForm.ori_tras_contactname_phone!==""?t(errorsForm.ori_tras_contactname_phone):t('PlaceHolder_CONTRACT_Phone')}
                        placeholder={t('PlaceHolder_CONTRACT_Phone')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Email')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="ori_tras_contactname_email" 
                        name="ori_tras_contactname_email"
                        value={formContract.ori_tras_contactname_email}
                        inputProps={{ style: {width: i18n.language==='es'?350:350, fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                        label={errorsForm.ori_tras_contactname_email!=null && errorsForm.ori_tras_contactname_email!==""?t(errorsForm.ori_tras_contactname_email):t('PlaceHolder_CONTRACT_Email')}
                        placeholder={t('PlaceHolder_CONTRACT_Email')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Ori_Tras}
                        onChange={handleChangeDataEditContract_Ori_Tras}
                    /> 
                </div>
            </div> 
            <ContractSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Ori_Tras"}
                setFormContract={setFormContract}
            />
            <ContractSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={tetxtSearch}
                setFormContract={setFormContract}
                titleTable={formContract.ori_tras_tradename}
                store_id={formContract.ori_tras_store_id}
                priority={priority}
            />
             <ContractSearchEmployees
                showModalSearchEmployees={showModalSearchEmployees}
                setShowModalSearchEmployees={setShowModalSearchEmployees}
                setFormContract={setFormContract}
            />
            
        </> 
    )
}

ContractCreateOrEditOriTras.propTypes = {
    formContract: PropTypes.object.isRequired,
    setFormContract: PropTypes.func.isRequired,
    showModalContractSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormContract: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default ContractCreateOrEditOriTras;

