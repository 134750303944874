import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 0,
  order: 'asc',
  orderBy: 'calories',
  rowsPerPage:10
};

const paginationRegistersSlice = createSlice({
  name: 'paginationRegisters',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    }, 
    resetPagination(state) {
      return initialState; // Reinicia el estado a los valores iniciales
    },
  },
});

export const { setCurrentPage, setOrder, setOrderBy, setRowsPerPage, resetPagination   } = paginationRegistersSlice.actions;
export default paginationRegistersSlice.reducer;
