import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box'; 
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'; 
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types'; 

const initialAddress = {
  "id":"",
  "indexrowaddress":0,
  "street":"",
  "defaultaddress": true,
  "fiscaladdress": true,
  "defaulttypeaddress":0,
  "defaulttypeaddress_namees":"",
  "defaulttypeaddress_nameen":"",
  "otherdata":"",
  "roadtype_id":0,
  "roadtype_namees":"",
  "roadtype_nameen":"",
  "country_id":1,
  "country_namees":"",
  "country_nameen":"",
  "province_id":30,
  "province_name":"",
  "postalcode_id":"",
  "postalcode_postalcodename":"",
  "community_id":6,
  "community_namees":"",
  "community_nameen":"",

  "nima_opt_tras":"",
  "pygr_opt_tras":"",
  "grnp_opt_tras":"",
  "opt_id_opt_tras":0,
  "opt_code_opt_tras":"",
  "opt_namees_opt_tras":"",
  "opt_nameen_opt_tras":"",
    
  "nima_ori_tras":"",
  "pygr_ori_tras":"",
  "grnp_ori_tras":"",
  "opt_id_ori_tras":0,
  "opt_code_ori_tras":"",
  "opt_namees_ori_tras":"",
  "opt_nameen_ori_tras":"",
  
  "nima_aut_tras":"",
  "pygr_aut_tras":"",
  "grnp_aut_tras":"",
  "opt_id_aut_tras":0,
  "opt_code_aut_tras":"",
  "opt_namees_aut_tras":"",
  "opt_nameen_aut_tras":"",
  
  "nima_dest_tras":"",
  "pygr_dest_tras":"",
  "grnp_dest_tras":"",
  "opt_id_dest_tras":0,
  "opt_code_dest_tras":"",
  "opt_namees_dest_tras":"",
  "opt_nameen_dest_tras":"",
  
  "nima_aut_dest":"",
  "pygr_aut_dest":"",
  "grnp_aut_dest":"",
  "opt_id_aut_dest":0,
  "opt_code_aut_dest":"",
  "opt_namees_aut_dest":"",
  "opt_nameen_aut_dest":"",
    
  "nima_rel_trans":"",
  "pygr_rel_trans":"",
  "grnp_rel_trans":"",
  "opt_id_rel_trans":0,
  "opt_code_rel_trans":"",
  "opt_namees_rel_trans":"",
  "opt_nameen_rel_trans":"",
  
  "nima_resp_prod":"",
  "pygr_resp_prod":"",
  "grnp_resp_prod":"",
  "opt_id_resp_prod":0,
  "opt_code_resp_prod":"",
  "opt_namees_resp_prod":"",
  "opt_nameen_resp_prod":""
};
 

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialTypesOptToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": ""
}];

const initialCountriesToSelect = [{
  "id" : 1,
  "namees" : "",
  "nameen" : "",
  "enabled":true
}];

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":30
}];


const typeOfPriority = [
  {
    id: 0,
    "namees":"SIN PRIORIDAD",
    "nameen":"WITHOUT PRIORITY",
  },{
    id: 1,
    "namees":"PRIORIDAD OPERADOR DE TRASLADO",
    "nameen":"TRANSFER OPERATOR PRIORITY",
  },{
    id: 2,
    "namees":"PRIORIDAD ORIGEN DEL TRASLADO (Centro Productor)",
    "nameen":"PRIORITY ORIGIN OF THE TRANSFER (Producer Center)",
  },{
    id: 3,
    "namees":"PRIORIDAD ORIGEN DEL TRASLADO (Empresa Autorizada)",
    "nameen":"PRIORITY ORIGIN OF THE TRANSFER (Authorized Company)",
  },{
    id: 4,
    "namees":"PRIORIDAD DESTINO DEL TRASLADO (Instalación de Destino)",
    "nameen":"TRANSFER DESTINATION PRIORITY (Destination Facility)",
  },{
    id: 5,
    "namees":"PRIORIDAD DESTINO DEL TRASLADO (Empresa Autorizada)",
    "nameen":"PRIORIDAD DESTINO DEL TRASLADO (Empresa Autorizada)",
  },{
    id: 6,
    "namees":"PRIORIDAD TRANSPORTISTA",
    "nameen":"CARRIER PRIORITY",
  },{
    id: 7,
    "namees":"PRIORIDAD SRAP",
    "nameen":"SRAP PRIORITY",
  },
] 


const StoreCreateOrEditAddress = ({openEditRowAddress,setOpenEditRowAddress,openEditNewAddress,setOpenEditNewAddress,formStore,setFormStore,editRowAddress,setEditRowAddress}) => {
  const {t,i18n} = useTranslation(['listDataStores']); 
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
  const [newAddress,setNewAddress]= useState(initialAddress);
  const [errorsAddress, setErrorsAddress] = useState({});
  const [roadTypesToSelect,setRoadTypesToSelect]= useState(initialTypesToSelect);
  const [communityToSelect,setCommunityToSelect]= useState(initialTypesToSelect);
  const [countryToSelect,setCountryToSelect]= useState(initialCountriesToSelect);
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const [optTypeToSelect,setOptTypeToSelect]= useState(initialTypesOptToSelect);
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowAddress) {
        setNewAddress(initialAddress);
        setErrorsAddress({});
        setRoadTypesToSelect(initialTypesToSelect);
        setCommunityToSelect(initialTypesToSelect);
        setCountryToSelect(initialCountriesToSelect);
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setOptTypeToSelect(initialTypesOptToSelect);
        setEditRowAddress(initialAddress);
        setOpenEditNewAddress(false);
        setOpenEditRowAddress(false); 
      }
    },[
      setNewAddress,
      setErrorsAddress,
      setRoadTypesToSelect,
      setCommunityToSelect,
      setCountryToSelect,
      setProvinceToSelect,
      setPostalCodeToSelect,
      setOptTypeToSelect,
      openEditRowAddress, 
      setEditRowAddress,
      setOpenEditRowAddress, 
      setOpenEditNewAddress]
  );

  const handleCloseNewAddress = () => {
    setNewAddress(initialAddress);
    setErrorsAddress({});
    setRoadTypesToSelect(initialTypesToSelect);
    setCommunityToSelect(initialTypesToSelect);
    setCountryToSelect(initialCountriesToSelect);
    setProvinceToSelect(initialProvincesToSelect);
    setOptTypeToSelect(initialTypesOptToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setEditRowAddress(initialAddress);
    setOpenEditNewAddress(false);
    setOpenEditRowAddress(false);
    toggleDrawerNewAddress(false);
  };

  const  handleChangeNewAddress = (e) => {
    const { name, value } = e.target;
    setNewAddress(newAddress => ({
      ...newAddress,
      [name]: value,
    }));   
  };
  
  const handleBlurNewAddress = (e) => {
    handleChangeNewAddress(e);
    setErrorsAddress(validateFormAddress());
  };
  
  const handleChangeSelectRoadType = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      roadtype_id : event.target.value
      
    }));
  };

  const handleBlurSelectRoadType = (e) => {
    handleChangeSelectRoadType(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectCommunity = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      community_id : event.target.value
    }));
  };

  const handleBlurSelectCommunity = (e) => {
    handleChangeSelectCommunity(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectOptType = (e) => {
    const { name, value } = e.target;
    setNewAddress(newAddress => ({
      ...newAddress,
      [name]: value,
    }));
  };

  const handleBlurSelectOptTyoe = (e) => {
    handleChangeSelectOptType(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectPriority = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      defaulttypeaddress : event.target.value
      
    }));
  };

  const handleBlurSelectPriority = (e) => {
    handleChangeSelectPriority(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectCountry = (event) => {
    setNewAddress(newAddress => ({
        ...newAddress,
        country_id: event.target.value,
        province_id:0,
        postalcode_id:0
    })); 
  };

  const handleBlurSelectCountry = (e) => {
      handleChangeSelectCountry(e);
      setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectProvince = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      province_id :event.target.value,
      postalcode_id:0
    }));
  };

  const handleBlurSelectProvince = (e) => {
    handleChangeSelectProvince(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeSelectPostalCode = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
      postalcode_id:event.target.value
    }));
  };

  const handleBlurSelectPostalCode = (e) => {
    handleChangeSelectPostalCode(e);
    setErrorsAddress(validateFormAddress());
  };

  const handleChangeEnabledDefaultAddress = () => {
    setNewAddress(newAddress => ({
      ...newAddress,
      defaultaddress: !newAddress.defaultaddress
    }));
  };

  const handleChangeEnabledFiscaleAddress = () => {
    setNewAddress(newAddress => ({
      ...newAddress,
      fiscaladdress: !newAddress.fiscaladdress
    }));
  };
    
  const toggleDrawerNewAddress  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewAddress();
    }
  };

  const deleteNima = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
        nima_opt_tras:"",
        nima_ori_tras:"",
        nima_aut_tras: "",
        nima_dest_tras:"",
        nima_aut_dest: "",
        nima_rel_trans: "",
        nima_resp_prod: "" 
    }));
  };

  const copyNima = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress, 
        nima_ori_tras:newAddress.nima_opt_tras,
        nima_aut_tras: newAddress.nima_opt_tras,
        nima_dest_tras:newAddress.nima_opt_tras,
        nima_aut_dest: newAddress.nima_opt_tras,
        nima_rel_trans: newAddress.nima_opt_tras,
        nima_resp_prod: newAddress.nima_opt_tras
    }));
  };

  const deletePYGR = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
        pygr_opt_tras:"",
        pygr_ori_tras:"",
        pygr_aut_tras: "",
        pygr_dest_tras:"",
        pygr_aut_dest: "",
        pygr_rel_trans: "",
        pygr_resp_prod: "" 
    }));
  };

  const deleteGRNP = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress,
        grnp_opt_tras:"",
        grnp_ori_tras:"",
        grnp_aut_tras: "",
        grnp_dest_tras:"",
        grnp_aut_dest: "",
        grnp_rel_trans: "",
        grnp_resp_prod: "" 
    }));
  };

  const copyGRNP = (event) => {
    setNewAddress(newAddress => ({
      ...newAddress, 
        grnp_ori_tras:newAddress.grnp_opt_tras,
        grnp_aut_tras: newAddress.grnp_opt_tras,
        grnp_dest_tras:newAddress.grnp_opt_tras,
        grnp_aut_dest: newAddress.grnp_opt_tras,
        grnp_rel_trans: newAddress.grnp_opt_tras,
        grnp_resp_prod: newAddress.grnp_opt_tras
    }));
  };

  const validateFormAddress = () => {
    let errorsAddress = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (newAddress.roadtype_id===undefined || newAddress.roadtype_id===null ||newAddress.roadtype_id==="" || newAddress.roadtype_id===0 || newAddress.roadtype_id==="0") {
      errorsAddress.roadtype_id = 'Text_RoadTypeRequired';
    }
    if (newAddress.street===""){
        errorsAddress.street = 'Text_StreetRequired';
    } else {
      if (regexInvalidCharacters.test(newAddress.street)) {
        errorsAddress.street = 'Text_TextNoValid';
      }
    } 

    if (regexInvalidCharacters.test(newAddress.otherdata)) {
      errorsAddress.otherdata = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(newAddress.nima_opt_tras)) {
      errorsAddress.nima_opt_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_opt_tras)) {
      errorsAddress.pygr_opt_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_opt_tras)) {
      errorsAddress.grnp_opt_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.nima_ori_tras)) {
      errorsAddress.nima_ori_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_ori_tras)) {
      errorsAddress.pygr_ori_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_ori_tras)) {
      errorsAddress.grnp_ori_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.nima_aut_tras)) {
      errorsAddress.nima_aut_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_aut_tras)) {
      errorsAddress.pygr_aut_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_aut_tras)) {
      errorsAddress.grnp_aut_tras = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(newAddress.nima_dest_tras)) {
      errorsAddress.nima_dest_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_dest_tras)) {
      errorsAddress.grnp_dest_tras = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_dest_tras)) {
      errorsAddress.pygr_dest_tras = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(newAddress.nima_aut_dest)) {
      errorsAddress.nima_aut_dest = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_aut_dest)) {
      errorsAddress.pygr_aut_dest = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_aut_dest)) {
      errorsAddress.grnp_aut_dest = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(newAddress.nima_rel_trans)) {
      errorsAddress.nima_rel_trans = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_rel_trans)) {
      errorsAddress.pygr_rel_trans = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_rel_trans)) {
      errorsAddress.grnp_rel_trans = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(newAddress.nima_resp_prod)) {
      errorsAddress.nima_resp_prod = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.pygr_resp_prod)) {
      errorsAddress.pygr_resp_prod = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(newAddress.grnp_resp_prod)) {
      errorsAddress.grnp_resp_prod = 'Text_TextNoValid';
    } 

    if (newAddress.country_id===undefined || newAddress.country_id===null ||newAddress.country_id==="" || newAddress.country_id===0 || newAddress.country_id==="0") {
      errorsAddress.country_id = 'Text_CountryRequired';
    }
    if (newAddress.postalcode_id===undefined || newAddress.postalcode_id===null ||newAddress.postalcode_id==="" || newAddress.postalcode_id===0 || newAddress.postalcode_id==="0") {
      errorsAddress.postalcode_id = 'Text_PostalCodeRequired';
    }
    if (!openEditNewAddress){
      if (formStore.addresses.find(item => {return (item.street.trim().toUpperCase()=== newAddress.street.trim().toUpperCase()) })){
        errorsAddress.addresses = 'Text_Store_Address_Exist';
      }
    }else{
      if (formStore.addresses.find((item) => {return (item.indexrowaddress!==newAddress.indexrowaddress && item.street.trim().toUpperCase()=== newAddress.street.trim().toUpperCase() ) })){
        errorsAddress.addresses = 'Text_Store_Address_Exist';
      }
    }  
    return errorsAddress;
  };

  const handleSubmitNewAddress = () => {  
    setErrorsAddress(validateFormAddress());
    let errorsCheckAddress=validateFormAddress();
    if ((Object.keys(errorsCheckAddress).length === 0) 
          && newAddress.roadtype_id!==undefined && newAddress.roadtype_id!==null 
          && newAddress.roadtype_id!=='' && newAddress.roadtype_id!=='0' && newAddress.roadtype_id!==0 
          && newAddress.street!==""
          && newAddress.country_id!==undefined && newAddress.country_id!==null 
          && newAddress.country_id!=='' && newAddress.country_id!=='0' && newAddress.country_id!==0 
          && newAddress.postalcode_id!==undefined && newAddress.postalcode_id!==null 
          && newAddress.postalcode_id!=='' && newAddress.postalcode_id!=='0' && newAddress.postalcode_id!==0 
      ) {
      let defaultaddressFound=newAddress.defaultaddress;
      let fiscaladdressFound=newAddress.fiscaladdress;
      if(newAddress.community_id===undefined || newAddress.community_id===null || newAddress.community_id===""|| 
         newAddress.community_id==="0" || newAddress.community_id===0){
          newAddress.community_id= Number(DEFAULTCOMMUNITY);
      }
      if(newAddress.province_id===undefined || newAddress.province_id===null || newAddress.province_id===""|| 
        newAddress.province_id==="0" || newAddress.province_id===0){
         newAddress.province_id= Number(DEFAULTPROVINCE);
      }
      if(newAddress.opt_id_opt_tras===undefined || newAddress.opt_id_opt_tras===null || newAddress.opt_id_opt_tras===""|| 
        newAddress.opt_id_opt_tras==="0" || newAddress.opt_id_opt_tras===0){
          setNewAddress(newAddress => ({
            ...newAddress,
            opt_id_opt_tras:0,
            opt_code_opt_tras:"",
            opt_namees_opt_tras:"",
            opt_nameen_opt_tras:""
          }));
      }else{
        let optTypeOpt_TrasFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_opt_tras));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_opt_tras:optTypeOpt_TrasFound.code,
            opt_namees_opt_tras:optTypeOpt_TrasFound.namees,
            opt_nameen_opt_tras:optTypeOpt_TrasFound.nameen
          }));
      }

      if(newAddress.opt_id_ori_tras===undefined || newAddress.opt_id_ori_tras===null || newAddress.opt_id_ori_tras===""|| 
        newAddress.opt_id_ori_tras==="0" || newAddress.opt_id_ori_tras===0){
          setNewAddress(newAddress => ({
            ...newAddress,
            opt_id_ori_tras:0,
            opt_code_ori_tras:"",
            opt_namees_ori_tras:"",
            opt_nameen_ori_tras:""
          }));
      }else{
        let optTypeOri_TrasFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_ori_tras));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_ori_tras:optTypeOri_TrasFound.code,
            opt_namees_ori_tras:optTypeOri_TrasFound.namees,
            opt_nameen_ori_tras:optTypeOri_TrasFound.nameen
          }));
      }

      if(newAddress.opt_id_aut_tras===undefined || newAddress.opt_id_aut_tras===null || newAddress.opt_id_aut_tras===""|| 
        newAddress.opt_id_aut_tras==="0" || newAddress.opt_id_aut_tras===0){
          setNewAddress(newAddress => ({
            ...newAddress,
              opt_id_aut_tras:0,
              opt_code_aut_tras:"",
              opt_namees_aut_tras:"",
              opt_nameen_aut_tras:""
          }));
      }else{
        let optTypeAut_TrasFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_aut_tras));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_aut_tras:optTypeAut_TrasFound.code,
            opt_namees_aut_tras:optTypeAut_TrasFound.namees,
            opt_nameen_aut_tras:optTypeAut_TrasFound.nameen
          }));
      }

      if(newAddress.opt_id_dest_tras===undefined || newAddress.opt_id_dest_tras===null || newAddress.opt_id_dest_tras===""|| 
        newAddress.opt_id_dest_tras==="0" || newAddress.opt_id_dest_tras===0){
          setNewAddress(newAddress => ({
            ...newAddress,
              opt_id_dest_tras:0,
              opt_code_dest_tras:"",
              opt_namees_dest_tras:"",
              opt_nameen_dest_tras:""
          }));
      }else{
        let optTypeDest_TrasFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_dest_tras));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_dest_tras:optTypeDest_TrasFound.code,
            opt_namees_dest_tras:optTypeDest_TrasFound.namees,
            opt_nameen_dest_tras:optTypeDest_TrasFound.nameen
          }));
      }

      if(newAddress.opt_id_aut_dest===undefined || newAddress.opt_id_aut_dest===null || newAddress.opt_id_aut_dest===""|| 
        newAddress.opt_id_aut_dest==="0" || newAddress.opt_id_aut_dest===0){
          setNewAddress(newAddress => ({
            ...newAddress,
              opt_id_aut_dest:0,
              opt_code_aut_dest:"",
              opt_namees_aut_dest:"",
              opt_nameen_aut_dest:""
          }));
      }else{
        let optTypeAut_DestFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_aut_dest));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_aut_dest:optTypeAut_DestFound.code,
            opt_namees_aut_dest:optTypeAut_DestFound.namees,
            opt_nameen_aut_dest:optTypeAut_DestFound.nameen
          }));
      }

      if(newAddress.opt_id_rel_trans===undefined || newAddress.opt_id_rel_trans===null || newAddress.opt_id_rel_trans===""|| 
        newAddress.opt_id_rel_trans==="0" || newAddress.opt_id_rel_trans===0){
          setNewAddress(newAddress => ({
            ...newAddress,
              opt_id_rel_trans:0,
              opt_code_rel_trans:"",
              opt_namees_rel_trans:"",
              opt_nameen_rel_trans:""
          }));
      }else{
        let optTypeRel_TransFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_rel_trans));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_rel_trans:optTypeRel_TransFound.code,
            opt_namees_rel_trans:optTypeRel_TransFound.namees,
            opt_nameen_rel_trans:optTypeRel_TransFound.nameen
          }));
      }

      if(newAddress.opt_id_resp_prod===undefined || newAddress.opt_id_resp_prod===null || newAddress.opt_id_resp_prod===""|| 
        newAddress.opt_id_resp_prod==="0" || newAddress.opt_id_resp_prod===0){
          setNewAddress(newAddress => ({
            ...newAddress,
              opt_id_resp_prod:0,
              opt_code_resp_prod:"",
              opt_namees_resp_prod:"",
              opt_nameen_resp_prod:""
          }));
      }else{
        let optTypeResp_ProdFound = optTypeToSelect.find( item => item.id === Number(newAddress.opt_id_resp_prod));
        setNewAddress(newAddress => ({
          ...newAddress,
            opt_code_resp_prod:optTypeResp_ProdFound.code,
            opt_namees_resp_prod:optTypeResp_ProdFound.namees,
            opt_nameen_resp_prod:optTypeResp_ProdFound.nameen
          }));
      }
      

      let communityFound = communityToSelect.find( item => item.id === Number(newAddress.community_id));
      let provinceFound = provinceToSelect.find( item => item.id === Number(newAddress.province_id));
      let countryFound = countryToSelect.find( item => item.id === Number(newAddress.country_id));
      let roadTypeFound = roadTypesToSelect.find( item => item.id === Number(newAddress.roadtype_id));
      let postalcodeFound = postalCodeToSelect.find( item => item.id === Number(newAddress.postalcode_id));
      let priorityFound = typeOfPriority.find( item => item.id === Number(newAddress.defaulttypeaddress));
      
      if (newAddress.length<=1){
        defaultaddressFound=true;
        fiscaladdressFound=true;
      }

      if (roadTypeFound!==undefined && postalcodeFound!==undefined) {
        let addressToAdd={
          "id":newAddress.id,
          "indexrowaddress":openEditNewAddress?newAddress.indexrowaddress:formStore.addresses.length>0?formStore.addresses[formStore.addresses.length-1].indexrowaddress+1:1,
          "street":newAddress.street,
          "defaultaddress": defaultaddressFound,
          "fiscaladdress": fiscaladdressFound,
          "defaulttypeaddress":priorityFound.id,
          "defaulttypeaddress_namees":priorityFound.namees,
          "defaulttypeaddress_nameen":priorityFound.nameen,
          "otherdata":newAddress.otherdata,
          "roadtype_id": roadTypeFound.id,
          "roadtype_namees": roadTypeFound.namees,
          "roadtype_nameen": roadTypeFound.nameen,
          "country_id": countryFound.id,
          "country_namees": countryFound.namees,
          "country_nameen": countryFound.nameen,
          "province_id": provinceFound.id,
          "province_name": provinceFound.name, 
          "postalcode_id":postalcodeFound.id,
          "postalcode_postalcodename":postalcodeFound.postalcodename,
          "community_id":communityFound.id,
          "community_namees":communityFound.namees,
          "community_nameen":communityFound.nameen,
          "nima_opt_tras":newAddress.nima_opt_tras,
          "pygr_opt_tras":newAddress.pygr_opt_tras,
          "grnp_opt_tras":newAddress.grnp_opt_tras,
          "opt_id_opt_tras":newAddress.opt_id_opt_tras,
          "opt_code_opt_tras":newAddress.opt_code_opt_tras,
          "opt_namees_opt_tras":newAddress.opt_namees_opt_tras,
          "opt_nameen_opt_tras":newAddress.opt_nameen_opt_tras,
            
          "nima_ori_tras":newAddress.nima_ori_tras,
          "pygr_ori_tras":newAddress.pygr_ori_tras,
          "grnp_ori_tras":newAddress.grnp_ori_tras,
          "opt_id_ori_tras":newAddress.opt_id_ori_tras,
          "opt_code_ori_tras":newAddress.opt_code_ori_tras,
          "opt_namees_ori_tras":newAddress.opt_namees_ori_tras,
          "opt_nameen_ori_tras":newAddress.opt_nameen_ori_tras,
          
          "nima_aut_tras":newAddress.nima_aut_tras,
          "pygr_aut_tras":newAddress.pygr_aut_tras,
          "grnp_aut_tras":newAddress.grnp_aut_tras,
          "opt_id_aut_tras":newAddress.opt_id_aut_tras,
          "opt_code_aut_tras":newAddress.opt_code_aut_tras,
          "opt_namees_aut_tras":newAddress.opt_namees_aut_tras,
          "opt_nameen_aut_tras":newAddress.opt_nameen_aut_tras,
          
          "nima_dest_tras":newAddress.nima_dest_tras,
          "grnp_dest_tras":newAddress.grnp_dest_tras,
          "pygr_dest_tras":newAddress.pygr_dest_tras,
          "opt_id_dest_tras":newAddress.opt_id_dest_tras,
          "opt_code_dest_tras":newAddress.opt_code_dest_tras,
          "opt_namees_dest_tras":newAddress.opt_namees_dest_tras,
          "opt_nameen_dest_tras":newAddress.opt_nameen_dest_tras,
          
          "nima_aut_dest":newAddress.nima_aut_dest,
          "pygr_aut_dest":newAddress.pygr_aut_dest,
          "grnp_aut_dest":newAddress.grnp_aut_dest,
          "opt_id_aut_dest":newAddress.opt_id_aut_dest,
          "opt_code_aut_dest":newAddress.opt_code_aut_dest,
          "opt_namees_aut_dest":newAddress.opt_namees_aut_dest,
          "opt_nameen_aut_dest":newAddress.opt_nameen_aut_dest,
            
          "nima_rel_trans":newAddress.nima_rel_trans,
          "pygr_rel_trans":newAddress.pygr_rel_trans,
          "grnp_rel_trans":newAddress.grnp_rel_trans,
          "opt_id_rel_trans":newAddress.opt_id_rel_trans,
          "opt_code_rel_trans":newAddress.opt_code_rel_trans,
          "opt_namees_rel_trans":newAddress.opt_namees_rel_trans,
          "opt_nameen_rel_trans":newAddress.opt_nameen_rel_trans,
          
          "nima_resp_prod":newAddress.nima_resp_prod,
          "pygr_resp_prod":newAddress.pygr_resp_prod,
          "grnp_resp_prod":newAddress.grnp_resp_prod,
          "opt_id_resp_prod":newAddress.opt_id_resp_prod,
          "opt_code_resp_prod":newAddress.opt_code_resp_prod,
          "opt_namees_resp_prod":newAddress.opt_namees_resp_prod,
          "opt_nameen_resp_prod":newAddress.opt_nameen_resp_prod
        }
        if (addressToAdd.defaultaddress){
          formStore.addresses.map((item)=>{
            return item.defaultaddress=false  
          })
          setFormStore(formStore => ({
            ...formStore,
          }))
        }

        if (addressToAdd.fiscaladdress){
          formStore.addresses.map((item)=>{
              return item.fiscaladdress=false  
          })
          setFormStore(formStore => ({
            ...formStore,
          }))
        }

        if (!openEditNewAddress){
          formStore.addresses.push(addressToAdd);
        }else{
          let idx=formStore.addresses.findIndex(((item) => item.indexrowaddress === newAddress.indexrowaddress));
          formStore.addresses[idx]=addressToAdd
        }
        setFormStore(formStore => ({
          ...formStore,
        }))
      }
      handleCloseNewAddress();
    }else{
      if (errorsCheckAddress.roadtype_id){
        dispatch(warningErrorAction(t(errorsCheckAddress.roadtype_id)));
      }
      if (errorsCheckAddress.street){
        dispatch(warningErrorAction(t(errorsCheckAddress.street)));
      }
      if (errorsCheckAddress.postalcode_id){
        dispatch(warningErrorAction(t(errorsCheckAddress.postalcode_id)));
      }
      if (errorsCheckAddress.addresses){
        dispatch(warningErrorAction(t(errorsCheckAddress.addresses)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataRoadType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setRoadTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCommunity = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynamees`, token)
        : await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCommunityToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCountry = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `country/simplecountriesbynamees`, token)
          : await helpHttp().get(ENDPOINT, `country/simplecountriesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCountryToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataOptType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `opttype/opttypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `opttype/opttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setOptTypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    fetchDataRoadType();
    fetchDataCommunity();
    fetchDataCountry();
    fetchDataOptType();

    return () => { isMounted = false; };

  }, [ENDPOINT, t, token, dispatch, i18n.language, openEditRowAddress, openEditNewAddress]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = newAddress.country_id!==""
        ? await helpHttp().get2(ENDPOINT, `province/simpleprovincesbycountrybyname/`, token,newAddress.country_id)
        : await  helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
   
    fetchDataProvince();
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowAddress,newAddress.country_id]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = newAddress.province_id!==""
        ? await helpHttp().get2(ENDPOINT, `postalcode/simplepostalcodesbyprovincebyname/`, token,newAddress.province_id)
        : await  helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,newAddress.country_id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    fetchDataPostalCode();
    return () => { isMounted = false };
    
  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowAddress,newAddress.country_id,newAddress.province_id]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `postalcode/getpostalcodewithprovinceandcountrybyid/`, token, editRowAddress.postalcode_id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setNewAddress(newAddress => ({
            ...newAddress,
            country_id: getData.country_id !== null ? getData.country_id : Number(DEFAULTCOUNTRY),
            province_id: getData.province_id !== null ? getData.province_id : Number(DEFAULTPROVINCE)
          }));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (openEditNewAddress && editRowAddress.postalcode_id !== "") {
      fetchDataPostalCode();
      setNewAddress(newAddress => ({
        ...newAddress,
      id: editRowAddress.id !== null ? editRowAddress.id : "",
      indexrowaddress: editRowAddress.indexrowaddress !== null ? editRowAddress.indexrowaddress : "",
      street: editRowAddress.street !== null ? editRowAddress.street : "",
      defaultaddress: editRowAddress.defaultaddress !== null ? editRowAddress.defaultaddress : false,
      fiscaladdress: editRowAddress.fiscaladdress !== null ? editRowAddress.fiscaladdress : false,
      defaulttypeaddress: editRowAddress.defaulttypeaddress !== null ? editRowAddress.defaulttypeaddress : 0,
      defaulttypeaddress_namees: editRowAddress.defaulttypeaddress_namees !== null ? editRowAddress.defaulttypeaddress_namees : "",
      defaulttypeaddress_nameen: editRowAddress.defaulttypeaddress_nameen !== null ? editRowAddress.defaulttypeaddress_nameen : "",
      otherdata: editRowAddress.otherdata !== null ? editRowAddress.otherdata : "",
      roadtype_id: editRowAddress.roadtype_id !== null ? editRowAddress.roadtype_id : 0,
      roadtype_namees: editRowAddress.roadtype_namees !== null ? editRowAddress.roadtype_namees : "",
      roadtype_nameen: editRowAddress.roadtype_nameen !== null ? editRowAddress.roadtype_nameen : "",
      country_id: editRowAddress.country_id !== null ? editRowAddress.country_id : Number(DEFAULTCOUNTRY),
      country_namees: editRowAddress.country_namees !== null ? editRowAddress.country_namees : "",
      country_nameen: editRowAddress.country_nameen !== null ? editRowAddress.country_nameen : "",
      province_id: editRowAddress.province_id !== null ? editRowAddress.province_id : Number(DEFAULTPROVINCE),
      province_name: editRowAddress.province_name !== null ? editRowAddress.province_name : "",
      postalcode_id: editRowAddress.postalcode_id !== null ? editRowAddress.postalcode_id : 0,
      postalcode_postalcodename: editRowAddress.postalcode_postalcodename !== null ? editRowAddress.postalcode_postalcodename : "",
      community_id: editRowAddress.community_id !== null ? editRowAddress.community_id : Number(DEFAULTCOMMUNITY),
      community_namees: editRowAddress.community_namees !== null ? editRowAddress.community_namees : "",
      community_nameen: editRowAddress.community_nameen !== null ? editRowAddress.community_nameen : "",

      nima_opt_tras: editRowAddress.nima_opt_tras !== null ? editRowAddress.nima_opt_tras : "",
      pygr_opt_tras: editRowAddress.pygr_opt_tras !== null ? editRowAddress.pygr_opt_tras : "",
      grnp_opt_tras: editRowAddress.grnp_opt_tras !== null ? editRowAddress.grnp_opt_tras : "",
      opt_id_opt_tras: editRowAddress.opt_id_opt_tras !== null ? editRowAddress.opt_id_opt_tras : 0,
      opt_code_opt_tras: editRowAddress.opt_code_opt_tras !== null ? editRowAddress.opt_code_opt_tras : "",
      opt_namees_opt_tras: editRowAddress.opt_namees_opt_tras !== null ? editRowAddress.opt_namees_opt_tras : "",
      opt_nameen_opt_tra: editRowAddress.opt_nameen_opt_tra !== null ? editRowAddress.opt_nameen_opt_tra : "",
        
      nima_ori_tras: editRowAddress.nima_ori_tras !== null ? editRowAddress.nima_ori_tras : "",
      pygr_ori_tras: editRowAddress.pygr_ori_tras !== null ? editRowAddress.pygr_ori_tras : "",
      grnp_ori_tras: editRowAddress.grnp_ori_tras !== null ? editRowAddress.grnp_ori_tras : "",
      opt_id_ori_tras: editRowAddress.opt_id_ori_tras !== null ? editRowAddress.opt_id_ori_tras : 0,
      opt_code_ori_tras: editRowAddress.opt_code_ori_tras !== null ? editRowAddress.opt_code_ori_tras : "",
      opt_namees_ori_tras: editRowAddress.opt_namees_ori_tras !== null ? editRowAddress.opt_namees_ori_tras : "",
      opt_nameen_ori_tras: editRowAddress.opt_nameen_ori_tras !== null ? editRowAddress.opt_nameen_ori_tras : "",
      
      nima_aut_tras: editRowAddress.nima_aut_tras !== null ? editRowAddress.nima_aut_tras : "",
      pygr_aut_tras: editRowAddress.pygr_aut_tras !== null ? editRowAddress.pygr_aut_tras : "",
      grnp_aut_tras: editRowAddress.grnp_aut_tras !== null ? editRowAddress.grnp_aut_tras : "",
      opt_id_aut_tras: editRowAddress.opt_id_aut_tras !== null ? editRowAddress.opt_id_aut_tras : 0,
      opt_code_aut_tras: editRowAddress.opt_code_aut_tras !== null ? editRowAddress.opt_code_aut_tras : "",
      opt_namees_aut_tras: editRowAddress.opt_namees_aut_tras !== null ? editRowAddress.opt_namees_aut_tras : "",
      opt_nameen_aut_tras: editRowAddress.opt_nameen_aut_tras !== null ? editRowAddress.opt_nameen_aut_tras : "",
      
      nima_dest_tras: editRowAddress.nima_dest_tras !== null ? editRowAddress.nima_dest_tras : "",
      grnp_dest_tras: editRowAddress.grnp_dest_tras !== null ? editRowAddress.grnp_dest_tras : "",
      pygr_dest_tras: editRowAddress.pygr_dest_tras !== null ? editRowAddress.pygr_dest_tras : "",
      opt_id_dest_tras: editRowAddress.opt_id_dest_tras !== null ? editRowAddress.opt_id_dest_tras : 0,
      opt_code_dest_tras: editRowAddress.opt_code_dest_tras !== null ? editRowAddress.opt_code_dest_tras : "",
      opt_namees_dest_tras: editRowAddress.opt_namees_dest_tras !== null ? editRowAddress.opt_namees_dest_tras : "",
      opt_nameen_dest_tras: editRowAddress.opt_nameen_dest_tras !== null ? editRowAddress.opt_nameen_dest_tras : "",
      
      nima_aut_dest: editRowAddress.nima_aut_dest !== null ? editRowAddress.nima_aut_dest : "",
      pygr_aut_dest: editRowAddress.pygr_aut_dest !== null ? editRowAddress.pygr_aut_dest : "",
      grnp_aut_dest: editRowAddress.grnp_aut_dest !== null ? editRowAddress.grnp_aut_dest : "",
      opt_id_aut_dest: editRowAddress.opt_id_aut_dest !== null ? editRowAddress.opt_id_aut_dest : 0,
      opt_code_aut_dest: editRowAddress.opt_code_aut_dest !== null ? editRowAddress.opt_code_aut_dest : "",
      opt_namees_aut_dest: editRowAddress.opt_namees_aut_dest !== null ? editRowAddress.opt_namees_aut_dest : "",
      opt_nameen_aut_dest: editRowAddress.opt_nameen_aut_dest !== null ? editRowAddress.opt_nameen_aut_dest : "",
        
      nima_rel_trans: editRowAddress.nima_rel_trans !== null ? editRowAddress.nima_rel_trans : "",
      pygr_rel_trans: editRowAddress.pygr_rel_trans !== null ? editRowAddress.pygr_rel_trans : "",
      grnp_rel_trans: editRowAddress.grnp_rel_trans !== null ? editRowAddress.grnp_rel_trans : "",
      opt_id_rel_trans: editRowAddress.opt_id_rel_trans !== null ? editRowAddress.opt_id_rel_trans : 0,
      opt_code_rel_trans: editRowAddress.opt_code_rel_trans !== null ? editRowAddress.opt_code_rel_trans : "",
      opt_namees_rel_trans: editRowAddress.opt_namees_rel_trans !== null ? editRowAddress.opt_namees_rel_trans : "",
      opt_nameen_rel_trans: editRowAddress.opt_nameen_rel_trans !== null ? editRowAddress.opt_nameen_rel_trans : "",
      
      nima_resp_prod: editRowAddress.nima_resp_prod !== null ? editRowAddress.nima_resp_prod : "",
      pygr_resp_prod: editRowAddress.pygr_resp_prod !== null ? editRowAddress.pygr_resp_prod : "",
      grnp_resp_prod: editRowAddress.grnp_resp_prod !== null ? editRowAddress.grnp_resp_prod : "",
      opt_id_resp_prod: editRowAddress.opt_id_resp_prod !== null ? editRowAddress.opt_id_resp_prod : 0,
      opt_code_resp_prod: editRowAddress.opt_code_resp_prod !== null ? editRowAddress.opt_code_resp_prod : "",
      opt_namees_resp_prod: editRowAddress.opt_namees_resp_prod !== null ? editRowAddress.opt_namees_resp_prod : "",
      opt_nameen_resp_prod: editRowAddress.opt_nameen_resp_prod !== null ? editRowAddress.opt_nameen_resp_prod : ""

      }));
    } else {
      setNewAddress(initialAddress);
    }
    return () => {isMounted = false; };

  }, [ENDPOINT, t, token, dispatch, editRowAddress, openEditNewAddress, DEFAULTCOUNTRY, DEFAULTCOMMUNITY,DEFAULTPROVINCE]);

 
  return(
    <>
      {openEditRowAddress && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewAddress(false)}
            onOpen={toggleDrawerNewAddress(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{openEditNewAddress?t('Text_Address_Edit'):t('Text_Address_New')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewAddress}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <div className="form-group">
                      <div className="row  px-0">
                        <div className="col-2">
                          <label className="text-primary"  style={{ fontSize: 18 }}>{t('Text_RoadType').toUpperCase()} :</label>
                        </div>
                        <div className="col-4">
                            <TextField
                            id="roadtype_id"
                            name="roadtype_id"
                            value={ (newAddress.roadtype_id === undefined || newAddress.roadtype_id ===null || newAddress.roadtype_id ===""
                                  || newAddress.roadtype_id ==="0" || newAddress.roadtype_id ===0)?"":newAddress.roadtype_id}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            inputProps={{ style: {width: 300, fontSize: '18px' }, maxLength: 100, }}
                            variant="outlined"
                            onBlur={handleBlurSelectRoadType}
                            onChange={handleChangeSelectRoadType}
                            helperText={errorsAddress.roadtype_id!=null?t(errorsAddress.roadtype_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.roadtype_id === undefined || newAddress.roadtype_id ===null || newAddress.roadtype_id ===""
                                    || newAddress.roadtype_id ==="0" || newAddress.roadtype_id ===0)?"": newAddress.roadtype_id
                            }}>
                            <option key={0} value=""/>
                            {roadTypesToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                        <div className="col-2">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Address').toUpperCase()} :</label>
                        </div>
                        <div className="col-4">
                           &nbsp;&nbsp;&nbsp;
                          <TextField
                            id="street" 
                            name="street"
                            value={newAddress.street}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {width: 320, fontSize: '18px'}, maxLength: 250 }}
                            label={errorsAddress.street!=null?t(errorsAddress.street):t('PlaceHolder_Store_Address')}
                            placeholder={t('PlaceHolder_Store_Address')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row  px-0">
                        <div className="col-2">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Country').toUpperCase()} :</label>
                        </div>
                        <div className="col-4">
                          <TextField
                            id="country_id"
                              name="country_id"
                              value={ (newAddress.country_id === undefined || newAddress.country_id ===null || newAddress.country_id ===""
                                    || newAddress.country_id ==="0" || newAddress.country_id ===0)?Number(DEFAULTCOUNTRY):newAddress.country_id}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                              inputProps={{ style: {width: 300, fontSize: '18px' }, maxLength: 100, }}
                              variant="outlined"
                              onBlur={handleBlurSelectCountry}
                              onChange={handleChangeSelectCountry}
                              helperText={errorsAddress.country_id!=null?t(errorsAddress.country_id):""}
                              select
                              SelectProps={{
                                native: true,
                                value: (newAddress.country_id === undefined || newAddress.country_id ===null || newAddress.country_id ===""
                                    || newAddress.country_id ==="0" || newAddress.country_id ===0)?Number(DEFAULTCOUNTRY): newAddress.country_id
                              }}>
                              {countryToSelect.map((option) => (
                              <option key={option.id} value={option.id}>
                                  {i18n.language==="es"?option.namees:option.nameen}
                              </option>
                              ))}
                          </TextField>
                        </div>
                        <div className="col-2">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Province')} :</label>
                        </div>
                        <div className="col-4">
                          {provinceToSelect.length===0?
                            <label className="text-danger">{t('Text_ProvinceNotExist')}</label>:
                              <TextField
                                id="province_id"
                                name="province_id"
                                value={ (newAddress.province_id === undefined || newAddress.province_id ===null || newAddress.province_id ===""
                                    || newAddress.province_id ==="0" || newAddress.province_id ===0)?Number(DEFAULTPROVINCE):newAddress.province_id}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                inputProps={{ style: {width: 300, fontSize: '18px' }, maxLength: 100, }}
                                variant="outlined"
                                onBlur={handleBlurSelectProvince}
                                onChange={handleChangeSelectProvince}
                                helperText={errorsAddress.province_id!=null?t(errorsAddress.province_id):""}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (newAddress.province_id === undefined || newAddress.province_id ===null || newAddress.province_id ===""
                                        || newAddress.province_id ==="0" || newAddress.province_id ===0)?Number(DEFAULTPROVINCE): newAddress.province_id
                                }}>
                                {provinceToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                                ))}
                              </TextField>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row  px-0">
                        <div className="col-2">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PostalCode')} :</label>
                        </div>
                        <div className="col-4">
                          {postalCodeToSelect.length===0?
                            <label className="text-danger">{t('Text_PostalCodeNotExist')}</label>:
                            <TextField
                                id="postalcode_id"
                                name="postalcode_id"
                                value={ (newAddress.postalcode_id === undefined || newAddress.postalcode_id ===null || newAddress.postalcode_id ===""
                                      || newAddress.postalcode_id ==="0" || newAddress.postalcode_id ===0)?"":newAddress.postalcode_id}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                inputProps={{ style: {width: 300, fontSize: '18px' }, maxLength: 100, }}
                                variant="outlined"
                                onBlur={handleBlurSelectPostalCode}
                                onChange={handleChangeSelectPostalCode}
                                helperText={errorsAddress.postalcode_id!=null?t(errorsAddress.postalcode_id):""}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (newAddress.postalcode_id === undefined || newAddress.postalcode_id ===null || newAddress.postalcode_id ===""
                                      || newAddress.postalcode_id ==="0" || newAddress.postalcode_id ===0)?"": newAddress.postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                            </TextField>
                          }
                        </div>
                        <div className="col-2">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Community')} :</label>
                        </div>
                        <div className="col-4">
                            <TextField
                            id="community_id"
                            name="community_id"
                            value={ (newAddress.community_id === undefined || newAddress.community_id ===null || newAddress.community_id ===""
                                  || newAddress.community_id ==="0" || newAddress.community_id ===0)?Number(DEFAULTCOMMUNITY):newAddress.community_id}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                            inputProps={{ style: {width: 300, fontSize: '18px' }, maxLength: 100, }}
                            variant="outlined"
                            onBlur={handleBlurSelectCommunity}
                            onChange={handleChangeSelectCommunity}
                            helperText={errorsAddress.community_id!=null?t(errorsAddress.community_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.community_id === undefined || newAddress.community_id ===null || newAddress.community_id ===""
                                    || newAddress.community_id ==="0" || newAddress.community_id ===0)?Number(DEFAULTCOMMUNITY): newAddress.community_id
                            }}>
                            {communityToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="row  px-0">
                        <div className="col-2">
                          <label className="text-primary" style={{ fontSize: 18}}>{t('Text_Address_Priority')} :</label>
                        </div>
                        <div className="col-4">
                            <TextField
                              id="defaulttypeaddress"
                              name="defaulttypeaddress"
                              value={ (newAddress.defaulttypeaddress === undefined || newAddress.defaulttypeaddress ===null || newAddress.defaulttypeaddress ===""
                                    || newAddress.defaulttypeaddress ==="0" || newAddress.defaulttypeaddress ===0)?0:newAddress.defaulttypeaddress}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                              inputProps={{ style: {width: 300, fontSize: '18px' }, maxLength: 100, }}
                              variant="outlined"
                              onBlur={handleBlurSelectPriority}
                              onChange={handleChangeSelectPriority}
                              helperText={errorsAddress.defaulttypeaddress!=null?t(errorsAddress.defaulttypeaddress):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: (newAddress.defaulttypeaddress === undefined || newAddress.defaulttypeaddress ===null || newAddress.defaulttypeaddress ===""
                                      || newAddress.defaulttypeaddress ==="0" || newAddress.defaulttypeaddress ===0)?0: newAddress.defaulttypeaddress
                              }}>
                              {typeOfPriority.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                              ))}
                            </TextField>  
                        </div>
                        <div className="col-3">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Address_IsDefault')} :</label>
                          &nbsp;&nbsp;&nbsp;
                          <Checkbox  
                            checked={newAddress.defaultaddress} 
                            onChange={handleChangeEnabledDefaultAddress} 
                            sx={{
                              '& .MuiSvgIcon-root': { color:"#4e73df",fontSize: 28 }, // Ajusta el tamaño del ícono
                            }}
                          />  
                        </div>
                        <div className="col-3">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Address_IsFiscal')} :</label>
                          &nbsp;&nbsp;&nbsp; 
                          <Checkbox   
                            checked={newAddress.fiscaladdress} 
                            onChange={handleChangeEnabledFiscaleAddress} 
                            sx={{
                              '& .MuiSvgIcon-root': { color:"#4e73df",fontSize: 28 }, // Ajusta el tamaño del ícono
                            }}
                          />
                        </div>
                      </div>
                    </div> 
                    <div className="form-group">
                      <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Address_Aditional')} :</label>
                      &nbsp;&nbsp;&nbsp;
                      <TextField
                        id="otherdata" 
                        name="otherdata"
                        value={newAddress.otherdata}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        inputProps={{ style: {width: 850, fontSize: '18px'}, maxLength: 250 }}
                        label={errorsAddress.door!=null?t(errorsAddress.door):t('PlaceHolder_Store_Address_OtherData')}
                        placeholder={t('PlaceHolder_Store_Address_OtherData')}
                        variant="standard"
                        onBlur={handleBlurNewAddress}
                        onChange={handleChangeNewAddress}/>
                    </div>

                     
                    
                    <div className="form-group">
                      <div className="row  px-0">
                        <div className="col-3 text-center">
                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_nima')} :</label>
                          &nbsp;&nbsp;&nbsp;
                          <IconButton  size="small"  style={{color:newAddress.nima_opt_tras===undefined || newAddress.nima_opt_tras===""?"#858796":"#4e73df", fontSize: 16}} disabled={newAddress.nima_opt_tras===undefined || newAddress.nima_opt_tras===""} onClick={copyNima}>
                              <CopyAllIcon style={{fontSize: 28}}/>
                          </IconButton>
                          &nbsp;&nbsp;&nbsp;
                          <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteNima}>
                              <DeleteIcon style={{fontSize: 28}}/>
                          </IconButton>
                        </div>
                        <div className="col-3 text-center">
                          <label className="text-primary"  style={{ fontSize: 18 }}>{t('Text_numberinscription')} :</label>
                          &nbsp;&nbsp;&nbsp;
                          <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deletePYGR}>
                              <DeleteIcon style={{fontSize: 28}}/>
                          </IconButton> 
                        </div>
                        <div className="col-3 text-center">
                          <label className="text-primary"  style={{ fontSize: 18 }}>{t('Text_GRNP')} :</label>
                          &nbsp;&nbsp;&nbsp;
                          <IconButton  size="small"  style={{color:newAddress.grnp_opt_tras===undefined || newAddress.grnp_opt_tras===""?"#858796":"#4e73df", fontSize: 16}} disabled={newAddress.grnp_opt_tras===undefined || newAddress.grnp_opt_tras===""} onClick={copyGRNP}>
                              <CopyAllIcon style={{fontSize: 28}}/>
                          </IconButton>
                          &nbsp;&nbsp;&nbsp;
                          <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteGRNP}>
                              <DeleteIcon style={{fontSize: 28}}/>
                        </IconButton> 
                        </div>
                        <div className="col-3 text-center">
                          <Typography style={{color:"#4e73df", fontSize: 18}} >
                            {t('Text_defaulttype').toUpperCase()}
                          </Typography>
                        </div>
                      </div>
                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_OPT_TRAS')}
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_opt_tras" 
                            name="nima_opt_tras"
                            value={newAddress.nima_opt_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.nima_opt_tras!=null?t(errorsAddress.nima_opt_tras):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_opt_tras" 
                            name="pygr_opt_tras"
                            value={newAddress.pygr_opt_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_opt_tras!=null?t(errorsAddress.pygr_opt_tras):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_opt_tras" 
                            name="grnp_opt_tras"
                            value={newAddress.grnp_opt_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_opt_tras!=null?t(errorsAddress.grnp_opt_tras):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_opt_tras"
                            name="opt_id_opt_tras"
                            value={ (newAddress.opt_id_opt_tras === undefined || newAddress.opt_id_opt_tras ===null || newAddress.opt_id_opt_tras ===""
                                  || newAddress.opt_id_opt_tras ==="0" || newAddress.opt_id_opt_tras ===0)?1:newAddress.opt_id_opt_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_opt_tras!=null?t(errorsAddress.opt_id_opt_tras):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_opt_tras === undefined || newAddress.opt_id_opt_tras ===null || newAddress.opt_id_opt_tras ===""
                                    || newAddress.opt_id_opt_tras ==="0" || newAddress.opt_id_opt_tras ===0)?0: newAddress.opt_id_opt_tras
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>
                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_ORI_TRAS')}  ({t('Text_FORM_ORI_TRAS_PROD')})
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_ori_tras" 
                            name="nima_ori_tras"
                            value={newAddress.nima_ori_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.nima_ori_tras!=null?t(errorsAddress.nima_ori_tras):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_ori_tras" 
                            name="pygr_ori_tras"
                            value={newAddress.pygr_ori_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_ori_tras!=null?t(errorsAddress.pygr_ori_tras):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_ori_tras" 
                            name="grnp_ori_tras"
                            value={newAddress.grnp_ori_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_ori_tras!=null?t(errorsAddress.grnp_ori_tras):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_ori_tras"
                            name="opt_id_ori_tras"
                            value={ (newAddress.opt_id_ori_tras === undefined || newAddress.opt_id_ori_tras ===null || newAddress.opt_id_ori_tras ===""
                                  || newAddress.opt_id_ori_tras ==="0" || newAddress.opt_id_ori_tras ===0)?1:newAddress.opt_id_ori_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_ori_tras!=null?t(errorsAddress.opt_id_ori_tras):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_ori_tras === undefined || newAddress.opt_id_ori_tras ===null || newAddress.opt_id_ori_tras ===""
                                    || newAddress.opt_id_ori_tras ==="0" || newAddress.opt_id_ori_tras ===0)?0: newAddress.opt_id_ori_tras
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>

                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_ORI_TRAS')}  ({t('Text_FORM_ORI_TRAS_EMPR')})
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_aut_tras" 
                            name="nima_aut_tras"
                            value={newAddress.nima_aut_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.nima_aut_tras!=null?t(errorsAddress.nima_aut_tras):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_aut_tras" 
                            name="pygr_aut_tras"
                            value={newAddress.pygr_aut_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_aut_tras!=null?t(errorsAddress.pygr_aut_tras):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_aut_tras" 
                            name="grnp_aut_tras"
                            value={newAddress.grnp_aut_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_aut_tras!=null?t(errorsAddress.grnp_aut_tras):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_aut_tras"
                            name="opt_id_aut_tras"
                            value={ (newAddress.opt_id_aut_tras === undefined || newAddress.opt_id_aut_tras ===null || newAddress.opt_id_aut_tras ===""
                                  || newAddress.opt_id_aut_tras ==="0" || newAddress.opt_id_aut_tras ===0)?1:newAddress.opt_id_aut_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_aut_tras!=null?t(errorsAddress.opt_id_aut_tras):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_aut_tras === undefined || newAddress.opt_id_aut_tras ===null || newAddress.opt_id_aut_tras ===""
                                    || newAddress.opt_id_aut_tras ==="0" || newAddress.opt_id_aut_tras ===0)?0: newAddress.opt_id_aut_tras
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>

                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_DEST_TRAS')}  ({t('Text_FORM_DEST_TRAS_PROD')})
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_dest_tras" 
                            name="nima_dest_tras"
                            value={newAddress.nima_dest_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.nima_dest_tras!=null?t(errorsAddress.nima_dest_tras):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_dest_tras" 
                            name="pygr_dest_tras"
                            value={newAddress.pygr_dest_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_dest_tras!=null?t(errorsAddress.pygr_dest_tras):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_dest_tras" 
                            name="grnp_dest_tras"
                            value={newAddress.grnp_dest_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_dest_tras!=null?t(errorsAddress.grnp_dest_tras):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_dest_tras"
                            name="opt_id_dest_tras"
                            value={ (newAddress.opt_id_dest_tras === undefined || newAddress.opt_id_dest_tras ===null || newAddress.opt_id_dest_tras ===""
                                  || newAddress.opt_id_dest_tras ==="0" || newAddress.opt_id_dest_tras ===0)?1:newAddress.opt_id_dest_tras}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_dest_tras!=null?t(errorsAddress.opt_id_dest_tras):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_dest_tras === undefined || newAddress.opt_id_dest_tras ===null || newAddress.opt_id_dest_tras ===""
                                    || newAddress.opt_id_dest_tras ==="0" || newAddress.opt_id_dest_tras ===0)?0: newAddress.opt_id_dest_tras
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>

                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_DEST_TRAS')}  ({t('Text_FORM_DEST_TRAS_EMPR')})
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_aut_dest" 
                            name="nima_aut_dest"
                            value={newAddress.nima_aut_dest}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.nima_aut_dest!=null?t(errorsAddress.nima_aut_dest):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_aut_dest" 
                            name="pygr_aut_dest"
                            value={newAddress.pygr_aut_dest}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_aut_dest!=null?t(errorsAddress.pygr_aut_dest):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_aut_dest" 
                            name="grnp_aut_dest"
                            value={newAddress.grnp_aut_dest}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_aut_dest!=null?t(errorsAddress.grnp_aut_dest):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_aut_dest"
                            name="opt_id_aut_dest"
                            value={ (newAddress.opt_id_aut_dest === undefined || newAddress.opt_id_aut_dest ===null || newAddress.opt_id_aut_dest ===""
                                  || newAddress.opt_id_aut_dest ==="0" || newAddress.opt_id_aut_dest ===0)?0:newAddress.opt_id_aut_dest}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_aut_dest!=null?t(errorsAddress.opt_id_aut_dest):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_aut_dest === undefined || newAddress.opt_id_aut_dest ===null || newAddress.opt_id_aut_dest ===""
                                    || newAddress.opt_id_aut_dest ==="0" || newAddress.opt_id_aut_dest ===0)?0: newAddress.opt_id_aut_dest
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>

                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_Carrier')} 
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_rel_trans" 
                            name="nima_rel_trans"
                            value={newAddress.nima_rel_trans}
                            inputProps={{ style: {maxLength: 50} }}
                            label={errorsAddress.nima_rel_trans!=null?t(errorsAddress.nima_rel_trans):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_rel_trans" 
                            name="pygr_rel_trans"
                            value={newAddress.pygr_rel_trans}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_rel_trans!=null?t(errorsAddress.pygr_rel_trans):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_rel_trans" 
                            name="grnp_rel_trans"
                            value={newAddress.grnp_rel_trans}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_rel_trans!=null?t(errorsAddress.grnp_rel_trans):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_rel_trans"
                            name="opt_id_rel_trans"
                            value={ (newAddress.opt_id_rel_trans === undefined || newAddress.opt_id_rel_trans ===null || newAddress.opt_id_rel_trans ===""
                                  || newAddress.opt_id_rel_trans ==="0" || newAddress.opt_id_rel_trans ===0)?0:newAddress.opt_id_rel_trans}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_rel_trans!=null?t(errorsAddress.opt_id_rel_trans):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_rel_trans === undefined || newAddress.opt_id_rel_trans ===null || newAddress.opt_id_rel_trans ===""
                                    || newAddress.opt_id_rel_trans ==="0" || newAddress.opt_id_rel_trans ===0)?0: newAddress.opt_id_rel_trans
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>

                      <Typography style={{color:"#4e73df", fontSize: 18}} >
                        {t('Text_FORM_SRAP')} 
                      </Typography>
                      <div className="row  px-0">
                        <div className="col-3 ">
                          <TextField
                            id="nima_resp_prod" 
                            name="nima_resp_prod"
                            value={newAddress.nima_resp_prod}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.nima_resp_prod!=null?t(errorsAddress.nima_resp_prod):t('Text_nima')}
                            placeholder={t('Text_nima')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                          </div>
                        <div className="col-3">
                          <TextField
                            id="pygr_resp_prod" 
                            name="pygr_resp_prod"
                            value={newAddress.pygr_resp_prod}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.pygr_resp_prod!=null?t(errorsAddress.pygr_resp_prod):t('Text_numberinscription')}
                            placeholder={t('Text_numberinscription')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="grnp_resp_prod" 
                            name="grnp_resp_prod"
                            value={newAddress.grnp_resp_prod}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            label={errorsAddress.grnp_resp_prod!=null?t(errorsAddress.grnp_resp_prod):t('Text_GRNP')}
                            placeholder={t('Text_GRNP')}
                            variant="standard"
                            onBlur={handleBlurNewAddress}
                            onChange={handleChangeNewAddress}/>
                        </div>
                        <div className="col-3 ">
                          <TextField
                            id="opt_id_resp_prod"
                            name="opt_id_resp_prod"
                            value={ (newAddress.opt_id_resp_prod === undefined || newAddress.opt_id_resp_prod ===null || newAddress.opt_id_resp_prod ===""
                                  || newAddress.opt_id_resp_prod ==="0" || newAddress.opt_id_resp_prod ===0)?1:newAddress.opt_id_resp_prod}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            inputProps={{ style: {fontSize: '18px'}, maxLength: 50 }}
                            variant="outlined"
                            onBlur={handleBlurSelectOptTyoe}
                            onChange={handleChangeSelectOptType}
                            helperText={errorsAddress.opt_id_resp_prod!=null?t(errorsAddress.opt_id_resp_prod):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (newAddress.opt_id_resp_prod === undefined || newAddress.opt_id_resp_prod ===null || newAddress.opt_id_resp_prod ===""
                                    || newAddress.opt_id_resp_prod ==="0" || newAddress.opt_id_resp_prod ===0)?0: newAddress.opt_id_resp_prod
                            }}>
                            <option key={0} value=""/>
                            {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                          </TextField>  
                        </div>
                      </div>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg  custom-btn" type="button" onClick={handleSubmitNewAddress}>{openEditNewAddress?t('Button_Update'):t('Button_Create')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg  custom-btn" type="button" onClick={handleCloseNewAddress}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

StoreCreateOrEditAddress.propTypes = {

  openEditRowAddress: PropTypes.bool.isRequired,
  setOpenEditRowAddress: PropTypes.func.isRequired,
  openEditNewAddress: PropTypes.bool.isRequired,
  setOpenEditNewAddress: PropTypes.func.isRequired,
  formStore: PropTypes.object.isRequired,
  setFormStore: PropTypes.func.isRequired, 
  setEditRowAddress: PropTypes.func.isRequired
};

export default StoreCreateOrEditAddress;