import React, {  useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { useDispatch } from "react-redux"; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { NumericFormat } from 'react-number-format'; 
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box';    
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem  from "@mui/material/MenuItem"; 
import TextField from '@mui/material/TextField';    
import Typography from '@mui/material/Typography'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingInvoiceTickets from "pages/tickets/ticketsEdit/invoiceTicketsEdit/SortingTablesInvoiceTickets";
import InvoiceNextTickets from "pages/tickets/ticketsEdit/invoiceTicketsEdit/InvoiceNextTickets";
import PropTypes from 'prop-types';

const initialFiltersInvoiceTickets= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "isFinished":false,
  "isNotFinished":true,
  "numberticketFrom":"",
  "numberticketTo":"",
  "purchasenoteFrom":"",
  "purchasenoteTo":"",
  "ownnumbertickesFrom":"",
  "ownnumbertickesTo":"",
  "start_date_ticketFrom":null,
	"start_date_ticketTo":null,
  "end_date_ticketFrom":"",
  "end_date_tickettTo":"",
  "vehiclestore_licenseplateFrom":"",
  "vehiclestore_licenseplateTo":"",
  "rel_trans_store_idFrom":"",
  "rel_trans_store_idTo":"",
  "ori_tras_store_idFrom":"",
  "ori_tras_store_idTo":"", 
};
 
const initialStateInvoiceTickets = [{
  "id" : "",
  "numberticket":0,
  "vehiclestore_licenseplate" : "",
  "start_date_ticket" : "",
  "end_date_ticket" : "",
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalstartweight" : 0,
  "totalendweight" : 0,
  "estimatedprice":0, 
  "discount":0, 
  "tax":0, 
  "taxincluded":false ,
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
}];

const headCellsInvoiceTickets = [
  {
    id: 'numberticket',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_NUMBERTICKETS',
    canOrder:true,
  },
  {
    id: 'vehiclestore_licenseplate',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_LICENSEPLATE',
    canOrder:true,
  },
  {
    id: 'start_date_ticket',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_START_DATE',
    canOrder:true,
  },
  {
    id: 'rel_trans_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_TRADENAME_TRANS',
    canOrder:true,
  },
  {
    id: 'ori_tras_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_NAME_STORE',
    canOrder:true,
  },
  {
    id: 'totalstartweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_FIRST_WEIGHTS',
    canOrder:true,
  }
];

const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];
 
const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];



const InvoiceTickets = ({showModalTicketsSettings,submitTickets,setSubmitTickets, configPrinter,refeshData,setRefeshData}) => {
  const { t,i18n } = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code);  
  const [dataInvoiceTickets, setDataInvoiceTickets] = useState(initialStateInvoiceTickets);
  const [filtersInvoiceTickets, setFiltersInvoiceTickets] = useState(initialFiltersInvoiceTickets);
  const [applyFiltersInvoiceTickets, setApplyFiltersInvoiceTickets] = useState(initialFiltersInvoiceTickets);
  const [numTotRegInvoiceTickets, setNumTotRegInvoiceTickets] = useState(0);
  const [numTotRegInvoiceTicketsFilter, setNumTotRegInvoiceTicketsFilter] = useState(0);
  const [errorsFiltersInvoiceTickets, setErrorsFiltersInvoiceTickets] = useState({}); 
  const [inputTextInvoiceTickets, setInputTextInvoiceTickets] = useState(""); 
  const [openDeleteRowInvoiceTickets, setOpenDeleteRowInvoiceTickets] = useState(false);
  const [openEditRowInvoiceTickets, setOpenEditRowInvoiceTickets] = useState(false);
  const [openEditFilterInvoiceTickets, setOpenEditFilterInvoiceTickets] = useState(false);
  const [rowEditInvoiceTickets, setRowEditInvoiceTickets] = useState(initialStateInvoiceTickets[0]);
  const [isLoadingInvoiceTickets, setIsLoadingInvoiceTickets] = useState(true);
  const [isLoadingInvoiceTicketsError, setIsLoadingInvoiceTicketsError] = useState(false);
  const [idCodeDeleteInvoiceTickets, setIdCodeDeleteInvoiceTickets] = useState(DELETE_CODE);
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect); 
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect);     
  const [filteredTransFrom, setFilteredTransFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexTransFrom, setHighlightedIndexTransFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredTransTo, setFilteredTransTo] = useState([]); // Opciones filtradas
  const [highlightedIndexTransTo, setHighlightedIndexTransTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleFrom, setFilteredVehicleFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleFrom, setHighlightedIndexVehicleFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleTo, setFilteredVehicleTo] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleTo, setHighlightedIndexVehicleTo] = useState(-1); // Índice de la opción resaltada 
  
  const dispatch = useDispatch();
  const [activeOffsetInvoiceTickets, setActiveOffsetInvoiceTickets] = useState(false);
  const [currencyOffsetInvoiceTickets, setCurrencyOffsetInvoiceTickets] = useState(0);
  const [rangeOffsetInvoiceTickets, setRangeOffsetInvoiceTickets] = useState([{}]); 
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModalTicketsSettings) {
        setDataInvoiceTickets(initialStateInvoiceTickets); 
        setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
        setApplyFiltersInvoiceTickets(initialFiltersInvoiceTickets);
        setNumTotRegInvoiceTickets(0);
        setNumTotRegInvoiceTicketsFilter(0);
        setErrorsFiltersInvoiceTickets({}); 
        setInputTextInvoiceTickets(""); 
        setOpenDeleteRowInvoiceTickets(false);
        setOpenEditRowInvoiceTickets(false);
        setOpenEditFilterInvoiceTickets(false);
        setRowEditInvoiceTickets(initialStateInvoiceTickets[0]);
        setIsLoadingInvoiceTickets(false);
        setIsLoadingInvoiceTicketsError(false);
        setIdCodeDeleteInvoiceTickets(DELETE_CODE);
        setTrasnporterToSelect(initialStoresToSelect);
        setActiveOffsetInvoiceTickets(false);
        setCurrencyOffsetInvoiceTickets(0); 
        setRangeOffsetInvoiceTickets([{}]); 
        setTrasnporterToSelect(initialStoresToSelect);
        setVehiclesToSelect(initialVehicleToSelect);
        setFilteredTransFrom([]);
        setHighlightedIndexTransFrom(-1);  
        setFilteredTransTo([]);
        setHighlightedIndexTransTo(-1);  
        setFilteredVehicleFrom([]);
        setHighlightedIndexVehicleFrom(-1);  
        setFilteredVehicleTo([]);
        setHighlightedIndexVehicleTo(-1);     
      }
    },
    [
      setDataInvoiceTickets, 
      setFiltersInvoiceTickets,
      setApplyFiltersInvoiceTickets,
      setNumTotRegInvoiceTickets,
      setNumTotRegInvoiceTicketsFilter,
      setErrorsFiltersInvoiceTickets, 
      setInputTextInvoiceTickets, 
      setOpenDeleteRowInvoiceTickets,
      setOpenEditRowInvoiceTickets,
      setOpenEditFilterInvoiceTickets,
      setRowEditInvoiceTickets,
      setIsLoadingInvoiceTickets,
      setIsLoadingInvoiceTicketsError,
      setIdCodeDeleteInvoiceTickets, 
      setActiveOffsetInvoiceTickets,
      setCurrencyOffsetInvoiceTickets,
      setRangeOffsetInvoiceTickets, 
      setVehiclesToSelect,
      setTrasnporterToSelect,
      setFilteredTransFrom,
      setHighlightedIndexTransFrom, 
      setFilteredTransTo,
      setHighlightedIndexTransTo,  
      setFilteredVehicleFrom,
      setHighlightedIndexVehicleFrom, 
      setFilteredVehicleTo,
      setHighlightedIndexVehicleTo,  
      showModalTicketsSettings,  
      DELETE_CODE
    ]
  );

  const openEditFilterInvoiceTicketss = () => {
    setFiltersInvoiceTickets(applyFiltersInvoiceTickets);
    setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFiltersInvoiceTickets(validateFormFilter());
    if (Object.keys(errorsFiltersInvoiceTickets).length === 0) {
      setApplyFiltersInvoiceTickets(filtersInvoiceTickets);
      setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
      setErrorsFiltersInvoiceTickets({});
      setOpenEditFilterInvoiceTickets(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFiltersInvoiceTickets({});
    setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
    setApplyFiltersInvoiceTickets(initialFiltersInvoiceTickets);
    setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
  };

  const handleCancelFilters = () => {
    setErrorsFiltersInvoiceTickets({});
    setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
    setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
  };

  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFiltersInvoiceTickets(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFiltersInvoiceTickets({});
      setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
      setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
    }
  };

  const validateFormFilter = () => {
    let errorsFiltersInvoiceTickets = {};
    let regexNumber = /^[0-9]+$/;
    //let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (filtersInvoiceTickets.idFrom!=="" & !regexNumber.test(filtersInvoiceTickets.idFrom)) {
      errorsFiltersInvoiceTickets.idFrom = 'Text_TextNoNumber';
    }
    if (filtersInvoiceTickets.idTo!=="" & !regexNumber.test(filtersInvoiceTickets.idTo)) {
      errorsFiltersInvoiceTickets.idTo = 'Text_TextNoNumber';
    } 

    if (filtersInvoiceTickets.numberticketFrom!=="" & !regexNumber.test(filtersInvoiceTickets.numberticketFrom)) {
      filtersInvoiceTickets.numberticketFrom = 'Text_TextNoNumber';
    }
    if (filtersInvoiceTickets.numberticketTo!=="" & !regexNumber.test(filtersInvoiceTickets.numberticketTo)) {
      errorsFiltersInvoiceTickets.numberticketTo = 'Text_TextNoNumber';
    }
    if (filtersInvoiceTickets.numberticketFrom!=="" & !errorsFiltersInvoiceTickets.numberticketFrom!==undefined){
      if (filtersInvoiceTickets.numberticketTo!=="" & !errorsFiltersInvoiceTickets.numberticketTo!==undefined){
        if (parseInt(filtersInvoiceTickets.numberticketTo)<parseInt(filtersInvoiceTickets.numberticketFrom)){
          filtersInvoiceTickets.numberticketTo = 'Text_TextGreater_NumberTickets';
        }
      }
    }
    
     
    if (filtersInvoiceTickets.idFrom!=="" & !errorsFiltersInvoiceTickets.idFrom!==undefined){
      if (filtersInvoiceTickets.idTo!=="" & !errorsFiltersInvoiceTickets.idTo!==undefined){
        if (parseInt(filtersInvoiceTickets.idTo)<parseInt(filtersInvoiceTickets.idFrom)){
          errorsFiltersInvoiceTickets.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (filtersInvoiceTickets.defaultStoreIdTo!=="" & filtersInvoiceTickets.defaultStoreIdTo<filtersInvoiceTickets.defaultStoreIdFrom){
      errorsFiltersInvoiceTickets.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersInvoiceTickets.useraccountIdTo!=="" & filtersInvoiceTickets.useraccountIdTo<filtersInvoiceTickets.useraccountIdFrom){
      errorsFiltersInvoiceTickets.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }

    if (filtersInvoiceTickets.start_date_ticketTo!=="" & filtersInvoiceTickets.start_date_ticketTo<filtersInvoiceTickets.start_date_ticketFrom){
      errorsFiltersInvoiceTickets.start_date_ticketTo = 'Text_TextGreater_Start_Date';
    }

    if (filtersInvoiceTickets.rel_trans_store_idTo!=="" & filtersInvoiceTickets.rel_trans_store_idTo<filtersInvoiceTickets.rel_trans_store_idFrom){
      errorsFiltersInvoiceTickets.rel_trans_store_idTo = 'Text_TextGreater_Rel_Trans_Store';
    }
     
    if (filtersInvoiceTickets.ori_tras_store_idTo!=="" & filtersInvoiceTickets.ori_tras_store_idTo<filtersInvoiceTickets.ori_tras_store_idFrom){
      errorsFiltersInvoiceTickets.ori_tras_store_idTo = 'Text_TextGreater_Ori_Tras_Store';
    }

    return errorsFiltersInvoiceTickets;
  }  

  const filteredData = dataInvoiceTickets.filter((el) => {
    if (inputTextInvoiceTickets === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.numberticket || '') +
        String(el.vehiclestore_licenseplate || '') +
        String(el.rel_trans_tradename || '') +
        String(el.ori_tras_tradename || '');
      let result = dataTranslate.toLowerCase().includes(inputTextInvoiceTickets.toLowerCase());
      return result; 
    }
  })

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTransFrom = (event) => {
    const searchTransFrom = event.target.value.toUpperCase();
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      rel_trans_store_idFrom:  searchTransFrom
    }));    
    if (searchTransFrom.trim() === "") {
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
      return;
    } 
    const filtered = trasnporterToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransFrom) ||
        normalizedDocumentId.includes(searchTransFrom)
      );
    });
    setFilteredTransFrom(filtered);
    setHighlightedIndexTransFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      rel_trans_store_idFrom: store.tradename || "",
      rel_trans_store_idTo: store.tradename || filtersInvoiceTickets.rel_trans_store_idTo,
    }));   
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);
  }; 
  const handleKeyDownTransFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransFrom.length === 0 && filtersInvoiceTickets.rel_trans_store_idFrom.trim() === "") {
        setFilteredTransFrom(trasnporterToSelect);
        setHighlightedIndexTransFrom(0);
      } else {
        setHighlightedIndexTransFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredTransFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTransFrom >= 0) {
      if (highlightedIndexTransFrom >= 0 && highlightedIndexTransFrom < filteredTransFrom.length) {
        handleSelectTransFrom(filteredTransFrom[highlightedIndexTransFrom]);
      }
    } else if (event.key === "Escape" ) {
      const isValid = trasnporterToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filtersInvoiceTickets.rel_trans_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFiltersInvoiceTickets(filtersInvoiceTickets => ({
          ...filtersInvoiceTickets,
          rel_trans_store_idFrom: "" 
        }));  
      }
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
    }
  };
  const handleSearchTransTo = (event) => {
    const searchTransTo = event.target.value.toUpperCase();
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      rel_trans_store_idTo:searchTransTo,
    }));   
    if (searchTransTo.trim() === "") {
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
      return;
    } 
    const filtered = trasnporterToSelect
    .filter(item=>(item.tradename>=filtersInvoiceTickets.rel_trans_store_idFrom))
    .filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransTo) ||
        normalizedDocumentId.includes(searchTransTo)
      );
    });
    setFilteredTransTo(filtered);
    setHighlightedIndexTransTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      rel_trans_store_idTo:store.tradename || ""
    }));  
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);
  }; 
  const handleKeyDownTransTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransTo.length === 0 && filtersInvoiceTickets.rel_trans_store_idTo.trim() === "") {
        const filtered = trasnporterToSelect.filter(item=>(item.tradename>=filtersInvoiceTickets.rel_trans_store_idFrom));
        setFilteredTransTo(filtered);
        setHighlightedIndexTransTo(0);
      } else {
        setHighlightedIndexTransTo((prevIndex) => {
          const nextIndex = prevIndex < filteredTransTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTransTo >= 0) {
      if (highlightedIndexTransTo >= 0 && highlightedIndexTransTo < filteredTransTo.length) {
        handleSelectTransTo(filteredTransTo[highlightedIndexTransTo]);
      }
    } else if (event.key === "Escape" ) {
      const isValid = trasnporterToSelect
      .filter(item=>(item.tradename>=filtersInvoiceTickets.rel_trans_store_idFrom))
      .some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filtersInvoiceTickets.rel_trans_store_idTo.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFiltersInvoiceTickets(filtersInvoiceTickets => ({
          ...filtersInvoiceTickets,
          rel_trans_store_idTo: ""
        }));  
      }
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
    }
  };  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicleFrom = event.target.value.toUpperCase();
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      vehiclestore_licenseplateFrom: searchVehicleFrom,
    }));   
    if (searchVehicleFrom.trim() === "") {
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      return;
    } 
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleFrom.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return ( normalizedVehicle.includes(searchNormailzedVehicled) );
    });
    setFilteredVehicleFrom(filtered);
    setHighlightedIndexVehicleFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      vehiclestore_licenseplateFrom: vehicle.vehiclestore_licenseplate || "",
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || filtersInvoiceTickets.vehiclestore_licenseplateTo,
    })); 
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleFrom.length === 0 && filtersInvoiceTickets.vehiclestore_licenseplateFrom.trim() === "") {
        setFilteredVehicleFrom(vehiclesToSelect);
        setHighlightedIndexVehicleFrom(0);
      } else {
        setHighlightedIndexVehicleFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexVehicleFrom >= 0) {
      if (highlightedIndexVehicleFrom >= 0 && highlightedIndexVehicleFrom < filteredVehicleFrom.length) {
        handleSelectVehicleFrom(filteredVehicleFrom[highlightedIndexVehicleFrom]);
      }
    } else if (event.key === "Escape") {
      const isValid = vehiclesToSelect.some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filtersInvoiceTickets.vehiclestore_licenseplateFrom.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFiltersInvoiceTickets(filtersInvoiceTickets => ({
          ...filtersInvoiceTickets,
          vehiclestore_licenseplateFrom: ""
        }));
      }
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
    }
  };
  const handleSearchVehicleTo = (event) => {
    const searchVehicleTo = event.target.value.toUpperCase();
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      vehiclestore_licenseplateTo:searchVehicleTo,
    })); 
    if (searchVehicleTo.trim() === "") {
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      return;
    } 
    const filtered = vehiclesToSelect
    .filter(item=>(item.vehiclestore_licenseplate>=filtersInvoiceTickets.vehiclestore_licenseplateFrom))
    .filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleTo.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return (normalizedVehicle.includes(searchNormailzedVehicled));
    });
    setFilteredVehicleTo(filtered);
    setHighlightedIndexVehicleTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleTo = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || ""
    })); 
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);
  }; 
  const handleKeyDownVehicleTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleTo.length === 0 && filtersInvoiceTickets.vehiclestore_licenseplateTo.trim() === "") {
        const filtered = vehiclesToSelect.filter(item=>(item.vehiclestore_licenseplate>=filtersInvoiceTickets.vehiclestore_licenseplateFrom));
        setFilteredVehicleTo(filtered);
        setHighlightedIndexVehicleTo(0);
      } else {
        setHighlightedIndexVehicleTo((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexVehicleTo >= 0) {
      if (highlightedIndexVehicleTo >= 0 && highlightedIndexVehicleTo < filteredVehicleTo.length) {
        handleSelectVehicleTo(filteredVehicleTo[highlightedIndexVehicleTo]);
      }
    } else if (event.key === "Escape") {
      const isValid = vehiclesToSelect
      .filter(item=>(item.vehiclestore_licenseplate>=filtersInvoiceTickets.vehiclestore_licenseplateFrom))
      .some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filtersInvoiceTickets.vehiclestore_licenseplateTo.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFiltersInvoiceTickets(filtersInvoiceTickets => ({
          ...filtersInvoiceTickets,
          vehiclestore_licenseplateTo: ""
        }));  
      }
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  };  

  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleBlur = useCallback((event) => {
    if (!event || !event.currentTarget) return;
    // Asegúrate de que no se cierra si haces clic en un elemento de la lista
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1); 
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1); 
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  }, []);
  
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextInvoiceTickets(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteInvoiceTickets(value);
  };

  const handleCancelDeleteRow = () => { 
    setRowEditInvoiceTickets(initialStateInvoiceTickets[0]); 
    setIsLoadingInvoiceTickets(false);    
    setIsLoadingInvoiceTicketsError(false);
    setIdCodeDeleteInvoiceTickets(DELETE_CODE);
    setOpenDeleteRowInvoiceTickets(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDeleteInvoiceTickets !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    if (e.key !== 'Escape') {
      if (rowEditInvoiceTickets.id !== '') {
        const fetchData = async () => {
          setIsLoadingInvoiceTickets(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `tickets/deletetickets`, token, rowEditInvoiceTickets);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
              setRefeshData(true);
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingInvoiceTickets(false);
          } finally {
            setIsLoadingInvoiceTickets(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDeleteInvoiceTickets(DELETE_CODE);
    setOpenDeleteRowInvoiceTickets(false);
    handleCloseDataEdit();
  };

  const handleCloseDataEdit = () => { 
    setOpenEditRowInvoiceTickets(false); 
    setRowEditInvoiceTickets(openEditRowInvoiceTickets?rowEditInvoiceTickets:initialStateInvoiceTickets[0]);
    setIsLoadingInvoiceTickets(false);
    setIsLoadingInvoiceTicketsError(false);
    toggleDrawerDataEdit(false);
	};

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100000000) return true;
    return false;
  };
  
  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const handleChangeSelectDateFrom = (event) => {
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      start_date_ticketFrom: event!==null?new Date(event):null,
      start_date_ticketTo:null
    }));
  };

  const handleBlurSelectDateFrom = (e) => {
    handleChangeSelectDateFrom(e);
    setErrorsFiltersInvoiceTickets(validateFormFilter());
  };

  const handleChangeSelectDateTo = (event) => {
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      start_date_ticketTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDatetTo = (e) => {
    handleChangeSelectDateTo(e);
    setErrorsFiltersInvoiceTickets(validateFormFilter());
  };
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    if (numTotRegInvoiceTicketsFilter > LIMITPAGESIZE) {
      setActiveOffsetInvoiceTickets(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegInvoiceTicketsFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetInvoiceTickets(newRangeOffset);
    } else {
      setCurrencyOffsetInvoiceTickets(0);
      setActiveOffsetInvoiceTickets(false);
      setRangeOffsetInvoiceTickets([{}]);
    }
  }, [numTotRegInvoiceTicketsFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `ticketsdetails/getlisttransportertickets`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        } 
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    if (showModalTicketsSettings ) { 
      fetchDataTransporter();  
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalTicketsSettings,defaultStore.id,useraccount_id]);
     

  useEffect(() => {
      let isMounted = true; 
      const fetchDataVehicles = async () => {
        try {
          const getData = await helpHttp().get8(ENDPOINT,`ticketsdetails/getlistvehiclestickets`,token,
            filtersInvoiceTickets.rel_trans_store_idFrom!==undefined && filtersInvoiceTickets.rel_trans_store_idFrom!==null && filtersInvoiceTickets.rel_trans_store_idFrom.replace(/[.\-/\\|]/g, "").trim()!==""?filtersInvoiceTickets.rel_trans_store_idFrom.replace(/[.\-/\\|]/g, "").trim():"-1",
            filtersInvoiceTickets.rel_trans_store_idTo!==undefined && filtersInvoiceTickets.rel_trans_store_idTo!==null && filtersInvoiceTickets.rel_trans_store_idTo.replace(/[.\-/\\|]/g, "").trim()!==""?filtersInvoiceTickets.rel_trans_store_idTo.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) { 
            setVehiclesToSelect(getData); 
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };  
    
      if (showModalTicketsSettings ) { 
          fetchDataVehicles();
      }
      return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,i18n,roles,filtersInvoiceTickets,showModalTicketsSettings]);
       

  useEffect(() => {
    let isMounted = true;  
    const fetchnumTotRegInvoiceTickets = async () => {
      setIsLoadingInvoiceTickets(true);
      try {
        const getData  = await helpHttp().get2(ENDPOINT,`tickets/getinformationtickets/`,token,false);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataInvoiceTickets(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegInvoiceTickets(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingInvoiceTickets(false);
      }
    };
  
    const fetchnumTotRegInvoiceTicketsFilter = async () => {
      setIsLoadingInvoiceTickets(true);
      try {
        const getData = await helpHttp().post4(ENDPOINT,`tickets/getcountfilterticket`,token,"-1","-1",false,applyFiltersInvoiceTickets);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataInvoiceTickets(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) { 
          setNumTotRegInvoiceTicketsFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingInvoiceTickets(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoadingInvoiceTickets(true);
      try {
        const getData = await helpHttp().post6(ENDPOINT,`tickets/getsimplelistticket`,token,currencyOffsetInvoiceTickets,LIMITPAGESIZE,"-1","-1",false,applyFiltersInvoiceTickets);
        if (getData.err || getData.message === "Load failed") {
          setDataInvoiceTickets(initialStateInvoiceTickets);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataInvoiceTickets(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingInvoiceTickets(false);
      }
    };
  
    if (showModalTicketsSettings || submitTickets || refeshData) {    
      fetchnumTotRegInvoiceTickets();
      fetchnumTotRegInvoiceTicketsFilter();
      fetchData();
      setSubmitTickets(false);
      setIsLoadingInvoiceTickets(false);
      setRefeshData(false);
      return () => { isMounted = false; };
    }
  }, [ENDPOINT, t, token,roles, dispatch, openDeleteRowInvoiceTickets, openEditRowInvoiceTickets, rowEditInvoiceTickets, showModalTicketsSettings, LIMITPAGESIZE, applyFiltersInvoiceTickets, currencyOffsetInvoiceTickets,defaultStore.id,useraccount_id,submitTickets,setSubmitTickets,refeshData,setRefeshData]);
  
  return (
    <div> 
      {showModalTicketsSettings && ( 
        <div className="card border-info shadow">
          {/* Search input */}
          <div className="card-header 2 bg-primary text-white text-center text-bold">
            <h3>
              {isLoadingInvoiceTicketsError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingInvoiceTickets?<CircularProgress color={openDeleteRowInvoiceTickets?"warning":"primary"}/>:<i className="fas fa-duotone fa-truck text-warning"></i>} {t('Text_TICKETS_SECOND_WEIGHTS')}
            </h3>
          </div>  
          <br/>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{ style: {fontSize: '18px'} }}
                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                  value={inputTextInvoiceTickets}
                  placeholder={t('Text_TICKETS_SECOND_WEIGHTS_Search')} 
                  onChange={inputHandler}
                  fullWidth
                  label={t('Text_TICKETS_SECOND_WEIGHTS_Search')}>
                </TextField>
              </div>
              <br/>
              <Typography style={{color:"#067330", fontSize: 20}} >  
                {t('Text_Filter')}&nbsp;&nbsp;&nbsp;
                  <button type="button" className="buttonFilterHigh" onClick={openEditFilterInvoiceTicketss}><i className="fas fa-solid fa-circle" style={{color:(
                    applyFiltersInvoiceTickets.numberticketFrom!=="" || applyFiltersInvoiceTickets.numberticketTo!==""
                    || applyFiltersInvoiceTickets.start_date_ticketFrom!==null || applyFiltersInvoiceTickets.start_date_ticketTo!==null
                    || applyFiltersInvoiceTickets.vehiclestore_licenseplateFrom!=="" || applyFiltersInvoiceTickets.vehiclestore_licenseplateTo!==""
                    || applyFiltersInvoiceTickets.rel_trans_store_idFrom!=="" || applyFiltersInvoiceTickets.rel_trans_store_idTo!==""
                    || applyFiltersInvoiceTickets.ori_tras_store_idFrom!=="" || applyFiltersInvoiceTickets.ori_tras_store_idTo!==""
                  ) ?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_TICKETS_SELECT_FILTERS')}</button><> </> 
              </Typography>
              <br/>
              <br/>
              <br/>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingInvoiceTickets
                    dataInvoiceTickets={filteredData}
                    headCellsInvoiceTickets={headCellsInvoiceTickets}
                    numTotRegInvoiceTicketsFilter={numTotRegInvoiceTicketsFilter}
                    titleTable={t('Text_TICKETS_SECOND_WEIGHTS_TABLE')}
                    setOpenDeleteRowInvoiceTickets={setOpenDeleteRowInvoiceTickets}
                    setOpenEditRowInvoiceTickets={setOpenEditRowInvoiceTickets}
                    setRowEditInvoiceTickets={setRowEditInvoiceTickets}
                    showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Tickets_Edit'))?true:false}
                    showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsDetails_Delete'))?true:false}
                    numTotRegInvoiceTickets={numTotRegInvoiceTickets}
                    activeOffsetInvoiceTickets={activeOffsetInvoiceTickets}
                    rangeOffsetInvoiceTickets={rangeOffsetInvoiceTickets}
                    currencyOffsetInvoiceTickets={currencyOffsetInvoiceTickets}
                    setCurrencyOffsetInvoiceTickets={setCurrencyOffsetInvoiceTickets}>
                  </TableSortingInvoiceTickets>
                  {openEditFilterInvoiceTickets &&
                    <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEdit(false)}
                        onOpen={toggleDrawerFilterEdit(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '480px' }}>
                            <br/>
                            <div id="myDIV">
                              <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                            </div>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <form className="createfilter" onSubmit={handleCancelFilters}>  
                              <div className="form-floating mb-3 px-4 primary">
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_NumberTicket_From')} :</label>
                                <div className="form-group">
                                  <NumericFormat 
                                    thousandSeparator={''} 
                                    decimalSeparator={'.'} 
                                    decimalScale= {0}
                                    fixedDecimalScale= {true}
                                    allowNegative= {false}
                                    isAllowed={withValueCap}
                                    id="numberticketFrom" 
                                    name="numberticketFrom" 
                                    value={filtersInvoiceTickets.numberticketFrom}
                                    label={errorsFiltersInvoiceTickets.numberticketFrom!=null?t(errorsFiltersInvoiceTickets.numberticketFrom):t('Text_Filter_NumberTicket_From')}
                                    placeholder={t('PlaceHolder_Filter_NumberTicket_From')}
                                    inputProps={{ style: {width: 420,  fontSize: '18px', }, maxLength: 20 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    customInput={TextField}
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit} 
                                  /> 
                                </div>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_NumberTicket_To')} :</label> 
                                <div className="form-group">
                                  <NumericFormat 
                                    thousandSeparator={''} 
                                    decimalSeparator={'.'} 
                                    decimalScale= {0}
                                    fixedDecimalScale= {true}
                                    allowNegative= {false}
                                    isAllowed={withValueCap}
                                    id="numberticketTo" 
                                    name="numberticketTo" 
                                    value={filtersInvoiceTickets.numberticketTo}
                                    label={errorsFiltersInvoiceTickets.numberticketTo!=null?t(errorsFiltersInvoiceTickets.numberticketTo):t('PlaceHolder_Filter_NumberTicket_to')}
                                    placeholder={t('PlaceHolder_Filter_NumberTicket_to')}
                                    inputProps={{ style: {width: 420,  fontSize: '18px', }, maxLength: 20 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    customInput={TextField}
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit} 
                                  /> 
                                </div>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_FirstWeight_From')} :</label>  
                                <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                    <DesktopDatePicker
                                      id="start_date_ticketFrom" 
                                      name="start_date_ticketFrom"
                                      value={ filtersInvoiceTickets.start_date_ticketFrom!== null ? new Date(filtersInvoiceTickets.start_date_ticketFrom): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsFiltersInvoiceTickets.start_date_ticketFrom!=null?t(errorsFiltersInvoiceTickets.start_date_ticketFrom):t('PlaceHolder_Filter_FirstWeight_From')}
                                      onBlur={handleBlurSelectDateFrom}
                                      onChange={handleChangeSelectDateFrom}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            maxLength: 160,
                                            width: 450,
                                            fontSize: '18px',  
                                          },
                                          InputProps: {
                                            style: {
                                              fontSize: '18px',
                                            }
                                          },
                                          InputLabelProps:{ 
                                            style: {
                                              fontSize: '18px', 
                                              background: '#FFFFFF'
                                            }
                                          },
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_FirstWeight_To')} :</label>   
                                <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                    <DesktopDatePicker
                                      id="start_date_ticketTo" 
                                      name="start_date_ticketTo"
                                      minDate={filtersInvoiceTickets.start_date_ticketFrom!== null ? new Date(filtersInvoiceTickets.start_date_ticketFrom): null}
                                      value={ filtersInvoiceTickets.start_date_ticketTo!== null ? new Date(filtersInvoiceTickets.start_date_ticketTo): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsFiltersInvoiceTickets.start_date_ticketTo!=null?t(errorsFiltersInvoiceTickets.start_date_ticketTo):t('PlaceHolder_Filter_FirstWeight_To')}
                                      onBlur={handleBlurSelectDatetTo}
                                      onChange={handleChangeSelectDateTo}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            maxLength: 160,
                                            width: 450,
                                            fontSize: '18px',  
                                          },
                                          InputProps: {
                                            style: {
                                              fontSize: '18px',
                                            }
                                          },
                                          InputLabelProps:{ 
                                            style: {
                                              fontSize: '18px', 
                                              background: '#FFFFFF'
                                            }
                                          },
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                                
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Trans_From')} :</label>   
                                <div className="form-group">
                                  <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  {/* Campo de texto para escribir y filtrar */}
                                    <TextField
                                      id="rel_trans_store_idFrom"
                                      name="rel_trans_store_idFrom"
                                      value={filtersInvoiceTickets.rel_trans_store_idFrom}
                                      onChange={handleSearchTransFrom}
                                      onKeyDown={handleKeyDownTransFrom}
                                      onBlur={(event) => {
                                        // Solo cerrar si el foco se pierde fuera del desplegable
                                        if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                          handleBlur();
                                        }
                                      }}
                                      label={errorsFiltersInvoiceTickets.rel_trans_store_idFrom!=null?t(errorsFiltersInvoiceTickets.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                      placeholder={t('PlaceHolder_Filter_Trans_From')}
                                      variant="outlined"
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    />
                                    {/* Desplegable dinámico */}
                                    {filteredTransFrom.length > 0 && (
                                      <div
                                        className="dropdown-class"
                                        style={{
                                          position: "absolute",
                                          left:'0px',
                                          border: "2px solid #4e73df", // Borde azul (#4e73df)
                                          borderRadius: "4px",
                                          marginTop: "4px",
                                          maxHeight: "200px",
                                          overflowY: "auto",
                                          width: "100%",
                                          fontSize: '18px',
                                          backgroundColor: "#f8f9fa", // Fondo gris claro
                                          zIndex: 1000,
                                        }}
                                        onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                      >
                                        {filteredTransFrom.map((store, index) => (
                                          <MenuItem
                                              key={store.id}
                                              onClick={() => handleSelectTransFrom(store)}
                                              style={{
                                                  cursor: "pointer",
                                                  padding: "8px",
                                                  borderBottom: "1px solid #eee",
                                                  fontSize: '18px',
                                                  backgroundColor:
                                                  index === highlightedIndexTransFrom ? "#4e73df" : "transparent",
                                                  color: index === highlightedIndexTransFrom ? "white" : "black",
                                              }}
                                          >
                                            {store.documentid} - {store.tradename}
                                          </MenuItem>
                                        ))}
                                      </div>
                                    )}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="rel_trans_store_idTo"
                                        name="rel_trans_store_idTo"
                                        value={filtersInvoiceTickets.rel_trans_store_idTo}
                                        onChange={handleSearchTransTo}
                                        onKeyDown={handleKeyDownTransTo}
                                        onBlur={(event) => {
                                          // Solo cerrar si el foco se pierde fuera del desplegable
                                          if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                            handleBlur();
                                          }
                                        }}
                                        label={errorsFiltersInvoiceTickets.rel_trans_store_idTo!=null?t(errorsFiltersInvoiceTickets.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                        placeholder={t('PlaceHolder_Filter_Trans_To')}
                                        inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        variant="outlined"
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredTransTo.length > 0 && (
                                        <div
                                          className="dropdown-class"
                                          style={{
                                            position: "absolute",
                                            left:'0px',
                                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                                            borderRadius: "4px",
                                            marginTop: "4px",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            width: "100%",
                                            fontSize: '18px',
                                            backgroundColor: "#f8f9fa", // Fondo gris claro
                                            zIndex: 1000,
                                          }}
                                          onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                        >
                                        {filteredTransTo.map((store, index) => (
                                          <MenuItem
                                            key={store.id}
                                              onClick={() => handleSelectTransTo(store)}
                                              style={{
                                                  cursor: "pointer",
                                                  padding: "8px",
                                                  borderBottom: "1px solid #eee",
                                                  fontSize: '18px',
                                                  backgroundColor:
                                                  index === highlightedIndexTransTo ? "#4e73df" : "transparent",
                                                  color: index === highlightedIndexTransTo ? "white" : "black",
                                              }}
                                            >
                                              {store.documentid} - {store.tradename}
                                            </MenuItem>
                                        ))}
                                      </div>
                                    )}
                                  </span>  
                                </div> 
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_LicensePlate_From')} :</label>  
                                <div className="form-group">
                                  {/* Campo de texto para escribir y filtrar */}
                                  <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                    <TextField
                                      id="vehiclestore_licenseplateFrom"
                                      name="vehiclestore_licenseplateFrom"
                                      value={filtersInvoiceTickets.vehiclestore_licenseplateFrom}
                                      onChange={handleSearchVehicleFrom}
                                      onKeyDown={handleKeyDownVehicleFrom}
                                      onBlur={(event) => {
                                        // Solo cerrar si el foco se pierde fuera del desplegable
                                        if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                          handleBlur();
                                        }
                                      }}
                                      label={errorsFiltersInvoiceTickets.vehiclestore_licenseplateFrom!=null?t(errorsFiltersInvoiceTickets.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                                      placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      variant="outlined"
                                    />
                                    {/* Desplegable dinámico */}
                                    {filteredVehicleFrom.length > 0 && (
                                      <div
                                        className="dropdown-class"
                                        style={{
                                          position: "absolute",
                                          left:'0px',
                                          border: "2px solid #4e73df", // Borde azul (#4e73df)
                                          borderRadius: "4px",
                                          marginTop: "4px",
                                          maxHeight: "200px",
                                          overflowY: "auto",
                                          width: "100%",
                                          fontSize: '18px',
                                          backgroundColor: "#f8f9fa", // Fondo gris claro
                                          zIndex: 1000,
                                        }}
                                        onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                      >
                                        {filteredVehicleFrom.map((vehicle, index) => (
                                          <MenuItem
                                              key={vehicle.id}
                                              onClick={() => handleSelectVehicleFrom(vehicle)}
                                              style={{
                                                  cursor: "pointer",
                                                  padding: "8px",
                                                  borderBottom: "1px solid #eee",
                                                  fontSize: '18px',
                                                  backgroundColor:
                                                  index === highlightedIndexVehicleFrom ? "#4e73df" : "transparent",
                                                  color: index === highlightedIndexVehicleFrom ? "white" : "black",
                                              }}
                                          >
                                            {vehicle.vehiclestore_licenseplate}
                                          </MenuItem>
                                        ))}
                                      </div>
                                    )}
                                    </span>  
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="vehiclestore_licenseplateTo"
                                        name="vehiclestore_licenseplateTo"
                                        value={filtersInvoiceTickets.vehiclestore_licenseplateTo}
                                        onChange={handleSearchVehicleTo}
                                        onKeyDown={handleKeyDownVehicleTo}
                                        onBlur={(event) => {
                                          // Solo cerrar si el foco se pierde fuera del desplegable
                                          if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                            handleBlur();
                                          }
                                        }}
                                        label={errorsFiltersInvoiceTickets.vehiclestore_licenseplateTo!=null?t(errorsFiltersInvoiceTickets.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                                        placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                        inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        variant="outlined"
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredVehicleTo.length > 0 && (
                                      <div
                                        className="dropdown-class"
                                        style={{
                                          position: "absolute",
                                          left:'0px',
                                          border: "2px solid #4e73df", // Borde azul (#4e73df)
                                          borderRadius: "4px",
                                          marginTop: "4px",
                                          maxHeight: "200px",
                                          overflowY: "auto",
                                          width: "100%",
                                          fontSize: '18px',
                                          backgroundColor: "#f8f9fa", // Fondo gris claro
                                          zIndex: 1000,
                                        }}
                                        onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                      >
                                        {filteredVehicleTo.map((vehicle, index) => (
                                          <MenuItem
                                            key={vehicle.id}
                                              onClick={() => handleSelectVehicleTo(vehicle)}
                                              style={{
                                                  cursor: "pointer",
                                                  padding: "8px",
                                                  borderBottom: "1px solid #eee",
                                                  fontSize: '18px',
                                                  backgroundColor:
                                                  index === highlightedIndexVehicleTo ? "#4e73df" : "transparent",
                                                  color: index === highlightedIndexVehicleTo ? "white" : "black",
                                              }}
                                            >
                                              {vehicle.vehiclestore_licenseplate}
                                            </MenuItem>
                                        ))}
                                      </div>
                                    )}    
                                  </span>  
                                </div> 
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                                </div>
                              </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  <InvoiceNextTickets 
                    openEditRowInvoiceTickets={openEditRowInvoiceTickets}
                    setOpenEditRowInvoiceTickets={setOpenEditRowInvoiceTickets}
                    id={rowEditInvoiceTickets.id.toString()}
                    configPrinter={configPrinter}
                    setRefeshData={setRefeshData}
                  />
                  <Dialog open={openDeleteRowInvoiceTickets} onClose={handleCancelDeleteRow}>
                    <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditInvoiceTickets.numberticket}  - {rowEditInvoiceTickets.vehiclestore_licenseplate} </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {t('Text_Line1')}<br/>
                        {t('Text_Line2')}
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDeleteInvoiceTickets}
                        onChange={handleChangeDeleteRow}/>
                    </DialogContent>
                    <DialogActions>
                      <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                      <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
                    </DialogActions>
                  </Dialog>
                  {/* End Content Row */}
                </div>             
              </div>
            </div>
          </div>
        </div>
        //* End Page Content *//
      )}
     </div>
  )
}

InvoiceTickets.propTypes = {
  showModalTicketsSettings: PropTypes.bool.isRequired,
  submitTickets: PropTypes.bool.isRequired,
  setSubmitTickets: PropTypes.func.isRequired,
  configPrinter:PropTypes.object.isRequired,
  refeshData: PropTypes.bool.isRequired,
  setRefeshData: PropTypes.func.isRequired
}; 

export default InvoiceTickets;
