import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  numberNTrom:"",
  numberNTTo:"",
  dateContractFrom:null,
  dateContractTo:null
};

const filtersContractsSlice = createSlice({
  name: 'filtersContracts',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersContractsSlice.actions;
export default filtersContractsSlice.reducer;
