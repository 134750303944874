import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from 'react-router-dom'; 
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed'; 
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { ToastContainer } from 'react-toastify'; 
import { TodayIs } from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import TypesDIsFilter from 'pages/typesDIFormat/typesDIFilterList/TypesDiFilter';

const initialFilters = {
  "idFrom": "",
  "idTo": "",
  "codeFrom": "",
  "codeTo": "",
  "nameesFrom": "",
  "nameesTo": "",
  "nameenFrom": "",
  "nameenTo": "",
  "codeHexFrom": "",
  "codeHexTo": "",
  "codeCotGroupFrom": "",
  "codeCotGroupTo": "",
  "code_iso2From": "",
  "code_iso2To": "",
  "code_iso3From": "",
  "code_iso3To": "",
  "extphoneFrom": "",
  "extphoneTo": "",
  "extinternetFrom": "",
  "extinternetTo": "",
  "isEnabled": true,
  "isNotEnabled": true
};

const HomeTypesDI = () => {
  const { t, i18n } = useTranslation(['listDataGeneralSettings']);
  const state = useSelector((state) => state);
  const enabled = state.loginUser.enabled;
  const roles = state.loginUser.roles;
  const defaultStore = state.loginUser.defaultStore;
  const [applyFilters, setApplyFilters] = useState(initialFilters);
  const [showModalTypesDISettings, setShowModalTypesDISettings] = useState(true);
  const scriptUrl = '/js/sb-admin-2.min.js'; 

  const closeModalTypesDI = () => {
    setApplyFilters(initialFilters);
    setShowModalTypesDISettings(false); 
  };

  const changeSelectedLanguage = () => {
    i18n.language === "es" ? i18n.changeLanguage("en") : i18n.changeLanguage("es");
  };
  
  return (
    <>
      <ImportScript resourceUrl={scriptUrl} />
      <ToastContainer/>
      {enabled === false ? <Navigate to="/" /> :
        <div id="page-top">
          <div id="wrapper">
            <ul className="navbar-nav bg-gradient-primary toggled sidebar sidebar-dark accordion" id="accordionSidebar">
              <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                <div className="sidebar-brand-icon rotate-n-15">
                  <DashboardIcon />
                </div>
                <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
              </Link>
              <hr className="sidebar-divider my-0" />
              <li className="nav-item active">
                <div className="nav-link">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span>{t('Menu_FormatDI')}</span></div>
              </li>
              <hr className="sidebar-divider" />
              <li className="nav-item active">
                <Link className="nav-link" to="/formatdi">
                  <FeedIcon />
                  <span>{t('Text_Information')}</span>
                </Link>
              </li>
              <div className={applyFilters.idFrom === '' ? "sidebar-heading" : "sidebar-heading text-white"}>
                {t('Text_Filter_Code_From')}
              </div>
              <li className={applyFilters.idFrom === '' ? "sidebar-heading text-warning" : "sidebar-heading text-warning"}>
                {applyFilters.idFrom === '' ? t('Text_Filter_Not_Apply') : applyFilters.idFrom}
              </li>
              <div className={applyFilters.idTo === '' ? "sidebar-heading" : "sidebar-heading text-white"}>
                {t('Text_Filter_Code_To')}
              </div>
              <li className={applyFilters.idTo === '' ? "sidebar-heading text-warning" : "sidebar-heading text-warning"}>
                {applyFilters.idTo === '' ? t('Text_Filter_Not_Apply') : applyFilters.idTo}
              </li>
              <div className={applyFilters.nameesFrom === '' ? "sidebar-heading" : "sidebar-heading text-white"}>
                {t('Text_Filter_NameEs_From')}
              </div>
              <li className={applyFilters.nameesFrom === '' ? "sidebar-heading text-warning" : "sidebar-heading text-warning"}>
                {applyFilters.nameesFrom === '' ? t('Text_Filter_Not_Apply') : applyFilters.nameesFrom}
              </li>
              <div className={applyFilters.nameesTo === '' ? "sidebar-heading" : "sidebar-heading text-white"}>
                {t('Text_Filter_NameEs_To')}
              </div>
              <li className={applyFilters.nameesTo === '' ? "sidebar-heading text-warning" : "sidebar-heading text-warning"}>
                {applyFilters.nameesTo === '' ? t('Text_Filter_Not_Apply') : applyFilters.nameesTo}
              </li>
              <div className={applyFilters.nameenFrom === '' ? "sidebar-heading" : "sidebar-heading text-white"}>
                {t('Text_Filter_NameEn_From')}
              </div>
              <li className={applyFilters.nameenFrom === '' ? "sidebar-heading text-warning" : "sidebar-heading text-warning"}>
                {applyFilters.nameenFrom === '' ? t('Text_Filter_Not_Apply') : applyFilters.nameenFrom}
              </li>
              <div className={applyFilters.nameenTo === '' ? "sidebar-heading" : "sidebar-heading text-white"}>
                {t('Text_Filter_NameEn_To')}
              </div>
              <li className={applyFilters.nameenTo === '' ? "sidebar-heading text-warning" : "sidebar-heading text-warning"}>
                {applyFilters.nameenTo === '' ? t('Text_Filter_Not_Apply') : applyFilters.nameenTo}
              </li>
              <li className="sidebar-heading text-warning">&nbsp;</li>
              <hr className="sidebar-divider" />
              {roles[0].listmenus.find((key) => (key.name === "Menu_Settings")) &&
                <li className="nav-item">
                  <Link className="nav-link collapsed" to={`/formatdi`} data-toggle="collapse" data-target="#collapseAdmin"
                    aria-expanded="true" aria-controls="collapseAdmin">
                    <SettingsIcon />
                    <span>{t('Menu_Settings')}</span>
                  </Link>
                  <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                      <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                      <Link className="collapse-item" to={`/formatdi`} onClick={changeSelectedLanguage}>{i18n.language === "es" ? t('SubMenu_Settings_ChangeToEnglish') : t('SubMenu_Settings_ChangeToSpanish')}</Link>
                    </div>
                  </div>
                </li>
              }
              <li className="nav-item">
                <Link className="nav-link" to="/home" onClick={closeModalTypesDI}>
                  <LogoutIcon />
                  <span>{t('Button_Back')}</span>
                </Link>
              </li>
              <hr className="sidebar-divider d-none d-md-block" />
              <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
              </div>
            </ul>
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <div className="text-center">
                      <br />
                      {t('Text_StoreSelected')}&nbsp;{defaultStore.tradename === null ? t('Text_StoreEmpty') : defaultStore.tradename}
                    </div>
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <Alerts />
                    <Messages />
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow mx-1">
                      <br />
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                        <TodayIs />
                      </span>
                    </li>
                  </ul>
                </nav>
                <TypesDIsFilter 
                  applyFilters={applyFilters}
                  setApplyFilters={setApplyFilters}
                  showModalTypesDISettings={showModalTypesDISettings}
                  setShowModalTypesDISettings={setShowModalTypesDISettings}  
                />   
              </div>
              <Footer />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default HomeTypesDI;
