import { combineReducers } from '@reduxjs/toolkit';
import { loginReducer } from "./LoginReducer";
import { errorReducer } from "./ErrorReducer";
import { variablesReducer } from "./VariablesReducer";
import { formatDIReducer } from "./FormatDIReducer"; 
import filtersPurchaseDeliveryReducer from './filtersPurchaseDeliverySlice'; 
import filtersPurchaseSignatureReducer from './filtersPurchaseSignatureSlice'; 
import filtersTicketsReducer from './filtersTicketsSlice.jsx'; 
import filtersVehiclesReducer from './filtersVehiclesSlice.jsx'; 
import filtersStoresReducer from './filtersStoresSlice.jsx'; 
import filtersArticlesReducer from './filtersArticlesSlice.jsx'; 
import filtersEmployeesReducer from './filtersEmployeesSlice.jsx'; 
import filtersShippingsReducer from './filtersShippingsSlice.jsx';
import filtersRegistersReducer from './filtersRegistersSlice.jsx';
import filtersContractsReducer from './filtersContractsSlice.jsx';
import filtersFormDIReducer from './filtersFormDISlice'; 

import paginationPurchaseDeliveryReducer from './paginationPurchaseDeliverySlice';
import paginationSignatureReducer from './paginationPurchaseSignatureSlice';
import paginationTicketsReducer from './paginationTicketsSlice';
import paginationVehiclesReducer from './paginationVehiclesSlice';
import paginationStoresReducer from './paginationStoresSlice';
import paginationArticlesReducer from './paginationArticlesSlice';
import paginationEmployeesReducer from './paginationEmployeesSlice';
import paginationShippingsReducer from './paginationShippingsSlice';
import paginationRegistersReducer from './paginationRegistersSlice';
import paginationContractsReducer from './paginationContractsSlice';
import paginationFormDIReducer from './paginationFormDISlice';

import loadingReducer from './loadingSlice.jsx';
import userDefaultReducer from './userDefaultSlice.jsx';

const rootReducer = combineReducers({
  loginUser: loginReducer,
  loadError: errorReducer,
  variablesUser: variablesReducer,
  formatDIUser:formatDIReducer, 
  filtersPurchaseDelivery: filtersPurchaseDeliveryReducer,
  filtersPurchaseSignature: filtersPurchaseSignatureReducer,
  filtersTickets: filtersTicketsReducer,
  filtersVehicles: filtersVehiclesReducer,
  filtersStores: filtersStoresReducer,
  filtersArticles: filtersArticlesReducer,
  filtersEmployees: filtersEmployeesReducer,
  filtersShippings: filtersShippingsReducer,
  filtersRegisters: filtersRegistersReducer,
  filtersContracts: filtersContractsReducer,
  filtersFormDI: filtersFormDIReducer,

  pagination: paginationPurchaseDeliveryReducer,
  paginationSignature: paginationSignatureReducer,
  paginationTickets: paginationTicketsReducer,
  paginationVehicles: paginationVehiclesReducer,
  paginationStores: paginationStoresReducer,
  paginationArticles: paginationArticlesReducer,
  paginationEmployees: paginationEmployeesReducer,
  paginationShippings: paginationShippingsReducer,
  paginationRegisters: paginationRegistersReducer,
  paginationContracts: paginationContractsReducer,
  paginationFormDI: paginationFormDIReducer,

  loading: loadingReducer,
  userDefault: userDefaultReducer
  
});

export default rootReducer;
