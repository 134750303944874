import * as XLSX from "xlsx";

const exportToExcel = (data, fileName) => {

  const formatDate = (dateValue) => {
    if (!dateValue) return "null";
    const date = new Date(dateValue);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  }; 

  const entradasData = data.flatMap(data => {
    const albaranDetails = [
      data.purchasenote || "",
      formatDate(data.datepurchase) || "",
      data.ori_tras_tradename || "",
      data.ori_tras_workplace || ""
    ];
  
    const lineEntries = (data.linespurchasedelivery || []).map(line => [
      ...albaranDetails,
      line.amount || 0,
      line.estimatedprice || 0,
      (line.amount*line.estimatedprice) || 0,
      (line.amount*line.estimatedprice)*(line.tax/100) || 0,
      line.total || 0,
      data.totalpurchasedelivery || 0,
      data.totaltaxespurchasedelivery || 0,
      data.totalNetoPurchasedelivery || 0,
      data.payment_namees || "",
      line.article_code || "",
      line.article_namees || ""
    ]);
  
    return lineEntries;
  });

   
  const entradasWorksheet = XLSX.utils.aoa_to_sheet([
    [
      "Nº ALBARÁN",
      "FECHA",
      "EMPRESA",
      "OBRA", 
      "CANTIDAD",
      "PRECIO ESTIMADO",
      "IMPORTE",
      "IMPUESTO",
      "TOTAL",
      "IMPORTE ALBARÁN",
      "IMPUESTO ALBARÁN",
      "TOTAL ALBARÁN",
      "FORMA DE PAGO",
      "CÓDIGO ARTÍCULO",
      "DESCRIPCIÓN ARTÍCULO" 
    ],
    ...entradasData
  ]);

  // Crea un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Verifica si el nombre del archivo ya tiene la extensión
    const safeFileName = fileName.endsWith(".xlsx") ? fileName : `${fileName}.xlsx`;

    // Agrega las hojas de cálculo al libro
    XLSX.utils.book_append_sheet(workbook, entradasWorksheet, "RESIDUOS"); 

    // Genera el archivo en formato binario
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Crea un blob con el contenido del archivo
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Crea un enlace temporal para la descarga
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = safeFileName;

    // Dispara el evento de clic en el enlace para descargar el archivo
    a.click();

    // Libera el objeto URL
    window.URL.revokeObjectURL(url); 
};

export default exportToExcel;
