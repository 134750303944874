import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setFilters, resetFilters} from 'reducers/filtersFormDISlice';
import { resetPagination } from 'reducers/paginationFormDISlice';
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import TableSortingFormDI from "pages/forms/filterFormDI/SortingTablesFormDI";    
import PropTypes from 'prop-types';
import FormDIPDF from "pages/forms/generatePDF/FormiPDF"

const initialFiltersFormDI= {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  typesdiFromES:"",
  typesdiToES:"",
  typesdiFromEN:"",
  typesdiToEN:"",
  isFinished:false, 
  isScanned:true,
  isPending:true,
  notIsScanned:true,
  preiddocSheetFrom:"",
  preiddocSheetTo:"",
  sufiddocSheetFrom:"",
  sufiddocSheetTo:"",
  numberDTFrom:"",
  numberDTTo:"",
  dateSheetFrom:null,
  dateSheetTo:null,  
  vehicleFrom:"",
  vehicleTo:"",
  acceptFROM:"",
  acceptTO:"",
  numberPurchaseDeliveryFROM:"",
  numberPurchaseDeliveryTO:""
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": "",
  "name": ""
}];

const initialUserAccountToSelect = [{
  "id": "",
  "username":"",
  "firstName": "",
  "lastName1": "",
  "lastName2": "",
  "email": "",
  "aliasuser": ""
}];
 

const initialTypesDiToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const textAccepted=[
  {"id":1,"name":"Text_NOT_REPORTING"},
  {"id":2,"name":"Text_FORM_IsAccept"},
  {"id":3,"name":"Text_FORM_IsNotAccept"}];

const initialStateFormDI = [{
  "id" : 0,
  "ownformdi_id":0,
  "defaultStore_id" : 0,
  "tradename" : "",
  "useraccount_id" : 0,
  "username" :"",
  "defaultformatnumber" : 0,
  "isPending": true,
  "isFinished" : false,
  "isScanned" : false,
  "preiddoc_sheet" : "",
  "sufiddoc_sheet" : "", 
  "number_dt": "",
  "date_sheet" : "",
  "accept" : "",

  "opt_tras_store_id" : 0,
  "opt_tras_tradename" : "",
  "opt_tras_nima" : "",
  "opt_tras_inscription" : "",
  "opt_tras_postalcode" : "",
  "opt_tras_shared" : false,
  "sendbyemail_form_opt_tras": false,

  "ori_tras_store_id" : 0,
  "ori_tras_tradename" : "",
  "ori_tras_nima" : "",
  "ori_tras_inscription" : "",
  "ori_tras_postalcode" : "",
  "ori_tras_shared" : false,
  "sendbyemail_form_ori_tras": false,
 
  "aut_tras_store_id" : 0,
  "aut_tras_tradename" : "",
  "aut_tras_nima" : "",
  "aut_tras_inscription" : "",
  "aut_tras_postalcode" : "",
  "aut_tras_shared" : false,
  "sendbyemail_form_aut_tras": false,
  
  "dest_tras_store_id" : 0,
  "dest_tras_tradename" : "",
  "dest_tras_nima" : "",
  "dest_tras_inscription" : "",
  "dest_tras_postalcode" : "",
  "dest_tras_shared" : false,
  "sendbyemail_form_dest_tras" :false,
 
  "aut_dest_store_id" : 0,
  "aut_dest_tradename" : "",
  "aut_dest_nima" : "",
  "aut_dest_inscription" : "",
  "aut_dest_postalcode" : "",
  "aut_dest_shared" : false,
  "sendbyemail_form_aut_dest" :false,
 
  "rel_trans_store_id" : 0,
  "rel_trans_tradename" : "",
  "rel_trans_nima" : "",
  "rel_trans_inscription" : "",
  "rel_trans_postalcode" : "",
  "rel_trans_licenseplate" : "",
  "rel_trans_shared" : false,
  "sendbyemail_form_rel_trans" :false,

  "resp_prod_store_id" : 0,
  "resp_prod_tradename" : "",
  "resp_prod_nima" : "",
  "resp_prod_inscription" : "",
  "resp_prod_postalcode" : "",
  "resp_prod_shared" : false,
  "sendbyemail_form_resp_prod" :false,

  "codeler_code" : "",
  "codeler_namees" : "",
  "treatmenttypeorigin_code" : "",
  "treatmenttypeorigin_namees" : "",
  "processtypepeorigin_code" : "",
  "processtypepeorigin_namees" : "",
  "treatmenttypedestine_code" : "",
  "treatmenttypedestine_namees" : "",
  "processtypedestine_code" : "",
  "processtypedestine_namees" : "",
  "amount":0,
  "sharedStored":"",
  "emailStored":"",
  "purchaseDeliveryAssociates": ""
}];  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FormsDIFilter = ({ showModalFormSettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_FORMDI = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const FILETOUPLOAD = useSelector((state) => state.formatDIUser.filenameexcel); 
  const [dataFormDI, setDataFormDI] = useState(initialStateFormDI);
  const [formFormDI, setFormFormDI] = useState(initialStateFormDI[0]);
  const filtersFormDIDetails = useSelector((state) => state.filtersFormDI);
  const [filtersFormDI, setFiltersFormDI]= useState(filtersFormDIDetails);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegFormDI,setNumTotRegFormDI]= useState(0);
  const [errorsFiltersFormDI, setErrorsFiltersFormDI] = useState({});
  const [inputTextFormDI, setInputTextFormDI] = useState(""); 
  const [openDeleteRowFormDI,setOpenDeleteRowFormDI]= useState(false); 
  const [openEditRowFormDI,setOpenEditRowFormDI]= useState(false);
  const [openEditFilterFormDI,setOpenEditFilterFormDI]= useState(false);
  const [rowEditFormDI,setRowEditFormDI]= useState(initialStateFormDI[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorFormDI, setIsLoadingErrorFormDI] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [viewPDF,setViewPDF]=useState(false);  
  const dispatch = useDispatch();
  const [activeOffsetFormDI, setActiveOffsetFormDI]= useState(false);
  const [currencyOffsetFormDI, setCurrencyOffsetFormDI] = useState(0);
  const [rangeOffsetFormDI, setRangeOffsetFormDI] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [userAccountsToSelect,setUserAccountsToSelect]= useState(initialUserAccountToSelect);
  const [typesDISelect,setTypesDISelect]= useState(initialTypesDiToSelect); 
  const [refeshData,setRefeshData]=useState(false);

  const [openSharedRowFormDI,setOpenSharedRowFormDI]= useState(false); 
  const [openSendedRowFormDI,setOpenSendedRowFormDI]= useState(false); 
  const handleCloseSharedRowFormDI = () => setOpenSharedRowFormDI(false);
  const handleCloseSendedRowFormDI = () => setOpenSendedRowFormDI(false);

  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalFormSettings) {
        setDataFormDI(initialStateFormDI);
        setFormFormDI(initialStateFormDI[0]);
        setNumTotRegFilter(0);
        setNumTotRegFormDI(0);
        setErrorsFiltersFormDI({});
        setInputTextFormDI("");
        setOpenDeleteRowFormDI(false); 
        setOpenEditRowFormDI(false);
        setOpenEditFilterFormDI(false);
        setRowEditFormDI(initialStateFormDI[0]); 
        setIsLoadingErrorFormDI(false);
        setIdCodeDelete(DELETE_CODE);
        setViewPDF(false);
        setRefeshData(false);
        setActiveOffsetFormDI(false);
        setCurrencyOffsetFormDI(0);
        setRangeOffsetFormDI([{}]);
        setStoresToSelect(initialStoresToSelect);
        setUserAccountsToSelect(initialUserAccountToSelect);
        setTypesDISelect(initialTypesDiToSelect);
        dispatch(setLoading(false));
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFiltersFormDI(initialFiltersFormDI); 
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataFormDI,
      setFormFormDI,
      setFiltersFormDI,
      setNumTotRegFilter,
      setNumTotRegFormDI,
      setErrorsFiltersFormDI,
      setInputTextFormDI,
      setOpenDeleteRowFormDI, 
      setOpenEditRowFormDI,
      setOpenEditFilterFormDI,
      setRowEditFormDI, 
      setIsLoadingErrorFormDI,
      setIdCodeDelete,
      setViewPDF,
      setRefeshData,
      setActiveOffsetFormDI,
      setCurrencyOffsetFormDI,
      setRangeOffsetFormDI,
      setStoresToSelect,
      setUserAccountsToSelect,
      setTypesDISelect,
      showModalFormSettings,  
      navigate,
      dispatch,
      DELETE_CODE]
  );

  const closeModalFormDI = () => {    
    setDataFormDI(initialStateFormDI);
    setFormFormDI(initialStateFormDI[0]);
    setNumTotRegFilter(0);
    setNumTotRegFormDI(0);
    setErrorsFiltersFormDI({});
    setInputTextFormDI("");
    setOpenDeleteRowFormDI(false); 
    setOpenEditRowFormDI(false);
    setOpenEditFilterFormDI(false);
    setRowEditFormDI(initialStateFormDI[0]); 
    setIsLoadingErrorFormDI(false);
    setIdCodeDelete(DELETE_CODE);
    setViewPDF(false);
    setRefeshData(false);
    setActiveOffsetFormDI(false);
    setCurrencyOffsetFormDI(0);
    setRangeOffsetFormDI([{}]);
    setStoresToSelect(initialStoresToSelect);
    setUserAccountsToSelect(initialUserAccountToSelect);
    setTypesDISelect(initialTypesDiToSelect);
    dispatch(setLoading(false));
    dispatch(resetPagination());
    dispatch(resetFilters());
    setFiltersFormDI(initialFiltersFormDI); 
    navigate("/home");
    return () => {};
  };
  
  const handleFinishSelectedFormDI = (e) => {
    let isMounted = true;
    if (e.key !== 'Escape') {
      const fetchnumTotRegFormDI = async () => { 
        try {
          const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))
          ?(typeOfList==="1"?
            await helpHttp().get2(ENDPOINT,`form/getinformationtableformdi/`,token,false):
            await helpHttp().get2(ENDPOINT,`form/getinformationtableformdi/`,token,true))
          : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master'))
              ?(typeOfList==="1"
                ?await helpHttp().get8(ENDPOINT,`form/getinformationtableformdiwithstore`,token,defaultStore.id,false)
                :await helpHttp().get8(ENDPOINT,`form/getinformationtableformdiwithstore`,token,defaultStore.id,true))
              :(typeOfList==="1"
                ?await helpHttp().get7(ENDPOINT,`form/getinformationtableformdiwithstoreanduseraccount`,token,defaultStore.id,useraccount_id,false)
                :await helpHttp().get7(ENDPOINT,`form/getinformationtableformdiwithstoreanduseraccount`,token,defaultStore.id,useraccount_id,true))
              )
          if (getData.err || getData.message === "Load failed") {
            if (isMounted) {
              setDataFormDI(initialStateFormDI);
              if (getData.message==="Load failed"){
                dispatch(warningErrorAction(t('Text_ConnectionFailied')));
              }else {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              }   
            }
          } else if (isMounted) {
            setNumTotRegFormDI(getData); 
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      }; 
      const fetchnumTotRegFilter = async () => { 
        try {
          const getData = 
              (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))?
              await helpHttp().post4(ENDPOINT,`form/getcountfilterformdI`,token,"-1","-1",typeOfList,filtersFormDIDetails):
              (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master')))?
              await helpHttp().post4(ENDPOINT,`form/getcountfilterformdI`,token,"-1",useraccount_id,typeOfList,filtersFormDIDetails):
              await helpHttp().post4(ENDPOINT,`form/getcountfilterformdI`,token,defaultStore.id,useraccount_id,typeOfList,filtersFormDIDetails);
  
          if (getData.err || getData.message === "Load failed") {
            if (isMounted) {
              setDataFormDI(initialStateFormDI);
              if (getData.message==="Load failed"){
                dispatch(warningErrorAction(t('Text_ConnectionFailied')));
              }else {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } 
            }
          } else if (isMounted) {
            setNumTotRegFilter(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        } 
      }; 
      const fetchDataFormDI = async () => {
        try {
          const getData = 
            (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))?
            await helpHttp().post6(ENDPOINT,`form/getformsdI`,token,currencyOffsetFormDI,LIMITPAGESIZE,"-1","-1",typeOfList,filtersFormDIDetails):
            (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master')))?
            await helpHttp().post6(ENDPOINT,`form/getformsdI`,token,currencyOffsetFormDI,LIMITPAGESIZE,"-1",useraccount_id,typeOfList,filtersFormDIDetails):
            await helpHttp().post6(ENDPOINT,`form/getformsdI`,token,currencyOffsetFormDI,LIMITPAGESIZE,defaultStore.id,useraccount_id,typeOfList,filtersFormDIDetails);
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setDataFormDI(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }

      };
      const fetchData = async () => {
        dispatch(setLoading(true));
        try {
          const getData = typeOfList==="1"
          ? await helpHttp().put(ENDPOINT, `form/finishformdi`, token, dataFormDI)
          :await helpHttp().put(ENDPOINT, `form/returnformdi`, token, dataFormDI);
          if (getData.status === "OK") {
            if (isMounted) {
              dispatch(successErrorAction(typeOfList==="1"?t('Text_Finished'):t('Text_Pending'))); 
              if (typeOfList==="1" && FILETOUPLOAD!==null && FILETOUPLOAD!==""){
                //fetchDataExcel();
              }
              handleCloseDataEditFormDI();
              fetchnumTotRegFormDI();
              fetchnumTotRegFilter();
              fetchDataFormDI();
              dispatch(setLoading(false));
              setRefeshData(true);
            }
          } else {
            if (isMounted) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }  
      }   
      fetchData(); 
    }
    setOpenDeleteRowFormDI(false);
    setIsLoadingErrorFormDI(false);
    dispatch(setLoading(false));
    handleCloseDataEditFormDI();
  };

  const openEditFiltersFormDI = () => {
    setOpenEditFilterFormDI(!openEditFilterFormDI);
  };

  const handleSubmitFilterEditFormDI = () => {
    setErrorsFiltersFormDI(validateFormFilterFormDI());
    if (Object.keys(errorsFiltersFormDI).length === 0) {
      setErrorsFiltersFormDI({});
      dispatch(resetPagination());
      dispatch(setFilters(filtersFormDI));
      setOpenEditFilterFormDI(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersFormDI = () => {
    setErrorsFiltersFormDI({});
    dispatch(resetPagination());
    dispatch(resetFilters()); 
    setFiltersFormDI(initialFiltersFormDI);
    setOpenEditFilterFormDI(!openEditFilterFormDI);
  };

  const handleCancelFiltersFormDI = () => {
    setErrorsFiltersFormDI({});
    setOpenEditFilterFormDI(!openEditFilterFormDI);
  };
 
  const handleChangeSelectDateSheetFrom = (event) => {
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      dateSheetFrom: event!==null?new Date(event):null,
      dateSheetTo:null
    }));
  };
 
  const handleBlurSelectDateSheetFrom = (e) => {
    handleChangeSelectDateSheetFrom(e);
    setErrorsFiltersFormDI(validateFormFilterFormDI());
  };

  const handleChangeSelectDateSheetTo = (event) => {
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      dateSheetTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateSheetTo = (e) => {
    handleChangeSelectDateSheetTo(e);
    setErrorsFiltersFormDI(validateFormFilterFormDI());
  };
 
  const handleChangeSelectAccepted = (e) => {
    const { value } = e.target;
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      acceptFROM: value,
      acceptTO: value
    }));
  };

  const handleBlurSelectAccepted = (e) => {
    handleChangeSelectAccepted(e);
    setErrorsFiltersFormDI(validateFormFilterFormDI());
  };
 
  const handleChangeIsScannedFilter = (e) => {
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      isScanned: !filtersFormDI.isScanned
    }));
  };
  
  const handleChangeIsNotScannedFilter = (e) => {
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      notIsScanned: !filtersFormDI.notIsScanned
    }));
  };

  const handleChangeFilterEditFormDI = (e) => {
    const { name, value } = e.target;
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      [name]: value
    }));
  };

  const handleBlurFilterEditFormDI = (e) => {
    setErrorsFiltersFormDI(validateFormFilterFormDI());
    handleChangeFilterEditFormDI(e);
  };

  const toggleDrawerFilterEditFormDI = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditFormDI();
    }
  };

  const validateFormFilterFormDI = () => {

    let errorsFiltersFormDI = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersFormDI.idFrom!=="" & !regexNumber.test(filtersFormDI.idFrom)) {
      errorsFiltersFormDI.idFrom = 'Text_TextNoNumber';
    }
    if (filtersFormDI.idTo!=="" & !regexNumber.test(filtersFormDI.idTo)) {
      errorsFiltersFormDI.idTo = 'Text_TextNoNumber';
    }
    if (filtersFormDI.idFrom!=="" & !errorsFiltersFormDI.idFrom!==undefined){
      if (filtersFormDI.idTo!=="" & !errorsFiltersFormDI.idTo!==undefined){
        if (parseInt(filtersFormDI.idTo)<parseInt(filtersFormDI.idFrom)){
          errorsFiltersFormDI.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filtersFormDI.numberPurchaseDeliveryFROM!=="" & !regexNumber.test(filtersFormDI.numberPurchaseDeliveryFROM)) {
      errorsFiltersFormDI.numberPurchaseDeliveryFROM = 'Text_TextNoNumber';
    }
    if (filtersFormDI.numberPurchaseDeliveryTO!=="" & !regexNumber.test(filtersFormDI.numberPurchaseDeliveryTO)) {
      errorsFiltersFormDI.numberPurchaseDeliveryTO = 'Text_TextNoNumber';
    }
    if (filtersFormDI.numberPurchaseDeliveryFROM!=="" & !errorsFiltersFormDI.numberPurchaseDeliveryFROM!==undefined){
      if (filtersFormDI.numberPurchaseDeliveryTO!=="" & !errorsFiltersFormDI.numberPurchaseDeliveryTO!==undefined){
        if (parseInt(filtersFormDI.numberPurchaseDeliveryTO)<parseInt(filtersFormDI.numberPurchaseDeliveryFROM)){
          errorsFiltersFormDI.numberPurchaseDeliveryTO = 'Text_TextGreater_Purchase';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersFormDI.preiddocSheetFrom)) {
      errorsFiltersFormDI.preiddocSheetFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersFormDI.preiddocSheetTo)) {
      errorsFiltersFormDI.preiddocSheetTo = 'Text_TextNoValid';
    }
    
    if (filtersFormDI.sufiddocSheetFrom!=="" & !regexNumber.test(filtersFormDI.sufiddocSheetFrom)) {
      errorsFiltersFormDI.sufiddocSheetFrom = 'Text_TextNoNumber';
    }
    if (filtersFormDI.sufiddocSheetTo!=="" & !regexNumber.test(filtersFormDI.sufiddocSheetTo)) {
      errorsFiltersFormDI.sufiddocSheetTo = 'Text_TextNoNumber';
    } 

    if (regexInvalidCharacters.test(filtersFormDI.numberDTFrom)) {
      errorsFiltersFormDI.numberDTFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersFormDI.numberDTTo)) {
      errorsFiltersFormDI.numberDTTo = 'Text_TextNoValid';
    }
 
    if (filtersFormDI.defaultStoreIdTo!=="" & filtersFormDI.defaultStoreIdTo<filtersFormDI.defaultStoreIdFrom){
      errorsFiltersFormDI.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersFormDI.useraccountIdTo!=="" & filtersFormDI.useraccountIdTo<filtersFormDI.useraccountIdFrom){
      errorsFiltersFormDI.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }

    if (filtersFormDI.typesdiToES!=="" & filtersFormDI.typesdiToES<filtersFormDI.typesdiFromEN){
      filtersFormDI.typesdiToES="";
      errorsFiltersFormDI.typesdiToES = 'Text_TextGreater_DefaultTypeDi';
    }    
    if (filtersFormDI.typesdiToEN!=="" & filtersFormDI.typesdiToEN<filtersFormDI.typesdiFromEN){
        filtersFormDI.typesdiToEN="";
        errorsFiltersFormDI.typesdiToEN = 'Text_TextGreater_DefaultTypeDi';
    }

    if (filtersFormDI.preiddocSheetTo!=="" & filtersFormDI.preiddocSheetTo<filtersFormDI.preiddocSheetFrom){
      errorsFiltersFormDI.preiddocSheetTo = 'Text_TextGreater_PreiddocSheet';
    }

    if (filtersFormDI.sufiddocSheetTo!=="" & filtersFormDI.sufiddocSheetTo<filtersFormDI.sufiddocSheetFrom){
      errorsFiltersFormDI.sufiddocSheetTo = 'Text_TextGreater_SufiddocSheet';
    }

    if (filtersFormDI.numberDTTo!=="" & filtersFormDI.numberDTTo<filtersFormDI.numberDTFrom){
      errorsFiltersFormDI.numberDTTo = 'Text_TextGreater_numberDTTo';
    }
    return errorsFiltersFormDI;
  }  

  const filteredDataFormDI = dataFormDI.filter((el) => {
    if (inputTextFormDI === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') + 
        String(el.preiddoc_sheet || '') +   
        String(el.sufiddoc_sheet || '');
      let result = dataTranslate.toLowerCase().includes(inputTextFormDI.toLowerCase());
      return result; 
    }
  })

  let inputHandlerFormDI = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextFormDI(lowerCase);
  };

  const handleChangeDeleteRowFormDI = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowFormDI = () => {
    setRowEditFormDI(initialStateFormDI[0]);
    setIsLoadingErrorFormDI(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowFormDI(false);
  };

  const handleCloseDeleteRowFormDI = (e) => {
    if (idCodeDelete !== DELETE_CODE_FORMDI) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditFormDI.id!=='') {      
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT,`form/deleteformdi`,token,rowEditFormDI);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
                setRefeshData(true);
                handleCloseDataEditFormDI(); 
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          } 
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowFormDI(false);
    setIsLoadingErrorFormDI(false);
    handleCloseDataEditFormDI(); 
  };

  const handleCloseDataEditFormDI = () => { 
    setOpenEditRowFormDI(false);
    setRowEditFormDI(openEditRowFormDI?rowEditFormDI:initialStateFormDI[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorFormDI(false);
    toggleDrawerFilterEditFormDI(false);  
    setRefeshData(false);
	};
  
  useEffect(() => {
    if (numTotRegFormDI > Number(LIMITPAGESIZE)) {
      setActiveOffsetFormDI(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFormDI; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetFormDI(newRangeOffset);
    } else {
      setCurrencyOffsetFormDI(0);
      setActiveOffsetFormDI(false);
      setRangeOffsetFormDI([{}]);
    }
  }, [numTotRegFormDI, LIMITPAGESIZE]);
  
  useEffect(() => {
    if (showModalFormSettings){
      setFormFormDI(formFormDI => ({
        ...formFormDI,
        id : rowEditFormDI.id!==null?rowEditFormDI.id:"",
        defaultStore_id : rowEditFormDI.defaultStore_id!==null?rowEditFormDI.defaultStore_id:0,
        tradename :rowEditFormDI.tradename!==null?rowEditFormDI.tradename:"",
        useraccount_id :rowEditFormDI.useraccount_id!==null?rowEditFormDI.useraccount_id:0,
        username :rowEditFormDI.username!==null?rowEditFormDI.username:"",
        defaultformatnumber :rowEditFormDI.defaultformatnumber!==null?rowEditFormDI.defaultformatnumber:0,
        isPending :rowEditFormDI.isPending!==null?rowEditFormDI.isPending:true,
        isFinished :rowEditFormDI.isFinished!==null?rowEditFormDI.isFinished:false,
        isScanned :rowEditFormDI.isScanned!==null?rowEditFormDI.isScanned:false,
        preiddoc_sheet :rowEditFormDI.preiddoc_sheet!==null?rowEditFormDI.preiddoc_sheet:"",
        sufiddoc_sheet :rowEditFormDI.sufiddoc_sheet!==null?rowEditFormDI.sufiddoc_sheet:"",
        number_dt :rowEditFormDI.number_dt!==null?rowEditFormDI.number_dt:"",
        date_sheet :rowEditFormDI.date_sheet!==null?rowEditFormDI.date_sheet:"",
        accept :rowEditFormDI.accept!==null?rowEditFormDI.accept:"",

        opt_tras_store_id:rowEditFormDI.opt_tras_store_id!==null?rowEditFormDI.opt_tras_store_id:0,
        opt_tras_tradename:rowEditFormDI.opt_tras_tradename!==null?rowEditFormDI.opt_tras_tradename:"",
        opt_tras_nima:rowEditFormDI.opt_tras_nima!==null?rowEditFormDI.opt_tras_nima:"",
        opt_tras_inscription:rowEditFormDI.opt_tras_inscription!==null?rowEditFormDI.opt_tras_inscription:"",
        opt_tras_postalcode:rowEditFormDI.opt_tras_postalcode!==null?rowEditFormDI.opt_tras_postalcode:"",
        opt_tras_shared:rowEditFormDI.opt_tras_shared!==null?rowEditFormDI.opt_tras_shared:false,
        sendbyemail_form_opt_tras:rowEditFormDI.sendbyemail_form_opt_tras!==null?rowEditFormDI.sendbyemail_form_opt_tras:false,
       
        ori_tras_store_id:rowEditFormDI.ori_tras_store_id!==null?rowEditFormDI.ori_tras_store_id:0,
        ori_tras_tradename:rowEditFormDI.ori_tras_tradename!==null?rowEditFormDI.ori_tras_tradename:"",
        ori_tras_nima:rowEditFormDI.ori_tras_nima!==null?rowEditFormDI.ori_tras_nima:"",
        ori_tras_inscription:rowEditFormDI.ori_tras_inscription!==null?rowEditFormDI.ori_tras_inscription:"",
        ori_tras_postalcode:rowEditFormDI.ori_tras_postalcode!==null?rowEditFormDI.ori_tras_postalcode:"",
        ori_tras_shared:rowEditFormDI.ori_tras_shared!==null?rowEditFormDI.ori_tras_shared:false,
        sendbyemail_form_ori_tras:rowEditFormDI.sendbyemail_form_ori_tras!==null?rowEditFormDI.sendbyemail_form_ori_tras:false,
        
        aut_tras_store_id:rowEditFormDI.aut_tras_store_id!==null?rowEditFormDI.aut_tras_store_id:0,
        aut_tras_tradename:rowEditFormDI.aut_tras_tradename!==null?rowEditFormDI.aut_tras_tradename:"",
        aut_tras_nima:rowEditFormDI.aut_tras_nima!==null?rowEditFormDI.aut_tras_nima:"",
        aut_tras_inscription:rowEditFormDI.aut_tras_inscription!==null?rowEditFormDI.aut_tras_inscription:"",
        aut_tras_postalcode:rowEditFormDI.aut_tras_postalcode!==null?rowEditFormDI.aut_tras_postalcode:"",
        aut_tras_shared:rowEditFormDI.aut_tras_shared!==null?rowEditFormDI.aut_tras_shared:false,
        sendbyemail_form_aut_tras:rowEditFormDI.sendbyemail_form_aut_tras!==null?rowEditFormDI.sendbyemail_form_aut_tras:false,
        
        dest_tras_store_id:rowEditFormDI.dest_tras_store_id!==null?rowEditFormDI.dest_tras_store_id:0,
        dest_tras_tradename:rowEditFormDI.dest_tras_tradename!==null?rowEditFormDI.dest_tras_tradename:"",
        dest_tras_nima:rowEditFormDI.dest_tras_nima!==null?rowEditFormDI.dest_tras_nima:"",
        dest_tras_inscription:rowEditFormDI.dest_tras_inscription!==null?rowEditFormDI.dest_tras_inscription:"",
        dest_tras_postalcode:rowEditFormDI.dest_tras_postalcode!==null?rowEditFormDI.dest_tras_postalcode:"",
        dest_tras_shared:rowEditFormDI.dest_tras_shared!==null?rowEditFormDI.dest_tras_shared:false,
        sendbyemail_form_dest_tras:rowEditFormDI.sendbyemail_form_dest_tras!==null?rowEditFormDI.sendbyemail_form_dest_tras:false,
        
        aut_dest_store_id:rowEditFormDI.aut_dest_store_id!==null?rowEditFormDI.aut_dest_store_id:0,
        aut_dest_tradename:rowEditFormDI.aut_dest_tradename!==null?rowEditFormDI.aut_dest_tradename:"",
        aut_dest_nima:rowEditFormDI.aut_dest_nima!==null?rowEditFormDI.aut_dest_nima:"",
        aut_dest_inscription:rowEditFormDI.aut_dest_inscription!==null?rowEditFormDI.aut_dest_inscription:"",
        aut_dest_postalcode:rowEditFormDI.aut_dest_postalcode!==null?rowEditFormDI.aut_dest_postalcode:"",
        aut_dest_shared:rowEditFormDI.aut_dest_shared!==null?rowEditFormDI.aut_dest_shared:false,
        sendbyemail_form_aut_dest:rowEditFormDI.sendbyemail_form_aut_dest!==null?rowEditFormDI.sendbyemail_form_aut_dest:false,

        rel_trans_store_id:rowEditFormDI.rel_trans_store_id!==null?rowEditFormDI.rel_trans_store_id:0,
        rel_trans_tradename:rowEditFormDI.rel_trans_tradename!==null?rowEditFormDI.rel_trans_tradename:"",
        rel_trans_nima:rowEditFormDI.rel_trans_nima!==null?rowEditFormDI.rel_trans_nima:"",
        rel_trans_inscription:rowEditFormDI.rel_trans_inscription!==null?rowEditFormDI.rel_trans_inscription:"",
        rel_trans_postalcode:rowEditFormDI.rel_trans_postalcode!==null?rowEditFormDI.rel_trans_postalcode:"",
        rel_trans_shared:rowEditFormDI.rel_trans_shared!==null?rowEditFormDI.rel_trans_shared:false,
        sendbyemail_form_rel_trans:rowEditFormDI.sendbyemail_form_rel_trans!==null?rowEditFormDI.sendbyemail_form_rel_trans:false,
        
        resp_prod_store_id:rowEditFormDI.resp_prod_store_id!==null?rowEditFormDI.resp_prod_store_id:0,
        resp_prod_tradename:rowEditFormDI.resp_prod_tradename!==null?rowEditFormDI.resp_prod_tradename:"",
        resp_prod_nima:rowEditFormDI.resp_prod_nima!==null?rowEditFormDI.resp_prod_nima:"",
        resp_prod_inscription:rowEditFormDI.resp_prod_inscription!==null?rowEditFormDI.resp_prod_inscription:"",
        resp_prod_postalcode:rowEditFormDI.resp_prod_postalcode!==null?rowEditFormDI.resp_prod_postalcode:"",
        resp_prod_shared:rowEditFormDI.resp_prod_shared!==null?rowEditFormDI.resp_prod_shared:false,
        sendbyemail_form_resp_prod:rowEditFormDI.sendbyemail_form_resp_prod!==null?rowEditFormDI.sendbyemail_form_resp_prod:false,

        codeler_code:rowEditFormDI.codeler_code!==null?rowEditFormDI.codeler_code:"",
        codeler_namees:rowEditFormDI.codeler_namees!==null?rowEditFormDI.codeler_namees:"",
        treatmenttypeorigin_code:rowEditFormDI.treatmenttypeorigin_code!==null?rowEditFormDI.treatmenttypeorigin_code:"", 
        treatmenttypeorigin_namees:rowEditFormDI.treatmenttypeorigin_namees!==null?rowEditFormDI.treatmenttypeorigin_namees:"",
        processtypepeorigin_code:rowEditFormDI.processtypepeorigin_code!==null?rowEditFormDI.processtypepeorigin_code:"",
        processtypepeorigin_namees:rowEditFormDI.processtypepeorigin_namees!==null?rowEditFormDI.processtypepeorigin_namees:"",
        treatmenttypedestine_code:rowEditFormDI.treatmenttypedestine_code!==null?rowEditFormDI.treatmenttypedestine_code:"",
        treatmenttypedestine_namees:rowEditFormDI.treatmenttypedestine_namees!==null?rowEditFormDI.treatmenttypedestine_namees:"",
        processtypedestine_code:rowEditFormDI.processtypedestine_code!==null?rowEditFormDI.processtypedestine_code:"",
        processtypedestine_namees:rowEditFormDI.processtypedestine_namees!==null?rowEditFormDI.processtypedestine_namees:"",
        amount:rowEditFormDI.amount!==null?rowEditFormDI.amount:0,
        sharedStored:rowEditFormDI.sharedStored!==null?rowEditFormDI.sharedStored:"",
        emailStored:rowEditFormDI.emailStored!==null?rowEditFormDI.emailStored:"" 

      }));
    }else{
      setFormFormDI(initialStateFormDI[0]);
    }
  },[rowEditFormDI,showModalFormSettings]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataTypesDI = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `form/typesdirrderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `form/typesdirrderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTypesDISelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`form/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    const fetchDataUserAccount = async () => {
      try {
        const getData =(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))?
            await helpHttp().get2(ENDPOINT,`form/useraccountsorderbyusername/`,token,"-1"):
            await helpHttp().get2(ENDPOINT,`form/useraccountsorderbyusername/`,token,defaultStore.id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalFormSettings || refeshData){
      setRefeshData(false);
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master'))){
        fetchDataStore();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master'))){
        fetchDataUserAccount();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')) 
        || roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master'))
      ){
        fetchDataTypesDI();
      }
      
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,typeOfList,LIMITPAGESIZE,showModalFormSettings,defaultStore.id,refeshData]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegFormDI = async () => { 
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))
        ?(typeOfList==="1"?
          await helpHttp().get2(ENDPOINT,`form/getinformationtableformdi/`,token,false):
          await helpHttp().get2(ENDPOINT,`form/getinformationtableformdi/`,token,true))
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master'))
            ?(typeOfList==="1"
              ?await helpHttp().get8(ENDPOINT,`form/getinformationtableformdiwithstore`,token,defaultStore.id,false)
              :await helpHttp().get8(ENDPOINT,`form/getinformationtableformdiwithstore`,token,defaultStore.id,true))
            :(typeOfList==="1"
              ?await helpHttp().get7(ENDPOINT,`form/getinformationtableformdiwithstoreanduseraccount`,token,defaultStore.id,useraccount_id,false)
              :await helpHttp().get7(ENDPOINT,`form/getinformationtableformdiwithstoreanduseraccount`,token,defaultStore.id,useraccount_id,false))
            )
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataFormDI(initialStateFormDI);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
          
        } else if (isMounted) {
          setNumTotRegFormDI(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }   
    }; 
    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = 
            (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))?
            await helpHttp().post4(ENDPOINT,`form/getcountfilterformdI`,token,"-1","-1",typeOfList,filtersFormDIDetails):
            (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master')))?
            await helpHttp().post4(ENDPOINT,`form/getcountfilterformdI`,token,"-1",useraccount_id,typeOfList,filtersFormDIDetails):
            await helpHttp().post4(ENDPOINT,`form/getcountfilterformdI`,token,defaultStore.id,useraccount_id,filtersFormDIDetails);

        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataFormDI(initialStateFormDI);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };  
    const fetchDataFormDI = async () => {
       dispatch(setLoading(true));
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')))?
          await helpHttp().post6(ENDPOINT,`form/getformsdI`,token,currencyOffsetFormDI,LIMITPAGESIZE,"-1","-1",typeOfList,filtersFormDIDetails):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master')))?
          await helpHttp().post6(ENDPOINT,`form/getformsdI`,token,currencyOffsetFormDI,LIMITPAGESIZE,"-1",useraccount_id,typeOfList,filtersFormDIDetails):
          await helpHttp().post6(ENDPOINT,`form/getformsdI`,token,currencyOffsetFormDI,LIMITPAGESIZE,defaultStore.id,useraccount_id,typeOfList,filtersFormDIDetails);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataFormDI(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalFormSettings || refeshData){
      fetchnumTotRegFormDI();
      fetchnumTotRegFilter();
      fetchDataFormDI();
      setRefeshData(false); 
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,typeOfList,LIMITPAGESIZE,showModalFormSettings,currencyOffsetFormDI,defaultStore.id,useraccount_id ,openDeleteRowFormDI,setOpenDeleteRowFormDI,setOpenEditRowFormDI,openEditRowFormDI,rowEditFormDI,defaultStore, filtersFormDIDetails,refeshData]);

  return (
    <>
       {showModalFormSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={typeOfList==="1"?"hANewStyle mb-2":"hBNewStyle mb-2"}>
            {isLoadingErrorFormDI?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowFormDI?"warning":"primary"}/>:<i className="fas fa-regular fa-clipboard text-warning"></i>} {typeOfList==="1"?t('Text_FormDI_Table'):t('Text_FormDI_ENDED')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Ended'))?
              <div>
                <button type="button" className="buttonFinish" onClick={handleFinishSelectedFormDI}>{typeOfList==="1"?t('Button_Finish'):t('Button_Pending')}</button><> </>
                 <button type="button" className="buttonCreate" onClick={closeModalFormDI}>{t('Button_Back')}</button><> </>
              </div>:
              <div>
                <button type="button" className="buttonCreate" onClick={closeModalFormDI}>{t('Button_Back')}</button><> </>
              </div>
              
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextFormDI}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_FormDI_Search')} 
              onChange={inputHandlerFormDI}
              fullWidth
              label={t('Text_FormDI_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyleHigh mb-2">
            {t('Text_Filter')}&nbsp;&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.idFrom!=="" || filtersFormDIDetails.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Forms_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.defaultStoreIdFrom!=="" || filtersFormDIDetails.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Store')}</button><> </>
                  </>
                }
                 {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Forms_User_Master"))&&
                  <>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.useraccountIdFrom!=="" || filtersFormDIDetails.useraccountIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_UserAccount')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.preiddocSheetFrom!=="" || filtersFormDIDetails.preiddocSheetTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Prefix')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.sufiddocSheetFrom!=="" || filtersFormDIDetails.sufiddocSheetTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Sufix')}</button><> </> 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.dateSheetFrom!==null || filtersFormDIDetails.dateSheetTo!==null )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Date')}</button><> </> 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.isScanned)?"#32CD32":"#FFFFFF"}}></i> {t('Text_IsScanned')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.notIsScanned)?"#32CD32":"#FFFFFF"}}></i> {t('Text_IsNotScanned')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(filtersFormDIDetails.defaultformatnumberFrom 
                      || filtersFormDIDetails.defaultformatnumberTo
                      || filtersFormDIDetails.numberPurchaseDeliveryFROM
                      || filtersFormDIDetails.numberPurchaseDeliveryTO
                      || filtersFormDIDetails.numberDTFrom
                      || filtersFormDIDetails.numberDTTo
                      || filtersFormDIDetails.vehicleFrom
                      || filtersFormDIDetails.vehicleTo
                      || filtersFormDIDetails.codelerFromES!=="" 
                      || filtersFormDIDetails.acceptFROM!==""
                      || filtersFormDIDetails.acceptTO!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </> 
                
            </h1>
          </div>
          <br/>

          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingFormDI 
                    dataFormDI={filteredDataFormDI}
                    setDataFormDI={setDataFormDI}
                    setFormFormDI={setFormFormDI}
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={typeOfList==="1"?t('Text_FormDI_Table_Pending'):t('Text_FormDI_Table_ENDED')}
                    setOpenDeleteRowFormDI={setOpenDeleteRowFormDI} 
                    setOpenSharedRowFormDI={setOpenSharedRowFormDI}
                    setOpenSendedRowFormDI={setOpenSendedRowFormDI} 
                    setRowEditFormDI={setRowEditFormDI}
                    showAssociatedPurchase={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases'))?true:false}
                    showFinishButtonFormDI={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Ended'))?true:false}
                    showEditButtonFormDI={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Edit'))?true:false}
                    showPrintButtonFormDI={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Print'))?true:true}
                    showDeleteButtonFormDI={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Delete'))?true:false}
                    showStoreFormDI={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master'))?true:false}
                    showUserAccountFormDI={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master'))?true:false}
                    numTotRegFormDI={numTotRegFormDI}
                    activeOffsetFormDI={activeOffsetFormDI}
                    rangeOffsetFormDI={rangeOffsetFormDI}
                    currencyOffsetFormDI={currencyOffsetFormDI}
                    setCurrencyOffsetFormDI={setCurrencyOffsetFormDI}
                    typeOfList={typeOfList}
                    setViewPDF={setViewPDF}
                    formFormDI={formFormDI}>
                  </TableSortingFormDI>
                </div>             
              </div>
              
              {openEditFilterFormDI &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditFormDI(false)}
                 onOpen={toggleDrawerFilterEditFormDI(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfiltformdi" onSubmit={handleCancelFiltersFormDI}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                              id="idFrom" 
                              name="idFrom"
                              value={filtersFormDI.idFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersFormDI.idFrom!=null?t(errorsFiltersFormDI.idFrom):t('Text_Filter_Code_From')}
                              placeholder={t('PlaceHolder_Filter_Code_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                              id="idTo" 
                              name="idTo"
                              value={filtersFormDI.idTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersFormDI.idTo!=null?t(errorsFiltersFormDI.idTo):t('Text_Filter_Code_To')}
                              placeholder={t('PlaceHolder_Filter_Code_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                           </div>
                           {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersFormDI.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={errorsFiltersFormDI.defaultStoreIdFrom!=null?t(errorsFiltersFormDI.defaultStoreIdFrom):t('Text_Filter_tradename_From')}
                                      placeholder={t('PlaceHolder_Filter_tradename_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDI}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                             {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="defaultStoreIdTo"
                                        name="defaultStoreIdTo"
                                        value={filtersFormDI.defaultStoreIdTo}  
                                        inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                        label={errorsFiltersFormDI.defaultStoreIdTo!=null?t(errorsFiltersFormDI.defaultStoreIdTo):t('Text_Filter_tradename_To')}
                                        placeholder={t('PlaceHolder_Filter_tradename_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditFormDI}
                                        onChange={handleChangeFilterEditFormDI}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {storesToSelect.filter(item=>(item.id>=filtersFormDI.defaultStoreIdFrom)).map(option => (
                                          <option key={option.id} value={option.id}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="useraccountIdFrom"
                                      name="useraccountIdFrom"
                                      value={filtersFormDI.useraccountIdFrom}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={errorsFiltersFormDI.useraccountIdFrom!=null?t(errorsFiltersFormDI.useraccountIdFrom):t('Text_Filter_UserAccount_From')}
                                      placeholder={t('PlaceHolder_UserAccount_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDI}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {userAccountsToSelect.map((option) => (
                                          <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="useraccountIdTo"
                                        name="useraccountIdTo"
                                        value={filtersFormDI.useraccountIdTo}  
                                        inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                        label={errorsFiltersFormDI.useraccountIdTo!=null?t(errorsFiltersFormDI.useraccountIdTo):t('Text_Filter_UserAccount_To')}
                                        placeholder={t('PlaceHolder_UserAccount_To')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditFormDI}
                                        onChange={handleChangeFilterEditFormDI}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {userAccountsToSelect.filter(item=>(item.username>=filtersFormDI.useraccountIdFrom)).map(option => (
                                          <option key={option.id} value={option.username}>
                                              {option.username+" - "+option.email}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           <div className="form-group">
                             <TextField
                              id="preiddocSheetFrom" 
                              name="preiddocSheetFrom"
                              value={filtersFormDI.preiddocSheetFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                              label={errorsFiltersFormDI.preiddocSheetFrom!=null?t(errorsFiltersFormDI.preiddocSheetFrom):t('Text_Filter_PreiddocSheet_From')}
                              placeholder={t('PlaceHolder_Filter_PreiddocSheet_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="preiddocSheetTo" 
                              name="preiddocSheetTo"
                              value={filtersFormDI.preiddocSheetTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                              label={errorsFiltersFormDI.preiddocSheetTo!=null?t(errorsFiltersFormDI.preiddocSheetTo):t('Text_Filter_PreiddocSheet_To')}
                              placeholder={t('PlaceHolder_Filter_PreiddocSheet_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="sufiddocSheetFrom" 
                              name="sufiddocSheetFrom"
                              value={filtersFormDI.sufiddocSheetFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                              label={errorsFiltersFormDI.sufiddocSheetFrom!=null?t(errorsFiltersFormDI.sufiddocSheetFrom):t('Text_Filter_SufiddocSheet_From')}
                              placeholder={t('PlaceHolder_Filter_SufiddocSheet_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="sufiddocSheetTo" 
                              name="sufiddocSheetTo"
                              value={filtersFormDI.sufiddocSheetTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                              label={errorsFiltersFormDI.sufiddocSheetTo!=null?t(errorsFiltersFormDI.sufiddocSheetTo):t('Text_Filter_SufiddocSheet_To')}
                              placeholder={t('PlaceHolder_Filter_SufiddocSheet_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="numberDTFrom" 
                              name="numberDTFrom"
                              value={filtersFormDI.numberDTFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                              label={errorsFiltersFormDI.numberDTFrom!=null?t(errorsFiltersFormDI.numberDTFrom):t('Text_Filter_NumberNT_From')}
                              placeholder={t('PlaceHolder_Filter_NumberNT_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="numberDTTo" 
                              name="numberDTTo"
                              value={filtersFormDI.numberDTTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                              label={errorsFiltersFormDI.numberDTTo!=null?t(errorsFiltersFormDI.numberDTTo):t('Text_Filter_NumberNT_To')}
                              placeholder={t('PlaceHolder_Filter_NumberNT_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                           </div>

                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                id="dateSheetFrom" 
                                name="dateSheetFrom"
                                value={ filtersFormDI.dateSheetFrom!== null ? new Date(filtersFormDI.dateSheetFrom): null}
                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                label={errorsFiltersFormDI.dateSheetFrom!=null?t(errorsFiltersFormDI.dateSheetFrom):t('PlaceHolder_Filter_DateSheet_From')}
                                onBlur={handleBlurSelectDateSheetFrom}
                                onChange={handleChangeSelectDateSheetFrom}
                                slotProps={{
                                  textField: {
                                    placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                    sx: {
                                      color: '#1565c0',
                                      maxLength: '100',
                                      width: 430,  
                                      fontSize: '18px'
                                    },
                                    InputProps: {
                                      style: {
                                        fontSize: '18px',
                                      }
                                    },
                                    InputLabelProps:{ 
                                      style: {
                                        fontSize: '18px', 
                                        background: '#FFFFFF'
                                      }
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                id="dateSheetTo" 
                                name="dateSheetTo"
                                minDate={filtersFormDI.dateSheetFrom!== null ? new Date(filtersFormDI.dateSheetFrom): null}
                                value={ filtersFormDI.dateSheetTo!== null ? new Date(filtersFormDI.dateSheetTo): null}
                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                label={errorsFiltersFormDI.dateSheetTo!=null?t(errorsFiltersFormDI.dateSheetTo):t('PlaceHolder_Filter_DateSheet_To')}
                                onBlur={handleBlurSelectDateSheetTo}
                                onChange={handleChangeSelectDateSheetTo}
                                slotProps={{
                                  textField: {
                                    placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                    sx: {
                                      color: '#1565c0',
                                      maxLength: '100',
                                      width: 430,  
                                      fontSize: '18px'
                                    },
                                    InputProps: {
                                      style: {
                                        fontSize: '18px',
                                      }
                                    },
                                    InputLabelProps:{ 
                                      style: {
                                        fontSize: '18px', 
                                        background: '#FFFFFF'
                                      }
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                          <div className="form-group">
                             <TextField
                              id="vehicleFrom" 
                              name="vehicleFrom"
                              value={filtersFormDI.vehicleFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersFormDI.vehicleFrom!=null?t(errorsFiltersFormDI.sufiddocSheetFrom):t('Text_Filter_LicensePlate_From')}
                              placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="vehicleTo" 
                              name="vehicleTo"
                              value={filtersFormDI.vehicleTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersFormDI.vehicleTo!=null?t(errorsFiltersFormDI.sufiddocSheetTo):t('Text_Filter_LicensePlate_To')}
                              placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditFormDI}
                              onChange={handleChangeFilterEditFormDI}/>
                           </div>
                           { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases')) &&
                            <div className="form-group">
                              <TextField
                                id="numberPurchaseDeliveryFROM" 
                                name="numberPurchaseDeliveryFROM"
                                value={filtersFormDI.numberPurchaseDeliveryFROM}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                label={errorsFiltersFormDI.numberPurchaseDeliveryFROM!=null?t(errorsFiltersFormDI.numberPurchaseDeliveryFROM):t('Text_Filter_PurchaseDelivery_From')}
                                placeholder={t('PlaceHolder_PurchaseDelivery_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditFormDI}
                                onChange={handleChangeFilterEditFormDI}/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="numberPurchaseDeliveryTO" 
                                name="numberPurchaseDeliveryTO"
                                value={filtersFormDI.numberPurchaseDeliveryTO}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                label={errorsFiltersFormDI.numberPurchaseDeliveryTO!=null?t(errorsFiltersFormDI.numberPurchaseDeliveryTO):t('Text_Filter_PurchaseDelivery_To')}
                                placeholder={t('PlaceHolder_PurchaseDelivery_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditFormDI}
                                onChange={handleChangeFilterEditFormDI}/>
                              </div>
                            }
                            { (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_Store_Master')) || 
                             roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Forms_User_Master')) ) && <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id={i18n.language==="es"?"typesdiFromES":"typesdiFromEN"}
                                      name={i18n.language==="es"?"typesdiFromES":"typesdiFromEN"}
                                      value={i18n.language==="es"?filtersFormDI.typesdiFromES:filtersFormDI.typesdiFromEN}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={i18n.language==="es"?errorsFiltersFormDI.typesdiFromES!=null?t(errorsFiltersFormDI.typesdiFromES):t('Text_Filter_typeDI_From'):errorsFiltersFormDI.typesdiFromEN!=null?t(errorsFiltersFormDI.typesdiFromEN):t('Text_Filter_typeDI_From')}
                                      placeholder={t('PlaceHolder_Filter_typeDI_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDI}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {typesDISelect.map((option) => (
                                      <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                          {i18n.language==="es"?option.namees:option.nameen}
                                      </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id={i18n.language==="es"?"typesdiToES":"typesdiToEN"}
                                      name={i18n.language==="es"?"typesdiToES":"typesdiToEN"}
                                      value={i18n.language==="es"?filtersFormDI.typesdiToES:filtersFormDI.typesdiToEN}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={i18n.language==="es"?errorsFiltersFormDI.typesdiToES!=null?t(errorsFiltersFormDI.typesdiToES):t('Text_Filter_typeDI_To'):errorsFiltersFormDI.typesdiToEN!=null?t(errorsFiltersFormDI.typesdiToEN):t('Text_Filter_typeDI_To')}
                                      placeholder={t('PlaceHolder_typeDI_To')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDI}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {typesDISelect.filter(item=>(i18n.language==="es"?item.namees>=filtersFormDI.typesdiFromES:item.nameen>=filtersFormDI.typesdiFromEN)).map(option => (
                                      <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                          {i18n.language==="es"?option.namees:option.nameen}
                                      </option>
                                      ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                            <div className="form-group">
                              <TextField
                                id="acceptFROM"
                                name="acceptFROM"
                                value={filtersFormDI.acceptFROM}
                                inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errorsFiltersFormDI.acceptFROM!=null?t(errorsFiltersFormDI.acceptFROM):t('Text_Filter_Accepted')}
                                placeholder={t('PlaceHolder_Filter_Accepted')}
                                variant="outlined"
                                onBlur={handleBlurSelectAccepted}
                                onChange={handleChangeSelectAccepted}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {textAccepted.map((option) => (
                                    <option key={option.id} value={option.name}>
                                        {t(option.name)}
                                    </option>
                                ))}
                              </TextField>
                                 
                            </div>
                            <div className="form-group">
                              <Checkbox
                                id="isScanned" 
                                name="isScanned"
                                checked={filtersFormDI.isScanned}
                                onChange={handleChangeIsScannedFilter}
                                variant="standard"
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                              &nbsp;&nbsp;    
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_isScanned')}</label>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox
                                id="notIsScanned" 
                                name="notIsScanned"
                                checked={filtersFormDI.notIsScanned}
                                onChange={handleChangeIsNotScannedFilter}
                                variant="standard"
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                              &nbsp;&nbsp;    
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_isNotScanned')}</label>    
                            </div>                           
                         </div>
                         <hr className="sidebar-divider d-none d-md-block"/>
                         <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                           <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditFormDI}>{t('Button_Apply')}</button><> </>
                           <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersFormDI}>{t('Button_Cancel_Filters')}</button>
                         </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
             </div>
              }  
              <FormDIPDF 
                rowEditFormDI={rowEditFormDI} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />

              <Dialog open={openDeleteRowFormDI} onClose={handleCancelDeleteRowFormDI}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditFormDI.id}  - {rowEditFormDI.fullname} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowFormDI}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowFormDI}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowFormDI}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 

               <Dialog open={openDeleteRowFormDI} onClose={handleCancelDeleteRowFormDI}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditFormDI.id}  - {rowEditFormDI.preiddoc_sheet} - {rowEditFormDI.sufiddoc_sheet} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowFormDI}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowFormDI}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowFormDI}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 
              <Modal
                open={openSharedRowFormDI}
                onClose={handleCloseSharedRowFormDI}
                aria-labelledby="modalshared" 
              >
                <Box sx={style}>
                  <Typography id="modalshared" style={{color:"#4e73df", fontSize: 24,textAlign:'center'}}>
                    {t('Text_Form').toUpperCase()} ( {rowEditFormDI.id}  - {rowEditFormDI.preiddoc_sheet} - {rowEditFormDI.sufiddoc_sheet} )
                  </Typography>
                  <Typography id="modalshared" style={{color:"#4e73df", fontSize: 24,textAlign:'center'}}>
                    {t('Text_Form_Share').toUpperCase()}
                  </Typography>
                  <Typography id="modalshared" style={{color:"#000000", fontSize: 18,textAlign:'center'}}>
                    {rowEditFormDI.sharedStored}
                  </Typography>
                </Box>
              </Modal>
              <Modal
                open={openSendedRowFormDI}
                onClose={handleCloseSendedRowFormDI}
                aria-labelledby="modalshared" 
              >
                <Box sx={style}>
                  <Typography id="modalshared" style={{color:"#4e73df", fontSize: 24,textAlign:'center'}}>
                    {t('Text_Form').toUpperCase()} ( {rowEditFormDI.id}  - {rowEditFormDI.preiddoc_sheet} - {rowEditFormDI.sufiddoc_sheet} )
                  </Typography>
                  <Typography id="modalshared" style={{color:"#4e73df", fontSize: 24,textAlign:'center'}}>
                    {t('Text_Form_Send').toUpperCase()}
                  </Typography>
                  <Typography id="modalshared" style={{color:"#000000", fontSize: 18,textAlign:'center'}}>
                    {rowEditFormDI.emailStored}
                  </Typography>
                </Box>
              </Modal>
              {/* End Content Row */}
          </>:
            <LoadingNews/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

FormsDIFilter.propTypes = { 
    showModalFormSettings: PropTypes.bool.isRequired,
  typeOfList: PropTypes.string.isRequired
};

export default FormsDIFilter;

