import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import { LoadingNews } from "components/utils/LoadingNews";
import PropTypes from 'prop-types';
import TableSortingFormDescription from 'pages/forms/editOrNewFormDI/SortingTablesFormDescription'; 

const initialFiltersDescription= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};
 
const initialStateDescription = [{
  "id": "",
  "code":"",
  "namees": "",
  "nameen": ""
  
}];
    
const FormSearchDescriptions = ({ showModalSearchDescriptions, setShowModalSearchDescriptions,setFormForm}) => {
  const {t,i18n} = useTranslation(['listDataForms']);
  const state = useSelector((state) => state);
  const {token} = state.loginUser
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const [dataDescription, setDataDescription] = useState(initialStateDescription);
  const [isLoadingErrorDescription, setIsLoadingErrorDescription] = useState(false);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [inputTextDescription, setInputTextDescription] = useState("");
  const [applyFiltersDescription, setApplyFiltersDescription]= useState(initialFiltersDescription);
  const [filtersDescription, setFiltersDescription]= useState(initialFiltersDescription);
  const [openEditFilterDescription,setOpenEditFilterDescription]= useState(false);
  const [errorsFiltersDescription, setErrorsFiltersDescription] = useState({});
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegDescription,setNumTotRegDescription]= useState(0);
  const [activeOffsetDescription, setActiveOffsetDescription]= useState(false);
  const [currencyOffsetDescription, setCurrencyOffsetDescription] = useState(0);
  const [rangeOffsetDescription, setRangeOffsetDescription] = useState([{}]); 
  const dispatch = useDispatch();
   
  const handleCloseDataEditDescription = () => {
    setDataDescription(initialStateDescription);
    setIsLoadingErrorDescription(false);
    setInputTextDescription("");
    setApplyFiltersDescription(initialFiltersDescription);
    setFiltersDescription(initialFiltersDescription);
    setOpenEditFilterDescription(false);
    setErrorsFiltersDescription({});
    setNumTotRegFilter(0);
    setNumTotRegDescription(0);
    setActiveOffsetDescription(false);
    setCurrencyOffsetDescription(0);
    setRangeOffsetDescription([{}]); 
    setShowModalSearchDescriptions(false)
	};

  const toggleDrawerSearchFormDescriptions  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditDescription();
    }
  };

  let inputHandlerDescription = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextDescription(lowerCase);
  };

  const openEditFiltersDescription = () => {
    setFiltersDescription(applyFiltersDescription);
    setOpenEditFilterDescription(!openEditFilterDescription);
  };

  const toggleDrawerFilterEditDescription = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditDescription();
    }
  };

  const handleCancelFiltersDescription = () => {
    setErrorsFiltersDescription({});
    setFiltersDescription(initialFiltersDescription);
    setOpenEditFilterDescription(!openEditFilterDescription);
  };

  const handleChangeFilterEditDescription = (e) => {
    const { name, value } = e.target;
    setFiltersDescription(filtersDescription => ({
      ...filtersDescription,
      [name]: value
    }));
  };

  const handleBlurFilterEditDescription = (e) => {
    setErrorsFiltersDescription(validateFormFilterDescription());
    handleChangeFilterEditDescription(e);
  };
 
  const handleSubmitFilterEditDescription = () => {
    setErrorsFiltersDescription(validateFormFilterDescription());
    if (Object.keys(errorsFiltersDescription).length === 0) {
      setApplyFiltersDescription(filtersDescription);
      setFiltersDescription(initialFiltersDescription);
      setErrorsFiltersDescription({});
      setOpenEditFilterDescription(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeleteFiltersDescription = () => {
    setErrorsFiltersDescription({});
    setFiltersDescription(initialFiltersDescription);
    setApplyFiltersDescription(initialFiltersDescription);
    setOpenEditFilterDescription(!openEditFilterDescription);
  };

  const validateFormFilterDescription = () => {

    let errorsFiltersDescription = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersDescription.idFrom!=="" & !regexNumber.test(filtersDescription.idFrom)) {
      errorsFiltersDescription.idFrom = 'Text_TextNoNumber';
    }
    if (filtersDescription.idTo!=="" & !regexNumber.test(filtersDescription.idTo)) {
      errorsFiltersDescription.idTo = 'Text_TextNoNumber';
    }
    if (filtersDescription.idFrom!=="" & !errorsFiltersDescription.idFrom!==undefined){
      if (filtersDescription.idTo!=="" & !errorsFiltersDescription.idTo!==undefined){
        if (parseInt(filtersDescription.idTo)<parseInt(filtersDescription.idFrom)){
          errorsFiltersDescription.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersDescription.nameesFrom)) {
      errorsFiltersDescription.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersDescription.nameesTo)) {
      errorsFiltersDescription.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersDescription.nameenFrom)) {
      errorsFiltersDescription.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersDescription.nameenTo)) {
      errorsFiltersDescription.nameenTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersDescription.codeFrom)) {
      errorsFiltersDescription.codeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersDescription.codeTo)) {
      errorsFiltersDescription.codeTo = 'Text_TextNoValid';
    } 
       
    if (filtersDescription.nameesTo!=="" & filtersDescription.nameesTo<filtersDescription.nameesFrom){
      errorsFiltersDescription.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filtersDescription.nameenTo!=="" & filtersDescription.nameenTo<filtersDescription.nameenFrom){
      errorsFiltersDescription.nameenTo = 'Text_TextGreater_Nameen';
    }
    if (filtersDescription.codeTo!=="" & filtersDescription.codeTo<filtersDescription.codeFrom){
      errorsFiltersDescription.codeTo = 'Text_TextGreater_Descript';
    }

    return errorsFiltersDescription;
  }  

  const filteredDataDescription = dataDescription.filter((el) => {
    if (inputTextDescription === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.namees || '') +
        String(el.nameen || '') +  
        String(el.code || '');
      let result = dataTranslate.toLowerCase().includes(inputTextDescription.toLowerCase());
      return result; 
    }
  })

  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegDescription = async () => {
      try { 
        const getData = await helpHttp().get(ENDPOINT, `description/getinformationtabledescription`, token);
        if (getData.err) {
          setDataDescription(initialStateDescription);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegDescription(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
 
    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = await helpHttp().post(ENDPOINT, `description/getcountfilterdescriptions`, token, applyFiltersDescription);
        if (getData.err || getData.message === "Load failed") {
          setDataDescription(initialStateDescription);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
 
    const fetchDataDescription = async () => { 
      try {
        const getData = await helpHttp().get3(ENDPOINT,`description/descriptions`,token,currencyOffsetDescription,LIMITPAGESIZE,applyFiltersDescription);
        if (getData.err || getData.message === "Load failed") {
          setDataDescription(initialStateDescription);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataDescription(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };

    
    if (showModalSearchDescriptions){
      fetchnumTotRegDescription();
      fetchnumTotRegFilter();
      fetchDataDescription(); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,token,dispatch,LIMITPAGESIZE,applyFiltersDescription,currencyOffsetDescription,showModalSearchDescriptions]);

  return(
    <>
      {showModalSearchDescriptions && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormDescriptions(false)}
            onOpen={toggleDrawerSearchFormDescriptions(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '1100px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorDescription?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_FORM_Descriptions_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditDescription}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={inputTextDescription}
                    inputProps={{ style: {fontSize: '18px'} }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                    placeholder={t('Text_Description_Search')} 
                    onChange={inputHandlerDescription}
                    fullWidth
                    label={t('Text_Description_Search')}>
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyleHigh mb-2">
                    {t('Text_Filter')}&nbsp;&nbsp;
                      <button type="button" className="buttonFilterHigh" onClick={openEditFiltersDescription}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersDescription.idFrom!=="" || applyFiltersDescription.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                      <button type="button" className="buttonFilterHigh" onClick={openEditFiltersDescription}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersDescription.codeFrom!=="" || applyFiltersDescription.codeTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_FORM_Description_Code')}</button><> </>
                      <button type="button" className="buttonFilterHigh" onClick={openEditFiltersDescription}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersDescription.nameenFrom!=="" || applyFiltersDescription.nameenTo!=="" || applyFiltersDescription.nameesFrom!=="" || applyFiltersDescription.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_FORM_CODE_DESCRIPTION_TITLE')}</button><> </>
                  </h1>
                </div>
                <br/>
                {!isLoadingData ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                        <TableSortingFormDescription
                          dataDescription={filteredDataDescription}
                          numTotRegFilter={ numTotRegFilter}
                          titleTable={t('Text_FORM_Description_Table')}
                          numTotRegDescription={numTotRegDescription}
                          activeOffsetDescription={activeOffsetDescription}
                          rangeOffsetDescription={rangeOffsetDescription}
                          currencyOffsetDescription={currencyOffsetDescription}
                          setCurrencyOffsetDescription={setCurrencyOffsetDescription}
                          setFormForm={setFormForm}
                          handleCloseDataEditDescription={handleCloseDataEditDescription}
                        >
                        </TableSortingFormDescription>
                      </div>             
                    </div>
                    {openEditFilterDescription &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditDescription(false)}
                        onOpen={toggleDrawerFilterEditDescription(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelFiltersDescription}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                    <TextField
                                      id="idFrom" 
                                      name="idFrom"
                                      value={filtersDescription.idFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={errorsFiltersDescription.idFrom!=null?t(errorsFiltersDescription.idFrom):t('Text_Filter_Code_From')}
                                      placeholder={t('PlaceHolder_Filter_Code_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="idTo" 
                                      name="idTo"
                                      value={filtersDescription.idTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={errorsFiltersDescription.idTo!=null?t(errorsFiltersDescription.idTo):t('Text_Filter_Code_To')}
                                      placeholder={t('PlaceHolder_Filter_Code_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="codeFrom" 
                                      name="codeFrom"
                                      value={filtersDescription.codeFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                      label={errorsFiltersDescription.codeFrom!=null?t(errorsFiltersDescription.codeFrom):t('Text_Filter_Descript_From')}
                                      placeholder={t('PlaceHolder_Filter_Descript_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="codeTo" 
                                      name="codeTo"
                                      value={filtersDescription.codeTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                      label={errorsFiltersDescription.codeTo!=null?t(errorsFiltersDescription.codeTo):t('Text_Filter_Descript_To')}
                                      placeholder={t('PlaceHolder_Filter_Descript_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameesFrom" 
                                      name="nameesFrom"
                                      value={filtersDescription.nameesFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                      label={errorsFiltersDescription.nameesFrom!=null?t(errorsFiltersDescription.nameesFrom):t('Text_Filter_NameEs_From')}
                                      placeholder={t('PlaceHolder_Filter_NameEs_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameesTo" 
                                      name="nameesTo"
                                      value={filtersDescription.nameesTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsFiltersDescription.nameesTo!=null?t(errorsFiltersDescription.nameesTo):t('Text_Filter_NameEs_To')}
                                      placeholder={t('PlaceHolder_Filter_NameEs_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="nameenFrom" 
                                      name="nameenFrom"
                                      value={filtersDescription.nameenFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsFiltersDescription.nameenFrom!=null?t(errorsFiltersDescription.nameenFrom):t('Text_Filter_NameEn_From')}
                                      placeholder={t('PlaceHolder_Filter_NameEn_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="nameenTo" 
                                      name="nameenTo"
                                      value={filtersDescription.nameenTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsFiltersDescription.nameenTo!=null?t(errorsFiltersDescription.nameenTo):t('Text_Filter_NameEn_To')}
                                      placeholder={t('PlaceHolder_Filter_NameEn_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditDescription}
                                      onChange={handleChangeFilterEditDescription}/>
                                  </div>  
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditDescription}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersDescription}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <LoadingNews/>
                } 
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

FormSearchDescriptions.propTypes = {
  showModalSearchDescriptions: PropTypes.bool.isRequired,
  setShowModalSearchDescriptions: PropTypes.func.isRequired,
  setFormForm: PropTypes.func.isRequired
};

export default FormSearchDescriptions;
