import React, {useState, useEffect} from 'react' 
import {useTranslation} from "react-i18next" 
import Box from '@mui/material/Box';  
import Icon from '@mui/material/Icon'; 
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import useTheme from '@mui/material/styles/useTheme'; 
import visuallyHidden from '@mui/utils/visuallyHidden'; 
import PropTypes from 'prop-types';

const headCellsCodeArticles = [
  {
    id: 'indexrowbutton',
    numeric: false,
    disablePadding: false,
    label: 'Text_Row',
    canOrder:true,
  },
  {
    id: 'button_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_ArticlesButton_button_name',
    canOrder:true,
  },
  {
    id: 'button_article_code',
    numeric: false,
    disablePadding: false,
    label: 'Text_ArticlesButton_buttonarticle_code',
    canOrder:true,
  },
  {
    id: 'button_article_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_ArticlesButton_buttonarticle_name',
    canOrder:true,
  },
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsCodeArticles.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 16}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { numTotRegButtonsArticles, t,setOpenEditRowButtonsArticles } = props;
    
    const handleAddNewArticle = () => {
      setOpenEditRowButtonsArticles(true);  
    };

    return (
      <Toolbar>
      <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 16}} id="tableTitle" component="div">
        {t('Text_ArticlesButton_Table_ADD')} &nbsp;&nbsp;
          <IconButton
            size="small"  
            style={{color:"#e74a3b", fontSize: 16}}
            onClick={handleAddNewArticle}>
              <Icon
                baseClassName="fas"
                className="fa-plus-circle"
                sx={{ color:"#4e73df",fontSize: 28}}
              />
          </IconButton>
        
      </Typography>
      <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 14}} id="tableTitle" component="div">
        {t('Text_Num_Reg')} {(numTotRegButtonsArticles).toLocaleString('de-DE')} {t('Text_Of')}  {(numTotRegButtonsArticles).toLocaleString('de-DE')}
      </Typography>
    </Toolbar>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataGeneralSettings']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingButtonArticles =(props)=>{
  const { dataButtonsArticles,numTotRegButtonsArticles, setOpenDeleteRowButtonsArticles,setOpenEditRowButtonsArticles,setEditRowButtonsArticles,LIMITPAGESIZE} = props;
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataButtonsArticles.length) : 0);
  const [countData,setCountData] = useState(dataButtonsArticles.length);
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);

  useEffect(() => {
    if (dataButtonsArticles.length !== countData || dataButtonsArticles.length <= rowsPerPage) {
      setPage(0);
    }  
    setCountData(dataButtonsArticles.length);
    setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataButtonsArticles.length) : 0);
  }, [dataButtonsArticles, rowsPerPage, page, countData]);
   
  useEffect(() => {
    if (numTotRegButtonsArticles > LIMITPAGESIZE) {
      setActiveOffset(true);
      const ranges = [
        {
          value: 1,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        },
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegButtonsArticles; i += Number(LIMITPAGESIZE)) {
        ranges.push({
          value: (i / Number(LIMITPAGESIZE)) + 1,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(ranges);
    } else {
      setCurrencyOffset(1);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegButtonsArticles, LIMITPAGESIZE]);

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowArticle = (row) => {
    setEditRowButtonsArticles(row);
    setOpenDeleteRowButtonsArticles(true);
  };

  const listItems= (
  stableSort(dataButtonsArticles, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keydataButtonsArticles) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataButtonsArticles}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {row.button_id}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {i18n.language==='es'?row.button_namees:row.button_nameen}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {row.button_article_code}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
              {i18n.language==="es"?row.button_article_namees:row.button_article_nameen}
          </Typography>  
        </TableCell>
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 16}} onClick={()=>openModalDeleteRowArticle(row)}>
            <DeleteIcon style={{fontSize: 28}}/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }));
  
  return(
    <>
        <EnhancedTableToolbar numTotRegButtonsArticles={numTotRegButtonsArticles} t={t} setOpenEditRowButtonsArticles={setOpenEditRowButtonsArticles}/>
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} />
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={5}>
                      <Box sx={{ width: '100%' }}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    colSpan={6}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      
    </>
  )
}

TableSortingButtonArticles.propTypes = { 
  dataButtonsArticles: PropTypes.array.isRequired,
  numTotRegButtonsArticles: PropTypes.number.isRequired,
  setOpenDeleteRowButtonsArticles: PropTypes.func.isRequired, 
  setOpenEditRowButtonsArticles: PropTypes.func.isRequired,
  setEditRowButtonsArticles: PropTypes.func.isRequired, 
  LIMITPAGESIZE: PropTypes.string.isRequired 
};

export default TableSortingButtonArticles;