import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, setOrder, setOrderBy,setRowsPerPage} from 'reducers/paginationEmployeesSlice';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import useTheme from '@mui/material/styles/useTheme';
import visuallyHidden from '@mui/utils/visuallyHidden';
import PropTypes from 'prop-types';

const headCellsEmployee = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Text_firstname',
    canOrder:true
  },
  {
    id: 'lastname1',
    numeric: false,
    disablePadding: false,
    label: 'Text_lastname1',
    canOrder:true
  },
  {
    id: 'lastname2',
    numeric: false,
    disablePadding: false,
    label: 'Text_lastname2',
    canOrder:true
  },
  {
    id: 'numberdocument',
    numeric: false,
    disablePadding: false,
    label: 'Text_document',
    canOrder:true
  },
  {
    id: 'numbersecuritysocial',
    numeric: false,
    disablePadding: false,
    label: 'Text_securitySocial',
    canOrder:true
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_enabled',
    canOrder:false
  },
  {
    id: 'emails',
    numeric: false,
    disablePadding: false,
    label: 'Text_emails',
    canOrder:false
  },
  {
    id: 'phones',
    numeric: false,
    disablePadding: false,
    label: 'Text_phones',
    canOrder:false
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showEditButtonEmployee,showDeleteButtonEmployee } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {showEditButtonEmployee&&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 16}} >
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        }
        {headCellsEmployee.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))} 
        {showDeleteButtonEmployee&&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 16}} > 
              {t('Button_Delete')}
            </Typography>
          </TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegEmployee, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegEmployee).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataGeneralSettings']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingEmployee =(props)=>{
  const {dataEmployee,numTotRegFilter,titleTable,setOpenDeleteRowEmployee,setRowEditEmployee,showEditButtonEmployee,showDeleteButtonEmployee,numTotRegEmployee,activeOffsetEmployee,rangeOffsetEmployee,currencyOffsetEmployee,setCurrencyOffsetEmployee,minvalue} = props;
  const {t} = useTranslation(['listDataEmployees']);
  const dispatch = useDispatch();
  const { currentPage, order, orderBy,rowsPerPage } = useSelector((state) => state.paginationEmployees);
  const [countData,setCountData] = useState(dataEmployee.length);
  const maxPage = Math.max(0, Math.ceil(countData / rowsPerPage) - 1);
  const page = Math.min(currentPage, maxPage);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataEmployee.length) : 0);

  useEffect(
    () => {
      setCountData(dataEmployee.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataEmployee.length) : 0);  
    },
    [dataEmployee,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetEmployee(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch(setOrder(isAsc ? 'desc' : 'asc')); // Actualiza Redux
    dispatch(setOrderBy(property)); // Actualiza Redux
  };  

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage(newPage)); // Guardar la página actual en Redux
  };
  
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(setRowsPerPage(newRowsPerPage)); // Guardar el número de elementos por página actual en Redux
    dispatch(setCurrentPage(0)); // Reiniciar la página cuando cambian las filas por página
  };

  const openModalDeleteRowDepartment = (row) => {
    setRowEditEmployee(row);
    setOpenDeleteRowEmployee(true);
  };
  
  const listItems = stableSort(dataEmployee, getComparator(order, orderBy))
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  .map((row, keyData) => {
     return (
      <TableRow hover tabIndex={-1} key={keyData}>
        {(showEditButtonEmployee && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Link  to={`/employeedetail/${row.id}`} >
              <IconButton  size="large"  style={{color:"#4e73df", fontSize: 16}} >
                <EditIcon style={{fontSize: 28}}/>
              </IconButton>
            </Link>
          </TableCell>
        }
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.id}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.firstname}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.lastname1}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >  
            {row.lastname2}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.numberdocument!==""?row.numberdocument:t('Text_NOT_REPORTING')}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.numbersecuritysocial!==""?row.numbersecuritysocial:t('Text_NOT_REPORTING')}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.enabled} disabled
           sx={{
            '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
            }}
          />
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.emails!==undefined && row.emails!==null && row.emails.length>0)?row.emails.map((em)=> {return (em.defaultemail?'*('+em.email+') ':'('+em.email+') ')}):t('Text_NOT_REPORTING')}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >  
           {(row.phones!==undefined && row.phones!==null && row.phones.length>0)?row.phones.map((ph)=> {return (ph.defaultphone?'*('+ph.phone+') ':'('+ph.phone+') ')}):t('Text_NOT_REPORTING')}
          </Typography>
        </TableCell> 
        {(showDeleteButtonEmployee && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{ color:row.id>minvalue?"#e74a3b":"#858796", fontSize: 16}} onClick={row.id>minvalue?()=>openModalDeleteRowDepartment(row):() => {}}>
              <DeleteIcon style={{fontSize: 28}}/>
            </IconButton>
          </TableCell>
        }
      </TableRow>
    );
  });
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegEmployee={numTotRegEmployee} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showEditButtonEmployee={showEditButtonEmployee} showDeleteButtonEmployee={showDeleteButtonEmployee}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetEmployee && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetEmployee"
                            name="currencyOffsetEmployee"
                            value={currencyOffsetEmployee}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetEmployee
                            }}>
                            {rangeOffsetEmployee.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={12}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingEmployee.propTypes = {
  dataEmployee: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenDeleteRowEmployee: PropTypes.func.isRequired,
  setRowEditEmployee: PropTypes.func.isRequired,    
  showEditButtonEmployee: PropTypes.bool.isRequired,  
  showDeleteButtonEmployee: PropTypes.bool.isRequired,
  numTotRegEmployee: PropTypes.number.isRequired,
  activeOffsetEmployee: PropTypes.bool.isRequired,
  rangeOffsetEmployee: PropTypes.array.isRequired,
  currencyOffsetEmployee: PropTypes.number.isRequired,
  setCurrencyOffsetEmployee: PropTypes.func.isRequired,
  minvalue: PropTypes.number.isRequired
};
  
export default TableSortingEmployee;