import React, {  useEffect,useCallback,useState } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';  
import Container from '@mui/material/Container'; 
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { NumericFormat } from 'react-number-format';
import "css/generalSettings.css";  
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import PropTypes from 'prop-types'; 

const initialExtInternationalToSelect = [{
  "id": "",
  "extphone": ""
}];

const initialCountriesToSelect = [{
  "id" : 1,
  "namees" : "",
  "nameen" : "",
  "enabled":true
}];

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const FormatGeneralDISettings = ({ openCreateFormatGeneralDI,setOpenCreateFormatGeneralDI,rowEdit,setRowEdit,titlees,titleen}) => {
  const { t, i18n } = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DEFAULTEXTPHONE = useSelector((state) => state.formatDIUser.default_ext_phone); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
  const [data, setData] = useState(rowEdit);
  const dispatch = useDispatch();
  const [extInternationalToSelect,setExtInternationalToSelect]= useState(initialExtInternationalToSelect);
  const [countryToSelect,setCountryToSelect]= useState(initialCountriesToSelect);
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [communityToSelect,setCommunityToSelect]= useState(initialTypesToSelect);
  
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openCreateFormatGeneralDI) {
        setExtInternationalToSelect(initialExtInternationalToSelect);
        setCountryToSelect(initialCountriesToSelect);
        setProvinceToSelect(initialProvincesToSelect);
        setCommunityToSelect(initialTypesToSelect);
        setData(rowEdit);
        setOpenCreateFormatGeneralDI(false);
      }
    },
    [setData, 
      setExtInternationalToSelect,
      setCountryToSelect,
      setProvinceToSelect,
      setCommunityToSelect,
      rowEdit, 
      openCreateFormatGeneralDI, 
      setOpenCreateFormatGeneralDI]
  );

  const closeModalFormatDI = () => { 
    setData(rowEdit);
    setExtInternationalToSelect(initialExtInternationalToSelect);
    setCountryToSelect(initialCountriesToSelect);
    setProvinceToSelect(initialProvincesToSelect);
    setCommunityToSelect(initialTypesToSelect);
    setOpenCreateFormatGeneralDI(false);
  };
   
  const handleBlurDataEditFormat = (e) => {
    handleChangeDataEditFormat(e);
  };
    
  const handleChangeDataEditFormat = (e) => {
    const { name, value } = e.target;
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        [name]: value
      }
    }));
  }; 
  const handleChangeSelectCountry = (event) => {
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        default_country: event.target.value,
        default_province:""
      }
    })); 
  };

  const handleBlurSelectCountry = (e) => {
      handleChangeSelectCountry(e); 
  };


  const handleChangeDataEditNumeric = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        [name]: newValue
      }
    }));  
  };

  const handleBlurDataEditNumeric = (e) => {
    handleChangeDataEditNumeric(e); 
  };


  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100) return true;
    return false;
  };
 
  const toggleDrawerFormatDI = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      closeModalFormatDI();
      return;
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchDataExtInternational = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `country/simplephonesextinternational`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setExtInternationalToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchDataCountry = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `country/simplecountriesbynamees`, token)
          : await helpHttp().get(ENDPOINT, `country/simplecountriesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCountryToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchDataCommunity = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynamees`, token)
        : await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCommunityToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (openCreateFormatGeneralDI) {
      setData(rowEdit);
      fetchDataExtInternational();
      fetchDataCountry();
      fetchDataCommunity();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,dispatch,token,rowEdit, openCreateFormatGeneralDI]);


  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = (data.formatDIDTO.default_country!==undefined && data.formatDIDTO.default_country!=="")
        ? await helpHttp().get2(ENDPOINT, `province/simpleprovincesbycountrybyname/`, token, data.formatDIDTO.default_country)
        : await  helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
   
    fetchDataProvince();
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,openCreateFormatGeneralDI,data.formatDIDTO.default_country]);

 
  const handleSubmitFormatDI = () => {
     setRowEdit(rowEdit => ({
      ...rowEdit,
      formatDIDTO:{
        id : data.id,
        typesdi_id : data.typesdi_id,
        filenameexcel:data.filenameexcel,
        delete_code:data.delete_code,
        delete_code_employee:data.delete_code_employee,
        delete_code_store:data.delete_code_store,
        delete_code_formdi:data.delete_code_formdi,
        default_ext_phone:data.default_ext_phone,
        default_country:data.default_country,
        default_province:data.default_province,
        default_community:data.default_community,
        limitphoneperstore:data.limitphoneperstore,
        limitemailperstore:data.limitemailperstore,
        limitaddressperstore:data.limitaddressperstore,
        limitbankaccountperstore:data.limitbankaccountperstore,
        limitphoneperemployee:data.limitphoneperemployee,
        limitemailperemployee:data.limitemailperemployee,
        limitaddressperemployee:data.limitaddressperemployee,
        limitbankaccountperemployee:data.limitbankaccountperemployee,
        signature_default:data.signature_default,
        signature_default_des_tras:data.signature_default_des_tras
        
      },
      
    })); 
    const fetchData = async () => {
      try {
        const getData = await helpHttp().put(ENDPOINT, `typesdi/updateformatdi`, token, data);
        if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ConnectionFailied')));
      } 
    };

    if (openCreateFormatGeneralDI) {
      fetchData();
      closeModalFormatDI();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]); 


  return (
    <>
      {openCreateFormatGeneralDI && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerFormatDI(false)}
            onOpen={toggleDrawerFormatDI(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                    {t('Text_FORMAT_DI_TITTLE')}
                  </h1>
                  <div>
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_TypesDi_Create'))?
                      <div>
                        <button type="button" className="buttonCreate" onClick={handleSubmitFormatDI}>{t('Button_Update')}</button><> </>
                        <button type="button" className="buttonBack" onClick={closeModalFormatDI}>{t('Button_Back')}</button>
                      </div>:
                      <div>
                        <button type="button" className="buttonBack" onClick={closeModalFormatDI}>{t('Button_Back')}</button>
                      </div>
                    }
                  </div>
                </div>
                <div id="myDIV">  
                  <h1 className="h1NewStyle mb-2">
                    {i18n.language==="es"?titlees:titleen}
                  </h1>  
                </div>  
                <br/>
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createformatdi" onSubmit={closeModalFormatDI}> 
                  <div className="card shadow mb-4">    
                    <div className="card-body">
                      <div className="form-group">
                        <Typography sx={{ flex: '1 1 100%', color: "#4e73df", fontSize: 24, paddingLeft:2 }} id="tableTitle" component="div" variant="h6">
                          {t('Text_FORMAT_DI_GENERAL')}
                        </Typography>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="container text-left px-2">
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_FILENAME_EXCEL')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <TextField
                                id="filenameexcel" 
                                name="filenameexcel"
                                value={data.formatDIDTO.filenameexcel}
                                inputProps={{ style: {width: 500,  fontSize: '18px', }, maxLength: 256 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_FILENAME_EXCEL')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_FILENAME_EXCEL')}
                                variant="standard"
                                onBlur={handleBlurDataEditFormat}
                                onChange={handleChangeDataEditFormat}/>
                            </div>
                          </div>
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DELETE_CODE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <TextField
                                id="delete_code" 
                                name="delete_code"
                                value={data.formatDIDTO.delete_code}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_CODE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_CODE')}
                                variant="standard"
                                onBlur={handleBlurDataEditFormat}
                                onChange={handleChangeDataEditFormat}/>
                            </div>
                          </div>
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DELETE_CODE_EMPLOYEE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <TextField
                                id="delete_code_employee" 
                                name="delete_code_employee"
                                value={data.formatDIDTO.delete_code_employee}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_CODE_EMPLOYEE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_CODE_EMPLOYEE')}
                                variant="standard"
                                onBlur={handleBlurDataEditFormat}
                                onChange={handleChangeDataEditFormat}/>
                            </div>
                          </div>
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DELETE_CODE_STORE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <TextField
                                id="delete_code_store" 
                                name="delete_code_store"
                                value={data.formatDIDTO.delete_code_store}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_CODE_STORE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_CODE_STORE')}
                                variant="standard"
                                onBlur={handleBlurDataEditFormat}
                                onChange={handleChangeDataEditFormat}/>
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DELETE_FORMI')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <TextField
                                id="delete_code_formdi" 
                                name="delete_code_formdi"
                                value={data.formatDIDTO.delete_code_formdi}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_FORMI')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DELETE_FORMI')}
                                variant="standard"
                                onBlur={handleBlurDataEditFormat}
                                onChange={handleChangeDataEditFormat}/>
                            </div>
                          </div>
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_PHONE')} :
                              </Typography> 
                            </div>
                            <div className="col-7">
                              <TextField
                                id="default_ext_phone"
                                name="default_ext_phone"
                                value={ (data.formatDIDTO.default_ext_phone === undefined || data.formatDIDTO.default_ext_phone ===null || data.formatDIDTO.default_ext_phone ===""
                                      || data.formatDIDTO.default_ext_phone ==="0" || data.formatDIDTO.default_ext_phone ===0)?Number(DEFAULTEXTPHONE):data.formatDIDTO.default_ext_phone}
                                inputProps={{ style: {width: 100,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                variant="outlined"
                                onBlur={handleBlurDataEditFormat }
                                onChange={handleChangeDataEditFormat}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (data.formatDIDTO.default_ext_phone === undefined || data.formatDIDTO.default_ext_phone ===null || data.formatDIDTO.default_ext_phone ===""
                                        || data.formatDIDTO.default_ext_phone ==="0" || data.formatDIDTO.default_ext_phone ===0)?Number(DEFAULTEXTPHONE): data.formatDIDTO.default_ext_phone
                                }}>
                                {extInternationalToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.extphone}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_COUNTRY')} :
                              </Typography> 
                            </div>
                            <div className="col-7">
                              <TextField
                                id="default_country"
                                name="default_country"
                                value={ (data.formatDIDTO.default_country === undefined || data.formatDIDTO.default_country ===null || data.formatDIDTO.default_country ===""
                                      || data.formatDIDTO.default_country ==="0" || data.formatDIDTO.default_country ===0)?Number(DEFAULTCOUNTRY):data.formatDIDTO.default_country}
                                inputProps={{ style: {width: 355,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                variant="outlined"
                                onBlur={handleBlurSelectCountry }
                                onChange={handleChangeSelectCountry}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (data.formatDIDTO.default_country === undefined || data.formatDIDTO.default_country ===null || data.formatDIDTO.default_country ===""
                                        || data.formatDIDTO.default_country ==="0" || data.formatDIDTO.default_country ===0)?Number(DEFAULTCOUNTRY): data.formatDIDTO.default_country
                                }}>
                                {countryToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_PROVINCE')} :
                              </Typography> 
                            </div>
                            <div className="col-7">
                              <TextField
                                id="default_province"
                                name="default_province"
                                value={ (data.formatDIDTO.default_province === undefined || data.formatDIDTO.default_province ===null || data.formatDIDTO.default_province ===""
                                      || data.formatDIDTO.default_province ==="0" || data.formatDIDTO.default_province ===0)?Number(DEFAULTPROVINCE):data.formatDIDTO.default_province}
                                inputProps={{ style: {width: 355,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                variant="outlined"
                                onBlur={handleBlurDataEditFormat }
                                onChange={handleChangeDataEditFormat}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (data.formatDIDTO.default_province === undefined || data.formatDIDTO.default_province ===null || data.formatDIDTO.default_province ===""
                                        || data.formatDIDTO.default_province ==="0" || data.formatDIDTO.default_province ===0)?Number(DEFAULTPROVINCE): data.formatDIDTO.default_province
                                }}>
                                {provinceToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_COMMUNITY')} :
                              </Typography> 
                            </div>
                            <div className="col-7">
                              <TextField
                                id="default_community"
                                name="default_community"
                                value={ (data.formatDIDTO.default_community === undefined || data.formatDIDTO.default_community ===null || data.formatDIDTO.default_community ===""
                                      || data.formatDIDTO.default_community ==="0" || data.formatDIDTO.default_community ===0)?Number(DEFAULTCOMMUNITY):data.formatDIDTO.default_community}
                                inputProps={{ style: {width: 355,  fontSize: '18px', }, maxLength: 10 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                variant="outlined"
                                onBlur={handleBlurDataEditFormat }
                                onChange={handleChangeDataEditFormat}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (data.formatDIDTO.default_community === undefined || data.formatDIDTO.default_community ===null || data.formatDIDTO.default_community ===""
                                        || data.formatDIDTO.default_community ==="0" || data.formatDIDTO.default_community ===0)?Number(DEFAULTCOMMUNITY): data.formatDIDTO.default_community
                                }}>
                                {communityToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_PHONE_STORE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitphoneperstore" 
                                name="limitphoneperstore"
                                value={data.formatDIDTO.limitphoneperstore}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_PHONE_STORE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_PHONE_STORE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_EMAIL_STORE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitemailperstore" 
                                name="limitemailperstore"
                                value={data.formatDIDTO.limitemailperstore}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_EMAIL_STORE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_EMAIL_STORE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_ADDRESS_STORE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitaddressperstore" 
                                name="limitaddressperstore"
                                value={data.formatDIDTO.limitaddressperstore}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_ADDRESS_STORE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_ADDRESS_STORE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_BANKACCOUNT_STORE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitbankaccountperstore" 
                                name="limitbankaccountperstore"
                                value={data.formatDIDTO.limitbankaccountperstore}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_BANKACCOUNT_STORE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_BANKACCOUNT_STORE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_PHONE_EMPLOYEE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitphoneperemployee" 
                                name="limitphoneperemployee"
                                value={data.formatDIDTO.limitphoneperemployee}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_PHONE_EMPLOYEE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_PHONE_EMPLOYEE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>
                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_EMAIL_EMPLOYEE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitemailperemployee" 
                                name="limitemailperemployee"
                                value={data.formatDIDTO.limitemailperemployee}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_EMAIL_EMPLOYEE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_EMAIL_EMPLOYEE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_ADDRESS_EMPLOYEE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitaddressperemployee" 
                                name="limitaddressperemployee"
                                value={data.formatDIDTO.limitaddressperemployee}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_ADDRESS_EMPLOYEE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_ADDRESS_EMPLOYEE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>

                          <div className="row  p-1">
                            <div className="col-5 text-right">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_GENERAL_DEFAULT_LIMIT_BANKACCOUNT_EMPLOYEE')} :
                              </Typography> 
                            </div>
                            <div className="col-7 ">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="limitbankaccountperemployee" 
                                name="limitbankaccountperemployee"
                                value={data.formatDIDTO.limitbankaccountperemployee}
                                label={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_BANKACCOUNT_EMPLOYEE')}
                                placeholder={t('PlaceHolder_FORMAT_DI_GENERAL_DEFAULT_LIMIT_BANKACCOUNT_EMPLOYEE')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                customInput={TextField}
                                onBlur={handleBlurDataEditNumeric}
                                onChange={handleChangeDataEditNumeric}
                              />
                            </div>
                          </div>
                        </div>  
                        <br/>
                      </div>  
                    </div> 
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>    
        </div>
      } 
    </>
  )
}

FormatGeneralDISettings.propTypes = {
  openCreateFormatGeneralDI: PropTypes.bool.isRequired,
  setOpenCreateFormatGeneralDI: PropTypes.func.isRequired,
  rowEdit: PropTypes.object.isRequired,
  setRowEdit: PropTypes.func.isRequired,
  titlees: PropTypes.string.isRequired,
  titleen: PropTypes.string.isRequired
};

export default FormatGeneralDISettings;