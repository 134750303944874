import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  typesdiFromES:"",
  typesdiToES:"",
  typesdiFromEN:"",
  typesdiToEN:"",
  isFinished:false, 
  isScanned:true,
  isPending:true,
  notIsScanned:true,
  preiddocSheetFrom:"",
  preiddocSheetTo:"",
  sufiddocSheetFrom:"",
  sufiddocSheetTo:"",
  numberDTFrom:"",
  numberDTTo:"",
  dateSheetFrom:null,
  dateSheetTo:null,  
  vehicleFrom:"",
  vehicleTo:"",
  acceptFROM:"",
  acceptTO:"",
  numberPurchaseDeliveryFROM:"",
  numberPurchaseDeliveryTO:""
};

const filtersFormDISlice = createSlice({
  name: 'filtersFormDI',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersFormDISlice.actions;
export default filtersFormDISlice.reducer;
