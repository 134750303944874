import React, {useState, useEffect} from 'react'; 
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, setOrder, setOrderBy,setRowsPerPage,setSelectAll,setSelectAllPendig,updateSelectedRowsPending, updateSelectedRows} from 'reducers/paginationPurchaseDeliverySlice';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import Box from '@mui/material/Box'; 
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'; 
import BackspaceIcon from '@mui/icons-material/Backspace';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import useTheme from '@mui/material/styles/useTheme';
import visuallyHidden from '@mui/utils/visuallyHidden'; 
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const headCellsPurchaseDelivery = [
  { id: 'purchasenote', numeric: false, disablePadding: false, label: 'Text_NumberPurchase', canOrder: true },
  { id: 'numberticket', numeric: false, disablePadding: false, label: 'Text_PURCHASEDELIVERY_TICKET', canOrder: true },
  { id: 'datepurchase', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_Date', canOrder: true },
  { id: 'ori_tras_tradename', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_STORE', canOrder: true },
  { id: 'workplace', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_WORKPLACE', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_TRADENAME_TRANS', canOrder: true },
  { id: 'vehiclestore_licenseplate', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_LICENSEPLATE', canOrder: true },
  { id: 'totalpurchase', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_TOTAL', canOrder: true } ,
  { id: 'payment_name', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_Payment', canOrder: true },
  { id: 'formDIAssociates', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_ASSOCIATES_DI', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showFinishButtonPurchaseDelivery, showEditButtonPurchaseDelivery,showPrintButtonPurchaseDelivery,showDeleteButtonPurchaseDelivery,showEnabledButtonPurchaseDelivery,selectAll,selectAllPendig,handleChangSelectAll,handleChangSelectAllPending,typeOfList} = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {showEditButtonPurchaseDelivery &&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 16}} >
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        }
        {showPrintButtonPurchaseDelivery &&
          <TableCell
            key='print'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 16}} >
              {t('Button_Print_PDF')}
            </Typography>
          </TableCell>
        }
        {showFinishButtonPurchaseDelivery &&
          <TableCell
            key='pending'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 16}} >
              {(typeOfList==="1" || typeOfList==="3")?t('Text_Finish'):t('Text_Return')}
            </Typography>
            <Checkbox checked={(typeOfList==="1" || typeOfList==="3")?selectAll:selectAllPendig} style={{color:"#4e73df", fontSize: 16}}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
              }}
              onClick={(typeOfList==="1" || typeOfList==="3")?()=>handleChangSelectAll():()=>handleChangSelectAllPending()}
            />
          </TableCell>
        }  
        {headCellsPurchaseDelivery.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
          <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)} // Llama a la función pasada como prop
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        {showEnabledButtonPurchaseDelivery &&
          <TableCell
            key='enabled'
            align='center'
            padding='normal' 
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 16}} > 
              {t('Button_ENABLED')}
            </Typography>
          </TableCell>
        }
         {showDeleteButtonPurchaseDelivery &&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 16}} > 
              {t('Button_Delete')}
            </Typography>
          </TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegPurchaseDelivery, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegPurchaseDelivery).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataPurchases']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingPurchaseDelivery =(props)=>{
  const { 
    dataPurchaseDelivery, 
    setDataPurchaseDelivery,
    setFormPurchaseDelivery,
    numTotRegFilter,
    titleTable,
    setOpenEnabledRowPurchaseDelivery,
    setOpenDeleteRowPurchaseDelivery,
    setRowEditPurchaseDelivery, 
    showFinishButtonPurchaseDelivery,
    showEditButtonPurchaseDelivery,
    showPrintButtonPurchaseDelivery,
    showDeleteButtonPurchaseDelivery, 
    showEnabledButtonPurchaseDelivery,
    showTotalPurchaseDelivery,
    numTotRegPurchaseDelivery,
    activeOffsetPurchaseDelivery,
    rangeOffsetPurchaseDelivery,
    currencyOffsetPurchaseDelivery,
    setCurrencyOffsetPurchaseDelivery,
    setViewPDF,
    typeOfList
  } = props;
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const dispatch = useDispatch();
  const { currentPage, order, orderBy,rowsPerPage,selectAll,selectAllPendig,selectedRowsPending,selectedRows} = useSelector((state) => state.pagination);
  const [countData,setCountData] = useState(dataPurchaseDelivery.length);
  const maxPage = Math.max(0, Math.ceil(countData / rowsPerPage) - 1);
  const page = Math.min(currentPage, maxPage);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataPurchaseDelivery.length) : 0);
 
  useEffect(
    () => {
      setCountData(dataPurchaseDelivery.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataPurchaseDelivery.length) : 0);  
    },
    [dataPurchaseDelivery,page,setEmptyRows,rowsPerPage,countData]
  );

  useEffect(() => {
    const updatedRowsPending = dataPurchaseDelivery.map(item => ({
      ...item,
      isFinished: selectedRowsPending[item.id] || selectAll
    }));
    
    // Evita llamar setDataPurchaseDelivery si los datos no cambian
    if (JSON.stringify(updatedRowsPending) !== JSON.stringify(dataPurchaseDelivery))  {
      setDataPurchaseDelivery(updatedRowsPending);
    }
  }, [dataPurchaseDelivery, selectedRowsPending,setDataPurchaseDelivery,selectAll]);
  
    
  // Mantén los otros useEffect que ya tienes
  useEffect(() => {
    const updatedRows = dataPurchaseDelivery.map((item) => ({
      ...item,
      isPending: selectedRows[item.id] || selectAllPendig,
    }));
  
    // Solo actualiza si hay cambios reales
    if (JSON.stringify(updatedRows) !== JSON.stringify(dataPurchaseDelivery)) {
      setDataPurchaseDelivery(updatedRows);
    }
  }, [selectedRows,dataPurchaseDelivery,setDataPurchaseDelivery,selectAllPendig]); // Elimina dataPurchaseDelivery de las dependencias
  
  const handleChangeOffset = (event) => {
    setCurrencyOffsetPurchaseDelivery(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch(setOrder(isAsc ? 'desc' : 'asc')); // Actualiza Redux
    dispatch(setOrderBy(property)); // Actualiza Redux
  };  

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage(newPage)); // Guardar la página actual en Redux
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(setRowsPerPage(newRowsPerPage)); // Guardar el número de elementos por página actual en Redux
    dispatch(setCurrentPage(0)); // Reiniciar la página cuando cambian las filas por página
  };

  const openModalEnabledRowTickets = (row) => {
    setRowEditPurchaseDelivery(row);
    setOpenEnabledRowPurchaseDelivery(true);
  };

  const openModalDeleteRowTickets = (row) => {
    setRowEditPurchaseDelivery(row);
    setOpenDeleteRowPurchaseDelivery(true);
  };

  const openModalTicketsPDF = async (row) => {
    setRowEditPurchaseDelivery(row);
    setViewPDF(true);
  }; 

  const handleChangSelectAll = () => { 
    const newSelectAll = !selectAll;
    const updatedSelectedRows = dataPurchaseDelivery.reduce((acc, item) => {
      acc[item.id] = newSelectAll;
      return acc;
    }, {});

    dispatch(setSelectAll(newSelectAll));
    dispatch(updateSelectedRowsPending(updatedSelectedRows));
    const updatedRows = dataPurchaseDelivery.map(item => ({
      ...item,
      isFinished: newSelectAll,
    })); 
    setDataPurchaseDelivery(updatedRows);
    setFormPurchaseDelivery(formPurchaseDelivery => ({
    ...formPurchaseDelivery,
    }));
  };

  const handleChangSelectAllPending = () => {
    const newSelectAllPending = !selectAllPendig;
    const updatedSelectedRows = dataPurchaseDelivery.reduce((acc, item) => {
      acc[item.id] = newSelectAllPending;
      return acc;
    }, {});
    dispatch(setSelectAllPendig(newSelectAllPending));
    dispatch(updateSelectedRows(updatedSelectedRows)); // Un único dispatch con todos los registros
    const updatedRows = dataPurchaseDelivery.map(item => ({
      ...item,
      isPending: newSelectAllPending,
    }));
    setDataPurchaseDelivery(updatedRows);
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
    }));
  };
   
  const handleChangeEnabledRow = (row) => {
    const updatedRowsPending = dataPurchaseDelivery.map(item =>
      item.id === row.id ? { ...item, isFinished: !item.isFinished } : item
    );
    setDataPurchaseDelivery(updatedRowsPending); 
    dispatch(updateSelectedRowsPending({ 
      id: row.id, 
      isFinished: !row.isFinished 
    }));
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
    }));
  };

  const handleChangeEnabledPendingRow = (row) => {
    const updatedRows = dataPurchaseDelivery.map(item =>
      item.id === row.id ? { ...item, isPending: !item.isPending } : item
    );
    setDataPurchaseDelivery(updatedRows);
    dispatch(updateSelectedRows({
      id: row.id,
      isPending: !row.isPending,
    }));
    setFormPurchaseDelivery((formPurchaseDelivery) => ({
      ...formPurchaseDelivery,
    }));
  }; 
  
  const listItems = stableSort(dataPurchaseDelivery, getComparator(order, orderBy))
   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
   .map((row, keyData) => {
     return (
      <TableRow hover tabIndex={-1} key={keyData}>
        {(showEditButtonPurchaseDelivery && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Link  to={typeOfList==="1"?`/purchasedeliverydetail/${row.id}`:typeOfList==="2"?`/purchasedeliverydetailended/${row.id}`:`/purchasedeliverydetailbegin/${row.id}`} >
              <IconButton  size="large"  style={{color:"#4e73df", fontSize: 16}} >
                <EditIcon style={{fontSize: 28}}/>
              </IconButton>
            </Link>
          </TableCell>
        }
         
         {(showPrintButtonPurchaseDelivery && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 16}} onClick={()=>openModalTicketsPDF(row)}>
              <PictureAsPdfIcon style={{fontSize: 28}}/>
            </IconButton>
          </TableCell>
        }
        {(showFinishButtonPurchaseDelivery && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Checkbox checked={(typeOfList==="1" || typeOfList==="3")?row.isFinished:row.isPending} style={{color:"#4e73df", fontSize: 16}}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
              }}
              onClick={(typeOfList==="1" || typeOfList==="3")?()=>handleChangeEnabledRow(row):()=>handleChangeEnabledPendingRow(row)}
            />
          </TableCell>
        } 
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 18, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.purchasenote}
          </Typography>  
        </TableCell> 
        <TableCell align="center">
         <Typography style={{color:"#000000", fontSize: 18, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.numberticket!==undefined && row.numberticket!=="" && row.numberticket!=="0" && row.numberticket!==0)?row.numberticket:""}
          </Typography>
        </TableCell> 
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 18, textDecorationLine: row.enabled?'':'line-through'}} >
          {row.datepurchase !== null &&  row.datepurchase !== ""
            ? new Date(row.datepurchase).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") 
            : ""}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.ori_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.workplace}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 18, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.vehiclestore_licenseplate}
          </Typography>
        </TableCell> 
        <TableCell align="right">
          <Typography style={{color:"#000000", fontSize: 18}} >
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              fixedDecimalScale= {true}
              allowNegative= {true}
              id="totalpurchase" 
              name="totalpurchase"
              value={row.totalpurchase}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 18, textAlign:'right', paddingRight:5}} 
              disabled
            />
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {i18n.language==='es'?row.payment_namees:row.payment_nameen}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.formDIAssociates===undefined || row.formDIAssociates===null || row.formDIAssociates==="")?t('Text_NOT_REPORTING_DI'): row.formDIAssociates}
          </Typography>
        </TableCell>
       
        {(showEnabledButtonPurchaseDelivery && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:row.enabled?"#4e73df":"#ffc107", fontSize: 14}} onClick={()=>openModalEnabledRowTickets(row)}>
              {row.enabled?<HighlightOffIcon  style={{fontSize: 28}}/>:<BackspaceIcon  style={{fontSize: 28}}/>} 
            </IconButton>
          </TableCell>
          }
        {(showDeleteButtonPurchaseDelivery && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowTickets(row)}>
              <DeleteIcon  style={{fontSize: 28}}/> 
            </IconButton>
          </TableCell>
          }
        
      </TableRow>
    );
  });
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegPurchaseDelivery={numTotRegPurchaseDelivery} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead 
                order={order} 
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
                t={t} 
                showFinishButtonPurchaseDelivery={showFinishButtonPurchaseDelivery} 
                showEditButtonPurchaseDelivery={showEditButtonPurchaseDelivery} 
                showPrintButtonPurchaseDelivery={showPrintButtonPurchaseDelivery} 
                showDeleteButtonPurchaseDelivery={showDeleteButtonPurchaseDelivery} 
                showEnabledButtonPurchaseDelivery={showEnabledButtonPurchaseDelivery}
                selectAll={selectAll} 
                selectAllPendig={selectAllPendig} 
                handleChangSelectAll={handleChangSelectAll} 
                handleChangSelectAllPending={handleChangSelectAllPending} 
                typeOfList={typeOfList}/
              >
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={13} />
                </TableRow>
                )}
                {showTotalPurchaseDelivery &&
                  <TableRow> 
                    <TableCell colSpan={10}>
                    </TableCell>
                      <TableCell align="right">
                        <Typography style={{ color: "#4e73df", fontSize: 18 }}>
                          <NumericFormat 
                            thousandSeparator={','} 
                            decimalSeparator={'.'} 
                            decimalScale= {2}
                            fixedDecimalScale= {true}
                            allowNegative= {true}
                            id="totalpurchase" 
                            name="totalpurchase"
                            value={dataPurchaseDelivery.reduce((acc, row) => acc + parseFloat(row.totalpurchase || 0), 0)}
                            style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 18, textAlign:'right', paddingRight:5}} 
                            disabled
                          />
                        </Typography>
                      </TableCell> 
                      <TableCell colSpan={4}>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
              
              <TableFooter>
                <TableRow>
                  {activeOffsetPurchaseDelivery && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetPurchaseDelivery"
                            name="currencyOffsetPurchaseDelivery"
                            value={currencyOffsetPurchaseDelivery}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetPurchaseDelivery
                            }}>
                            {rangeOffsetPurchaseDelivery.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )} 
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={15}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={page} // Página validada
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  /> 
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingPurchaseDelivery.propTypes = {
  dataPurchaseDelivery: PropTypes.array.isRequired, 
  setDataPurchaseDelivery:PropTypes.func.isRequired, 
  setFormPurchaseDelivery:PropTypes.func.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired, 
  setOpenEnabledRowPurchaseDelivery: PropTypes.func.isRequired, 
  setOpenDeleteRowPurchaseDelivery: PropTypes.func.isRequired, 
  setRowEditPurchaseDelivery: PropTypes.func.isRequired,   
  showFinishButtonPurchaseDelivery: PropTypes.bool.isRequired,
  showEditButtonPurchaseDelivery: PropTypes.bool.isRequired,  
  showPrintButtonPurchaseDelivery: PropTypes.bool.isRequired,  
  showDeleteButtonPurchaseDelivery: PropTypes.bool.isRequired,
  showEnabledButtonPurchaseDelivery: PropTypes.bool.isRequired,
  showTotalPurchaseDelivery: PropTypes.bool.isRequired,
  numTotRegPurchaseDelivery: PropTypes.number.isRequired,
  activeOffsetPurchaseDelivery: PropTypes.bool.isRequired,
  rangeOffsetPurchaseDelivery: PropTypes.array.isRequired, 
  setCurrencyOffsetPurchaseDelivery: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired,
  typeOfList: PropTypes.string.isRequired 
};
  
export default TableSortingPurchaseDelivery;
