import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  firstnameFrom:"",
  firstnameTo:"",
  lastname1From:"",
  lastname1To:"",
  lastname2From:"",
  lastname2To:"",
  enabled:true,
  notenabled:true,
  languageFromES:"",
  languageToES:"",
  languageFromEN:"",
  languageToEN:"",
  emailsFrom:"",
  emailsTo:"",
  phonesFrom:"",
  phonesTo:"",
};

const filtersEmployeesSlice = createSlice({
  name: 'filtersEmployee',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersEmployeesSlice.actions;
export default filtersEmployeesSlice.reducer;
