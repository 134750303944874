import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import Card from '@mui/material/Card'; 
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import Grid  from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles'; 
import SignatureCanvas from 'react-signature-canvas';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'; 

import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { NumericFormat } from 'react-number-format';

import ContractCreateOrEditOptTras  from "pages/contracts/editOrNewContract/ContractOptTrasEdit";
import ContractCreateOrEditOriTras  from "pages/contracts/editOrNewContract/ContractOriTrasEdit";
import ContractCreateOrEditAutTras  from "pages/contracts/editOrNewContract/ContractAutTrasEdit";
import ContractCreateOrEditDestTras from "pages/contracts/editOrNewContract/ContractDestTrasEdit";
import ContractCreateOrEditAutDest from "pages/contracts/editOrNewContract/ContractAutDestEdit";
import TableSortingCodeLERS from "pages/contracts/editOrNewContract/SortingTablesCodeLers";
import ContractCreateCodeLER from "pages/contracts/editOrNewContract/ContractNewCodeLER";
import ContractShareEdit from "pages/contracts/editOrNewContract/ContractSharedEdit";
import image1 from "components/photos/formdi/tosign.png"; 

import PropTypes from 'prop-types';  

const initialStateContract = {
  "id" : "",
  "owncontract_id" : "",
  "defaultStore_id":0, 
  "useraccount_id":0,
  "defaultformatnumber":1,
  "isFinished":true,
  "isScanned":false,
 
  "number_nt":"",
  "date_contract":"",
  "amount_contract":0,
  "periodicity_contract":"",
  "duration_contract":"",
  "other_information_contract":"",
  "conditions_contract":"",
  "obligations_contract":"",

  "contractopttrasstore_id": 0,
  "opt_tras_store_id" : 0,
  "opt_tras_documnettype_id" : 0,
  "opt_tras_documentid" : "",
  "opt_tras_tradename" : "",
  "opt_tras_name" : "",
  "opt_tras_contactname" : "",
  "opt_tras_nima":"", 
  "opt_tras_inscription":"",
  "opt_tras_grnp":"",
  "opt_tras_opt":0,
  "opt_tras_phone":"",
  "opt_tras_email":"",
  "opt_tras_roadtype_id" : 0,
  "opt_tras_street" : "",
  "opt_tras_country_id":1,
  "opt_tras_province_id":30,
  "opt_tras_postalcode_id":0,
  "opt_tras_community_id":6,
  "opt_tras_roadtype_id_fiscal" : 0,
  "opt_tras_street_fiscal" : "",
  "opt_tras_country_id_fiscal":1,
  "opt_tras_province_id_fiscal":30,
  "opt_tras_postalcode_id_fiscal":0,
  "opt_tras_community_id_fiscal":6,
  
  "contractoritrasstore_id": 0,
  "ori_tras_store_id" : 0,
  "ori_tras_documnettype_id" : 0,
  "ori_tras_documentid" : "",
  "ori_tras_tradename" : "",
  "ori_tras_name" : "",
  "ori_tras_contactname_employee_id" : 0,
  "ori_tras_contactname_documnettype_id" : 0,
  "ori_tras_contactname_documentid" : "",
  "ori_tras_contactname_firstaname" : "",
  "ori_tras_contactname_lastname1" : "",
  "ori_tras_contactname_lastname2" : "",
  "ori_tras_contactname_position" : "",
  "ori_tras_contactname_email" : "",
  "ori_tras_contactname_phone" : "",
  "ori_tras_nima":"",
  "ori_tras_inscription":"",
  "ori_tras_grnp":"",
  "ori_tras_opt":0,
  "ori_tras_phone":"",
  "ori_tras_email":"",
  "ori_tras_roadtype_id" : "",
  "ori_tras_street" : "",
  "ori_tras_country_id":1,
  "ori_tras_province_id":30,
  "ori_tras_postalcode_id":0,
  "ori_tras_community_id":6,
  "ori_tras_roadtype_id_fiscal" : "",
  "ori_tras_street_fiscal" : "",
  "ori_tras_country_id_fiscal":1,
  "ori_tras_province_id_fiscal":30,
  "ori_tras_postalcode_id_fiscal":0,
  "ori_tras_community_id_fiscal":6,
  
  "aut_tras_store_id" : 0,
  "aut_tras_documnettype_id" : 0,
  "aut_tras_documentid" : "",
  "aut_tras_tradename" : "",
  "aut_tras_name" : "",
  "aut_tras_contactname" : "",
  "aut_tras_nima":"", 
  "aut_tras_inscription":"",
  "aut_tras_grnp":"",
  "aut_tras_opt":0,
  "aut_tras_phone":"",
  "aut_tras_email":"",
  "aut_tras_roadtype_id" : "",
  "aut_tras_street" : "",
  "aut_tras_country_id":1,
  "aut_tras_province_id":30,
  "aut_tras_postalcode_id":0,
  "aut_tras_community_id":6,

  "contractdesttrasstore_id": 0,
  "dest_tras_store_id" : 0,
  "dest_tras_documnettype_id" : 0,
  "dest_tras_documentid" : "",
  "dest_tras_tradename" : "",
  "dest_tras_name" : "",
  "dest_tras_contactname" : "", 
  "dest_tras_contactname_position" : "",
  "dest_tras_nima":"",
  "dest_tras_inscription":"",
  "dest_tras_grnp":"",
  "dest_tras_opt":0,
  "dest_tras_phone":"",
  "dest_tras_email":"",
  "dest_tras_roadtype_id" : "",
  "dest_tras_street" : "",
  "dest_tras_country_id":1,
  "dest_tras_province_id":30,
  "dest_tras_postalcode_id":0,
  "dest_tras_community_id":6,

  "aut_dest_store_id" : 0,
  "aut_dest_documnettype_id" : 0,
  "aut_dest_documentid" : "",
  "aut_dest_tradename" : "",
  "aut_dest_name" : "",
  "aut_dest_contactname" : "",
  "aut_dest_nima":"", 
  "aut_dest_inscription":"",
  "aut_dest_grnp":"",
  "aut_dest_opt":0,
  "aut_dest_phone":"",
  "aut_dest_email":"",
  "aut_dest_roadtype_id" : "",
  "aut_dest_street" : "",
  "aut_dest_country_id":1,
  "aut_dest_province_id":30,
  "aut_dest_postalcode_id":0,
  "aut_dest_community_id":6,
  
  "opt_tras_shared":false,
  "ori_tras_shared":false,
  "aut_tras_shared":false,
  "dest_tras_shared":false,
  "aut_dest_shared":false,

  "sendbyemail_opt_tras":false,
  "sendbyemail_ori_tras":false,
  "sendbyemail_aut_tras":false,
  "sendbyemail_dest_tras":false,
  "sendbyemail_aut_dest":false,

  "signature_opt_tras":"",
  "signature_ori_tras":"",
  "signature_dest_tras":"",

  "codelers":[]

};
 
const initialDocumentTypesToSelect = [{
  "id": "",
  "name": ""
}];

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const initialCodeLERToSelect = [{
  "id": "",
  "indexrowcodeler":"",
  "codeler_id":"",
  "codeler_code": "",
  "codeler_namees": "",
  "codeler_nameen": "",
  "processtypes":[],
  "treatmenttypes":[]
}];

const initialCountriesToSelect = [{
  "id" : 1,
  "namees" : "",
  "nameen" : "",
  "enabled":true
}];

const initialRDToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": ""
}];
 
const useStyles = makeStyles((theme) => ({
  root: {
    width: '880px' 
  },
  backcolor: {
    backgroundColor:  "#F4F4F4"
  },
  heading: {
    fontSize: 18,
    paddingLeft: 6,
    width: '450px',
    color: "#067330" 
  },
  secondaryHeading: {
    fontSize: 18,
    width: '1050px',
    color: "#000000" 
  },
  thirdHeading: {
    fontSize: 18,
    width: '600px',
    color: "#e74a3b" 
  },
}));

const ContractEditOrCreate = ({id, showModalContractSettings}) => {
  const {t,i18n} = useTranslation(['listDataContracts']);
  const token = useSelector((state) => state.loginUser.token);  
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
  const default_contract_view_aut_tras = useSelector((state) => state.loginUser.defaulttypesdi.default_contract_view_aut_tras);  
  const default_contract_view_aut_dest = useSelector((state) => state.loginUser.defaulttypesdi.default_contract_view_aut_dest);  
  const default_conditions_contract = useSelector((state) => state.loginUser.defaulttypesdi.default_conditions_contract);  
  const default_obligations_contract = useSelector((state) => state.loginUser.defaulttypesdi.default_obligations_contract);  
  const LIMITCODELERSCONTRACT = useSelector((state) => state.loginUser.defaulttypesdi.limitcodelerscontract);  
  const share_form_view = useSelector((state) => state.formatDIUser.share_form_view);
  const share_form_opt_tras = useSelector((state) => state.formatDIUser.share_form_opt_tras);
  const share_form_ori_tras = useSelector((state) => state.formatDIUser.share_form_ori_tras);
  const share_form_aut_tras = useSelector((state) => state.formatDIUser.share_form_aut_tras);
  const share_form_dest_tras = useSelector((state) => state.formatDIUser.share_form_dest_tras);
  const share_form_aut_dest = useSelector((state) => state.formatDIUser.share_form_aut_dest);
  const sendbyemail_form_view = useSelector((state) => state.formatDIUser.sendbyemail_form_view);
  const sendbyemail_form_opt_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_opt_tras);
  const sendbyemail_form_ori_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_ori_tras);
  const sendbyemail_form_aut_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_aut_tras);
  const sendbyemail_form_dest_tras = useSelector((state) => state.formatDIUser.sendbyemail_form_dest_tras);
  const sendbyemail_form_aut_dest = useSelector((state) => state.formatDIUser.sendbyemail_form_aut_dest);
  const signature_default = useSelector((state) => state.formatDIUser.signature_default);
  const [formContract, setFormContract] = useState(initialStateContract);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [documentTypesToSelect,setDocumentTypesToSelect]= useState(initialDocumentTypesToSelect); 
  const [roadTypesToSelect,setRoadTypesToSelect]= useState(initialTypesToSelect);
  const [optTypeToSelect,setOptTypeToSelect]= useState(initialRDToSelect);
  const [communityToSelect,setCommunityToSelect]= useState(initialTypesToSelect);
  const [countryToSelect,setCountryToSelect]= useState(initialCountriesToSelect); 
  const [expanded, setExpanded] =  useState(false);
  const [openShareContract, setOpenShareContract] =  useState(false);  
  const [openDeleteRowCodeLERS, setOpenDeleteRowCodeLERS] = useState(false);
  const [openEditRowCodeLERS, setOpenEditRowCodeLERS] = useState(false);
  const [openCreateEditRowCodeLERS, setOpenCreateEditRowCodeLERS] = useState(false);
  const [editRowCodeLER, setEditRowCodeLER] = useState(initialCodeLERToSelect);
  const [idCodeDeleteCodeLER,setIdCodeDeleteCodeLER]=useState(DELETE_CODE);
  const [openSignOpt_Tras,setOpenSignOpt_Tras]= useState(false); 
  const [openSignOri_Tras,setOpenSignOri_Tras]= useState(false);
  const [openSignDest_Tras,setOpenSignDest_Tras]= useState(false);
  const [signOptTrasCanvas, setSignOptTrasCanvas]= useState(); 
  const [signOriTrasCanvas, setSignOriTrasCanvas]= useState(); 
  const [signDestTrasCanvas, setSignDestTrasCanvas]= useState();  

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
 
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }; 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalContractSettings) {
        setFormContract(initialStateContract);
        setFormContract(formContract => ({
          ...formContract,
          codelers:[]
        })); 
        setIsLoadingErrorData(false);
        setErrorsForm({});
        setDocumentTypesToSelect(initialDocumentTypesToSelect);
        setRoadTypesToSelect(initialTypesToSelect);
        setOptTypeToSelect(initialRDToSelect);
        setCommunityToSelect(initialTypesToSelect);
        setCountryToSelect(initialCountriesToSelect); 
        setExpanded(false);
        setOpenDeleteRowCodeLERS(false);
        setOpenEditRowCodeLERS(false);
        setOpenCreateEditRowCodeLERS(false);
        setEditRowCodeLER(initialCodeLERToSelect);
        setIdCodeDeleteCodeLER(DELETE_CODE);
        setOpenShareContract(false);
        setOpenSignOpt_Tras(false); 
        setOpenSignOri_Tras(false);
        setOpenSignDest_Tras(false);
        setSignOptTrasCanvas();
        setSignOriTrasCanvas();
        setSignDestTrasCanvas();
        dispatch(setLoading(false));
        navigate("/contract");
        return () => {};
      }
    },
    [
      setFormContract, 
      setIsLoadingErrorData,
      setErrorsForm,
      setDocumentTypesToSelect,
      setRoadTypesToSelect,
      setCommunityToSelect,
      setCountryToSelect,
      setExpanded,
      setOpenDeleteRowCodeLERS,
      setOpenEditRowCodeLERS,
      setOptTypeToSelect,
      setOpenCreateEditRowCodeLERS,
      setEditRowCodeLER,
      setIdCodeDeleteCodeLER,
      setOpenShareContract,  
      setOpenSignOpt_Tras,
      setOpenSignOri_Tras,
      setOpenSignDest_Tras,
      setSignOptTrasCanvas,
      setSignOriTrasCanvas,
      setSignDestTrasCanvas, 
      showModalContractSettings,
      navigate,
      dispatch,
      DELETE_CODE]
  );
  
  const handleCloseDataEditContract = () => {
    setFormContract(initialStateContract);
    setFormContract(formContract => ({
      ...formContract,
      codelers:[]
    })); 
    setIsLoadingErrorData(false);
    setErrorsForm({});
    setDocumentTypesToSelect(initialDocumentTypesToSelect);
    setRoadTypesToSelect(initialTypesToSelect);
    setOptTypeToSelect(initialRDToSelect);
    setCommunityToSelect(initialTypesToSelect);
    setCountryToSelect(initialCountriesToSelect);  
    setExpanded(false);
    setOpenDeleteRowCodeLERS(false);
    setOpenEditRowCodeLERS(false);
    setOpenCreateEditRowCodeLERS(false);
    setEditRowCodeLER(initialCodeLERToSelect);
    setIdCodeDeleteCodeLER(DELETE_CODE);
    setOpenShareContract(false);
    setOpenSignOpt_Tras(false); 
    setOpenSignOri_Tras(false);
    setOpenSignDest_Tras(false);
    setSignOptTrasCanvas();
    setSignOriTrasCanvas();
    setSignDestTrasCanvas(); 
    dispatch(setLoading(false));
    navigate("/contract") ;
    return () => {};
	};
    
  const selectModalSignature = (id) => {
    switch (id) {
      case 1:   
        setOpenSignOpt_Tras(true);  
        break;
      case 2:    
        setOpenSignOri_Tras(true);
        break;
      case 3:    
        setOpenSignDest_Tras(true);
        break; 
      default:
        break;
    } 
  };

  const deleteSignature = (id) => {
    switch (id) {
      case 1:   
        setFormContract(formContract => ({
          ...formContract,
          signature_opt_tras: "",
        }));
        break;
      case 2:    
        setFormContract(formContract => ({
          ...formContract,
          signature_ori_tras: "",
        }));
        break;
      case 3:    
        setFormContract(formContract => ({
          ...formContract,
          signature_dest_tras: "",
        }));
        break; 
      default:
        break;
    } 
  };
  
  const handleCancelSignOptTras = () => {
    signOptTrasCanvas.clear();
    setOpenSignOpt_Tras(false);
  };
  const handleDeleteSignOptTras = () => {
    signOptTrasCanvas.clear();
    setFormContract(formContract => ({
      ...formContract,
      signature_opt_tras: "",
    }));
    setOpenSignOpt_Tras(false);
  };
  const handleSubmitSignOptTras = () => {
    const dataURL = signOptTrasCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormContract(formContract => ({
      ...formContract,
      signature_opt_tras:dataURL,
    }));
    setOpenSignOpt_Tras(false);
  };
  
  const handleCancelSignOriTras = () => {
    signOriTrasCanvas.clear();
    setOpenSignOri_Tras(false);
  };
  const handleDeleteSignOriTras = () => {
    signOriTrasCanvas.clear();
    setFormContract(formContract => ({
      ...formContract,
      signature_ori_tras: "",
    }));
    setOpenSignOri_Tras(false);
  };
  const handleSubmitSignOriTras = () => {
    const dataURL = signOriTrasCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormContract(formContract => ({
      ...formContract,
      signature_ori_tras:dataURL,
    }));
    setOpenSignOri_Tras(false);
  };

  const handleCancelSignDestTras = () => {
    signDestTrasCanvas.clear();
    setOpenSignDest_Tras(false);
  };
  const handleDeleteSignDestTras = () => {
    signDestTrasCanvas.clear();
    setFormContract(formContract => ({
      ...formContract,
      signature_dest_tras: "",
    }));
    setOpenSignDest_Tras(false);
  };
  const handleSubmitSignDestTras = () => {
    const dataURL = signDestTrasCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormContract(formContract => ({
      ...formContract,
      signature_dest_tras:dataURL,
    }));
    setOpenSignDest_Tras(false);
  };

  const handleChangeDataEditContract = (e) => {
    const { name, value } = e.target;
    setFormContract(formContract => ({
      ...formContract,
      [name]: value,
    }));
  };

  const handleBlurDataEditContract = (e) => {
    handleChangeDataEditContract(e);
    setErrorsForm(validateFormContract());
  };

  const handleChangeSelectDate = (event) => {
    setFormContract(formContract => ({
      ...formContract,
      date_contract: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDate = (e) => {
    handleChangeSelectDate(e);
    setErrorsForm(validateFormContract());
  };
  
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000000) return true;
    return false;
  };

  const handleChangeDataEditContractAmount = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setFormContract(formContract => ({
      ...formContract,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditContractAmount = (e) => {
    handleChangeDataEditContractAmount(e);
    setErrorsForm(validateFormContract());
  };
 
  const handleCancelDeleteRowCodeLER = () => {
    setIdCodeDeleteCodeLER(DELETE_CODE);
    setOpenDeleteRowCodeLERS(false);
  };

  const handleChangeDeleteRowCodeLER = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteCodeLER(value);
  };

  const handleCloseDeleteRowCodeLER = (e) => {
    e.preventDefault();
    if (idCodeDeleteCodeLER!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formContract.codelers.findIndex(((item) => item.indexrowcodeler === editRowCodeLER.indexrowcodeler));
      if ( indexDelete !== -1 ) {
        formContract.codelers.splice( indexDelete, 1 );
      }
      setFormContract(formContract => ({
        ...formContract,
      }))
      setIdCodeDeleteCodeLER(DELETE_CODE);
      setOpenDeleteRowCodeLERS(false);
    }
  };

  const validateFormContract = () => {
    let errorsForm = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    let regexEmail = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (regexInvalidCharacters.test(formContract.number_nt)) {
      errorsForm.number_nt = 'Text_TextNoValid';
    }
    if (formContract.date_contract==="") {
      errorsForm.date_contract = 'Text_FieldDateContractRequired';
    }
    if (regexInvalidCharacters.test(formContract.periodicity_contract)) {
      errorsForm.periodicity_contract = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.duration_contract)) {
      errorsForm.duration_contract = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.other_information_contract)) {
      errorsForm.other_information_contract = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.conditions_contract)) {
      errorsForm.conditions_contract = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.obligations_contract)) {
      errorsForm.obligations_contract = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.opt_tras_documentid)) {
      errorsForm.opt_tras_documentid = 'Text_TextNoValid';
    }else{
      if (formContract.opt_tras_documentid==="") {
        errorsForm.opt_tras_documentid = 'Text_FieldDateOptTrasDocumentIDRequired';
      }
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_tradename)) {
      errorsForm.opt_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_name)) {
      errorsForm.opt_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_contactname)) {
      errorsForm.opt_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_nima)) {
      errorsForm.opt_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_inscription)) {
      errorsForm.opt_tras_inscription = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.opt_tras_grnp)) {
      errorsForm.opt_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.opt_tras_phone)) {
      errorsForm.opt_tras_phone = 'Text_TextNoValid';
    }
    if (formContract.opt_tras_email!=="" && regexEmail.test(formContract.opt_tras_email)) {
      errorsForm.opt_tras_email = 'Text_Email_Invalid';
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_street)) {
      errorsForm.opt_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.opt_tras_street_fiscal)) {
      errorsForm.opt_tras_street_fiscal = 'Text_TextNoValid';
    }   
    if (regexInvalidCharacters.test(formContract.ori_tras_documentid)) {
      errorsForm.ori_tras_documentid = 'Text_TextNoValid';
    }else{
      if (formContract.ori_tras_documentid==="") {
        errorsForm.ori_tras_documentid = 'Text_FieldDateOriTrasDocumentIDRequired';
      }
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_tradename)) {
      errorsForm.ori_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_name)) {
      errorsForm.ori_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_contactname_documentid)) {
      errorsForm.ori_tras_contactname_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_contactname_name)) {
      errorsForm.ori_tras_contactname_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_contactname_position)) {
      errorsForm.ori_tras_contactname_position = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_contactname_email)) {
      errorsForm.ori_tras_contactname_email = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_contactname_phone)) {
      errorsForm.ori_tras_contactname_phone = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_nima)) {
      errorsForm.ori_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_inscription)) {
      errorsForm.ori_tras_inscription = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.ori_tras_grnp)) {
      errorsForm.ori_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.ori_tras_street)) {
      errorsForm.ori_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.ori_tras_phone)) {
      errorsForm.ori_tras_phone = 'Text_TextNoValid';
    }
    if (formContract.ori_tras_email!=="" && regexEmail.test(formContract.ori_tras_email)) {
      errorsForm.ori_tras_email = 'Text_Email_Invalid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_tras_documentid)) {
      errorsForm.aut_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_tras_tradename)) {
      errorsForm.aut_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_tras_name)) {
      errorsForm.aut_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_tras_contactname)) {
      errorsForm.aut_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_tras_nima)) {
      errorsForm.aut_tras_nima = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_tras_inscription)) {
      errorsForm.aut_tras_inscription = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_tras_grnp)) {
      errorsForm.aut_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_tras_street)) {
      errorsForm.aut_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_tras_phone)) {
      errorsForm.aut_tras_phone = 'Text_TextNoValid';
    }
    if (formContract.aut_tras_email!=="" && regexEmail.test(formContract.aut_tras_email)) {
      errorsForm.aut_tras_email = 'Text_Email_Invalid';
    } 
    if (regexInvalidCharacters.test(formContract.dest_tras_documentid)) {
      errorsForm.dest_tras_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_tradename)) {
      errorsForm.dest_tras_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_name)) {
      errorsForm.dest_tras_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_contactname)) {
      errorsForm.dest_tras_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_contactname_position)) {
      errorsForm.dest_tras_contactname_position = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_nima)) {
      errorsForm.dest_tras_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_inscription)) {
      errorsForm.dest_tras_inscription = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.dest_tras_grnp)) {
      errorsForm.dest_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.dest_tras_street)) {
      errorsForm.dest_tras_street = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_phone)) {
      errorsForm.dest_tras_phone = 'Text_TextNoValid';
    }
    if (formContract.dest_tras_email!=="" && regexEmail.test(formContract.dest_tras_email)) {
      errorsForm.dest_tras_email = 'Text_Email_Invalid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_dest_documentid)) {
      errorsForm.aut_dest_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_dest_tradename)) {
      errorsForm.aut_dest_tradename = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_dest_name)) {
      errorsForm.aut_dest_name = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_dest_contactname)) {
      errorsForm.aut_dest_contactname = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_dest_nima)) {
      errorsForm.aut_dest_nima = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.aut_dest_inscription)) {
      errorsForm.aut_dest_inscription = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_dest_grnp)) {
      errorsForm.aut_dest_grnp = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formContract.dest_tras_grnp)) {
      errorsForm.dest_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formContract.aut_dest_phone)) {
      errorsForm.aut_dest_phone = 'Text_TextNoValid';
    }
    if (formContract.aut_dest_email!=="" && regexEmail.test(formContract.aut_dest_email)) {
      errorsForm.aut_dest_email = 'Text_Email_Invalid';
    }
    return errorsForm;
  };

  const handleConfirmFetchDataEditContract = () => {
    
    const fetchData = async () => { 
      handleCloseDataEditContract(); 
      try {
        const getData = id !== "0"
          ? await helpHttp().put(ENDPOINT, `contract/updatecontract`, token, formContract)
          : await helpHttp().post(ENDPOINT, `contract/savecontract`, token, formContract);
        if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        } 
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }   
      return () => {  };
    }; 
    fetchData();  
  };
 
  const handleSubmitDataEditContract = () => {
    const errors = validateFormContract();
    setErrorsForm(errors);
    if (Object.keys(errors).length === 0 && formContract.date_contract !== ''
        && formContract.opt_tras_documentid !== ''
        && formContract.ori_tras_documentid !== '') { 
      const hasDocumentId = [
        formContract.opt_tras_documentid,
        formContract.ori_tras_documentid,
        formContract.aut_tras_documentid,
        formContract.dest_tras_documentid,
        formContract.aut_dest_documentid
      ].some(id => id !== '');
      if (share_form_view || sendbyemail_form_view) {
        hasDocumentId ? setOpenShareContract(true) : handleConfirmFetchDataEditContract();
      } else {
        handleConfirmFetchDataEditContract();
      }
    } else { 
      if (errors.date_contract) {
        dispatch(warningErrorAction(t(errors.date_contract)));
      } 
      if (errors.opt_tras_documentid) {
        dispatch(warningErrorAction(t(errors.opt_tras_documentid)));
      } 
      if (errors.ori_tras_documentid) {
        dispatch(warningErrorAction(t(errors.ori_tras_documentid)));
      } 
     
    }
  }; 

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataRoadType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `roadtype/roadtypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setRoadTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataOptType = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `opttype/opttypesorderbynamees`, token)
          : await helpHttp().get(ENDPOINT, `opttype/opttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setOptTypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCommunity = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynamees`, token)
        : await helpHttp().get(ENDPOINT, `community/simplecommunitiesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCommunityToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataCountry = async () => {
      try {
        const getData = i18n.language === "es"
          ? await helpHttp().get(ENDPOINT, `country/simplecountriesbynamees`, token)
          : await helpHttp().get(ENDPOINT, `country/simplecountriesbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setCountryToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataDocumentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `documenttype/simpledocumenttypesbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentTypesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formContract.id!==null){
      fetchDataDocumentType();
      fetchDataRoadType();
      fetchDataOptType();
      fetchDataCommunity();
      fetchDataCountry(); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,formContract.id,showModalContractSettings]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataForm = async () => { 
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`contract/getcontractbyid/`,token,id);
        if (getData.err) {
          setFormContract(initialStateContract);
          setIsLoadingErrorData(true); 
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormContract(getData); 
        }  
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    if (id!=="0"){
      if (formContract.id===""){
        fetchDataForm();  
      }
    }else{
      setFormContract(initialStateContract);
      setFormContract(formContract => ({
        ...formContract,
        owncontract_id:0,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi.formatnumber,
        number_nt:"",
        date_contract:"",
        amount_contract:0,
        periodicity_contract:"",
        duration_contract:"",
        other_information_contract:"",
        conditions_contract:default_conditions_contract,
        obligations_contract:default_obligations_contract,
 
        contractopttrasstore_id:0,
        opt_tras_store_id:defaulttypesdi.default_opt_tras_id,
        opt_tras_documnettype_id:defaulttypesdi.default_opt_tras_documnettype_id!==0?defaulttypesdi.default_opt_tras_documnettype_id:(DEFAULTTYPEOFDOCUMENT),
        opt_tras_documentid:defaulttypesdi.default_opt_tras_documentid,
        opt_tras_tradename:defaulttypesdi.default_opt_tras_tradename,
        opt_tras_name:defaulttypesdi.default_opt_tras_name,
        opt_tras_contactname:defaulttypesdi.default_opt_tras_contactname,
        opt_tras_nima:defaulttypesdi.default_opt_tras_nima,
        opt_tras_inscription:defaulttypesdi.default_opt_tras_inscription,
        opt_tras_grnp:"",
        opt_tras_opt:defaulttypesdi.default_opt_tras_opt,
        opt_tras_phone:defaulttypesdi.default_opt_tras_phone,
        opt_tras_email:defaulttypesdi.default_opt_tras_email,
        opt_tras_roadtype_id:defaulttypesdi.default_opt_tras_roadtype_id,
        opt_tras_street:defaulttypesdi.default_opt_tras_street,
        opt_tras_country_id:defaulttypesdi.default_opt_tras_country_id!==0?defaulttypesdi.default_opt_tras_country_id:Number(DEFAULTCOUNTRY),
        opt_tras_province_id:defaulttypesdi.default_opt_tras_province_id!==0?defaulttypesdi.default_opt_tras_province_id:Number(DEFAULTPROVINCE),
        opt_tras_postalcode_id:defaulttypesdi.default_opt_tras_postalcode_id,
        opt_tras_community_id:defaulttypesdi.default_opt_tras_community_id!==0?defaulttypesdi.default_opt_tras_community_id:Number(DEFAULTCOMMUNITY),
        opt_tras_roadtype_id_fiscal:defaulttypesdi.default_opt_tras_roadtype_id,
        opt_tras_street_fiscal:defaulttypesdi.default_opt_tras_street,
        opt_tras_country_id_fiscal:defaulttypesdi.default_opt_tras_country_id!==0?defaulttypesdi.default_opt_tras_country_id:Number(DEFAULTCOUNTRY),
        opt_tras_province_id_fiscal:defaulttypesdi.default_opt_tras_province_id!==0?defaulttypesdi.default_opt_tras_province_id:Number(DEFAULTPROVINCE),
        opt_tras_postalcode_id_fiscal:defaulttypesdi.default_opt_tras_postalcode_id,
        opt_tras_community_id_fiscal:defaulttypesdi.default_opt_tras_community_id!==0?defaulttypesdi.default_opt_tras_community_id:Number(DEFAULTCOMMUNITY),
          
        contractoritrasstore_id:0,
        ori_tras_store_id:0,
        ori_tras_documnettype_id:DEFAULTTYPEOFDOCUMENT,
        ori_tras_documentid: "",
        ori_tras_tradename: "",
        ori_tras_name: "",
        ori_tras_contactname_employee_id : "",
        ori_tras_contactname_documnettype_id : "",
        ori_tras_contactname_documentid : "",
        ori_tras_contactname_firstaname : "",
        ori_tras_contactname_lastname1 : "",
        ori_tras_contactname_lastname2 : "",
        ori_tras_contactname_position : "",
        ori_tras_contactname_email : "",
        ori_tras_contactname_phone : "",
        ori_tras_nima: "",
        ori_tras_inscription: "",
        ori_tras_grnp:"",
        ori_tras_opt: 0,
        ori_tras_phone: "",
        ori_tras_email: "",
        ori_tras_roadtype_id:0,
        ori_tras_street: "",
        ori_tras_country_id:Number(DEFAULTCOUNTRY),
        ori_tras_province_id:Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id:0,
        ori_tras_community_id:Number(DEFAULTCOMMUNITY),
        
        aut_tras_store_id:0,
        aut_tras_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        aut_tras_documentid: "",
        aut_tras_tradename: "",
        aut_tras_name: "",
        aut_tras_contactname: "",
        aut_tras_nima: "",
        aut_tras_inscription: "",
        aut_tras_grnp:"",
        aut_tras_opt: 0,
        aut_tras_phone: "",
        aut_tras_email: "",
        aut_tras_roadtype_id:0,
        aut_tras_street: "",
        aut_tras_country_id:Number(DEFAULTCOUNTRY),
        aut_tras_province_id:Number(DEFAULTPROVINCE),
        aut_tras_postalcode_id:0,
        aut_tras_community_id:Number(DEFAULTCOMMUNITY),

        contractdesttrasstore_id:0,
        dest_tras_store_id:0,
        dest_tras_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        dest_tras_documentid: "",
        dest_tras_tradename: "",
        dest_tras_name: "",
        dest_tras_contactname: "",
        dest_tras_contactname_position:"",
        dest_tras_nima: "",
        dest_tras_inscription: "",
        dest_tras_grnp: "",
        dest_tras_opt: 0,
        dest_tras_phone: "",
        dest_tras_email: "",
        dest_tras_roadtype_id:0,
        dest_tras_street: "",
        dest_tras_country_id:Number(DEFAULTCOUNTRY),
        dest_tras_province_id:Number(DEFAULTPROVINCE),
        dest_tras_postalcode_id:0,
        dest_tras_community_id:Number(DEFAULTCOMMUNITY),

        aut_dest_store_id:0,
        aut_dest_documnettype_id:Number(DEFAULTTYPEOFDOCUMENT),
        aut_dest_documentid: "",
        aut_dest_tradename: "",
        aut_dest_name: "",
        aut_dest_contactname: "",
        aut_dest_nima: "",
        aut_dest_inscription: "",
        aut_dest_grnp: "",
        aut_dest_opt: 0,
        aut_dest_phone: "",
        aut_dest_email: "",
        aut_dest_roadtype_id:0,
        aut_dest_street: "",
        aut_dest_country_id:Number(DEFAULTCOUNTRY),
        aut_dest_province_id:Number(DEFAULTPROVINCE),
        aut_dest_postalcode_id:0,
        aut_dest_community_id:Number(DEFAULTCOMMUNITY),

        opt_tras_shared:share_form_opt_tras,
        ori_tras_shared:share_form_ori_tras,
        aut_tras_shared:share_form_aut_tras,
        dest_tras_shared:share_form_dest_tras,
        aut_dest_shared:share_form_aut_dest,

        sendbyemail_opt_tras:sendbyemail_form_opt_tras,
        sendbyemail_ori_tras:sendbyemail_form_ori_tras,
        sendbyemail_aut_tras:sendbyemail_form_aut_tras,
        sendbyemail_dest_tras:sendbyemail_form_dest_tras,
        sendbyemail_aut_dest:sendbyemail_form_aut_dest,
      
        signature_opt_tras:signature_default,
        signature_ori_tras:"",
        signature_dest_tras:"",

        codelers:[] 

      })); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,dispatch,id,t,token,formContract.id,
    share_form_opt_tras,share_form_ori_tras,share_form_aut_tras,share_form_dest_tras,share_form_aut_dest,default_conditions_contract,default_obligations_contract,
    DEFAULTTYPEOFDOCUMENT,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,defaulttypesdi,defaultStore.id,useraccount_id,isLoadingData,DEFAULTPROVINCE,sendbyemail_form_aut_dest, 
    sendbyemail_form_aut_tras, sendbyemail_form_dest_tras, sendbyemail_form_opt_tras, sendbyemail_form_ori_tras, signature_default]);  

 return (
      <>
        {showModalContractSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '1600px'}}>
              <form className="createform" onSubmit={handleCloseDataEditContract}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                  &nbsp;&nbsp;{id==="0"?t('Text_CONTRACT_New'):t('Text_CONTRACT_Edit')+': '+formContract.owncontract_id}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditContract}>
                                      {id==="0"?t('Button_Create'):t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditContract}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NT_CONTRACT')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="number_nt" 
                                  name="number_nt"
                                  value={formContract.number_nt}
                                  inputProps={{ style: {width: i18n.language==='es'?293:305,  fontSize: '18px'}, maxLength: 20 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsForm.number_nt!=null?t(errorsForm.number_nt):t('PlaceHolder_CONTRACT_NT_CONTRACT')}
                                  placeholder={t('PlaceHolder_CONTRACT_NT_CONTRACT')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditContract}
                                  onChange={handleChangeDataEditContract}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                  <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_DATE')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="date_contract" 
                                    name="date_contract"
                                    value={ formContract.date_contract!== null ? new Date(formContract.date_contract):null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    inputProps={{ style: {width: 250}, maxLength: 100 }}
                                    label={errorsForm.date_contract!=null?t(errorsForm.date_contract):t('PlaceHolder_CONTRACT_DATE')}
                                    onBlur={handleBlurSelectDate}
                                    onChange={handleChangeSelectDate}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy",
                                        sx: {
                                          maxLength: 250,
                                          width: 250,
                                          fontSize: '18px',  
                                        },
                                        InputProps: {
                                          style: {
                                            fontSize: '18px',
                                          }
                                        },
                                        InputLabelProps:{ 
                                          style: {
                                            fontSize: '18px', 
                                            background: '#FFFFFF'
                                          }
                                        },
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_AMOUNT_KG')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {2}
                                  fixedDecimalScale= {true}
                                  allowNegative= {false}
                                  isAllowed={withValueCap}
                                  id="amount_contract" 
                                  name="amount_contract"
                                  value={formContract.amount_contract}
                                  label={errorsForm.amount_contract!=null?t(errorsForm.amount_contract):t('PlaceHolder_CONTRACT_AMOUNT_KG')}
                                  placeholder={t('PlaceHolder_CONTRACT_AMOUNT_KG')}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200,  fontSize: '18px'}, maxLength: 20 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  customInput={TextField}
                                  onBlur={handleBlurDataEditContractAmount}
                                  onChange={handleChangeDataEditContractAmount}
                                />
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_PERIODICITY')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="periodicity_contract" 
                                    name="periodicity_contract"
                                    value={formContract.periodicity_contract}
                                    inputProps={{ style: {width: i18n.language==='es'?395:395,  fontSize: '18px'}, maxLength: 20 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.periodicity_contract!=null?t(errorsForm.periodicity_contract):t('PlaceHolder_CONTRACT_PERIODICITY')}
                                    placeholder={t('PlaceHolder_CONTRACT_PERIODICITY')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditContract}
                                    onChange={handleChangeDataEditContract}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_DURATION')} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="duration_contract" 
                                    name="duration_contract"
                                    value={formContract.duration_contract}
                                    inputProps={{ style: {width: i18n.language==='es'?410:482,  fontSize: '18px'}, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.duration_contract!=null?t(errorsForm.duration_contract):t('PlaceHolder_CONTRACT_DURATION')}
                                    placeholder={t('PlaceHolder_CONTRACT_DURATION')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditContract}
                                    onChange={handleChangeDataEditContract}
                                  /> 
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_INFORMATION')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="other_information_contract" 
                                    name="other_information_contract"
                                    value={formContract.other_information_contract}
                                    inputProps={{ style: {width: i18n.language==='es'?1051:1132,  fontSize: '18px'}, maxLength: 255 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.other_information_contract!=null?t(errorsForm.other_information_contract):t('PlaceHolder_CONTRACT_INFORMATION')}
                                    placeholder={t('PlaceHolder_CONTRACT_INFORMATION')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditContract}
                                    onChange={handleChangeDataEditContract}
                                /> 
                               </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_CONDITIONS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="conditions_contract" 
                                    name="conditions_contract"
                                    value={formContract.conditions_contract}
                                    inputProps={{ style: {width: i18n.language==='es'?1260:1298,  fontSize: '18px'}, maxLength: 255 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                    label={errorsForm.conditions_contract!=null?t(errorsForm.conditions_contract):t('PlaceHolder_CONTRACT_CONDITIONS')}
                                    placeholder={t('PlaceHolder_CONTRACT_CONDITIONS')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditContract}
                                    onChange={handleChangeDataEditContract}
                                /> 
                               </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_OBLLIGATIONS')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="obligations_contract" 
                                    name="obligations_contract"
                                    value={formContract.obligations_contract}
                                    inputProps={{ style: {width: i18n.language==='es'?945:922,  fontSize: '18px'}, maxLength: 255 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                    label={errorsForm.obligations_contract!=null?t(errorsForm.obligations_contract):t('PlaceHolder_CONTRACT_OBLLIGATIONS')}
                                    placeholder={t('PlaceHolder_CONTRACT_OBLLIGATIONS')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditContract}
                                    onChange={handleChangeDataEditContract}
                                  /> 
                              </div>
                            </div>


                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  className={classes.backcolor}
                                >
                                  <Typography className={classes.heading}>{t('Text_CONTRACT_OPT_TRAS')} :</Typography>
                                  <Typography className={classes.secondaryHeading}>
                                    {formContract.opt_tras_tradename!==undefined && formContract.opt_tras_tradename!==null && formContract.opt_tras_tradename!==""?formContract.opt_tras_tradename:t('Text_NOT_REPORTING')}
                                    {formContract.opt_tras_documentid!=="" && formContract.opt_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                    {errorsForm.opt_tras_email!==undefined && errorsForm.opt_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                    {(errorsForm.opt_tras_tradename!==undefined && errorsForm.opt_tras_tradename!=="") ||
                                    (errorsForm.opt_tras_name!==undefined && errorsForm.opt_tras_name!=="") ||
                                    (errorsForm.opt_tras_contactname!==undefined && errorsForm.opt_tras_contactname!=="") ||
                                    (errorsForm.opt_tras_nima!==undefined && errorsForm.opt_tras_nima!=="") ||
                                    (errorsForm.opt_tras_inscription!==undefined && errorsForm.opt_tras_inscription!=="") ||
                                    (errorsForm.opt_tras_opt!==undefined && errorsForm.opt_tras_opt!=="") ||
                                    (errorsForm.opt_tras_phone!==undefined && errorsForm.opt_tras_phone!=="") ||
                                    (errorsForm.opt_tras_street!==undefined && errorsForm.opt_tras_street!=="") ||
                                    (errorsForm.opt_tras_street_fiscal!==undefined && errorsForm.opt_tras_street_fiscal!=="")
                                    ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    {(errorsForm.opt_tras_documentid!==undefined && errorsForm.opt_tras_documentid!=="")
                                    ?<span className={classes.thirdHeading}>  -  {t(errorsForm.opt_tras_documentid)} !!</span>:""}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ContractCreateOrEditOptTras
                                      formContract={formContract}
                                      setFormContract={setFormContract}
                                      showModalContractSettings={showModalContractSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormContract={validateFormContract}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect}
                                      expanded={expanded}
                                      optTypeToSelect={optTypeToSelect}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header"
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_CONTRACT_ORI_TRAS')} : <span>{t('Text_CONTRACT_ORI_TRAS_PROD')}</span></Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formContract.ori_tras_tradename!==undefined && formContract.ori_tras_tradename!==null && formContract.ori_tras_tradename!==""?formContract.ori_tras_tradename:t('Text_NOT_REPORTING')}
                                      {formContract.ori_tras_documentid!=="" && formContract.ori_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.ori_tras_email!==undefined && errorsForm.ori_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.ori_tras_tradename!==undefined && errorsForm.ori_tras_tradename!=="") ||
                                      (errorsForm.ori_tras_name!==undefined && errorsForm.ori_tras_name!=="") ||
                                      (errorsForm.ori_tras_street!==undefined && errorsForm.ori_tras_street!=="") ||
                                      (errorsForm.ori_tras_phone!==undefined && errorsForm.ori_tras_phone!=="") ||
                                      (errorsForm.ori_tras_nima!==undefined && errorsForm.ori_tras_nima!=="") ||
                                      (errorsForm.ori_tras_inscription!==undefined && errorsForm.ori_tras_inscription!=="") ||
                                      (errorsForm.ori_tras_opt!==undefined && errorsForm.ori_tras_opt!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.ori_tras_documentid!==undefined && errorsForm.ori_tras_documentid!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t(errorsForm.ori_tras_documentid)} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ContractCreateOrEditOriTras
                                      formContract={formContract}
                                      setFormContract={setFormContract}
                                      showModalContractSettings={showModalContractSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormContract={validateFormContract}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect} 
                                      expanded={expanded}
                                      optTypeToSelect={optTypeToSelect}
                                  />
                                </AccordionDetails>
                              </Accordion>
                              { default_contract_view_aut_tras &&
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header" 
                                    className={classes.backcolor}
                                  >
                                      <Typography className={classes.heading}>{t('Text_CONTRACT_ORI_TRAS')} : <span>{t('Text_CONTRACT_ORI_TRAS_EMPR')}</span></Typography>
                                      <Typography className={classes.secondaryHeading}>
                                        {formContract.aut_tras_tradename!==undefined && formContract.aut_tras_tradename!==null && formContract.aut_tras_tradename!==""?formContract.aut_tras_tradename:t('Text_NOT_REPORTING')}
                                        {formContract.aut_tras_documentid!=="" && formContract.aut_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                        {errorsForm.aut_tras_email!==undefined && errorsForm.aut_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                        {(errorsForm.aut_tras_documentid!==undefined && errorsForm.aut_tras_documentid!=="") ||
                                        (errorsForm.aut_tras_tradename!==undefined && errorsForm.aut_tras_tradename!=="") ||
                                        (errorsForm.aut_tras_name!==undefined && errorsForm.aut_tras_name!=="") ||
                                        (errorsForm.aut_tras_contactname!==undefined && errorsForm.aut_tras_contactname!=="") ||
                                        (errorsForm.aut_tras_street!==undefined && errorsForm.aut_tras_street!=="") ||
                                        (errorsForm.aut_tras_phone!==undefined && errorsForm.aut_tras_phone!=="") ||
                                        (errorsForm.aut_tras_nima!==undefined && errorsForm.aut_tras_nima!=="") ||
                                        (errorsForm.aut_tras_inscription!==undefined && errorsForm.aut_tras_inscription!=="") ||
                                        (errorsForm.aut_tras_opt!==undefined && errorsForm.aut_tras_opt!=="")
                                
                                        ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                      </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ContractCreateOrEditAutTras
                                        formContract={formContract}
                                        setFormContract={setFormContract}
                                        showModalContractSettings={showModalContractSettings}
                                        setIsLoadingErrorData={setIsLoadingErrorData}
                                        errorsForm={errorsForm}
                                        setErrorsForm={setErrorsForm}
                                        validateFormContract={validateFormContract}
                                        documentTypesToSelect={documentTypesToSelect}
                                        roadTypesToSelect={roadTypesToSelect}
                                        communityToSelect={communityToSelect}
                                        countryToSelect={countryToSelect} 
                                        expanded={expanded}
                                        optTypeToSelect={optTypeToSelect}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              }
                              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel4bh-content"
                                  id="panel4bh-header"
                                  className={classes.backcolor}
                                >
                                    <Typography className={classes.heading}>{t('Text_CONTRACT_DEST_TRAS')} : <span>{t('Text_CONTRACT_DEST_TRAS_PROD')}</span></Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {formContract.dest_tras_tradename!==undefined && formContract.dest_tras_tradename!==null && formContract.dest_tras_tradename!==""?formContract.dest_tras_tradename:t('Text_NOT_REPORTING')}
                                      {formContract.dest_tras_documentid!=="" && formContract.dest_tras_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                      {errorsForm.dest_tras_email!==undefined && errorsForm.dest_tras_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                      {(errorsForm.dest_tras_documentid!==undefined && errorsForm.dest_tras_documentid!=="") ||
                                      (errorsForm.dest_tras_tradename!==undefined && errorsForm.dest_tras_tradename!=="") ||
                                      (errorsForm.dest_tras_name!==undefined && errorsForm.dest_tras_name!=="") ||
                                      (errorsForm.dest_tras_contactname!==undefined && errorsForm.dest_tras_contactname!=="") ||
                                      (errorsForm.dest_tras_street!==undefined && errorsForm.dest_tras_street!=="") ||
                                      (errorsForm.dest_tras_phone!==undefined && errorsForm.dest_tras_phone!=="") ||
                                      (errorsForm.dest_tras_nima!==undefined && errorsForm.dest_tras_nima!=="") ||
                                      (errorsForm.dest_tras_inscription!==undefined && errorsForm.dest_tras_inscription!=="") ||
                                      (errorsForm.dest_tras_opt!==undefined && errorsForm.dest_tras_opt!=="")
                                      ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ContractCreateOrEditDestTras
                                      formContract={formContract}
                                      setFormContract={setFormContract}
                                      showModalContractSettings={showModalContractSettings}
                                      setIsLoadingErrorData={setIsLoadingErrorData}
                                      errorsForm={errorsForm}
                                      setErrorsForm={setErrorsForm}
                                      validateFormContract={validateFormContract}
                                      documentTypesToSelect={documentTypesToSelect}
                                      roadTypesToSelect={roadTypesToSelect}
                                      communityToSelect={communityToSelect}
                                      countryToSelect={countryToSelect}
                                      expanded={expanded} 
                                      optTypeToSelect={optTypeToSelect}
                                  />
                                </AccordionDetails>
                              </Accordion>
                              { default_contract_view_aut_dest &&
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header" 
                                    className={classes.backcolor}
                                  >
                                      <Typography className={classes.heading}>{t('Text_CONTRACT_DEST_TRAS')} : <span>{t('Text_CONTRACT_DEST_TRAS_EMPR')}</span></Typography>
                                      <Typography className={classes.secondaryHeading}>
                                        {formContract.aut_dest_tradename!==undefined && formContract.aut_dest_tradename!==null && formContract.aut_dest_tradename!==""?formContract.aut_dest_tradename:t('Text_NOT_REPORTING')}
                                        {formContract.aut_dest_documentid!=="" && formContract.aut_dest_tradename===""?<span className={classes.thirdHeading}> - {t('Text_Field_Tradename_Sheet_Required')} !!</span>:""}
                                        {errorsForm.aut_dest_email!==undefined && errorsForm.aut_dest_email!==""?<span className={classes.thirdHeading}>  -  {t('Text_Field_Email_Sheet_Invalid')} !!</span>:""}
                                        {(errorsForm.aut_dest_documentid!==undefined && errorsForm.aut_dest_documentid!=="") ||
                                        (errorsForm.aut_dest_tradename!==undefined && errorsForm.aut_dest_tradename!=="") ||
                                        (errorsForm.aut_dest_name!==undefined && errorsForm.aut_dest_name!=="") ||
                                        (errorsForm.aut_dest_contactname!==undefined && errorsForm.aut_dest_contactname!=="") ||
                                        (errorsForm.aut_dest_street!==undefined && errorsForm.aut_dest_street!=="") ||
                                        (errorsForm.aut_dest_phone!==undefined && errorsForm.aut_dest_phone!=="") ||
                                        (errorsForm.aut_dest_nima!==undefined && errorsForm.aut_dest_nima!=="") ||
                                        (errorsForm.aut_dest_cnae!==undefined && errorsForm.aut_dest_cnae!=="") ||
                                        (errorsForm.aut_dest_inscription!==undefined && errorsForm.aut_dest_inscription!=="") ||
                                        (errorsForm.aut_dest_opt!==undefined && errorsForm.aut_dest_opt!=="") ||
                                        (errorsForm.aut_dest_economicactivity!==undefined && errorsForm.aut_dest_economicactivity!=="") ||
                                        (errorsForm.aut_dest_licenseplate!==undefined && errorsForm.aut_dest_licenseplate!=="")
                                        ?<span className={classes.thirdHeading}>  -  {t('Text_Field_Character_Sheet_Invalid')} !!</span>:""}
                                      </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ContractCreateOrEditAutDest
                                        formContract={formContract}
                                        setFormContract={setFormContract}
                                        showModalContractSettings={showModalContractSettings}
                                        setIsLoadingErrorData={setIsLoadingErrorData}
                                        errorsForm={errorsForm}
                                        setErrorsForm={setErrorsForm}
                                        validateFormContract={validateFormContract}
                                        documentTypesToSelect={documentTypesToSelect}
                                        roadTypesToSelect={roadTypesToSelect}
                                        communityToSelect={communityToSelect}
                                        countryToSelect={countryToSelect} 
                                        expanded={expanded}
                                        optTypeToSelect={optTypeToSelect}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              }  
                              <div className="row p-2"> 
                                <div className="form-group">
                                  <TableSortingCodeLERS 
                                    dataCodeLERS={formContract.codelers} 
                                    numTotRegCodeLERS={formContract.codelers.length}
                                    setOpenDeleteRowCodeLERS={setOpenDeleteRowCodeLERS}
                                    setOpenEditRowCodeLERS={setOpenEditRowCodeLERS}
                                    setOpenCreateEditRowCodeLERS={setOpenCreateEditRowCodeLERS}
                                    setEditRowCodeLER={setEditRowCodeLER} 
                                    LIMITPAGESIZE={LIMITPAGESIZE}
                                    LIMITCODELERSCONTRACT={LIMITCODELERSCONTRACT}
                                  />
                                  <ContractCreateCodeLER 
                                    openEditRowCodeLERS={openEditRowCodeLERS}
                                    setOpenEditRowCodeLERS={setOpenEditRowCodeLERS} 
                                    openCreateEditRowCodeLERS={openCreateEditRowCodeLERS} 
                                    editRowCodeLER={editRowCodeLER}
                                    formContract={formContract} 
                                    setFormContract={setFormContract}
                                  />
                                </div>
                              </div>
                          </div>

                          <Grid container spacing={4} justify="center">
                              <Grid item xs={4} sm={4} md={4} key={1}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={(formContract.signature_opt_tras!==undefined && formContract.signature_opt_tras!=="")?formContract.signature_opt_tras:image1}
                                    title={t("Text_Signature_Opt_Tras")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t( "Text_Signature_Opt_Tras")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(1)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(1)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} key={2}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={(formContract.signature_ori_tras!==undefined && formContract.signature_ori_tras!=="")?formContract.signature_ori_tras:image1}
                                    title={t("Text_Signature_Ori_Tras")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t("Text_Signature_Ori_Tras")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(2)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(2)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} key={3}>
                                <Card sx={{ width: 250 }}>
                                  <CardMedia
                                    sx={{ height: 150 }}
                                    image={formContract.signature_dest_tras!==undefined && formContract.signature_dest_tras!==""?formContract.signature_dest_tras:image1}
                                    title={t("Text_Signature_Dest_tras")}
                                  />
                                  <CardContent>
                                    <Typography style={{color:"#067330", fontSize: 14, textAlign:'center'}}>
                                      {t("Text_Signature_Dest_tras")}
                                    </Typography>
                                  </CardContent>
                                  <CardActions>
                                    <Button size="small" onClick={(e) => selectModalSignature(3)}>{t('Button_Sign')}</Button>
                                    <Button size="small" onClick={(e) => deleteSignature(3)}>{t('Button_Delete')}</Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                            </Grid>
                        </div>   
                      </div>

                      <div>
                        <Dialog open={openDeleteRowCodeLERS} onClose={handleCancelDeleteRowCodeLER}>
                          <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowCodeLER.codeler_code} - {i18n.language==='es'?editRowCodeLER.codeler_namees:editRowCodeLER.codeler_nameen} </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {t('Text_Line1')}<br/>
                              {t('Text_Line2')}
                            </DialogContentText>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="idCode"
                              label={t('Text_Label')}
                              type="text"
                              fullWidth
                              variant="standard"
                              error
                              defaultValue={idCodeDeleteCodeLER}
                              onChange={handleChangeDeleteRowCodeLER}/>
                          </DialogContent>
                          <DialogActions>
                            <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowCodeLER}>{t('Button_Cancel')}</Button>
                            <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowCodeLER}>{t('Button_Delete')}</Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                      <ContractShareEdit
                        openShareContract={openShareContract}
                        setOpenShareContract={setOpenShareContract} 
                        formContract={formContract}
                        setFormContract={setFormContract}  
                        handleCloseDataEditContract={handleCloseDataEditContract}
                        handleConfirmFetchDataEditContract={handleConfirmFetchDataEditContract}
                      /> 
                    </div>
                </div>
              </>:
                <LoadingNews/>
              }
            </form>
          </Box>
          <br/>
          <Dialog open={openSignOpt_Tras} onClose={handleCancelSignOptTras}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_Opt_Tras')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataOptTras=>setSignOptTrasCanvas(dataOptTras)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignOptTras}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignOptTras}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignOptTras}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openSignOri_Tras} onClose={handleCancelSignOriTras}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_Ori_Tras')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataDestTras=>setSignOriTrasCanvas(dataDestTras)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignOriTras}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignOriTras}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignOriTras}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openSignDest_Tras} onClose={handleCancelSignDestTras}>
            <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_Dest_tras')} </DialogTitle>
              <DialogContent>
                <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                    ref={dataRefund=>setSignDestTrasCanvas(dataRefund)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignDestTras}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignDestTras}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignDestTras}>{t('Button_Sign')}</Button>
              </DialogActions>
          </Dialog>
        </div>
      //* End Page Content *//
    ) :  null }
  </>
  )    
}

ContractEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalContractSettings: PropTypes.bool.isRequired
};

export default ContractEditOrCreate;