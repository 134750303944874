import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import Box from '@mui/material/Box';  
import Container from '@mui/material/Container'; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';  

const initialTreatmentType = [{
  "id": "", 
  "treatmenttype_id":"",
  "treatmenttype_code": "",
  "treatmenttype_namees": "",
  "treatmenttype_nameen": ""
}]; 

const RegisterBookSelectTreatmentTypes = ({showModalSearchTreatmentType,setShowModalSearchTreatmentType,setFormRegisterBook}) => {
  const {t,i18n} = useTranslation(['listDataRegisterBook']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [newTreatmentType,setNewTreatmentType]= useState(initialTreatmentType[0]);
  const [treatmentTypeToSelect,setTreatmentTypeToSelect]= useState(initialTreatmentType);
  const [errorsTreatmentType, setErrorsTreatmentType] = useState({}); 
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalSearchTreatmentType) {
        setNewTreatmentType(initialTreatmentType[0]);
        setErrorsTreatmentType({});
        setShowModalSearchTreatmentType(false); 
        setTreatmentTypeToSelect(initialTreatmentType);
      }
    },[
      setNewTreatmentType,
      setErrorsTreatmentType, 
      setTreatmentTypeToSelect,
      showModalSearchTreatmentType,
      setShowModalSearchTreatmentType]
  );

  const handleCloseNewTraetmentType = () => {
    setNewTreatmentType(initialTreatmentType[0]);
    setErrorsTreatmentType({}); 
    setTreatmentTypeToSelect(initialTreatmentType);
    setShowModalSearchTreatmentType(false); 
  };

  const handleChangeNewTreatmentType = (e) => {
    const { name, value } = e.target;
    setNewTreatmentType(newTreatmentType => ({
      ...newTreatmentType,
      [name]: value,
    }));   
  };
  
  const handleBlurNewTreatmentType = (e) => {
    handleChangeNewTreatmentType(e); 
  };
    
  const toggleDrawerNewTreatmentType  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewTraetmentType();
    }
  };
  
  const handleChangeSelectTreatmentType = (event) => { 
    if (event.target.value!==""){
      let idx=treatmentTypeToSelect.findIndex(((item) => item.treatmenttype_code === event.target.value));
      setNewTreatmentType(newTreatmentType => ({
          ...newTreatmentType,
          treatmenttype_id:treatmentTypeToSelect[idx].treatmenttype_id,
          treatmenttype_code:treatmentTypeToSelect[idx].treatmenttype_code,
          treatmenttype_namees:treatmentTypeToSelect[idx].treatmenttype_namees,
          treatmenttype_nameen:treatmentTypeToSelect[idx].treatmenttype_nameen
      }));
    }
  };

  const handleBlurSelectTreatmentType = (e) => {
    handleChangeSelectTreatmentType(e);
  }; 

  const handleSubmitNewTreatmentType = () => {   
    setFormRegisterBook(formRegisterBook => ({
      ...formRegisterBook,
      codetreatment:newTreatmentType.treatmenttype_code,
      nametreatment:newTreatmentType.treatmenttype_namees
    }))
    handleCloseNewTraetmentType();
   
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);


  useEffect(() => {
    let isMounted = true;

    const fetchDataTreatmentType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `register/treatmenttypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTreatmentTypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (showModalSearchTreatmentType){
      fetchDataTreatmentType(); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,showModalSearchTreatmentType]);


  return(
    <>
      {showModalSearchTreatmentType && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewTreatmentType(false)}
            onOpen={toggleDrawerNewTreatmentType(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_TREATMENTTYPE_SELECT')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewTraetmentType}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('PlaceHolder_TREATMENTTYPE')} :</label>
                    <div className="form-group">
                      <TextField
                          id="treatmenttype_code"
                          name="treatmenttype_code"
                          value={ (newTreatmentType.treatmenttype_code === undefined || newTreatmentType.treatmenttype_code ===null || newTreatmentType.treatmenttype_code ==="")?"":newTreatmentType.treatmenttype_code}
                          InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                          inputProps={{ style: {width: 750},  fontSize: '18px', maxLength: 100 }} 
                          variant="outlined"
                          onBlur={handleBlurSelectTreatmentType}
                          onChange={handleChangeSelectTreatmentType}
                          select
                          SelectProps={{
                            native: true,
                            value: (newTreatmentType.treatmenttype_code === undefined || newTreatmentType.treatmenttype_code ===null || newTreatmentType.treatmenttype_code ==="")?"": newTreatmentType.treatmenttype_code
                          }}>
                          <option key={0} value=""/>
                          {treatmentTypeToSelect.map((option) => (
                          <option key={option.treatmenttype_code} value={option.treatmenttype_code}>
                              {i18n.language==="es"?option.treatmenttype_code+" - "+option.treatmenttype_namees.substring(0,120):option.treatmenttype_code+" - "+option.treatmenttype_nameen.substring(0,120)}
                          </option>
                          ))}
                        </TextField>
                    </div>
                    <label className="text-primary">{t('Text_TREATMENTTYPE_CODE')} :</label>
                    <div className="form-group">
                      <TextField
                        id="treatmenttype_code" 
                        name="treatmenttype_code"
                        value={newTreatmentType.treatmenttype_code}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        inputProps={{ style: {width: 800},  fontSize: '18px', maxLength: 255 }}  
                        label={errorsTreatmentType.treatmenttype_code!=null?t(errorsTreatmentType.treatmenttype_code):t('Text_TREATMENTTYPE_CODE')}
                        placeholder={t('Text_TREATMENTTYPE_CODE')}
                        variant="standard"
                        onBlur={handleBlurNewTreatmentType}
                        onChange={handleChangeNewTreatmentType}
                        disabled/>
                    </div>
                    <label className="text-primary">{t('Text_TREATMENTTYPE_TITLEES')} :</label>
                    <div className="form-group">
                      <TextField
                        id="namees" 
                        name="namees"
                        value={newTreatmentType.treatmenttype_namees}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        inputProps={{ style: {width: 800},  fontSize: '18px', maxLength: 255 }} 
                        label={errorsTreatmentType.treatmenttype_namees!=null?t(errorsTreatmentType.treatmenttype_namees):t('Text_TREATMENTTYPE_TITLEES')}
                        placeholder={t('Text_TREATMENTTYPE_TITLEES')}
                        variant="standard"
                        onBlur={handleBlurNewTreatmentType}
                        onChange={handleChangeNewTreatmentType}
                        disabled/>
                    </div>

                    <label className="text-primary">{t('Text_TREATMENTTYPE_TITLEEN')} :</label>
                    <div className="form-group">
                      <TextField
                        id="nameen" 
                        name="nameen"
                        value={newTreatmentType.treatmenttype_nameen}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        inputProps={{ style: {width: 800},  fontSize: '18px', maxLength: 255 }}  
                        label={errorsTreatmentType.treatmenttype_nameen!=null?t(errorsTreatmentType.treatmenttype_nameen):t('Text_TREATMENTTYPE_TITLEEN')}
                        placeholder={t('Text_TREATMENTTYPE_TITLEEN')}
                        variant="standard"
                        onBlur={handleBlurNewTreatmentType}
                        onChange={handleChangeNewTreatmentType}
                        disabled/>
                    </div>
                   
      
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitNewTreatmentType}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleCloseNewTraetmentType}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

RegisterBookSelectTreatmentTypes.propTypes = {
  showModalSearchTreatmentType: PropTypes.bool.isRequired,
  setShowModalSearchTreatmentType: PropTypes.func.isRequired, 
  setFormRegisterBook: PropTypes.func.isRequired, 
};

export default RegisterBookSelectTreatmentTypes;