import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';  
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mui/material/Icon'; 
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'; 
import Paper from '@mui/material/Paper';  
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel'; 
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import TextField from '@mui/material/TextField';
import useTheme from '@mui/material/styles/useTheme';   
import visuallyHidden from '@mui/utils/visuallyHidden';
import { warningErrorAction } from "actions/ErrorActions";
import PropTypes from 'prop-types';

const headCellsBankAccount = [
  {
    id: 'indexrowbankAccount',
    numeric: false,
    disablePadding: false,
    label: 'Text_Row',
    canOrder:true,
  },
  {
    id: 'namebank',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_Name',
    canOrder:true,
  },
  {
    id: 'codbic',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_Swift',
    canOrder:true,
  },
  {
    id: 'iban',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_Iban',
    canOrder:true,
  },
  {
    id: 'entity',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_Entity',
    canOrder:false,
  },
  {
    id: 'office',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_Office',
    canOrder:false,
  },
  {
    id: 'cd',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_CD',
    canOrder:false,
  },
  {
    id: 'accountbank',
    numeric: false,
    disablePadding: false,
    label: 'Text_BankAccount_Number',
    canOrder:false,
  },
  {
    id: 'defaultaccountbank',
    numeric: false,
    disablePadding: false,
    label: 'Text_Text_BankData_Default',
    canOrder:false,
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,roles } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Modify_BankAccount'))&&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 16}}>
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        }
        {headCellsBankAccount.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
       
         {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Delete_BankAccount'))&&
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 16}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { checkedRow,numTotRegBankAccount, t, roles,LIMITBANKACCOUNTPERSTORE,setOpenEditNewBankAccount,setOpenEditRowBankAccount } = props;
    const dispatch = useDispatch();

    const handleAddNewBankAccount = () => {
      if (numTotRegBankAccount>=Number(LIMITBANKACCOUNTPERSTORE)){
        dispatch(warningErrorAction(t('Text_Store_BankAccount_Length')+" "+LIMITBANKACCOUNTPERSTORE));
      }else{
        setOpenEditNewBankAccount(false);
        setOpenEditRowBankAccount(true);
      }  
    };

    return (
      <Toolbar>
      <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 20}} id="tableTitle" component="div">
        {t('Text_Bank_Data')} &nbsp;&nbsp;
        {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_BankAccount'))&&
          <IconButton
            size="small"  
            style={{color:checkedRow?"#e74a3b":"#858796", fontSize: 16}}
            onClick={checkedRow?handleAddNewBankAccount:() => {}}>
              <Icon
                baseClassName="fas"
                className="fa-plus-circle"
                sx={{ color:checkedRow?"#4e73df":"#858796" }}
              />
          </IconButton>
        }
      </Typography>
      <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
        {t('Text_Num_Reg')} {(numTotRegBankAccount).toLocaleString('de-DE')} {t('Text_Of')}  {(numTotRegBankAccount).toLocaleString('de-DE')}
      </Typography>
    </Toolbar>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataStores']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
       {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingBankAccount =(props)=>{
  const { dataBankAccount,setFormStore,checkedRow,numTotRegBankAccount, setOpenDeleteRowBankAccount,setOpenEditNewBankAccount,setOpenEditRowBankAccount,setEditRowBankAccount,roles,LIMITPAGESIZE,LIMITBANKACCOUNTPERSTORE} = props;
  const {t} = useTranslation(['listDataStores']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataBankAccount.length) : 0);
  const [countData,setCountData] = useState(dataBankAccount.length);
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);

  useEffect(() => {
    if (dataBankAccount.length !== countData || dataBankAccount.length <= rowsPerPage) {
      setPage(0);
    }  
    setCountData(dataBankAccount.length);
    setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataBankAccount.length) : 0);
  }, [dataBankAccount, rowsPerPage, page, countData]);
  
  useEffect(() => {
    if (numTotRegBankAccount > LIMITPAGESIZE) {
      setActiveOffset(true);
      const ranges = [
        {
          value: 1,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        },
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegBankAccount; i += Number(LIMITPAGESIZE)) {
        ranges.push({
          value: (i / Number(LIMITPAGESIZE)) + 1,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(ranges);
    } else {
      setCurrencyOffset(1);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegBankAccount, LIMITPAGESIZE]);

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowBankAccount = (row) => {
    setEditRowBankAccount(row);
    setOpenDeleteRowBankAccount(true);
  };

  const openEditRowBankAccount = (row) => {
    setEditRowBankAccount(row);
    setOpenEditNewBankAccount(true);
    setOpenEditRowBankAccount(true);
  };

  const handleChangeDefaultBankAccount = (item) => {
    var i =  dataBankAccount.indexOf( item );
    dataBankAccount.map((item,index)=>{
      if ( index!==i ) {
        return item.defaultaccountbank=false
      }else{
        return item.defaultaccountbank=true
      }  
    })
    setFormStore(formStore => ({
       ...formStore,
    }))
   };

  const listItems= (
  stableSort(dataBankAccount, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keydataBankAccount) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataBankAccount}>
        {(roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Modify_BankAccount')))&&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:checkedRow?"#4e73df":"#858796", fontSize: 16}} onClick={checkedRow?()=>openEditRowBankAccount(row):() => {}}>
              <EditIcon style={{fontSize: 28}}/>
            </IconButton>
          </TableCell>
        }
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.indexrowbankaccount}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.namebank}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.codbic}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.iban}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.entity}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.office}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.cd}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:checkedRow?"#4e73df":"#858796", textDecorationLine:checkedRow?'none':'line-through', fontSize: 16}} >
            {row.accountbank}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.defaultaccountbank} 
            sx={{
              '& .MuiSvgIcon-root': { color:checkedRow?"#4e73df":"#858796",fontSize: 28 }, // Ajusta el tamaño del ícono
            }}
            disabled={!roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Default_BankAccount'))} onClick={checkedRow?()=>handleChangeDefaultBankAccount(row):() => {}}
          />
        </TableCell>
       
        {(roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Delete_BankAccount'))) &&
        <TableCell align="center">
          <IconButton  size="large"  style={{color:checkedRow?"#e74a3b":"#858796", fontSize: 14}} onClick={checkedRow?()=>openModalDeleteRowBankAccount(row):() => {}}>
            <DeleteIcon style={{fontSize: 28}}/>
          </IconButton>
        </TableCell>}
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
        <EnhancedTableToolbar checkedRow={checkedRow}  numTotRegBankAccount={numTotRegBankAccount} t={t} roles={roles} LIMITBANKACCOUNTPERSTORE={LIMITBANKACCOUNTPERSTORE} setOpenEditNewBankAccount={setOpenEditNewBankAccount} setOpenEditRowBankAccount={setOpenEditRowBankAccount}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '1550px',width: '100%' }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} roles={roles}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    colSpan={12}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingBankAccount.propTypes = {
  dataBankAccount: PropTypes.array.isRequired,
  setFormStore: PropTypes.func.isRequired,
  checkedRow: PropTypes.bool.isRequired,
  numTotRegBankAccount: PropTypes.number.isRequired,
  setOpenDeleteRowBankAccount: PropTypes.func.isRequired,
  setOpenEditNewBankAccount: PropTypes.func.isRequired,
  setOpenEditRowBankAccount: PropTypes.func.isRequired,
  setEditRowBankAccount: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired 
};

export default TableSortingBankAccount;