import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  documentIdFrom:"",
  documentIdTo:"",
  tradenameFrom:"",
  tradenameTo:"",
  nameFrom:"",
  nameTo:"",
  contacpersonFrom:"",
  contacpersonTo:"", 
  cnaeFrom:"",
  cnaeTo:"",
  accountingentryFrom:"",
  accountingentryTo:"",
  enabled:true,
  notenabled:true,
  vehicleStoreFrom:"",
  vehicleStoreTo:"",
  emailsFrom:"",
  emailsTo:"",
  phonesFrom:"",
  phonesTo:""
};

const filtersStoresSlice = createSlice({
  name: 'filtersStore',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersStoresSlice.actions;
export default filtersStoresSlice.reducer;
