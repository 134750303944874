import React, {useState, useEffect} from 'react'
import {useTranslation} from "react-i18next" 
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';  
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt'; 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel'; 
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter'
import useTheme from '@mui/material/styles/useTheme';
import visuallyHidden from '@mui/utils/visuallyHidden';
import PropTypes from 'prop-types'

const headCellsAddress = [
  {
    id: 'indexrowaddress',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'roadtype',
    numeric: false,
    disablePadding: false,
    label: 'Text_CONTRACT_RoadType',
    canOrder:false,
  },
  {
    id: 'street',
    numeric: false,
    disablePadding: false,
    label: 'Text_CONTRACT_Address',
    canOrder:true,
  },
  {
    id: 'postalcode',
    numeric: false,
    disablePadding: false,
    label: 'Text_CONTRACT_PostalCode',
    canOrder:true,
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {t('Button_Select')}
          </Typography>
        </TableCell>
        {headCellsAddress.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { numTotRegStoreAddresses, t,titleTable } = props; 
    return (
      <Toolbar>
      <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 20}} id="tableTitle" component="div">
        {titleTable} &nbsp;&nbsp;
      </Typography>
      <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
        {t('Text_Num_Reg')} {(numTotRegStoreAddresses).toLocaleString('de-DE')} {t('Text_Of')}  {(numTotRegStoreAddresses).toLocaleString('de-DE')}
      </Typography>
    </Toolbar>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataContracts']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingContractStoreAddresses =(props)=>{
  const { dataStoreAddresses,numTotRegStoreAddresses,titleTable,selectStores,setFormContract,handleCloseDataEditStoreAdresses} = props;
  const {t,i18n} = useTranslation(['listDataContracts']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataStoreAddresses.length) : 0);
  const [countData,setCountData] = useState(dataStoreAddresses.length);  

  useEffect(() => {
    if (dataStoreAddresses.length !== countData || dataStoreAddresses.length <= rowsPerPage) {
      setPage(0);
    }  
    setCountData(dataStoreAddresses.length);
    setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataStoreAddresses.length) : 0);
  }, [dataStoreAddresses, rowsPerPage, page, countData]);
   
  
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectDefaultAddress = (row) => {
    switch (selectStores) {
      case "Opt_Tras": 
        setFormContract(formFrom => ({
          ...formFrom,
          opt_tras_roadtype_id:row.roadtype_id,
          opt_tras_street:row.street,
          opt_tras_country_id:row.country_id,
          opt_tras_province_id:row.province_id,
          opt_tras_community_id:row.community_id,
          opt_tras_postalcode_id:row.postalcode_id,
          opt_tras_nima:row.nima_opt_tras,
          opt_tras_inscription:row.pygr_opt_tras,
          opt_tras_grnp:row.grnp_opt_tras,
          opt_tras_opt:row.opt_id_opt_tras
          
        }));
        break; 
      case "Opt_Tras_Fiscal": 
        setFormContract(formFrom => ({
          ...formFrom,
          opt_tras_roadtype_id_fiscal:row.roadtype_id,
          opt_tras_street_fiscal:row.street,
          opt_tras_country_id_fiscal:row.country_id,
          opt_tras_province_id_fiscal:row.province_id,
          opt_tras_community_id_fiscal:row.community_id,
          opt_tras_postalcode_id_fiscal:row.postalcode_id
          
        }));
        break;
      case "Ori_Tras": 
        setFormContract(formFrom => ({
          ...formFrom,
          ori_tras_roadtype_id:row.roadtype_id,
          ori_tras_street:row.street,
          ori_tras_country_id:row.country_id,
          ori_tras_province_id:row.province_id,
          ori_tras_community_id:row.community_id,
          ori_tras_postalcode_id:row.postalcode_id,
          ori_tras_nima:row.nima_ori_tras,
          ori_tras_inscription:row.pygr_ori_tras,
          ori_tras_grnp:row.grnp_ori_tras,
          ori_tras_opt:row.opt_id_ori_tras
        }));
        break;
      case "Ori_Tras_Fiscal": 
        setFormContract(formFrom => ({
          ...formFrom,
          ori_tras_roadtype_id_fiscal:row.roadtype_id,
          ori_tras_street_fiscal:row.street,
          ori_tras_country_id_fiscal:row.country_id,
          ori_tras_province_id_fiscal:row.province_id,
          ori_tras_community_id_fiscal:row.community_id,
          ori_tras_postalcode_id_fiscal:row.postalcode_id
          
        }));
        break;
      case "Aut_Tras": 
        setFormContract(formFrom => ({
          ...formFrom,
          aut_tras_roadtype_id:row.roadtype_id,
          aut_tras_street:row.street,
          aut_tras_country_id:row.country_id,
          aut_tras_province_id:row.province_id,
          aut_tras_community_id:row.community_id,
          aut_tras_postalcode_id:row.postalcode_id,
          aut_tras_nima:row.nima_aut_tras,
          aut_dest_inscription:row.pygr_aut_tras,
          aut_tras_grnp:row.grnp_aut_tras,
          aut_dest_opt:row.opt_id_aut_tras
        }));
        break;
      case "Dest_Tras": 
        setFormContract(formFrom => ({
          ...formFrom,
          dest_tras_roadtype_id:row.roadtype_id,
          dest_tras_street:row.street,
          dest_tras_country_id:row.country_id,
          dest_tras_province_id:row.province_id,
          dest_tras_community_id:row.community_id,
          dest_tras_postalcode_id:row.postalcode_id,
          dest_tras_nima:row.nima_dest_tras,
          dest_tras_inscription:row.pygr_dest_tras,
          dest_tras_grnp:row.grnp_dest_tras,
          dest_tras_opt:row.opt_id_dest_tras
        }));
        break;
      case "Aut_Dest": 
        setFormContract(formFrom => ({
          ...formFrom,
          aut_dest_roadtype_id:row.roadtype_id,
          aut_dest_street:row.street,
          aut_dest_country_id:row.country_id,
          aut_dest_province_id:row.province_id,
          aut_dest_community_id:row.community_id,
          aut_dest_postalcode_id:row.postalcode_id,
          aut_dest_nima:row.nima_aut_dest,
          aut_dest_inscription:row.pygr_aut_dest,
          aut_dest_grnp:row.grnp_aut_dest,
          aut_dest_opt:row.opt_id_aut_dest
        }));
        break;
      case "Rel_Trans": 
        setFormContract(formFrom => ({
          ...formFrom,
          rel_trans_roadtype_id:row.roadtype_id,
          rel_trans_street:row.street,
          rel_trans_country_id:row.country_id,
          rel_trans_province_id:row.province_id,
          rel_trans_community_id:row.community_id,
          rel_trans_postalcode_id:row.postalcode_id,
          rel_trans_nima:row.nima_rel_trans,
          rel_trans_inscription:row.pygr_rel_trans,
          rel_trans_grnp:row.grnp_rel_trans,
          rel_trans_opt:row.opt_id_rel_trans
        }));
        break;
      case "Resp_Prod": 
        setFormContract(formFrom => ({
          ...formFrom,
          resp_prod_roadtype_id:row.roadtype_id,
          resp_prod_street:row.street,
          resp_prod_country_id:row.country_id,
          resp_prod_province_id:row.province_id,
          resp_prod_community_id:row.community_id,
          resp_prod_postalcode_id:row.postalcode_id,
          resp_prod_nima:row.nima_resp_prod,
          resp_prod_inscription:row.pygr_resp_prod,
          resp_prod_grnp:row.grnp_resp_prod,
          resp_prod_opt:row.opt_id_resp_prod
        }));
        break;
      default:
        break;
    } 
    handleCloseDataEditStoreAdresses();
  };

  const listItems= (
  stableSort(dataStoreAddresses, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keydataStoreAddresses) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataStoreAddresses}>
        {(row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#4e73df", fontSize: 16}} onClick={()=>selectDefaultAddress(row)}>
              <PanToolAltIcon style={{fontSize: 28}}/>
            </IconButton>
          </TableCell>
        }
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {row.id}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:"#4e73df", fontSize: 16}} >
            {i18n.language==="es"?row.roadtype_namees:row.roadtype_nameen}
        </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {row.street}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {row.postalcode_postalcodename}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
        <EnhancedTableToolbar numTotRegStoreAddresses={numTotRegStoreAddresses} t={t} titleTable={titleTable}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10,15]}
                    colSpan={12}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingContractStoreAddresses.propTypes = { 
  dataStoreAddresses: PropTypes.array.isRequired, 
  numTotRegStoreAddresses: PropTypes.number.isRequired,
  titleTable: PropTypes.string.isRequired,
  selectStores : PropTypes.string.isRequired,
  setFormContract : PropTypes.func.isRequired,
  handleCloseDataEditStoreAdresses: PropTypes.func.isRequired
};

export default TableSortingContractStoreAddresses;