import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  codeFrom:"",
  codeTo:"",
  nameesFrom:"",
  nameesTo:"",
  nameenFrom:"",
  nameenTo:"",
  enabled:true,
  notenabled:true,
  typeinside:true,
  typeoutside:true,
  ticketweight:true,
  tickenotweight:true,
  typesoftaxFromES:"",
  typesoftaxToES:"",
  typesoftaxFromEN:"",
  typesoftaxToEN:"",
  codelerFrom:"",
  codelerTo:""  
};

const filtersArticlesSlice = createSlice({
  name: 'filtersArticles',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersArticlesSlice.actions;
export default filtersArticlesSlice.reducer;
