import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom: "",
  idTo: "",
  defaultStoreIdFrom: "",
  defaultStoreIdTo: "",
  useraccountIdFrom: "",
  useraccountIdTo: "",
  purchasenoteFrom: "",
  purchasenoteTo: "",
  datepurchaseFrom: null,
  datepurchaseTo: null,
  vehiclestore_licenseplateFrom: "",
  vehiclestore_licenseplateTo: "",
  ori_tras_workplaceFrom: "",
  ori_tras_workplaceTo: "",
  rel_trans_store_idFrom: "", 
  rel_trans_store_idTo: "", 
  ori_tras_store_idFrom: "", 
  ori_tras_store_idTo: "", 
  numberDIFROM: "",
  numberDITO: "",
  amountFROM:0,
  amountTO:0,
  paymentFromES: "",
  paymentToES: "",
  paymentFromEN: "",
  paymentToEN: "",
};

const filtersPurchaseDeliverySlice = createSlice({
  name: 'filtersPurchaseDelivery',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersPurchaseDeliverySlice.actions;
export default filtersPurchaseDeliverySlice.reducer;
